import { Field } from 'formik';
import React from 'react';

import { TextInput as StyledTextInput } from '../TextInput';

import { useFormFieldIsErrorShowing, useFormFieldName } from './FormField';
import { PropsWithoutField } from './utils';

type Props = PropsWithoutField<typeof StyledTextInput>;

const TextInput: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const name = useFormFieldName();
  const isErrorShowing = useFormFieldIsErrorShowing();

  return (
    <Field
      as={StyledTextInput}
      {...props}
      name={name}
      id={name}
      state={isErrorShowing ? 'error' : 'normal'}
    />
  );
};

export { TextInput };
