import { styled } from '../../stitches.config';

/**
 * Box component. Useful for styling elements with PicnicCss.
 * Use the polymorphic as prop to define what tag to render.
 */
export const Box = styled('div', {});
export type BoxProps = React.ComponentProps<typeof Box>;

Box.displayName = 'Box';
