import {
  parseRequestOptionsFromJSON,
  get,
  CredentialRequestOptionsJSON,
} from '@github/webauthn-json/browser-ponyfill';

import { AuthSession } from '@attentive/acore-utils';
import { AuthFlowStrategy, Environment } from '@attentive/data';

import { commitVerifyChallenge } from './commitVerifyChallengeMutation';

import { commitVerifyChallengeMutation$data } from './__generated__/commitVerifyChallengeMutation.graphql';

type ChallengeResponse = {
  companyId: string;
  companyInternalId: number;
  companyName: string;
};

const performChallenge = async (nonce: string) => {
  const parsedNonce = JSON.parse(nonce) as CredentialRequestOptionsJSON;
  const options = parseRequestOptionsFromJSON(parsedNonce);
  return await get(options);
};

const handleVerify = (
  publicKeyCredential: string,
  companyId: string,
  environment: Environment,
  handleError: () => void,
  handleComplete: (response: commitVerifyChallengeMutation$data) => void
) => {
  commitVerifyChallenge(
    environment,
    { input: { nonce: publicKeyCredential, companyId } },
    handleError,
    handleComplete
  );
};

export const handleChallenge = (
  nonce: string,
  companyId: string,
  relayEnvironment: Environment
) => {
  return new Promise<ChallengeResponse>((res, rej) => {
    const handleError = () => rej();
    const handleComplete = (response: commitVerifyChallengeMutation$data) => {
      if (!response.verifyChallenge) {
        rej();
        return;
      }
      if (
        response.verifyChallenge.__typename === 'VerifyChallengeSuccess' &&
        response.verifyChallenge.success
      ) {
        AuthSession.persistToken(AuthFlowStrategy.Internal, response.verifyChallenge.token);
        res({
          companyId: response.verifyChallenge.company.id,
          companyInternalId: response.verifyChallenge.company?.internalId,
          companyName: response.verifyChallenge.company.name,
        });
      } else {
        rej();
      }
    };

    performChallenge(nonce)
      .then((result) => {
        const publicKeyCredential = JSON.stringify(result);
        handleVerify(publicKeyCredential, companyId, relayEnvironment, handleError, handleComplete);
      })
      .catch(() => {
        rej();
      });
  });
};
