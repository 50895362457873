/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const GIF = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M15.296 10.436a4.86 4.86 0 1 1-9.72 0 4.86 4.86 0 0 1 9.72 0Zm-1.823 0-4.657 2.43v-4.86l4.657 2.43Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M2 18.76h3.24V22H22V5.24h-3.24V2H2v16.76Zm16.76 0H6.637v1.843h13.966V6.638H18.76V18.76ZM3.397 17.363h13.966V3.397H3.397v13.966Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(GIF);
export default Memo;
