// Radix's Switch, Checkbox and Radio expose an HTMLInputElement change event "onCheckedChange",
// we prefer to use "onChange" to match our other input components.
export type RadixButtonInputProps<T, V> = Omit<
  T,
  // Remove radix event handler.
  | 'onCheckedChange'
  // Remove onChange with FormEvent<HTMLButtonElement> argument from @react DOMAttributes.
  | 'onChange'
> & {
  onChange?: (checked: V) => void;
};
