/**
 * @generated SignedSource<<ccef1eca0e02dd0320cbe1028ca34839>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AuthorizeZendeskAppGenerateZendeskSSOTokenMutation$variables = Record<PropertyKey, never>;
export type AuthorizeZendeskAppGenerateZendeskSSOTokenMutation$data = {
  readonly generateZendeskJwt: {
    readonly jwt: string;
  } | null;
};
export type AuthorizeZendeskAppGenerateZendeskSSOTokenMutation = {
  response: AuthorizeZendeskAppGenerateZendeskSSOTokenMutation$data;
  variables: AuthorizeZendeskAppGenerateZendeskSSOTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "type": "ZENDESK_JWT_TYPE_SSO"
        }
      }
    ],
    "concreteType": "GenerateZendeskJwtPayload",
    "kind": "LinkedField",
    "name": "generateZendeskJwt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jwt",
        "storageKey": null
      }
    ],
    "storageKey": "generateZendeskJwt(input:{\"type\":\"ZENDESK_JWT_TYPE_SSO\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorizeZendeskAppGenerateZendeskSSOTokenMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthorizeZendeskAppGenerateZendeskSSOTokenMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a3c023e7e80346f1903a05e15016434f",
    "id": null,
    "metadata": {},
    "name": "AuthorizeZendeskAppGenerateZendeskSSOTokenMutation",
    "operationKind": "mutation",
    "text": "mutation AuthorizeZendeskAppGenerateZendeskSSOTokenMutation {\n  generateZendeskJwt(input: {type: ZENDESK_JWT_TYPE_SSO}) {\n    jwt\n  }\n}\n"
  }
};
})();

(node as any).hash = "3132d254cff7e73b371e4fae5ff76cad";

export default node;
