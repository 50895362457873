/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const TestTube = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m10.313 10.827.937-.38V4.5h1.5v5.947l.937.38a4.502 4.502 0 0 1 2.76 4.865H7.553a4.502 4.502 0 0 1 2.76-4.865Zm-4.274 4.865a6.002 6.002 0 0 1 3.71-6.256V3h4.501v6.436a6.002 6.002 0 0 1-3.089 11.506 6.003 6.003 0 0 1-5.122-5.25Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(TestTube);
export default Memo;
