import { Locale, LocaleFormatEntry } from '../constants/locale';
import { Timezone } from '../constants/date';

type TimezoneNameFormat = Record<Timezone, LocaleFormatEntry>;

export const TIMEZONE_NAMES: TimezoneNameFormat = {
  [Timezone.EST]: {
    default: 'Eastern Time (New York)',
    [Locale.enCA]: 'Eastern Time (Ottawa)',
  },
  [Timezone.CST]: {
    default: 'Central Time (Chicago)',
    [Locale.enCA]: 'Central Time (Winnipeg)',
  },
  [Timezone.MST]: {
    default: 'Mountain Time (Denver)',
    [Locale.enCA]: 'Mountain Time (Edmonton)',
  },
  [Timezone.PST]: {
    default: 'Pacific Time (Los Angeles)',
    [Locale.enCA]: 'Pacific Time (Vancouver)',
  },
  [Timezone.GMT]: {
    default: 'Greenwich Mean Time (London)',
    [Locale.enIE]: 'Greenwich Mean Time (Dublin)',
    [Locale.ptPT]: 'Greenwich Mean Time (Lisbon)',
    [Locale.esES]: 'Greenwich Mean Time (Canary Islands)',
  },
  [Timezone.NST]: {
    default: 'Newfoundland Time (Newfoundland)',
  },
  [Timezone.AST]: {
    default: 'Atlantic Time (Halifax)',
  },
  [Timezone.AWT]: {
    default: 'Australian Western Time (Perth)',
  },
  [Timezone.ACWT]: {
    default: 'Australian Central Western Time (Eucla)',
  },
  [Timezone.ACT]: {
    default: 'Australian Central Time (Darwin)',
  },
  [Timezone.AET]: {
    default: 'Australian Eastern Time (Sydney)',
  },
  [Timezone.AETB]: {
    default: 'Australian Eastern Time (Brisbane)',
  },
  [Timezone.LHT]: {
    default: 'Lord Howe Time (Lord Howe)',
  },
  [Timezone.CEST]: {
    default: 'Central European Time (Paris)',
    [Locale.itIT]: 'Central European Time (Rome)',
    [Locale.nlNL]: 'Central European Time (Amsterdam)',
    [Locale.esES]: 'Central European Time (Madrid)',
    [Locale.svSE]: 'Central European Time (Stockholm)',
    [Locale.deDE]: 'Central European Time (Berlin)',
  },
  [Timezone.AZOST]: {
    default: 'Azores Standard Time (Ponta Delgada)',
  },
  [Timezone.ACT1]: {
    default: 'Acre Time (Rio Branco)',
  },
  [Timezone.AMT]: {
    default: 'Amazon Time (Manaus)',
  },
  [Timezone.BRT]: {
    default: 'Brasilia Time (Sao_Paulo)',
  },
  [Timezone.FNT]: {
    default: 'Fernando de Noronha Time (Noronha)',
  },
  [Timezone.PT]: {
    default: 'Pacific Time (Tijuana)',
  },
  [Timezone.MT]: {
    default: 'Mountain Time (Hermosillo)',
  },
  [Timezone.CT]: {
    default: 'Central Time (Mexico City)',
  },
  [Timezone.ET]: {
    default: 'Eastern Time (Cancun)',
  },
  [Timezone.NZDT]: {
    default: 'New Zealand Time (Auckland)',
  },
};

export function getTimezoneName(timezone: Timezone, locale: Locale) {
  const timezoneNameMap = TIMEZONE_NAMES[timezone] || TIMEZONE_NAMES[Timezone.EST];

  // If there is not a locale override for the given timezone, use the default name
  return timezoneNameMap[locale] || timezoneNameMap.default;
}
