import { createStitches, CSS, VariantProps } from '@stitches/react';
import { useEffect } from 'react';

import { globalDatePickerCss as PU_datepickerGlobalCss } from './components/DatePicker/datepicker-global-css';
import { media } from './media';
import { theme2021 as theme2021Scales, themeDark as themeDarkScales } from './themes';
import utils from './utils';

const CLASS_PREFIX = 'picnic-';

const config = createStitches({
  prefix: CLASS_PREFIX,
  theme: theme2021Scales,
  media,
  utils,
});

const { styled, css, keyframes, globalCss, getCssText, createTheme } = config;

type PicnicCss = CSS<typeof config>;
type Theme = ReturnType<typeof createPicnicTheme>;

const createPicnicTheme = (className: string, theme: Parameters<typeof createTheme>[1]) => {
  return createTheme(`${CLASS_PREFIX}${className}`, theme);
};

enum ThemeName {
  Theme2021 = 'theme2021',
  ThemeDark = 'themeDark',
}

const theme2021 = createPicnicTheme(ThemeName.Theme2021, theme2021Scales);
const themeDark = createPicnicTheme(ThemeName.ThemeDark, themeDarkScales);

const Themes: { [key in ThemeName]: Theme } = {
  theme2021,
  themeDark,
};

const DEFAULT_THEME: Theme = Themes.theme2021;

const applyGlobalReset = globalCss({
  '*, *::before, *::after': { boxSizing: 'border-box' },
  'html, body, p': {
    padding: '0',
    margin: '0',
  },
  // Remove margin in Safari.
  'button, input, optgroup, select, textarea': {
    margin: '0',
  },
  'input, button, textarea, select': {
    fontFamily: 'inherit',
  },
  a: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...(PU_datepickerGlobalCss as any),
});

const themeResetStyles = css({
  fontFamily: '$body',
  backgroundColor: '$bgDefault',
  color: '$textDefault',
  letterSpacing: '$letterSpacing1',
  lineHeight: '$lineHeight2',

  'b, strong': {
    fontWeight: '$bold',
  },
});

const applyPicnicTheme = (theme: Theme) => {
  // Remove all picnic classes from body.
  Array.from(document.body.classList.entries())
    .map(([_, value]) => value)
    .filter((value) => value.startsWith(CLASS_PREFIX))
    .forEach((value) => document.body.classList.remove(value));

  document.body.classList.add(theme, themeResetStyles());
};

const usePicnicStyles = (theme = Themes.theme2021) => {
  useEffect(() => {
    applyGlobalReset();
  }, []);

  useEffect(() => {
    applyPicnicTheme(theme);
  }, [theme]);
};

export {
  styled,
  css,
  keyframes,
  globalCss,
  getCssText,
  applyGlobalReset,
  usePicnicStyles,
  createPicnicTheme as createTheme,
  DEFAULT_THEME,
  Themes,
  ThemeName,
};
export type { VariantProps, Theme, PicnicCss };
