import { useFormikContext } from 'formik';
import React from 'react';

import { Button as StyledButton } from '../Button';

type Props = React.ComponentProps<typeof StyledButton>;

const ResetButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { dirty: isDirty, isSubmitting } = useFormikContext();

  return (
    <StyledButton type="reset" variant="secondary" disabled={!isDirty || isSubmitting} {...props} />
  );
};

export { ResetButton };
