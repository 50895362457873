// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/.internal/castSlice.ts

import { slice } from './slice';

/**
 * Casts `array` to a slice if it's needed.
 */
export function castSlice(array: Array<any>, start: number, end?: number): Array<any> {
  const { length } = array;
  end = end === undefined ? length : end;
  return !start && end >= length ? array : slice(array, start, end);
}
