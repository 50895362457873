import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Text } from '@attentive/picnic';

import { ChallengeUIComponent } from './ChallengeUIComponent';

export const AdminChallenge = () => {
  return (
    <ChallengeUIComponent>
      <Text css={{ mb: '$space6' }}>Authentication is required before receiving admin access.</Text>
      <Link
        css={{ mb: '$space4' }}
        href="https://attentivemobile.atlassian.net/wiki/spaces/ISD/pages/3903553541/Attentive+UI+MFA"
      >
        Help
      </Link>
      <Link as={RouterLink} to="/home">
        Return to home
      </Link>
    </ChallengeUIComponent>
  );
};
