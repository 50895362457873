/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Keyboard = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.286 7.8H6v1.68H4.286V7.8ZM9.429 7.8H7.714v1.68H9.43V7.8ZM12.857 7.8h-1.714v1.68h1.714V7.8ZM16.286 7.8H14.57v1.68h1.715V7.8ZM19.714 7.8H18v1.68h1.714V7.8ZM19.714 11.16H18v1.68h1.714v-1.68ZM18 14.52h1.714v1.68H18v-1.68ZM14.571 11.16h1.715v1.68H14.57v-1.68ZM11.143 11.16h1.714v1.68h-1.714v-1.68ZM7.714 11.16H9.43v1.68H7.714v-1.68ZM16.286 14.52H7.714v1.68h8.572v-1.68ZM6 11.16H4.286v1.68H6v-1.68ZM4.286 14.52H6v1.68H4.286v-1.68Z" />
    <path
      fillRule="evenodd"
      d="M24 3.6H0v16.8h24V3.6ZM2.286 18.16V5.84h19.428v12.32H2.286Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Keyboard);
export default Memo;
