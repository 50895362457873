/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Coupon = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M4.5 3v18L6 22.5 7.5 21 9 22.5l1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5V3L18 1.5 16.5 3 15 1.5 13.5 3 12 1.5 10.5 3 9 1.5 7.5 3 6 1.5 4.5 3Zm3.375 5.7c0-.45.375-.825.825-.825.45 0 .825.375.825.825 0 .45-.375.825-.825.825a.832.832 0 0 1-.825-.825ZM8.7 6.375A2.337 2.337 0 0 0 6.375 8.7 2.337 2.337 0 0 0 8.7 11.025 2.337 2.337 0 0 0 11.025 8.7 2.337 2.337 0 0 0 8.7 6.375Zm5.775 8.925c0-.45.375-.825.825-.825.45 0 .825.375.825.825 0 .45-.375.825-.825.825a.832.832 0 0 1-.825-.825Zm.825-2.325a2.337 2.337 0 0 0-2.325 2.325 2.337 2.337 0 0 0 2.325 2.325 2.337 2.337 0 0 0 2.325-2.325 2.337 2.337 0 0 0-2.325-2.325ZM6.45 16.35l1.2 1.2 9.9-9.9-1.2-1.2-9.9 9.9Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Coupon);
export default Memo;
