// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/.internal/createCaseFirst.ts

import { castSlice } from './castSlice';
import { hasUnicode } from './hasUnicode';
import { stringToArray } from './stringToArray';

/**
 * Creates a function like `lowerFirst`.
 */
export function createCaseFirst(methodName: string) {
  return (string?: string) => {
    if (!string) {
      return '';
    }
    const strSymbols = hasUnicode(string) ? stringToArray(string) : undefined;
    const chr = strSymbols ? strSymbols[0] : string[0];
    const trailing = strSymbols ? castSlice(strSymbols, 1).join('') : string.slice(1);
    return chr[methodName]() + trailing;
  };
}
