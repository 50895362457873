import React from 'react';
import { PolymorphicComponent } from 'react-polymorphic-box';

import { styled } from '../../stitches.config';
import { Icon, IconName } from '../Icon';
import { IconColor } from '../Icon/StyledIconComponents';

export const IconButtonPrimitive = styled('button', {
  borderRadius: '$radius1',
  display: 'inline-flex',
  alignItems: 'center',
  border: '$borderWidths$borderWidth1 solid transparent',
  cursor: 'pointer',
  outline: 'none',
  appearance: 'none',
  width: '$size6',
  height: '$size6',
  color: 'inherit',
  padding: 0,

  '&:disabled': {
    cursor: 'not-allowed',
  },
  focusVisible: '$focus',

  variants: {
    variant: {
      basic: {
        color: '$textDefault',
        backgroundColor: '$bgActionBasic',
        borderColor: '$borderActionBasic',
        '&:hover:not([disabled])': {
          color: '$textDefault',
          borderColor: '$borderActionBasic',
        },
        '&:hover:not([disabled]):not(:active)': {
          backgroundColor: '$bgActionBasicHover',
        },
        '&:active:not([disabled])': {
          backgroundColor: '$bgActionBasicPressed',
        },
      },
      primary: {
        color: '$textDefault',
        backgroundColor: '$bgActionPrimary',
        '&:hover:enabled': {
          backgroundColor: '$bgActionPrimaryHover',
        },
        '&:active:enabled': {
          backgroundColor: '$bgActionPrimaryPressed',
        },
      },
      secondary: {
        color: '$textDefault',
        backgroundColor: '$bgActionSecondary',
        borderColor: '$bgActionSecondary',
        '&:hover:not([disabled])': {
          color: '$textDefault',
        },
        '&:hover:not([disabled]):not(:active)': {
          backgroundColor: '$bgActionSecondaryHover',
          borderColor: '$bgActionSecondaryHover',
        },
        '&:active:not([disabled])': {
          backgroundColor: '$bgActionSecondaryPressed',
          borderColor: '$bgActionSecondaryPressed',
        },
      },
      subdued: {
        color: '$textDefault',
        backgroundColor: 'transparent',
        '&:hover:enabled': {
          color: '$textHover',
          '& svg': {
            fill: 'currentColor',
          },
        },
        '&:active:enabled': {
          color: '$textPressed',
        },
      },
      inverted: {
        color: '$textInverted',
        backgroundColor: 'transparent',
        borderColor: '$bgDefault',
        '&:hover:not([disabled]):not(:active)': {
          color: '$textDefault',
          backgroundColor: '$borderInverted',
          borderColor: '$borderInverted',
        },
        '&:active:not([disabled])': {
          color: '$textDefault',
          backgroundColor: '$borderLoud',
          borderColor: '$borderLoud',
        },
      },
      'legacy-inverted': {
        color: '$textInverted',
        backgroundColor: '$bgInverted',
        borderColor: '$bgInverted',
        // TO-DO: Make inverted button better, more functional color oriented
        '&:hover:not([disabled]):not(:active)': {
          backgroundColor: '$grayscale1000',
          borderColor: '$grayscale1000',
        },
        '&:active:not([disabled])': {
          backgroundColor: '$grayscale1000',
          borderColor: '$grayscale1000',
        },
      },
    },
    disabledVisually: {
      true: {},
      false: {},
    },
    size: {
      extraSmall: {
        width: '$size5',
        height: '$size5',
      },
      small: {
        width: '$size9',
        height: '$size9',
      },
      medium: {
        width: '$size12',
        height: '$size12',
      },
      large: {
        width: '$size13',
        height: '$size13',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'basic',
      disabledVisually: true,
      css: {
        color: '$textDisabled',
        borderColor: '$borderActionBasicDisabled',
      },
    },
    {
      variant: 'primary',
      disabledVisually: true,
      css: {
        color: '$textDisabled',
        backgroundColor: '$bgActionPrimaryDisabled',
        borderColor: '$bgActionPrimaryDisabled',
      },
    },
    {
      variant: 'secondary',
      disabledVisually: true,
      css: {
        color: '$textDisabled',
        backgroundColor: '$bgActionSecondaryDisabled',
        borderColor: '$bgActionSecondaryDisabled',
      },
    },
    {
      variant: 'subdued',
      disabledVisually: true,
      css: {
        color: '$textDisabled',
      },
    },
    {
      variant: 'inverted',
      disabledVisually: true,
      css: {
        color: '$textSubdued',
        borderColor: '$textSubdued',
      },
    },
  ],
  defaultVariants: {
    disabledVisually: false,
    variant: 'subdued',
    size: 'medium',
  },
});

const StyledButtonIcon = styled(Icon, {
  display: 'block',
  margin: '0 auto',

  variants: {
    size: {
      // FIXME: The `!important`s here are necessary because of a stitches
      // style insertion issue.
      // See: https://github.com/modulz/stitches/issues/671
      extraSmall: {
        width: '$size4 !important',
        height: '$size4 !important',
      },
      small: {
        width: '$size5 !important',
        height: '$size5 !important',
      },
      medium: {
        width: '$size6 !important',
        height: '$size6 !important',
      },
      large: {
        width: '$size7 !important',
        height: '$size7 !important',
      },
    },
  },
});

export interface IconButtonProps extends React.ComponentProps<typeof IconButtonPrimitive> {
  iconName: IconName;
  iconColor?: IconColor;
  description: string;
}

const IconButton: PolymorphicComponent<IconButtonProps, 'button'> = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps
>((props, ref) => {
  const { disabled, iconName, iconColor, size = 'medium', description, children, ...rest } = props;

  return (
    <IconButtonPrimitive
      type="button"
      size={size}
      disabledVisually={disabled}
      disabled={disabled}
      ref={ref}
      {...rest}
    >
      <StyledButtonIcon
        name={iconName}
        color={iconColor}
        size={size}
        mode="presentational"
        description={description}
      />
      {children}
    </IconButtonPrimitive>
  );
});

// react-polymorphic-box has an issue with displayName prop type.
// https://github.com/kripod/react-polymorphic-box/issues/22
// @ts-ignore
IconButton.displayName = 'IconButton';

export { IconButton };
