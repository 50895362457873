import { Role } from '@attentive/data';

export const credentialsConnectionMock = {
  connectionName: 'credentials',
  connectionType: 'CREDENTIALS',
  connectionId: null,
};

export const samlConnectionMock = {
  connectionName: 'sso-provider',
  connectionType: 'SAML',
  connectionId: null,
};

export const googleConnectionMock = {
  connectionName: 'google',
  connectionType: 'GOOGLE',
  connectionId: 'client-id',
};

export const authUserPayloadMock = {
  id: 1,
  firstName: 'Robby',
  lastName: 'Rest',
  email: 'robby.rest@example.org',
  superUser: false,
  companies: [
    {
      id: 1,
      name: 'Company 1',
      displayName: 'Company 1',
    },
    {
      id: 2,
      name: 'Company 2',
      displayName: 'Company 2',
    },
    {
      id: 3,
      name: 'Company 3',
      displayName: 'Company 3',
    },
    {
      id: 4,
      name: 'Company 4',
      displayName: 'Company 4',
    },
    {
      id: 5,
      name: 'Company 5',
      displayName: 'Company 5',
    },
  ],
  company: {
    id: 5,
    name: 'Siren Denim 1',
    displayName: 'Siren Denim',
    domain: 'www.sirendenim.attentivemobile.com',
    attentiveDomain: 'siren.dev.attn.tv',
    status: 'ACTIVE',
    country: 'US',
    confirmOptIn: 'Reply Y to opt in to text alerts from {company}.',
    timezone: 'America/New_York',
    businessHours: {
      open: '09:00',
      close: '23:00',
      timezone: 'America/New_York',
    },
    nonAttentiveMessageLinksAllowed: true,
    viewThroughAttributionWindow: 30,
    clickThroughAttributionWindow: 1,
    rateLimit: 200,
    mmsRateLimit: 50,
    replyWhenOptedOut: true,
  },
  department: 'Department',
  phone: '+123456789',
  created: '2020-08-10T19:25:01Z',
  deleted: null,
  hasSignature: false,
  signature: '',
  roles: [Role.RoleClientAccount],
  role: 'OWNER',
};
