// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/.internal/stringToArray.ts

import { asciiToArray } from './asciiToArray';
import { hasUnicode } from './hasUnicode';
import { unicodeToArray } from './unicodeToArray';

/**
 * Converts `string` to an array.
 *
 * @private
 * @param {string} string The string to convert.
 * @returns {Array} Returns the converted array.
 */
export function stringToArray(string: string): Array<any> {
  return hasUnicode(string) ? unicodeToArray(string) : asciiToArray(string);
}
