import React from 'react';

import { PicnicCss } from '../../stitches.config';
import { PicnicSpaceToken } from '../../themes';
import { Box, BoxProps } from '../Box';

const child = '> *';
const childWithSpacing = '> * + *';

export type StackableHtmlElements =
  | 'div'
  | 'section'
  | 'article'
  | 'figcaption'
  | 'header'
  | 'footer'
  | 'form'
  | 'hgroup'
  | 'nav';

interface StackProps extends BoxProps {
  spacing?: PicnicSpaceToken;
  direction?: 'vertical' | 'horizontal';
  as?: StackableHtmlElements;
}

const Stack: React.FC<React.PropsWithChildren<StackProps>> = ({
  children,
  spacing = '$space$space4',
  direction = 'vertical',
  css,
  as = 'div',
  ...props
}) => {
  // NOTE: we remove `gap` from CSS since it doesn't work w/ Safari
  const { gap: _gap, ...restOfCSS } = css || {};

  const cssObj: PicnicCss = {
    display: 'flex',
    alignItems: 'flex-start',

    [child]: {
      margin: 0,
    },

    ...(direction === 'vertical'
      ? {
          flexDirection: 'column',
          [childWithSpacing]: { marginTop: spacing },
        }
      : {
          flexDirection: 'row',
          [childWithSpacing]: { marginLeft: spacing },
        }),

    ...restOfCSS,
  };

  return (
    <Box css={cssObj} as={as} {...props}>
      {children}
    </Box>
  );
};

export type { StackProps };
export { Stack };
