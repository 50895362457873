/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Segments = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M3.749 3h9v1.5c-4.125 0-7.5 3.374-7.5 7.5 0 4.124 3.375 7.5 7.5 7.5V21h-9V3Zm9 16.5v-15c4.125 0 7.5 3.374 7.5 7.5 0 4.124-3.375 7.5-7.5 7.5Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Segments);
export default Memo;
