/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const EyeStrikethrough = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.25 10.125c-.3-.225-.6-.525-.9-.75l-3.3 3.3a4.146 4.146 0 0 1-3.375 3.375l-1.8 1.8c.3.075.6.075.975.075h.3c3 0 5.325-1.875 8.1-4.2.75-.6 1.425-1.2 2.25-1.8-.825-.6-1.575-1.2-2.25-1.8ZM21 4.575 19.425 3 15.6 6.825C14.475 6.3 13.425 6 12.15 6h-.3c-3 0-5.325 1.875-8.1 4.2-.75.6-1.425 1.2-2.25 1.8.825.6 1.5 1.2 2.25 1.8.975.75 1.875 1.5 2.775 2.1L3 19.425 4.575 21 21 4.575ZM7.95 12c0-2.25 1.875-4.125 4.125-4.125.75 0 1.425.15 1.95.525l-1.5 1.5c-.15-.15-.3-.15-.525-.15-1.275 0-2.25.975-2.25 2.25 0 .225 0 .375.075.6L8.4 14.025c-.3-.6-.45-1.275-.45-2.025Z" />
  </svg>
);

const Memo = memo(EyeStrikethrough);
export default Memo;
