import { useMediaQuery } from '@react-hook/media-query';
import React from 'react';

import { media } from '../../media';
import { styled, VariantProps } from '../../stitches.config';
import { PicnicSpaceToken } from '../../themes';
import { BoxProps } from '../Box';

const PADDING_LOOSE = '$space12';
const PADDING_TIGHT = '$space6';

export const PageGutters = styled('div', {
  variants: {
    variant: {
      tight: { px: PADDING_TIGHT },
      loose: { px: PADDING_LOOSE },
    },
  },
  defaultVariants: {
    variant: 'tight',
  },
});

export const usePageGuttersPaddingValue = (): PicnicSpaceToken => {
  const atBp2 = useMediaQuery(media.bp2);

  if (atBp2) {
    return PADDING_LOOSE;
  }

  return PADDING_TIGHT;
};

export const usePageGuttersVariant = (): VariantProps<typeof PageGutters>['variant'] => {
  const atBp2 = useMediaQuery(media.bp2);

  if (atBp2) {
    return 'loose';
  }

  return 'tight';
};

export const ResponsivePageGutters: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  const variant = usePageGuttersVariant();

  return <PageGutters variant={variant} {...props} />;
};

export const PageLayout: React.FC<React.PropsWithChildren<BoxProps>> = ({ css, ...rest }) => {
  return (
    <ResponsivePageGutters
      css={{
        height: '100%',
        overflow: 'auto',
        paddingTop: '$space8',
        ...css,
      }}
      {...rest}
    />
  );
};
