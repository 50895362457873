import useResizeObserver from '@react-hook/resize-observer';
import React, { useState } from 'react';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook';
import { Box, BoxProps } from '../Box';
import { TextArea } from '../TextArea';
import { TextInput } from '../TextInput/TextInput';

import { InlineButton } from './InlineButton';

const ContainedElement = styled('span', {
  position: 'absolute',
  top: '0px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const LeftElement = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ css, children, ...props }, ref) => {
    return (
      <ContainedElement
        css={{
          left: '0px',
          pl: '$space3',
          pr: '$space2',
          ...css,
        }}
        ref={ref}
        {...props}
      >
        {children}
      </ContainedElement>
    );
  }
);

const RightElement = React.forwardRef<HTMLDivElement, BoxProps>(
  ({ css, children, ...props }, ref) => {
    return (
      <ContainedElement
        css={{
          right: '0px',
          pl: '$space2',
          pr: '$space3',
          ...css,
        }}
        ref={ref}
        {...props}
      >
        {children}
      </ContainedElement>
    );
  }
);

const InputGroupComponent = React.forwardRef<HTMLInputElement, BoxProps>(
  ({ css, children, ...props }, ref) => {
    let leftElement: React.ReactElement | null = null;
    let rightElement: React.ReactElement | null = null;
    let inputElement: React.ReactElement | null = null;

    const [leftElementRef, setLeftElementRef] = React.useState<Element>();
    const [rightElementRef, setRightElementRef] = React.useState<Element>();

    const [leftElementWidth, setLeftElementWidth] = useState<number | undefined>();
    const [rightElementWidth, setRightElementWidth] = useState<number | undefined>();

    const setLeftElementSize = (node: Element) => {
      if (node !== null) {
        const { clientWidth } = node;
        // small threshold to prevent infinite loops
        if (leftElementWidth === undefined || Math.abs(clientWidth - leftElementWidth) > 2) {
          setLeftElementWidth(clientWidth);
        }
      }
    };

    const setRightElementSize = (node: Element) => {
      if (node !== null) {
        const { clientWidth } = node;
        // small threshold to prevent infinite loops
        if (rightElementWidth === undefined || Math.abs(clientWidth - rightElementWidth) > 2) {
          setRightElementWidth(clientWidth);
        }
      }
    };

    useResizeObserver(leftElementRef as HTMLElement, (entry) => {
      setLeftElementSize(entry.target);
    });
    useResizeObserver(rightElementRef as HTMLElement, (entry) => {
      setRightElementSize(entry.target);
    });

    React.Children.forEach(children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case LeftElement:
          leftElement = React.cloneElement(child, {
            ref: (node: Element) => {
              setLeftElementRef(node);
              // Set size as soon as we have access to the DOM node to avoid a wiggle.
              setLeftElementSize(node);
            },
          });
          break;
        case RightElement:
          rightElement = React.cloneElement(child, {
            ref: (node: Element) => {
              setRightElementRef(node);
              // Set size as soon as we have access to the DOM node to avoid a wiggle.
              setRightElementSize(node);
            },
          });
          break;
        case TextInput:
        case TextArea:
          inputElement = React.cloneElement(child, {
            css: {
              ...(leftElementWidth ? { paddingLeft: leftElementWidth } : {}),
              ...(rightElementWidth ? { paddingRight: rightElementWidth } : {}),
              ...(child.props.css || {}),
            },
          });
          break;
        default:
      }
    });

    return (
      <Box
        css={{
          position: 'relative',
          ...css,
        }}
        {...props}
        ref={ref}
      >
        {leftElement}
        {inputElement}
        {rightElement}
      </Box>
    );
  }
);

type ComponentType = typeof InputGroupComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  LeftElement: typeof LeftElement & DisplayNamed;
  RightElement: typeof RightElement & DisplayNamed;
  InlineButton: typeof InlineButton & DisplayNamed;
}

const InputGroup = InputGroupComponent as CompositeComponent;
InputGroup.LeftElement = LeftElement;
InputGroup.RightElement = RightElement;
InputGroup.InlineButton = InlineButton;

InputGroup.displayName = 'InputGroup';
InputGroup.LeftElement.displayName = 'InputGroup.LeftElement';
InputGroup.RightElement.displayName = 'InputGroup.RightElement';
InputGroup.InlineButton.displayName = 'InputGroup.InlineButton';

export { InputGroup };
