/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Flag = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.5 13.267c-.583 0-1.08.042-1.5.109V22H5V2.867S5.875 2 8.5 2c1.313 0 2.406.433 3.5.867 1.094.433 2.188.866 3.5.866 2.625 0 3.5-.866 3.5-.866v11.266s-.875.867-3.5.867c-1.313 0-2.406-.433-3.5-.867-1.094-.433-2.188-.866-3.5-.866Z" />
  </svg>
);

const Memo = memo(Flag);
export default Memo;
