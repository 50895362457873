import * as SwitchPrimitive from '@radix-ui/react-switch';
import React from 'react';

import { styled } from '../../stitches.config';
import { RadixButtonInputProps } from '../../utils';
import { Box } from '../Box';
import { FormField } from '../FormField';
import { Icon } from '../Icon';

const SwitchIcon = styled(Icon, {
  // TO-DO: Could this switch icon sizes between 'Checkmark' and 'XHeavy,' as the Checkmark looks a little small (should be $size4 or extraSmall). $size3 is a one-off for the 'XHeavy' icon in the switch. that we don't support in our new icon sizes
  // FIXME: The `!important`s here are necessary because of a stitches
  // style insertion issue.
  // See: https://github.com/modulz/stitches/issues/671
  width: '$size3 !important',
  height: '$size3 !important',
  margin: 'auto',
  pointerEvents: 'none',
});

const StyledSwitch = styled(SwitchPrimitive.Root, {
  position: 'relative',
  appearance: 'none',
  cursor: 'pointer',
  width: '$size10',
  height: '$size6',
  padding: '$space0',
  borderRadius: '$radiusMax',
  backgroundColor: '$bgActionSecondary',
  border: '$borderWidths$borderWidth1 solid $borderInput',
  flexShrink: 0,

  '&:disabled': {
    cursor: 'not-allowed',

    [`& ${SwitchIcon}`]: {
      color: '$iconDisabled',
    },
  },

  '&:hover:enabled': {
    // TO-DO: Figure out better structure for toggles, e.g. toggleHover
    backgroundColor: '$bgActionSecondaryHover',
  },

  focusVisible: '$focus',

  '&[data-state="checked"]:enabled, &[data-state="checked"]:enabled:hover': {
    backgroundColor: '$bgToggleSelected',

    [`& ${SwitchIcon}`]: {
      color: '$bgToggleSelected',
      // TO-DO: Switch to iconToggleSelected later
    },
  },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: 'flex',
  width: '$size6',
  height: '$size6',
  // Adjust Thumb margin to fits inside Root component exactly on the edge. Tied to the border-width of the component.
  marginLeft: '-1px',
  marginTop: '-1px',
  backgroundColor: '$bgDefault',
  borderRadius: '$radiusMax',
  border: '$borderWidths$borderWidth1 solid $borderInput',

  defaultTransition: ['transform'],
  transform: 'translateX($sizes$size0)',
  '&[data-state="checked"]': {
    transform: 'translateX($sizes$size4)',
  },
});

type SwitchProps = RadixButtonInputProps<React.ComponentProps<typeof StyledSwitch>, boolean>;

const SwitchItem: React.VFC<SwitchProps> = ({ checked, onChange, ...rest }) => (
  <StyledSwitch
    checked={checked}
    onCheckedChange={(c: boolean) => {
      onChange?.(c);
    }}
    {...rest}
  >
    <StyledThumb>
      <SwitchIcon name={checked ? 'Checkmark' : 'Delete'} />
    </StyledThumb>
  </StyledSwitch>
);

const SwitchComponent: React.FC<React.PropsWithChildren<SwitchProps>> = ({
  disabled,
  css,
  children,
  ...rest
}) => {
  return (
    <FormField.Label
      disabled={disabled}
      css={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        fontWeight: '$regular',
        ...css,
      }}
    >
      <SwitchItem disabled={disabled} {...rest} />
      <Box as="span" css={{ ml: '$space2' }}>
        {children}
      </Box>
    </FormField.Label>
  );
};

type ComponentType = typeof SwitchComponent;
interface CompositeComponent extends ComponentType {
  SwitchItem: typeof SwitchItem;
}

const Switch = SwitchComponent as CompositeComponent;
Switch.SwitchItem = SwitchItem;

Switch.displayName = 'Switch';
Switch.SwitchItem.displayName = 'Switch.SwitchItem';

export type { SwitchProps };
export { Switch };
