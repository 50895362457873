import React, { ComponentProps, FC } from 'react';

import { Icon, InputGroup, TextInput } from '..';
import { PicnicCss } from '../../stitches.config';
import { IconButton } from '../Button';

interface SearchBarProps {
  onChange: (value: string) => void;
  value?: string;
  size?: ComponentProps<typeof TextInput>['size'];
  css?: PicnicCss;
  placeholder?: string;
}

export const SearchBar: FC<React.PropsWithChildren<SearchBarProps>> = ({
  onChange,
  value,
  size,
  css,
  placeholder = 'Search',
}) => {
  return (
    <InputGroup css={{ ...css }}>
      <TextInput
        size={size}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        aria-label={placeholder}
      />
      <InputGroup.LeftElement>
        <Icon name="MagnifyingGlass" size="small" />
      </InputGroup.LeftElement>
      <InputGroup.RightElement>
        <IconButton
          iconName="Delete"
          size="small"
          description="clear"
          onClick={() => onChange('')}
        />
      </InputGroup.RightElement>
    </InputGroup>
  );
};
