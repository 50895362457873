/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Unlink = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.5 1.5h-2v4h2v-4ZM1.5 6.5v2h4v-2h-4ZM18.913 8.757l-3.094 3.094 1.65 1.65 3.093-3.094a4.926 4.926 0 0 0-.006-6.963 4.926 4.926 0 0 0-6.963-.006L10.5 6.53l1.65 1.65 3.093-3.094a2.594 2.594 0 0 1 3.664.006 2.594 2.594 0 0 1 .006 3.664ZM5.088 15.243 8.18 12.15l-1.65-1.65-3.093 3.094a4.926 4.926 0 0 0 .006 6.962 4.926 4.926 0 0 0 6.963.006L13.5 17.47l-1.65-1.65-3.093 3.094a2.593 2.593 0 0 1-3.663-.006 2.594 2.594 0 0 1-.006-3.664ZM18 23h-2v-4h2v4ZM23 16v2h-4v-2h4Z" />
  </svg>
);

const Memo = memo(Unlink);
export default Memo;
