/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Checkmark = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M9.564 18.3 20.4 7.464 18.636 5.7l-9.072 9.072-4.2-4.2L3.6 12.336 9.564 18.3Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Checkmark);
export default Memo;
