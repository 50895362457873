/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Lock = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M1.5 9.5h21v13l-19.337-.023H1.5V9.5ZM12 18.993a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      clipRule="evenodd"
    />
    <path d="M16.004 7.493a4.001 4.001 0 0 0-8.003 0L8 10H6V7.493a6.002 6.002 0 0 1 12.005 0H18V10h-2V7.493h.004Z" />
  </svg>
);

const Memo = memo(Lock);
export default Memo;
