import React, { forwardRef, useCallback, useEffect } from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { IconButton } from '../Button';
import { LoadingIndicator } from '../LoadingIndicator';

const ImagePreviewStyled = styled('div', {
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$radius1',
  cursor: 'pointer',
});

const ImageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  borderRadius: '$radius1',
  backgroundColor: '$bgDefault',
  border: '$borderWidths$borderWidth1 solid $borderDefault',

  variants: {
    size: {
      small: {
        width: '$size6',
        height: '$size6',
      },
      medium: {
        width: '$size8',
        height: '$size8',
      },
      large: {
        width: '$size12',
        height: '$size12',
      },
    },
  },

  defaultVariants: {
    size: 'large',
  },
});

const ImageStyled = styled('img', {
  flex: 1,
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export interface ImagePreviewProps {
  src: string | null;
  altText: string;
  css?: PicnicCss;
  size?: 'small' | 'medium' | 'large';
  onRemove?: () => void;
}

export const ImagePreview = forwardRef<HTMLDivElement, ImagePreviewProps>(
  ({ size, altText, src, onRemove, css }, ref) => {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const onImageLoad = useCallback(() => {
      setLoading(false);
      setError(false);
    }, []);
    const onImageError = useCallback(() => {
      setLoading(false);
      setError(true);
    }, []);

    useEffect(() => {
      setLoading(true);
      setError(false);

      if (src === null) {
        return;
      }

      const img = new Image();
      img.src = src;
      img.addEventListener('load', onImageLoad);
      img.addEventListener('error', onImageError);

      if (img.complete) {
        setLoading(false);
      }

      return () => {
        img?.removeEventListener?.('load', onImageLoad);
        img?.removeEventListener?.('error', onImageError);
      };
    }, [src, onImageLoad, onImageError]);

    return (
      <ImagePreviewStyled css={css} ref={ref}>
        <ImageContainer size={size} data-testid={error ? 'image-preview-error' : ''}>
          {loading && <LoadingIndicator />}
          {!loading && !error && src && <ImageStyled alt={altText} src={src} />}
        </ImageContainer>
        {onRemove && (
          <IconButton
            iconName="Delete"
            size={size === 'large' ? 'medium' : 'small'}
            variant="basic"
            disabled={loading}
            description="Remove image"
            data-image-preview="remove-cover"
            css={{ ml: '$space2' }}
            onClick={onRemove}
          />
        )}
      </ImagePreviewStyled>
    );
  }
);
