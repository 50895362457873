/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Total = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M6.938 3h2.25v3.938h5.624V3h2.25v3.938H21v2.25h-3.938v5.624H21v2.25h-3.938V21h-2.25v-3.938H9.189V21h-2.25v-3.938H3v-2.25h3.938V9.189H3v-2.25h3.938V3Zm2.25 11.813h5.624V9.186H9.189v5.626Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Total);
export default Memo;
