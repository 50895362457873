import React from 'react';

import { IconComponent, IconColor } from './StyledIconComponents';
import { iconSet, IconName, IconSvgProps } from './icon-set';

export interface IconPrimitiveProps extends React.ComponentProps<typeof IconComponent> {
  name: IconName;
  color?: IconColor;
}

export const IconPrimitive = ({
  name,
  color = 'inherit',
  css,
  ...props
}: IconPrimitiveProps & IconSvgProps) => (
  <IconComponent
    // eslint-disable-next-line import/namespace
    as={iconSet[name]}
    color={color}
    css={css}
    {...props}
  />
);
