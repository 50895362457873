// NOTE: This is the allowlist of public interface exported from @attentive/acore-utils.
// Do not make a breaking change here!

export {
  initDatadog,
  addDatadogContext,
  setDatadogUser,
  logDatadogError,
  DatadogLogger,
  AttentiveError,
  NetworkError,
  ServerError,
  LibError,
  UiError,
  VendorError,
  StandardMutationError,
  useLibLogger,
  useProjectLogger,
  useSyncDatadogUserFragmentData,
  Severity,
  enableSessionReplay,
} from './Logger';
export type { ProjectNames } from './Logger';
export { ProjectNameProvider, useProjectName } from './ProjectNameContext';
