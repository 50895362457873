/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const CircleInformation = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 20.994a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0-11.552a1.252 1.252 0 1 1 0-2.505 1.252 1.252 0 0 1 0 2.505Zm-1.125 1.499h2.25v6.111h-2.25v-6.111Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(CircleInformation);
export default Memo;
