import { commitLocalUpdate } from 'react-relay';
import { Network, Store, RecordSource, Environment } from 'relay-runtime';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createMockEnvironment } from 'relay-test-utils';

import { ClientUIAppStatus, ClientSiteAppStatus } from './client-ui-site-data';
import {
  createSite,
  createSiteRecord,
  createSiteRecords,
  setSiteRecordProperties,
  siteDataId,
} from './client-schema-extensions';
import { ApiDataSource } from './api-data-source';
import { fetchUsingRelay, GraphQLOptions } from './graphql-fetch';

export const relayCompanyTransitionDataId = 'client:root:companyTransition';
export const relayAppTeardownTransactionDataId = 'client:root:appTeardownTransaction';
export const relayUserAuthDataId = 'client:root:userAuth';
export const relayAppStatusDataId = 'client:root:appStatus';
export const relayFeatureFlagsDataId = 'client:root:featureFlags';

export type { Environment };

export enum ToastType {
  Success = 'success',
  Error = 'error',
  Syncing = 'syncing',
}

export type Toast = {
  type: ToastType | null;
  title: string | null;
  text: string;
};
export enum AuthFlowStrategy {
  Internal = 'internal',
  Mock = 'mock',
}

export interface RelayClientData {
  transitionCompanyId: string | null;
  appTeardownReasonMessage: string | null;
  userAuthState: 'unknown' | 'authenticated' | 'unauthenticated';
  appStatus?: ClientUIAppStatus;
  toasts?: Toast[];
  legacyDegradationWarningMessage?: string | null;
}

// NOTE: this constants is duplicated in libs/acore-utils/src/AuthSession/AuthSession.ts otherwise
// there is a circular dependency
const MOCK_AUTH_TOKEN_STORAGE_KEY = 'mock-auth-token';

export const createRelayClientExtensions = (
  relayEnvironment: Environment,
  clientData: RelayClientData
) => {
  commitLocalUpdate(relayEnvironment, (relayStore) => {
    createSite(relayStore);

    const degradationWarningMessage = {
      degradationWarningMessage: clientData.legacyDegradationWarningMessage || null,
    };
    setSiteRecordProperties(relayStore, siteDataId, degradationWarningMessage);

    createSiteRecords(relayStore, 'Toast', 'toasts', clientData.toasts || []);

    const companyTransition = {
      companyId: clientData.transitionCompanyId,
    };
    createSiteRecord(
      relayStore,
      relayCompanyTransitionDataId,
      'CompanyTransition',
      'companyTransition',
      companyTransition
    );
    const appTeardownTransaction = {
      reasonMessage: clientData.appTeardownReasonMessage,
    };
    createSiteRecord(
      relayStore,
      relayAppTeardownTransactionDataId,
      'AppTeardownTransaction',
      'appTeardownTransaction',
      appTeardownTransaction
    );

    const authState = { userAuthStatus: clientData.userAuthState };
    createSiteRecord(relayStore, relayUserAuthDataId, 'AuthStatus', 'authStatus', authState);

    const appStatus: ClientSiteAppStatus = {
      state: clientData.appStatus || ClientUIAppStatus.ACTIVE,
      errorCode: null,
      errorMessage: null,
    };
    createSiteRecord(relayStore, relayAppStatusDataId, 'AppStatus', 'appStatus', appStatus);
  });
};

const defaultClientData: RelayClientData = {
  transitionCompanyId: null,
  appTeardownReasonMessage: null,
  userAuthState: 'unknown',
  appStatus: ClientUIAppStatus.ACTIVE,
  toasts: [],
  legacyDegradationWarningMessage: null,
};

export const buildClientData = (clientDataOverrides: Partial<RelayClientData> = {}) => {
  return {
    ...defaultClientData,
    ...clientDataOverrides,
  };
};

export const relayEnvironment = (graphqlApiUrl: string, options?: GraphQLOptions) => {
  return new Environment({
    store: new Store(new RecordSource(), {
      gcReleaseBufferSize: 20,
    }),
    network: Network.create(
      fetchUsingRelay(graphqlApiUrl, {
        tokenCallback: () => sessionStorage.getItem(MOCK_AUTH_TOKEN_STORAGE_KEY),
        ...options,
      })
    ),
  });
};

export const relayTestEnvironment = (clientDataOverrides: Partial<RelayClientData> = {}) => {
  const relayEnv = relayEnvironment('https://TEST_URL', {
    apiDataSource: ApiDataSource.Mock,
  });
  createRelayClientExtensions(relayEnv, buildClientData(clientDataOverrides));

  return relayEnv;
};

export const relayMSWEnvironment = (clientDataOverrides: Partial<RelayClientData> = {}) => {
  const relayEnv = relayEnvironment('https://MSW_RELAY_REQUEST', {
    apiDataSource: ApiDataSource.Mock,
  });
  createRelayClientExtensions(relayEnv, buildClientData(clientDataOverrides));

  return relayEnv;
};

export const relayMockEnvironment = (clientDataOverrides: Partial<RelayClientData> = {}) => {
  const relayEnv = createMockEnvironment();
  // TODO: update @types/relay-test-utils once maintainers catch up to relay 18
  // @ts-ignore
  createRelayClientExtensions(relayEnv, buildClientData(clientDataOverrides));

  return relayEnv;
};
