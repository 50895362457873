import React from 'react';

import { isTruthy } from '../../utils/is-truthy';

import {
  PublicSelectGroup,
  PublicSelectItem,
  PublicSelectIconItem,
  PublicSelectThirdPartyIconItem,
} from './StyledSelectComponents';
import { MappedChildren, MenuItem, SelectItemTypes } from './types';

const SELECT_ITEM_TYPES = [PublicSelectItem, PublicSelectIconItem, PublicSelectThirdPartyIconItem];

const isSelectElement = (type: React.FC<React.PropsWithChildren<unknown>>) =>
  SELECT_ITEM_TYPES.includes(type);

const getChildProps = (child: MappedChildren) => {
  if (!React.isValidElement(child)) return undefined;

  const props: MenuItem = {
    value: child.props.value,
    label: child.props.children,
    disabled: child.props.disabled,
    css: child.props.css,
    name: undefined,
    type: SelectItemTypes.DEFAULT,
  };

  if (child.type === PublicSelectIconItem) {
    props.name = child.props.name;
    props.type = SelectItemTypes.ICON;
  }

  if (child.type === PublicSelectThirdPartyIconItem) {
    props.name = child.props.name;
    props.type = SelectItemTypes.THIRD_PARTY_ICON;
  }

  return props;
};

export const useItems = (children: React.ReactNode): MenuItem[] => {
  const items = React.useMemo(
    () =>
      React.Children.toArray(children as MappedChildren[])
        .map((child, _) => {
          if (
            React.isValidElement(child) &&
            isSelectElement(child.type as React.FC<React.PropsWithChildren<unknown>>)
          ) {
            return getChildProps(child);
          }
          if (React.isValidElement(child) && child.type === PublicSelectGroup) {
            return React.Children.map(child.props.children as MappedChildren[], (labelledChild) => {
              if (
                React.isValidElement(labelledChild) &&
                isSelectElement(labelledChild.type as React.FC<React.PropsWithChildren<unknown>>)
              ) {
                return getChildProps(labelledChild);
              }
            });
          }
          return undefined;
        })
        .filter(isTruthy)
        .flat(),
    [children]
  );
  return items as MenuItem[];
};
