/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ImageFilled = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3 3v18h18V3H3Zm1.5 16.5v-6.15L8.55 9.3l5.1 5.1 2.55-2.55 3.375 3.375V19.5H4.5ZM17.625 7.875c0 .825-.675 1.5-1.5 1.5s-1.5-.675-1.5-1.5.675-1.5 1.5-1.5 1.5.675 1.5 1.5Z" />
  </svg>
);

const Memo = memo(ImageFilled);
export default Memo;
