import * as RadixSeparator from '@radix-ui/react-separator';
import React from 'react';

import { PicnicCss, styled } from '../../stitches.config';

const StyledSeparator = styled(RadixSeparator.Root, {
  margin: 0,
  backgroundColor: '$borderDefault',
});

export interface SeparatorProps extends RadixSeparator.SeparatorProps {
  css?: PicnicCss;
}

const horizontalCss: PicnicCss = { height: 1, width: '100%' };
const verticalCss: PicnicCss = { width: 1, height: '100%' };

export const Separator = ({ css = {}, orientation = 'horizontal', decorative }: SeparatorProps) => {
  const dimensionCss: PicnicCss = orientation === 'horizontal' ? horizontalCss : verticalCss;

  return (
    <StyledSeparator
      orientation={orientation}
      decorative={decorative}
      css={{ ...dimensionCss, ...css }}
    />
  );
};
