import React, { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { SignInLayout } from '@attentive/auth-ui';
import { Banner, Box, Heading, List, Wordmark } from '@attentive/picnic';

import { getMissingLoginParameters } from '../utils/app';
import { AUTH_PAGE_WIDTH } from '../utils/constants';

export const Error: FC = () => {
  const search = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(search.search), [search]);
  const missingUrlParams = getMissingLoginParameters(urlParams);

  return (
    <SignInLayout>
      <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
        <Wordmark
          title="Attentive signin page"
          width="140px"
          css={{ mb: '$space12', display: 'block' }}
        />
        <Heading css={{ marginBottom: '$space6' }} variant="page">
          Authorization Error
        </Heading>
        <Banner variant="error" css={{ mb: '$space4' }}>
          <Banner.Text>invalid_request</Banner.Text>
        </Banner>
        <List>
          {missingUrlParams.map((param) => (
            <List.Item key={param}>Required parameter is missing - {param}</List.Item>
          ))}
        </List>
      </Box>
    </SignInLayout>
  );
};
