import React from 'react';
import { PolymorphicComponent } from 'react-polymorphic-box';

import { styled, PicnicCss } from '../../stitches.config';
import { LoadingIndicator } from '../LoadingIndicator';

const PU_SUBDUED_COMPOUND_VARIANT_PADDING_OVERRIDE = '0 !important';

const ButtonStyles = {
  fontWeight: '$bold',
  lineHeight: '$lineHeight7',
  borderRadius: '$radius1',
  borderStyle: 'solid',
  borderWidth: '$borderWidth1',
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  outline: 'none',
  userSelect: 'none',
  backgroundColor: 'transparent',
  appearance: 'none',
  textDecoration: 'none',

  defaultTransition: ['box-shadow'],

  focusVisible: '$focus',

  '&:disabled': {
    cursor: 'not-allowed',
  },
  // defaultTransition util isn't getting the correct type in PicnicCss.
  // TODO: Remove when stitches team fixes this (they've acknowledged the issue in discord).
} as unknown as PicnicCss;

const ButtonPrimitive = styled('button', {
  ...ButtonStyles,

  variants: {
    variant: {
      primary: {
        color: '$textDefault',
        backgroundColor: '$bgActionPrimary',
        borderColor: '$bgActionPrimary',
        '&:hover:not([disabled]):not(:active)': {
          backgroundColor: '$bgActionPrimaryHover',
          borderColor: '$bgActionPrimaryHover',
        },
        '&:active:not([disabled])': {
          backgroundColor: '$bgActionPrimaryPressed',
          borderColor: '$bgActionPrimaryPressed',
        },
      },
      secondary: {
        color: '$textDefault',
        backgroundColor: '$bgActionSecondary',
        borderColor: '$bgActionSecondary',
        '&:hover:not([disabled])': {
          color: '$textDefault',
        },
        '&:hover:not([disabled]):not(:active)': {
          backgroundColor: '$bgActionSecondaryHover',
          borderColor: '$bgActionSecondaryHover',
        },
        '&:active:not([disabled])': {
          backgroundColor: '$bgActionSecondaryPressed',
          borderColor: '$bgActionSecondaryPressed',
        },
      },
      basic: {
        color: '$textDefault',
        backgroundColor: '$bgActionBasic',
        borderColor: '$borderActionBasic',
        '&:hover:not([disabled])': {
          color: '$textDefault',
          borderColor: '$borderActionBasic',
        },
        '&:hover:not([disabled]):not(:active)': {
          backgroundColor: '$bgActionBasicHover',
        },
        '&:active:not([disabled])': {
          backgroundColor: '$bgActionBasicPressed',
        },
      },
      subdued: {
        color: '$textDefault',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        p: '$space0',
        border: 0,
        '&:hover:not([disabled]):not(:active)': {
          color: '$textHover',
        },
        '&:active:not([disabled])': {
          color: '$textPressed',
        },
      },
      inverted: {
        color: '$textInverted',
        backgroundColor: 'transparent',
        borderColor: '$bgDefault',
        '&:hover:not([disabled]):not(:active)': {
          color: '$textDefault',
          backgroundColor: '$borderInverted',
          borderColor: '$borderInverted',
        },
        '&:active:not([disabled])': {
          color: '$textDefault',
          backgroundColor: '$borderLoud',
          borderColor: '$borderLoud',
        },
      },
      'legacy-inverted': {
        color: '$textInverted',
        backgroundColor: '$bgInverted',
        borderColor: '$bgInverted',
        // TO-DO: Make inverted button better, more functional color oriented
        '&:hover:not([disabled]):not(:active)': {
          backgroundColor: '$grayscale1000',
          borderColor: '$grayscale1000',
        },
        '&:active:not([disabled])': {
          backgroundColor: '$grayscale1000',
          borderColor: '$grayscale1000',
        },
      },
    },
    disabledVisually: {
      true: {},
      false: {},
    },
    size: {
      small: { minHeight: '$size9', py: '$space1', px: '$space4', fontSize: '$fontSize2' },
      medium: { minHeight: '$size12', py: '$space1', px: '$space6', fontSize: '$fontSize3' },
      large: { minHeight: '$size13', py: '$space1', px: '$space6', fontSize: '$fontSize4' },
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      disabledVisually: true,
      css: {
        color: '$textDisabled',
        backgroundColor: '$bgActionPrimaryDisabled',
        borderColor: '$bgActionPrimaryDisabled',
      },
    },
    {
      variant: 'secondary',
      disabledVisually: true,
      css: {
        color: '$textDisabled',
        backgroundColor: '$bgActionSecondaryDisabled',
        borderColor: '$bgActionSecondaryDisabled',
      },
    },
    {
      variant: 'basic',
      disabledVisually: true,
      css: {
        color: '$textDisabled',
        borderColor: '$borderActionBasicDisabled',
      },
    },
    {
      variant: 'subdued',
      disabledVisually: true,
      css: {
        color: '$textDisabled',
      },
    },
    {
      variant: 'subdued',
      size: 'small',
      css: {
        padding: PU_SUBDUED_COMPOUND_VARIANT_PADDING_OVERRIDE,
      },
    },
    {
      variant: 'subdued',
      size: 'medium',
      css: {
        padding: PU_SUBDUED_COMPOUND_VARIANT_PADDING_OVERRIDE,
      },
    },
    {
      variant: 'inverted',
      disabledVisually: true,
      css: {
        color: '$textSubdued',
        borderColor: '$textSubdued',
      },
    },
    {
      variant: 'legacy-inverted',
      disabledVisually: true,
      css: {
        backgroundColor: '$bgInvertedDisabled',
        borderColor: '$bgInvertedDisabled',
      },
    },
  ],
  defaultVariants: {
    disabledVisually: false,
    variant: 'primary',
    size: 'medium',
  },
});

interface ButtonProps
  extends Omit<
    React.ComponentProps<typeof ButtonPrimitive>,
    // This variant is used internally to support the loading state.
    // Consumers of <Button> should use "disabled" prop.
    'disabledVisually'
  > {
  loading?: boolean;
}

const Button: PolymorphicComponent<ButtonProps, 'button'> = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>((props, ref) => {
  const { children, disabled, loading = false, type = 'button', ...rest } = props;

  const disableInteraction = disabled || loading;

  return (
    <ButtonPrimitive
      disabledVisually={disabled}
      disabled={disableInteraction}
      type={type}
      ref={ref}
      {...rest}
    >
      {loading && <LoadingIndicator css={{ color: 'inherit' }} />}
      {!loading && children}
    </ButtonPrimitive>
  );
});
// @ts-ignore
Button.displayName = 'Button';

export type { ButtonProps };
export { ButtonPrimitive, Button, ButtonStyles };
