/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Sun = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.347 13.165a4.5 4.5 0 1 1-8.694-2.33 4.5 4.5 0 0 1 8.694 2.33Z" />
    <path
      fillRule="evenodd"
      d="M17.82 13.458 21 12l-3.178-1.457a5.97 5.97 0 0 1-.001 2.915ZM6.18 10.543 3 12.001l3.179 1.457a5.97 5.97 0 0 1 0-2.915ZM13.457 6.18 12 3 10.543 6.18a5.97 5.97 0 0 1 2.914.001ZM10.543 17.82 12 21l1.457-3.178a5.97 5.97 0 0 1-2.914-.001ZM15.085 17.146l3.28 1.218-1.218-3.278a5.971 5.971 0 0 1-2.062 2.06ZM8.915 6.854l-3.28-1.218 1.218 3.278a5.97 5.97 0 0 1 2.062-2.06ZM17.146 8.915l1.218-3.279-3.278 1.217a5.97 5.97 0 0 1 2.06 2.062ZM6.854 15.085l-1.218 3.28 3.278-1.218a5.971 5.971 0 0 1-2.06-2.061Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Sun);
export default Memo;
