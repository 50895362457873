import { getAllSearchableText, getLabelText } from './select-helpers';
import { MenuItems } from './types';

export const useMatch = (options: MenuItems, term: string) => {
  const lowerCaseTerm = term.toLocaleLowerCase();
  return options.filter((b) => {
    const label = getLabelText(b.label);
    return label && label.toLocaleLowerCase().includes(lowerCaseTerm);
  });
};

export const useRecursiveMatch = (options: MenuItems, term: string) => {
  const lowerCaseTerm = term.toLocaleLowerCase();
  return options.filter((b) => {
    const label = getAllSearchableText(b.label);
    return label && label.toLocaleLowerCase().includes(lowerCaseTerm);
  });
};
