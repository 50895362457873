import React from 'react';

import { Box, Button, IconCircle, Heading, Text, Wordmark } from '@attentive/picnic';

import { AUTH_PAGE_WIDTH } from '../../constants';

export const TfaSetupComplete = () => {
  return (
    <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
      <Wordmark
        title="Attentive signin page"
        width="140px"
        css={{ mb: '$space12', display: 'block' }}
      />
      <Heading as="h1" css={{ mb: '$space6', display: 'inline-flex' }} variant="page">
        <IconCircle iconName="Checkmark" color="success" css={{ mr: '$space2' }} />
        Set up complete!
      </Heading>
      <Text css={{ mb: '$space6' }}>
        <Text as="span" css={{ fontWeight: '$bold' }}>
          Two-Factor Authentication is now activated.
        </Text>{' '}
        Moving forward, you will be asked to provide an authentication code in addition to your
        password each time you log in.
      </Text>
      {/* TODO: redirect to login with any addition MFA requests */}
      <Button css={{ width: '100%' }}>Login</Button>
    </Box>
  );
};
