/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Star = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m16.094 14.146 1.724 6.877-5.817-3.77-5.833 3.77 1.727-6.887L3 9.945l6.428-.496L12 3.023l2.572 6.427L21 9.946l-4.906 4.2Z" />
  </svg>
);

const Memo = memo(Star);
export default Memo;
