import React, { useState } from 'react';
import * as Yup from 'yup';

import { useToast, ToastType } from '@attentive/acore-utils';
import { Box, Form, Heading, Text, Wordmark } from '@attentive/picnic';

// import { obfuscateEmail, obfuscatePhone } from '../../common/obfuscation';
import { AUTH_PAGE_WIDTH } from '../../constants';
import { RequestDemo } from '../../components/RequestDemo';
import { AuthOptionButton } from '../../components/AuthOptionButton';
import { CODE_LENGTH, CODE_TIME_LIMIT } from './codeConstants';

import { useSendMfaOneTimePasscode, useVerifyMfaOneTimePasscode } from './codeVerificationHooks';
import { RememberDeviceCheck } from './RememberDeviceCheck';

export type CodeVerificationSetupFormProps = {
  mfaToken: string;
  deviceIdentifier: string;
  smsHint: string | null;
  onVerify: (jwt: string) => void;
};

const codeVerificationSetupFormSchema = Yup.object().shape({
  verificationCode: Yup.string().max(CODE_LENGTH).required('A verification code is required.'),
});

export const CodeVerificationSetupForm = ({
  mfaToken,
  deviceIdentifier,
  smsHint,
  onVerify,
}: CodeVerificationSetupFormProps) => {
  const [makeToast] = useToast();

  const { sendMfaOneTimePasscode, isSendingCode } = useSendMfaOneTimePasscode();
  const { verifyMfaOneTimePasscode, isVerifyingCode } = useVerifyMfaOneTimePasscode();

  const [rememberDevice, setRememberDevice] = useState<boolean | 'indeterminate'>(false);

  const resendVerificationCode = () => {
    sendMfaOneTimePasscode(
      {
        deviceIdentifier,
        mfaToken,
        preferredMfaMethod: 'PREFERRED_MFA_METHOD_SMS',
      },
      () => {
        makeToast({
          type: ToastType.Success,
          text: 'Verification code sent.',
        });
      },
      (message) => {
        makeToast({
          type: ToastType.Error,
          text: message,
        });
      }
    );
  };

  return (
    <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
      <Form
        validationSchema={codeVerificationSetupFormSchema}
        initialValues={{ verificationCode: '' }}
        onSubmit={async (values, formHelpers) => {
          verifyMfaOneTimePasscode(
            {
              oneTimePasscode: values.verificationCode,
              mfaToken,
              rememberDevice: Boolean(rememberDevice),
            },
            (data) => {
              const { jwt, error } = data.verifyMfaOneTimePasscode || {};
              if (jwt) {
                onVerify(jwt);
              } else if (error) {
                formHelpers.setErrors({ verificationCode: error });
              }
            },
            () => {
              if (values.verificationCode) {
                formHelpers.setErrors({
                  verificationCode: 'Invalid code. Please re-enter a valid code or resend code.',
                });
              } else {
                formHelpers.setErrors({
                  verificationCode: 'This code is no longer valid. Please select resend code.',
                });
              }
            }
          );
        }}
      >
        <Wordmark title="Attentive" width="140px" css={{ mb: '$space12', display: 'block' }} />
        <Heading as="h1" css={{ mb: '$space6' }} variant="page">
          Secure your account with Two-Factor Authentication
        </Heading>
        <Text css={{ mb: '$space6' }}>
          {smsHint && (
            <>
              Please enter the verification code we sent to the number ending in{' '}
              <Text as="span" css={{ fontWeight: '$bold' }}>
                {smsHint}
              </Text>{' '}
              to finish logging in. The code is valid for {CODE_TIME_LIMIT} minutes.
            </>
          )}
          {!smsHint && (
            <Text css={{ mb: '$space6' }}>
              Please enter the verification code that we sent to your sms number to finish logging
              in. The code is valid for {CODE_TIME_LIMIT} minutes.
            </Text>
          )}
        </Text>
        <Form.FormField name="verificationCode">
          <Form.Label>Verification Code</Form.Label>
          <Form.TextInput placeholder="XXXXXX" maxLength={CODE_LENGTH} />
        </Form.FormField>
        <RememberDeviceCheck onChange={setRememberDevice} value={Boolean(rememberDevice)} />

        <Form.SubmitButton
          css={{ width: '100%', marginTop: '$space6' }}
          loading={isSendingCode || isVerifyingCode}
        >
          Submit
        </Form.SubmitButton>
      </Form>
      <Box css={{ display: 'grid', gap: '$space2', marginTop: '$space6' }}>
        <AuthOptionButton onClick={resendVerificationCode}>Resend code</AuthOptionButton>
      </Box>
      <RequestDemo />
    </Box>
  );
};
