// This is useful as a replacement for `Boolean` when filtering an array.
// It adjusts the return typing, whereas `Boolean` still leaves falsy values
// in the resulting type.
type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

function isTruthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export { isTruthy };
