import React from 'react';
import { Link, Separator, Text } from '@attentive/picnic';

export const RequestDemo: React.FC = () => (
  <>
    <Separator css={{ mt: '$space12', mb: '$space6', textAlign: 'center' }} />
    <Text css={{ textAlign: 'center' }}>
      Don't have an Attentive account?{' '}
      <Link href="https://www.attentivemobile.com/demo">Request a demo</Link>
    </Text>
  </>
);
