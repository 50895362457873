/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const TextFileOutline = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M21 21H3V3h18v18ZM4.5 19.5h15v-15h-15v15Z" />
    <path d="M17.625 7.5H6.375V9h11.25V7.5ZM17.625 11.25H6.375v1.5h11.25v-1.5ZM12 15H6.375v1.5H12V15Z" />
  </svg>
);

const Memo = memo(TextFileOutline);
export default Memo;
