/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ImageOutline = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.625 7.875c0 .825-.675 1.5-1.5 1.5s-1.5-.675-1.5-1.5.675-1.5 1.5-1.5 1.5.675 1.5 1.5Z" />
    <path d="M3 3v18h18V3H3Zm16.5 1.5v9.975L16.125 11.1l-2.55 2.55-5.1-5.1L4.5 12.6V4.5h15Zm-15 15v-4.8l4.05-4.05 5.1 5.1 2.55-2.55 3.375 3.375V19.5H4.5Z" />
  </svg>
);

const Memo = memo(ImageOutline);
export default Memo;
