import React from 'react';

import { styled } from '../../stitches.config';
import { Text } from '../Text';

import {
  PublicSelectGroupProps,
  PublicSelectIconItemProps,
  PublicSelectItemProps,
  PublicSelectThirdPartyIconItemProps,
} from './types';

const PU_MENU_WIDTH = '80px';
const PU_MENU_MAX_HEIGHT = '400px';

export const SelectButtonText = styled(Text, {
  flex: 1,
  // FIXME: The `!important` here is necessary because of a stitches
  // style insertion issue.
  // See: https://github.com/modulz/stitches/issues/671
  fontSize: 'inherit !important',
  fontWeight: '$regular',
  textAlign: 'left',
  marginRight: '$space2',

  variants: {
    selectTextState: {
      placeholder: {
        color: '$textSubdued',
      },
      selectedItem: {},
    },
    disabledVisually: {
      true: {},
      false: {},
    },
    selectedLines: {
      'one-line': { maxLines: 1 },
      'two-lines': { maxLines: 2 },
    },
  },

  compoundVariants: [
    {
      selectTextState: 'placeholder',
      disabledVisually: true,
      css: {
        color: 'inherit',
      },
    },
  ],
});

export const SelectButton = styled('button', {
  backgroundColor: '$bgActionBasic',
  border: '$borderWidths$borderWidth1 solid $borderInput',
  borderRadius: '$radius1',
  color: '$textDefault',
  lineHeight: '$lineHeight7',

  alignItems: 'center',
  cursor: 'pointer',
  display: 'inline-flex',
  focusVisible: '$focus',
  justifyContent: 'space-between',
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'middle',

  '&:hover:enabled:not(:focus-visible)': {
    borderColor: '$borderInputHover',
  },

  variants: {
    state: {
      normal: { borderColor: '$borderInput' },
      error: { '&:not(:focus)': { borderColor: '$borderInputError' } },
    },
    size: {
      small: { minHeight: '$size9', py: '$space1', px: '$space3', fontSize: '$fontSize2' },
      medium: { minHeight: '$size12', py: '$space2', px: '$space4', fontSize: '$fontSize3' },
    },
    disabledVisually: {
      true: {
        cursor: 'not-allowed',
        color: '$textDisabled',
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      state: 'normal',
      disabledVisually: true,
      css: {
        borderColor: '$borderInputDisabled',
      },
    },
  ],
});

export const ArrowWrapper = styled('span', {
  width: '$size5',
  height: '$size5',
  display: 'inline-block',
  position: 'relative',
  marginLeft: 8,
  fill: 'currentColor',
  transform: 'rotate(-180deg)',
  defaultTransition: ['transform'],
  cursor: 'pointer',

  variants: {
    variant: {
      up: {},
      down: {
        transform: 'rotate(0)',
      },
    },
  },

  defaultVariants: {
    variant: 'down',
  },
});

export const Menu = styled('ul', {
  listStyleOverride: 'unstyled',
  minWidth: PU_MENU_WIDTH,
  backgroundColor: '$bgDefault',
  borderRadius: '$radius1',
  border: '1px solid $borderLoud',
  padding: '$space1 $space0',
  boxShadow: '$shadow2',
  overflowX: 'hidden',
  overflowY: 'auto',

  '&:focus': {
    outline: 'none',
  },
});

export const SelectItem = styled('li', {
  backgroundColor: '$bgRow',
  padding: '$space2 $space3',
  lineHeight: '$lineHeight3',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  variants: {
    highlighted: {
      true: {
        backgroundColor: '$bgRowHover',
      },
    },
    selected: {
      true: {
        backgroundColor: '$bgRowSelected',
        fontWeight: '$bold',
      },
    },
    disabledVisually: {
      true: {
        color: '$textDisabled',
        cursor: 'not-allowed',
      },
      false: {
        '&:focus': {
          outline: 'none',
          backgroundColor: '$bgRowHover',
        },

        '&:hover': {
          backgroundColor: '$bgRowHover',
        },

        '&:active': {
          outline: 'none',
          backgroundColor: '$bgRowPressed',
        },
      },
    },
  },
});

export const PublicSelectGroup: React.FC<React.PropsWithChildren<PublicSelectGroupProps>> = () => {
  // This component is never rendered.
  // It is used to provide typing information to our Composite Component API
  return null;
};

export const PublicSelectItem: React.FC<React.PropsWithChildren<PublicSelectItemProps>> = () => {
  // This component is never rendered.
  // It is used to provide typing information to our Composite Component API
  return null;
};

export const PublicSelectIconItem: React.FC<
  React.PropsWithChildren<PublicSelectIconItemProps>
> = () => {
  // This component is never rendered.
  // It is used to provide typing information to our Composite Component API
  return null;
};

export const PublicSelectThirdPartyIconItem: React.FC<
  React.PropsWithChildren<PublicSelectThirdPartyIconItemProps>
> = () => {
  // This component is never rendered.
  // It is used to provide typing information to our Composite Component API
  return null;
};

export const SelectPopout = styled('div', {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 'calc(100% + $space2)',
  minWidth: 230,
  maxHeight: PU_MENU_MAX_HEIGHT,
  backgroundColor: '$bgDefault',
  borderRadius: '$radius1',
  border: '$borderWidths$borderWidth1 solid $borderDefault',
  padding: '$space2 $space0',
  boxShadow: '$drastic',
  overflowY: 'scroll',
  overflowX: 'hidden',
  zIndex: '$layer4',
});

export const MultiSelectWrapper = styled('span', {
  display: 'flex',
  flexWrap: 'wrap',
  border: '$borderWidths$borderWidth1 solid $borderInput',
  alignItems: 'center',
  cursor: 'text',
  backgroundColor: '$bgDefault',
  borderRadius: '$radius1',
  width: '100%',
  position: 'relative',
  focusVisible: '$focus',
  '&:hover:not(:focus-visible)': {
    borderColor: '$borderInputHover',
  },
  variants: {
    size: {
      small: { minHeight: '$size9', py: '$space0', px: '$space3', fontSize: '$fontSize2' },
      medium: { minHeight: '$size12', py: '$space2', px: '$space4', fontSize: '$fontSize3' },
    },
    state: {
      normal: { borderColor: '$borderInput' },
      error: { '&:not(:focus)': { borderColor: '$borderInputError' } },
    },
    disabled: {
      true: {
        color: '$textDisabled',
        borderColor: '$borderInputDisabled',
        cursor: 'not-allowed',
        '&:hover:not(:focus-visible)': {
          borderColor: '$borderInputDisabled',
        },
      },
      false: {},
    },
  },
  defaultVariant: {
    size: 'medium',
    state: 'normal',
  },
});

export const MultiSelectTextInput = styled('input', {
  backgroundColor: '$bgDefault',
  boxSizing: 'border-box',
  display: 'inline-flex',
  margin: '$space1',
  flexDirection: 'column',
  justifyContent: 'stretch',
  lineHeight: '$lineHeight2',
  padding: '0',
  width: '100%',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: '$textSubdued',
    opacity: 1,
  },
  '&:disabled': {
    color: '$textDisabled',
    borderColor: '$borderInputDisabled',
    cursor: 'not-allowed',
    '&::placeholder': {
      color: '$textDisabled',
    },
  },
  variants: {
    size: {
      small: { fontSize: '$fontSize2' },
      normal: { fontSize: '$fontSize3' },
    },
    state: {
      normal: { borderColor: '$borderInput' },
      error: { '&:not(:focus)': { borderColor: '$borderInputError' } },
    },
  },
  defaultVariants: {
    size: 'normal',
    state: 'normal',
  },
});
