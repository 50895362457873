/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const HeartChart = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M5.844 4.95c2.325-1.05 4.95-.075 6.15 2.25 1.2-2.325 3.825-3.375 6.15-2.325 2.475 1.2 3.525 4.2 2.475 6.825a3.81 3.81 0 0 1-.17.33h-3.627l-1.927-1.964-2.756 4.135-3.033-6.823-2.776 4.626-2.845.023a3.058 3.058 0 0 1-.116-.252c-1.05-2.625.075-5.7 2.475-6.825Zm-1.34 8.569c1.244 1.56 3.22 3.54 4.94 5.156L11.994 21l2.55-2.4c1.639-1.587 3.556-3.523 4.823-5.07h-3.175l-1.074-1.094-3.243 4.865-2.967-6.677-1.724 2.874-2.68.02Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(HeartChart);
export default Memo;
