import _SetupMfaFormSetAccountMfaPreferencesMutation from "./__generated__/SetupMfaFormSetAccountMfaPreferencesMutation.graphql";
import { graphql } from '@attentive/data';
import { useMutation } from 'react-relay';
import { default as SetAccountMfaPreferencesMutation } from './__generated__/SetupMfaFormSetAccountMfaPreferencesMutation.graphql';
import { logger } from '../../common/logger';
_SetupMfaFormSetAccountMfaPreferencesMutation; // NOTE: This hook is now used solely in the setup form to assign a 2FA phone number to the user account. Rather than calling both this mutation and the sendMfaOneTimePasscode mutation, the latter now takes a method parameter and sets it as the preference. The requirement is that we always default to the last-used verification method.

export const useSetAccountMfaPreferencesMutation = () => {
  const [commit, isLoading] = useMutation(SetAccountMfaPreferencesMutation);

  const setAccountMfaPreferences = (variables, onCompleted, onError) => commit({
    variables: {
      input: { ...variables
      }
    },
    onCompleted: (_, errors) => {
      if (errors && (errors === null || errors === void 0 ? void 0 : errors.length) > 0) {
        onError(errors.map(error => error.message).join(', '));
      } else {
        onCompleted();
      }
    },
    onError: error => {
      logger.logError(error);
      onError(error.message);
    }
  });

  return {
    setAccountMfaPreferences,
    isLoading
  };
};