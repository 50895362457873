/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Rocket = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M21.616 2.384s.687 5.594-1.047 7.328l-8.374 8.374-6.281-6.28 8.374-8.375c1.734-1.734 7.328-1.047 7.328-1.047Zm-9.16 9.16a2.59 2.59 0 1 0 3.664-3.665 2.59 2.59 0 0 0-3.664 3.665Z"
      clipRule="evenodd"
    />
    <path d="m10.1 5.525-5.233 5.234L2.25 8.142l7.85-2.617ZM18.475 13.9l-5.234 5.233 2.617 2.617 2.617-7.85ZM7.73 15.212l1.06 1.06L4.764 20.3l-1.06-1.06 4.026-4.027ZM5.874 13.357l1.06 1.06-2.865 2.865-1.06-1.06 2.865-2.866ZM9.586 17.068l1.06 1.06-2.865 2.866-1.06-1.06 2.865-2.866Z" />
  </svg>
);

const Memo = memo(Rocket);
export default Memo;
