// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/.internal/baseGet.ts

import { castPath } from './castPath';
import { toKey } from './toKey';

/**
 * The base implementation of `get` without support for default values.
 */
export function baseGet(object: object, path: Array<any> | string): any {
  path = castPath(path, object);

  let index = 0;
  const length = path.length;

  while (object != null && index < length) {
    object = object[toKey(path[index++]) as keyof typeof object];
  }
  return index && index === length ? object : undefined;
}
