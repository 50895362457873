import { useField, useFormikContext } from 'formik';
import React, { createContext, useContext } from 'react';

import { FormField as StyledFormField } from '../FormField';

import { ErrorText } from './ErrorText';

const FormFieldNameContext = createContext<string>('');
const FormFieldNameProvider = FormFieldNameContext.Provider;
const useFormFieldName = () => {
  const name = useContext(FormFieldNameContext);
  return name;
};

const FormFieldErrorContext = createContext<boolean>(false);
const FormFieldErrorProvider = FormFieldErrorContext.Provider;
const useFormFieldIsErrorShowing = () => {
  const error = useContext(FormFieldErrorContext);
  return error;
};

type Props = React.ComponentProps<typeof StyledFormField> & {
  name: string;
};

const FormField: React.FC<React.PropsWithChildren<Props>> = ({ name, children, ...rest }) => {
  const [, meta] = useField(name);
  const { validateOnMount } = useFormikContext();
  const isShowingError = Boolean(meta.error && (meta.touched || validateOnMount));

  return (
    <FormFieldNameProvider value={name}>
      <FormFieldErrorProvider value={isShowingError}>
        <StyledFormField {...rest}>
          {children}
          {isShowingError && <ErrorText name={name} />}
        </StyledFormField>
      </FormFieldErrorProvider>
    </FormFieldNameProvider>
  );
};

export { FormField, useFormFieldName, useFormFieldIsErrorShowing };
