import { PolymorphicComponent } from 'react-polymorphic-box';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';

const LinkComponent = styled('a', {
  fontSize: 'inherit',
  textDecoration: 'underline',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$radius1',

  focusVisible: '$focus',

  variants: {
    variant: {
      default: {
        color: '$textLink',
        '&:hover': {
          color: '$textHover',
        },
        '&:active': {
          color: '$textPressed',
        },
      },
      inverted: {
        color: '$textInverted',
        '&:hover': {
          color: '$textInverted',
        },
        '&:active': {
          color: '$textInverted',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type LinkProps = React.ComponentProps<typeof LinkComponent>;

// Using react-polymorphic-box over stitches' built-in "as" prop because of a bug
// preventing use of "as" with react-router Link: https://github.com/modulz/stitches/issues/427.
const Link = LinkComponent as PolymorphicComponent<LinkProps, 'a'> & DisplayNamed;

Link.displayName = 'Link';

export type { LinkProps };
export { Link };
