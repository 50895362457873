import React from 'react';

import { IconPrimitive, IconPrimitiveProps } from './IconPrimitive';

export interface PresentationalIconProps extends IconPrimitiveProps {
  mode?: 'presentational';
  description: string;
}

interface DecorativeIconProps extends IconPrimitiveProps {
  mode?: 'decorative';
  description?: never;
}

export type IconProps = PresentationalIconProps | DecorativeIconProps;

export const Icon = ({ mode = 'decorative', css, description = '', ...props }: IconProps) => {
  const a11yProps: Partial<IconPrimitiveProps> = {
    ...(mode === 'presentational' && {
      title: description,
      'aria-label': description,
      role: 'img',
    }),
    ...(mode === 'decorative' && {
      'aria-hidden': true,
    }),
  };

  return <IconPrimitive {...a11yProps} css={css} {...props} />;
};
