const listStyleOverride = (value: 'unstyled') => {
  if (value === 'unstyled') {
    return {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    };
  }

  return {};
};

export default { listStyleOverride };
