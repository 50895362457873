/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Megaphone = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M15.55 20.333 2.998 14.536V9.464l12.55-5.797v16.666Zm1.5-12.275c2.188 0 3.948 1.775 3.948 3.95 0 2.173-1.76 3.948-3.949 3.933V8.058Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Megaphone);
export default Memo;
