// Copied from libs/mock-data, temporary kludge to resolve circular dependency.
import kebabCase from 'lodash/kebabCase';

export const mswHeaderPrefix = 'msw-';
export const MSW_STORAGE_KEY = 'mock';

export const addMSWHeaders = (headers: Headers) => {
  let mockHeaders: Record<string, string> = {};
  try {
    mockHeaders = JSON.parse(sessionStorage.getItem(MSW_STORAGE_KEY) || '{}');
  } catch (err) {
    console.warn(`Unable to parse JSON data from ${MSW_STORAGE_KEY} in sessionStorage`);
    return {};
  }
  for (const [name, value] of Object.entries(mockHeaders)) {
    headers.set(`${mswHeaderPrefix}${kebabCase(name)}`, JSON.stringify(value));
  }
  return headers;
};
