/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Clock = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 3a9 9 0 1 0 .001 18.001A9 9 0 0 0 12 3Zm5.5 10H11V6.5h2V11h4.5v2Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Clock);
export default Memo;
