/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Flower = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M19.795 7.5a.75.75 0 0 1-1.298.75.75.75 0 0 1 1.298-.752V7.5Zm-14.292.75a.75.75 0 0 1-1.298-.75v-.002a.75.75 0 1 1 1.298.752ZM8.25 5.503a.75.75 0 1 1-.752-1.298H7.5a.75.75 0 1 1 .751 1.298Zm8.252-1.298a.75.75 0 0 1-.752 1.298.75.75 0 0 1 .75-1.298h.002ZM12 4.5Zm0 1.274.48.4c2.131 1.776 3.266 3.868 3.437 5.999 1.164-.576 2.591-.923 4.333-.923H21V12a9 9 0 0 1-18 0v-.75h.75c1.742 0 3.17.347 4.332.923.171-2.131 1.306-4.223 3.438-6l.48-.4Zm2.438 7.133a5.576 5.576 0 0 1-.012.234c-.943.785-1.604 1.724-2.066 2.649a9.799 9.799 0 0 0-.36.812 9.799 9.799 0 0 0-.36-.812c-.463-.925-1.123-1.864-2.066-2.649a5.688 5.688 0 0 1-.012-.234c-.05-1.701.677-3.51 2.438-5.157 1.76 1.648 2.488 3.456 2.438 5.157Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Flower);
export default Memo;
