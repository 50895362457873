/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const EndJourney = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m5.85 20.025 1.8.975 3.45-6.075L9.975 12.9 5.85 20.025Z" />
    <path d="m19.5 12.15-.45-3.75 3.45-1.5L15.675 3 12 9.375 8.325 3 1.5 6.9l3.45 1.5-.45 3.75 5.1-2.925L16.35 21l1.8-1.05-4.95-8.55 1.2-2.175 5.1 2.925Z" />
  </svg>
);

const Memo = memo(EndJourney);
export default Memo;
