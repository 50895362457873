import React from 'react';

import { loadPicnicFonts } from '../../fonts';
import { Tooltip } from '../components';
import { Themes, ThemeName, usePicnicStyles } from '../stitches.config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Story = any;

const PicnicDecorator = (Story: Story, context: { globals: { theme: ThemeName } }) => {
  loadPicnicFonts();
  usePicnicStyles(Themes[context.globals.theme]);
  Story.displayName = 'PicnicDecorator';

  // Needs to call storybook as a function instead of using JSX (e.g. return <Story/>) otherwise
  // stories that use `useArgs()` lose focus on controlled inputs.
  // https://github.com/storybookjs/storybook/issues/11657#issuecomment-722209470
  return <Tooltip.Provider>{Story()}</Tooltip.Provider>;
};

const ThemeSelector = {
  name: 'Theme',
  description: 'Global theme for components',
  defaultValue: ThemeName.Theme2021,
  toolbar: {
    icon: 'circlehollow',
    items: [ThemeName.Theme2021, ThemeName.ThemeDark],
    showName: true,
  },
};

export { PicnicDecorator, ThemeSelector };
