import { v4 as uuidv4 } from 'uuid';

import { Routes } from './constants';
import { generateStateString } from './utils';

export interface GoogleSignInOptions {
  clientId: string;
  redirectPath: string;
  loginHint: string;
  sneakPreviewCommitSha?: string;
}

export class GoogleSignInClient {
  public signIn({ clientId, redirectPath, loginHint, sneakPreviewCommitSha }: GoogleSignInOptions) {
    const { protocol, host } = location;
    const domain = `${protocol}//${host}`;
    const stateLength = 10;
    const url = 'https://accounts.google.com/o/oauth2/v2/auth';

    // We are setting a link between our random state and a redirect path. We are using AUTH_REDIRECT_METADATA
    // as a key and storing the state, redirect path and sneak peak commit sha in order to mitigate CSRF attacks
    const state = generateStateString(stateLength);
    sessionStorage.setItem(
      'AUTH_REDIRECT_METADATA',
      JSON.stringify({ state, redirectPath, sneakPreviewCommitSha })
    );

    const params = new URLSearchParams({
      client_id: clientId,
      response_type: 'id_token',
      scope: 'openid profile email',
      nonce: uuidv4(),
      ss_domain: domain,
      fetch_basic_profile: 'true',
      redirect_uri: domain + Routes.GoogleSigninCallback,
      state,
      login_hint: loginHint,
    });
    window.location.assign(`${url}?${params}`);
  }
}

export const googleSignInClient = new GoogleSignInClient();
