import { useField } from 'formik';
import React from 'react';

import { compositeComponent } from '../../utils/composite-component';
import { RadioGroup as StyledRadioGroup } from '../RadioGroup';

import { useFormFieldName } from './FormField';
import { PropsWithoutField } from './utils';

type Props = PropsWithoutField<typeof StyledRadioGroup>;

const RadioGroup: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const name = useFormFieldName();
  const [field, , { setValue }] = useField(name);

  return <StyledRadioGroup {...props} {...field} name={name} id={name} onChange={setValue} />;
};

const Namespace = compositeComponent(RadioGroup, {
  Radio: StyledRadioGroup.Radio,
  RadioItem: StyledRadioGroup.RadioItem,
});

export { Namespace as RadioGroup };
