import { Environment } from 'react-relay';

export interface AuthClient {
  verifySession(
    input: VerifySessionInput,
    relayEnvironment: Environment
  ): Promise<VerifySessionResponse>;
  logout(tearDownReason: string | null): void;
}

export interface VerifySessionInput {
  companyId?: string | null;
  companyRestId?: number | null;
  companyExternalId?: number | null;
}
export interface VerifySessionResponse {
  companyId: string;
  companyRestId: number;
  challengeString?: string;
}

export enum ConnectionType {
  CREDENTIALS = 'CREDENTIALS',
  SAML = 'SAML',
  GOOGLE = 'GOOGLE',
}
export interface CredentialsConnection {
  connectionName: string;
  connectionType: ConnectionType.CREDENTIALS;
  connectionId: null;
}
export interface SamlConnection {
  connectionName: string;
  connectionType: ConnectionType.SAML;
  connectionId: null;
}
export interface GoogleConnection {
  connectionName: string;
  connectionType: ConnectionType.GOOGLE;
  connectionId: string;
}
export type Connection = CredentialsConnection | SamlConnection | GoogleConnection;
