/**
 * @generated SignedSource<<641b58502ab176559a639f82b5162394>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type VerifyChallengeInput = {
  companyId: string;
  nonce: string;
};
export type commitVerifyChallengeMutation$variables = {
  input: VerifyChallengeInput;
};
export type commitVerifyChallengeMutation$data = {
  readonly verifyChallenge: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
  } | {
    readonly __typename: "VerifyChallengeSuccess";
    readonly company: {
      readonly id: string;
      readonly internalId: number;
      readonly name: string;
    };
    readonly success: boolean;
    readonly token: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type commitVerifyChallengeMutation = {
  response: commitVerifyChallengeMutation$data;
  variables: commitVerifyChallengeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "DefaultErrorFailure",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "commitVerifyChallengeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "verifyChallenge",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "kind": "RequiredField",
                "field": (v4/*: any*/),
                "action": "LOG",
                "path": "verifyChallenge.company"
              },
              (v5/*: any*/)
            ],
            "type": "VerifyChallengeSuccess",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "commitVerifyChallengeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "verifyChallenge",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "VerifyChallengeSuccess",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3d61120df3bdd3d59be3bba3b067e4e",
    "id": null,
    "metadata": {},
    "name": "commitVerifyChallengeMutation",
    "operationKind": "mutation",
    "text": "mutation commitVerifyChallengeMutation(\n  $input: VerifyChallengeInput!\n) {\n  verifyChallenge(input: $input) {\n    __typename\n    ... on VerifyChallengeSuccess {\n      success\n      company {\n        id\n        internalId\n        name\n      }\n      token\n    }\n    ... on DefaultErrorFailure {\n      status\n      message\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe8e3a37804065483eba91a7d407e0a2";

export default node;
