import React from 'react';
import { Button } from '@attentive/picnic';

export const AuthOptionButton = (props: React.ComponentProps<typeof Button>) => (
  <Button
    variant="subdued"
    size="medium"
    css={{ minHeight: 0, fontWeight: '$regular' }}
    {...props}
  />
);
