/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const BagCheckmark = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 4.5c1.65 0 3 1.35 3 3H9c0-1.65 1.35-3 3-3ZM12 3a4.513 4.513 0 0 0-4.5 4.5H5.25V21h13.5V7.5H16.5C16.5 5.025 14.475 3 12 3Zm4.5 8.85-5.775 5.775L7.5 14.4l.975-.975 2.25 2.25 4.8-4.8.975.975Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(BagCheckmark);
export default Memo;
