/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const NoData = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M2 22V2h2v18h18v2H2Z" />
    <path d="M8.686 16.314 14 11l5.314 5.314 1.414-1.415-5.314-5.313L20 5l-1.414-1.414L14 8.172 9.414 3.586 8 5l4.586 4.586-5.314 5.313 1.414 1.415Z" />
  </svg>
);

const Memo = memo(NoData);
export default Memo;
