/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const BarChart = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M20.999 3h-5.003v18H21V3ZM8 15.52H3v5.478H8v-5.478Zm1.49-6.26h5.005v11.738H9.492V9.26Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(BarChart);
export default Memo;
