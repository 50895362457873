/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const PersonStacked = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M17.624 17.7h4.5c-.9-2.55-3.375-4.35-6.225-4.35-.825 0-1.575.15-2.325.45 1.725.824 3.15 2.25 4.05 3.9ZM15.974 12.3c-.675 0-1.275-.15-1.8-.45.6-.9.9-1.95.9-3.076 0-1.125-.3-2.175-.9-3.075.525-.3 1.125-.45 1.8-.45a3.52 3.52 0 0 1 3.525 3.525 3.52 3.52 0 0 1-3.525 3.525Z"
      clipRule="evenodd"
    />
    <path d="M9.299 13.05a4.275 4.275 0 1 0 0-8.55 4.275 4.275 0 0 0 0 8.55Z" />
    <path
      fillRule="evenodd"
      d="M9.299 14.25a7.973 7.973 0 0 0-7.5 5.25h15a7.973 7.973 0 0 0-7.5-5.25Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(PersonStacked);
export default Memo;
