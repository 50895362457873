import merge from 'lodash/merge';
import React from 'react';

import { PicnicCss } from '../../stitches.config';
import { ResponsiveValue, responsiveRule } from '../../utils';
import { Box, BoxProps } from '../Box';

interface CellProps extends BoxProps {
  colSpan?: ResponsiveValue<number | null>;
}

const Cell: React.FC<React.PropsWithChildren<CellProps>> = ({
  children,
  colSpan,
  css = {},
  ...props
}) => {
  const cellCss: PicnicCss = css;

  if (colSpan) {
    merge(css, responsiveRule('gridColumnSpan', colSpan));
  }

  return (
    <Box css={cellCss} {...props}>
      {children}
    </Box>
  );
};

export { Cell };
