/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Button = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M5.25 3h16.5v9.781L12 7.151v8.599H5.25V3Z" />
    <path d="M3.75 5.25v12H12v1.5H2.25V5.25h1.5ZM22.5 14.946l-9-5.196v10.392l3.289-1.898 2.43 4.207 2.421-1.398-2.429-4.208 3.29-1.899Z" />
  </svg>
);

const Memo = memo(Button);
export default Memo;
