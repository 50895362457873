/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const CirclePerson = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 2.994c-4.95 0-9 4.05-9 9 0 2.775 1.2 5.175 3.15 6.825A8.931 8.931 0 0 0 12 20.994c2.25 0 4.275-.825 5.85-2.175A8.933 8.933 0 0 0 21 11.994c0-4.95-4.05-9-9-9Zm0 3.375a3.169 3.169 0 0 1 3.15 3.15A3.169 3.169 0 0 1 12 12.67a3.169 3.169 0 0 1-3.15-3.15A3.169 3.169 0 0 1 12 6.37Zm0 13.125a7.43 7.43 0 0 1-5.25-2.175A5.964 5.964 0 0 1 12 14.244c2.25 0 4.2 1.275 5.25 3.075A7.43 7.43 0 0 1 12 19.494Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(CirclePerson);
export default Memo;
