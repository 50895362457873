/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MessageBubbleDotsStacked = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M6.401 5.5V3.625h14.566V17l-1.84-1.84V5.5H6.4Zm11.223 1.499H3.033v13.377l3.663-3.663h10.928V6.999Zm-6.293 4.857a1.003 1.003 0 1 1-2.006 0 1.003 1.003 0 0 1 2.006 0Zm2.22 1.003a1.003 1.003 0 1 0 0-2.006 1.003 1.003 0 0 0 0 2.006Zm-5.442-1.003a1.003 1.003 0 1 1-2.006 0 1.003 1.003 0 0 1 2.006 0Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(MessageBubbleDotsStacked);
export default Memo;
