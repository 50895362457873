import promiseRetry from 'promise-retry';

import { Response } from '@attentive/acore-utils';
import {
  inSneakPreview,
  sneakPreviewCommitSha,
} from '@attentive/sneak-preview-utils/sneak-preview';

import { Routes } from './constants';
import {
  AuthError,
  getErrorCodeFromResponse,
  InvalidPasswordError,
  NeedsPasswordResetError,
} from './errors';

// for MB use only
export const EXTERNAL_COMPANY_ID_PARAM_NAME = 'ecid';

export const buildCallbackUri = (redirectUrl?: string, state?: string) => {
  const { protocol, host } = window.location;
  const queryParams = new URLSearchParams();
  if (redirectUrl) {
    queryParams.set('redir', redirectUrl);
  }
  if (state) {
    queryParams.set('state', state);
  }
  return `${protocol}//${host}/signin/callback?${queryParams}`;
};

export const buildLogoutRedirectUri = (location: Location, error: string | null) => {
  const { protocol, host, pathname } = location;
  const params = new URLSearchParams();
  if (error) {
    params.set('error', error);
  }
  if (inSneakPreview(pathname)) {
    const commitSha = pathname.split('/')[2];
    params.set('redir', `/sneak-preview/${commitSha}/`);
  }
  const url = `${protocol}//${host}/signin`;
  const qs = params.toString();
  return qs ? `${url}?${qs}` : url;
};

// Joins an array of scopes into a space separated string.
// Inner arrays are joined with a ":" (i.e "scope:value")
export const compileScope = (scopes: Array<string | string[]>) => {
  return scopes
    .map((scope) => {
      if (typeof scope === 'string') return scope;
      return scope.join(':');
    })
    .join(' ');
};

const parseRedirectParam = (location: Location) => {
  const redirQueryParam = new URLSearchParams(location.search).get('redir');
  if (!redirQueryParam || redirQueryParam.startsWith('/signin')) {
    return undefined;
  }
  return redirQueryParam;
};

export const parseRedirectPath = (location: Location) => {
  const redir = parseRedirectParam(location) || Routes.Root;
  if (inSneakPreview(location.pathname) && !inSneakPreview(redir)) {
    const commitSha = sneakPreviewCommitSha(location.pathname);
    return `/sneak-preview/${commitSha}${redir}`;
  }
  return redir;
};

export const parseExternalCompanyId = (location: Location) => {
  const externalCompanyId = new URLSearchParams(location.search).get(
    EXTERNAL_COMPANY_ID_PARAM_NAME
  );
  if (!externalCompanyId) {
    return undefined;
  }
  return externalCompanyId;
};

export const OLD_TOKEN_SESSION_STORAGE_KEY = 'auth-token';
export const OLD_SSO_LOGIN_STORAGE_KEY = 'SSO_LOGIN';

const dec2hex = (dec: number) => {
  // dec2hex :: Integer -> String
  // i.e. 0-255 -> '00'-'ff'
  return dec.toString(16).padStart(2, '0');
};

export const generateStateString = (stateLength: number) => {
  // We need to generate a random string of statelength

  // The resulting string will be twice as long as the random bytes you generate;
  // each byte encoded to hex is 2 characters. 20 bytes will be 40 characters of hex
  // so we divide our Uint8Array length by 2, generate our string, convert decimals to hex
  // and return the resulting secure random string of length state length
  const arrayOfRandomValues = new Uint8Array(stateLength / 2);
  window.crypto.getRandomValues(arrayOfRandomValues);
  return Array.from(arrayOfRandomValues, dec2hex).join('');
};

export const assignNewLocation = (location: Location, redirectPath: string) => {
  location.assign(location.origin + redirectPath);
};

export const retryRequest = async <T>(description: string, action: () => Promise<Response<T>>) => {
  return await promiseRetry(
    async (retry) => {
      try {
        const response = await action();
        if (response.status < 400) {
          return response.body as T;
        }

        const code = getErrorCodeFromResponse(response);
        if (code === 'INVALID_PASSWORD') {
          throw InvalidPasswordError.fromResponse(`Could not ${description}`, response);
        } else if (code === 'NEEDS_PASSWORD_RESET') {
          throw NeedsPasswordResetError.fromResponse(`Could not ${description}`, response);
        }

        throw AuthError.fromResponse(`Could not ${description}`, response);
      } catch (err) {
        const error = AuthError.fromError(`Could not ${description}`, err);
        if (error.statusCode && error.statusCode >= 500) {
          // Only retry on 5xx errors
          return retry(error);
        }
        throw error;
      }
    },
    {
      retries: 2,
      minTimeout: 200,
    }
  );
};

export const buildAuthError = (code: string, message: string) => {
  if (code === 'INVALID_PASSWORD') {
    return InvalidPasswordError.fromGqlError(code, message);
  }
  if (code === 'NEEDS_PASSWORD_RESET') {
    return NeedsPasswordResetError.fromGqlError(code, message);
  }
  return new AuthError(code, message, { statusCode: 401 });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseAuthErrors = (errors: any[] | null): AuthError => {
  if (!errors?.length) {
    return new AuthError('Could not authenticate', 'Unknown error occurred');
  }
  const { code = '', description = '' } = errors[0];
  return new AuthError(code, description, { statusCode: 401 });
};
