/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Cookie = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M21 12c0-1.312-.28-2.557-.785-3.681a2.5 2.5 0 0 1-4.06-.949 4 4 0 0 1-6.077-4.165C6.032 4.086 3 7.69 3 12a9 9 0 1 0 18 0ZM7 9h2v3H7V9Zm1.232 7.146 1.414-1.414 2.122 2.122-1.415 1.414-2.12-2.122Zm5.623-1.439v-2h3v2h-3Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Cookie);
export default Memo;
