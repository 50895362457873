/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MobileDisplay = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18 21H6V3h12v18ZM7.5 19.5h9v-15h-9v15Z" />
    <path d="M12 18a1.125 1.125 0 1 0 0-2.25A1.125 1.125 0 0 0 12 18Z" />
  </svg>
);

const Memo = memo(MobileDisplay);
export default Memo;
