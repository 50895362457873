import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        fill="#53BAD3"
        fillRule="evenodd"
        d="M13.447 6.78L9.296 8.947l1.259.713 3.936-2.332-1.044-.548zm4.13 2.163l-1.822-.954-3.984 2.361 1.672.948 4.134-2.355zm-4.516 3.004L11.389 11v1.71l-1.21-.685v-1.712l-1.28-.726v4.954l4.162 2.34v-4.934zm4.93 2.577v-4.95c-1.402.8-2.789 1.589-4.168 2.374v4.965l4.168-2.39zM8.145 8.698l5.302-2.767 5.296 2.773v6.257l-5.264 3.018-5.334-2.997V8.698z"
        clipRule="evenodd"
      />
      <path
        fill="#53BAD3"
        fillRule="evenodd"
        d="M18.704 3.728l-.494-1.19.78-.326 1.26 3.029-3.179.267-.07-.843 1.362-.115A8.287 8.287 0 0014.6 3.102c-2.494-.305-4.749.304-6.71 1.883-3.863 3.112-4.358 8.7-1.298 12.455 3.168 3.89 9.133 4.444 12.963.785l.58.603c-3.138 3.085-8.433 3.764-12.437.818-4.076-2.998-5.16-8.624-2.476-12.903 1.429-2.278 3.474-3.74 6.101-4.327 2.625-.585 5.084-.115 7.381 1.312z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const ReChargeColor = React.memo(Icon);
