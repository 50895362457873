import { get } from '@attentive/nodash';
import set from 'lodash/set';
import { AcoreUtilsConfig } from './types';

const sharedStorageKey = '__ACORE';

declare global {
  interface Window {
    [sharedStorageKey]: AcoreUtilsConfig;
  }
}

/**
 * Config static class.
 *
 * The `Config` class is a singleton class that allows configuration items to be shared across apps.
 * The `Config` reads the window object `__ACORE` where all keys in the object represent a key in the
 * `Config`.
 *
 * @static
 * @deprecated use Jotai atoms + env vars for global config state
 */
export abstract class Config {
  /**
   * Initializes the storage key on the DOM.
   *
   * Should only be called once. Currently done by the boot loader
   *
   * @param {Object} config
   *
   * @public
   * @static
   */
  public static init(config: AcoreUtilsConfig) {
    window[sharedStorageKey] = config;
  }

  /**
   * Sets a value of a given a key path. i.e. "object.test.value"
   *
   * **Will override existing value**
   *
   * @param {string} keyPath
   * @param {any} value
   *
   * @public
   * @static
   * @deprecated use Jotai atoms + env vars for global config state
   */
  public static set<KeyType extends keyof AcoreUtilsConfig>(
    keyPath: KeyType,
    value: AcoreUtilsConfig[KeyType]
  ): void {
    window[sharedStorageKey] = window[sharedStorageKey] || {};
    set(window[sharedStorageKey], keyPath, value);
  }

  /**
   * Gets the value of a given a key path. i.e. "object.test.value"
   *
   * @param {string} keyPath
   *
   * @public
   * @static
   * @deprecated use Jotai atoms + env vars for global config state
   */
  public static get<KeyType extends keyof AcoreUtilsConfig>(
    keyPath: KeyType
  ): AcoreUtilsConfig[KeyType] {
    return get(window[sharedStorageKey], keyPath);
  }
}
