import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        fill="#95BF47"
        d="M18.748 5.73a.191.191 0 00-.181-.165c-.082 0-1.529-.033-1.529-.033s-1.216-1.183-1.331-1.298c-.115-.115-.362-.082-.444-.05 0 0-.23.066-.608.181a4.817 4.817 0 00-.296-.723c-.428-.822-1.069-1.266-1.825-1.266-.05 0-.098 0-.164.017-.017-.033-.05-.05-.066-.082-.329-.362-.756-.526-1.266-.51-.986.033-1.972.74-2.761 2.005-.559.888-.986 2.006-1.118 2.877-1.134.345-1.923.592-1.94.608-.575.181-.591.197-.657.74-.05.41-1.562 12-1.562 12L15.56 22.2l5.44-1.349c-.016-.016-2.235-15.024-2.251-15.122zM14.03 4.563c-.296.082-.625.197-.97.295 0-.493-.066-1.2-.296-1.791.74.148 1.102.986 1.266 1.496zm-1.644.51c-.657.196-1.38.426-2.104.64.197-.772.592-1.545 1.052-2.055.181-.18.428-.394.707-.526.296.576.362 1.381.345 1.94zm-1.331-2.614c.23 0 .427.049.592.147a3.218 3.218 0 00-.773.609c-.625.674-1.101 1.726-1.299 2.728-.591.181-1.183.362-1.725.526.345-1.578 1.676-3.961 3.205-4.01z"
      />
      <path
        fill="#5E8E3E"
        d="M18.567 5.565c-.082 0-1.529-.033-1.529-.033s-1.216-1.183-1.331-1.298a.313.313 0 00-.165-.082v18.032l5.441-1.348-2.235-15.123a.193.193 0 00-.181-.148z"
      />
      <path
        fill="#fff"
        d="M12.518 8.36l-.625 2.367s-.707-.33-1.545-.263c-1.233.082-1.233.854-1.233 1.052.066 1.052 2.844 1.282 3.008 3.764.115 1.956-1.035 3.287-2.695 3.386-2.006.131-3.107-1.052-3.107-1.052l.427-1.808s1.101.838 1.99.772c.574-.032.788-.51.772-.838-.082-1.38-2.35-1.299-2.499-3.567-.115-1.907 1.134-3.846 3.896-4.01 1.068-.083 1.61.197 1.61.197z"
      />
    </svg>
  );
};

export const ShopifyColor = React.memo(Icon);
