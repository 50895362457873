import React, {
  ReactNode,
  useState,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook';
import { Box, BoxProps } from '../Box';
import { Checkbox } from '../Checkbox';
import { Text, TextProps } from '../Text';

import { SortIcon } from './SortIcon';

const cellPadding = '$space4 $space6';
const ENTER_KEYS = [' ', 'Enter'];
const focusableRowBorderCss = '$borderWidths$borderWidth1 solid transparent';
const focusableRowHoverCss = { backgroundColor: '$bgRowHover' };
const focusableRowFocusCss = {
  borderColor: '$borderFocus',
  ...focusableRowHoverCss,
};

export interface TableProps {
  children?: ReactNode;
  css?: PicnicCss;
  onClick?: () => void;
  textVariant?: Extract<TextProps['variant'], 'body' | 'caption'>;
}

export interface TableCellProps extends TableProps {
  align?: 'center' | 'left' | 'right';
}

export interface SortableHeaderCellProps extends TableCellProps {
  onChange: () => void;
  isSortActive?: boolean;
  ascending?: boolean;
}

export interface FocusWrapperProps extends Omit<TableProps, 'onClick'> {
  onKeyDown: () => void;
}

export interface TablePrimitiveProps extends TableProps {
  columns?: number | number[];
  columnSizes?: string | string[];
}

interface TableContextType {
  selectableRows: Map<string, boolean>;
  registerSelectableRow: (id: string, checked: boolean) => void;
  unregisterSelectableRow: (id: string) => void;
}

const TableContext = createContext<TableContextType>({
  selectableRows: new Map(),
  registerSelectableRow: () => {},
  unregisterSelectableRow: () => {},
});

const TablePrimitive = React.forwardRef<HTMLDivElement, TablePrimitiveProps>(
  ({ children, columns, columnSizes, css, textVariant = 'body', ...rest }, ref) => {
    let gridTemplateColumns;
    if (Array.isArray(columns)) {
      gridTemplateColumns = columns.map((c) => `minmax(0, ${c}fr)`).join(' ');
    } else if (Number.isInteger(columns)) {
      gridTemplateColumns = `repeat(${columns}, minmax(0, 1fr))`;
    }
    if (columnSizes) {
      gridTemplateColumns = Array.isArray(columnSizes) ? columnSizes.join(' ') : columnSizes;
    }

    const [selectableRows, setSelectableRows] = useState<Map<string, boolean>>(new Map());

    const registerSelectableRow = useCallback(
      (id: string, checked: boolean) => {
        setSelectableRows((prev) => {
          if (prev.has(id)) {
            return prev;
          }

          return new Map([...prev, [id, checked]]);
        });
      },
      [setSelectableRows]
    );

    const unregisterSelectableRow = useCallback(
      (id: string) => {
        setSelectableRows((prev) => {
          prev.delete(id);
          return new Map(prev);
        });
      },
      [setSelectableRows]
    );

    const value = useMemo(
      () => ({
        selectableRows,
        registerSelectableRow,
        unregisterSelectableRow,
      }),
      [selectableRows, registerSelectableRow, unregisterSelectableRow]
    );

    return (
      <TableContext.Provider value={value}>
        <Text
          as="div"
          ref={ref}
          role="table"
          variant={textVariant}
          css={{
            display: 'grid',
            gridTemplateColumns,
            ...css,
          }}
          {...rest}
        >
          {children}
        </Text>
      </TableContext.Provider>
    );
  }
);

const cellAlignVariants = {
  left: {
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
  },
  right: {
    justifyContent: 'flex-end',
  },
};

const BodyCellPrimitive = styled('div', {
  padding: cellPadding,
  overflowWrap: 'break-word',
  minHeight: '$size16',
  height: '100%',
  borderBottom: '$borderWidths$borderWidth1 solid $borderDefault',
  display: 'flex',
  alignItems: 'center',
  variants: {
    align: cellAlignVariants,
  },
  defaultVariants: {
    align: 'left',
  },
});

const HeaderCellPrimitive = styled('div', {
  padding: cellPadding,
  fontWeight: '$bold',
  borderBottom: '$borderWidths$borderWidth1 solid $borderDefault',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  variants: {
    align: cellAlignVariants,
  },
  defaultVariants: {
    align: 'left',
  },
});

const HeaderCellFocusWrapper = styled('div', {
  display: 'flex',
  '&:hover': { color: '$textHover', cursor: 'pointer' },
  '&:focus': { color: '$textDefault' },
  '&:hover:focus': { color: '$textHover' },
  '&:hover > [data-sort-icon], &:focus > [data-sort-icon]': { visibility: 'visible' },
  focusVisible: '$focus',
  variants: {
    align: {
      left: {},
      center: {},
      right: {
        flexDirection: 'row-reverse',
        '& > [data-sort-icon]': {
          marginRight: '$space2',
        },
      },
    },
  },
});

const triggerKeyHandler = (handler: () => void) => (event: React.KeyboardEvent) => {
  if (ENTER_KEYS.includes(event.key)) {
    event.preventDefault();
    event.stopPropagation();
    handler();
  }
};

const Header: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Box role="rowgroup" css={{ display: 'contents' }}>
    {children}
  </Box>
);

const HeaderRow: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Box role="row" css={{ display: 'contents' }}>
    {children}
  </Box>
);

const HeaderCell = React.forwardRef<HTMLDivElement, TableCellProps>(
  ({ children, css, ...rest }: TableProps, ref) => (
    <HeaderCellPrimitive ref={ref} role="columnheader" css={css} {...rest}>
      {children}
    </HeaderCellPrimitive>
  )
);

const SortableHeaderCell = React.forwardRef<HTMLDivElement, SortableHeaderCellProps>(
  (
    { children, isSortActive = false, ascending = false, css = {}, onChange, align, ...rest },
    ref
  ) => {
    const getAriaSortValue = () => {
      if (!isSortActive) return undefined;
      return ascending ? 'ascending' : 'descending';
    };

    return (
      <HeaderCell ref={ref} align={align} aria-sort={getAriaSortValue()} css={css} {...rest}>
        <HeaderCellFocusWrapper
          tabIndex={0}
          role="button"
          onClick={onChange}
          onKeyDown={triggerKeyHandler(onChange)}
          align={align}
        >
          {children}
          <SortIcon
            aria-hidden={true}
            ascending={ascending}
            visible={true}
            data-sort-icon
            css={{
              visibility: isSortActive ? 'visible' : 'hidden',
              flexShrink: 0,
              alignSelf: 'center',
            }}
          />
        </HeaderCellFocusWrapper>
      </HeaderCell>
    );
  }
);

const Body: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Box role="rowgroup" css={{ display: 'contents' }}>
    {children}
  </Box>
);

const BodyRow: React.FC<React.PropsWithChildren<Omit<BoxProps, 'css'>>> = ({
  children,
  ...rest
}) => (
  <Box {...rest} role="row" css={{ display: 'contents' }}>
    {children}
  </Box>
);

const BodyFocusableRow: React.FC<React.PropsWithChildren<Omit<BoxProps, 'css'>>> = ({
  children,
  onClick = () => {},
  ...rest
}) => {
  const [showFocusStyling, setShowFocusStyling] = useState(true);

  const hoverCss = showFocusStyling ? focusableRowHoverCss : {};
  const focusCss = showFocusStyling ? focusableRowFocusCss : {};
  const rowCss = {
    display: 'contents',
    '& > [role=cell]': { cursor: 'pointer', borderTop: focusableRowBorderCss },
    '& > [role=cell]:first-child': { borderLeft: focusableRowBorderCss },
    '& > [role=cell]:last-child': { borderRight: focusableRowBorderCss },
    '&:hover > [role=cell]': hoverCss,
    // <Popup>s add an empty div to anchor to (no class, no id, etc)
    // This is so that the anchor of the popup has the correct hover bg color
    '&:hover > div:not([class]) > [role=cell]': hoverCss,
    '&:focus-within > [role=cell]': focusCss,
  };

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as HTMLDivElement;
    const handleClickEvent = !target.closest('[data-disable-focus]');
    const clickedOnRowChild = target.closest('[role=row]') === event.currentTarget;

    if (handleClickEvent && clickedOnRowChild) {
      onClick(event);
    }
  };

  const onFocusHandler = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLDivElement;
    if (target.matches('[data-disable-focus]')) {
      setShowFocusStyling(false);
    }
  };

  const onBlurHandler = () => {
    if (!showFocusStyling) {
      setShowFocusStyling(true);
    }
  };

  return (
    <Box
      {...rest}
      role="row"
      onClick={onClickHandler}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      css={rowCss}
    >
      {children}
    </Box>
  );
};

const BodyCell = React.forwardRef<HTMLDivElement, TableCellProps>(
  ({ children, align, css, ...rest }, ref) => (
    <BodyCellPrimitive ref={ref} align={align} role="cell" css={css} {...rest}>
      {children}
    </BodyCellPrimitive>
  )
);

interface RowSelectorCellProps extends TableCellProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  value: string;
  'aria-label'?: string;
  'aria-labelledby'?: string;
}

const RowSelectorCell = React.forwardRef<HTMLDivElement, RowSelectorCellProps>(
  (
    {
      align,
      css,
      checked,
      onChange,
      value,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby,
      ...rest
    },
    ref
  ) => {
    const { registerSelectableRow, unregisterSelectableRow } = useContext(TableContext);

    useEffect(() => {
      registerSelectableRow(value, checked);

      return () => {
        unregisterSelectableRow(value);
      };
    }, [value, checked, registerSelectableRow, unregisterSelectableRow]);

    const handleCheck = () => {
      onChange(!checked);
    };

    return (
      <BodyCellPrimitive
        ref={ref}
        align={align}
        role="cell"
        css={{
          ...css,
          '&:hover [role=checkbox]': { opacity: 1 },
          '&:not(:hover) [role=checkbox]:not(:focus-visible)': { opacity: checked ? 1 : 0 },
          '[role=checkbox]': {
            transition: 'opacity 0.3s ease',
          },
          justifyContent: 'center',
        }}
        {...rest}
      >
        <Checkbox.CheckboxItem
          checked={checked}
          onChange={handleCheck}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledby}
        />
      </BodyCellPrimitive>
    );
  }
);

interface HeaderSelectorCellProps extends TableCellProps {
  onChange: (checked: boolean) => void;
  'aria-label'?: string;
  'aria-labelledby'?: string;
}

const HeaderSelectorCell = React.forwardRef<HTMLDivElement, HeaderSelectorCellProps>(
  (
    { align, css, onChange, 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledby, ...rest },
    ref
  ) => {
    const tableContext = useContext(TableContext);

    const getCheckedState = () => {
      if (tableContext.selectableRows.size === 0) return false;
      const values = Array.from(tableContext.selectableRows.values());
      const allChecked = values.every((checked) => checked);
      const someChecked = values.some((checked) => checked);

      if (allChecked) return true;
      if (someChecked) return 'indeterminate';
      return false;
    };

    const handleCheck = () => {
      const checkedState = getCheckedState();
      const checkedVal = checkedState === 'indeterminate' ? false : !checkedState;
      onChange(checkedVal);
    };

    return (
      <BodyCellPrimitive
        ref={ref}
        align={align}
        role="cell"
        css={{ ...css, justifyContent: 'center' }}
        {...rest}
      >
        <Checkbox.CheckboxItem
          checked={getCheckedState()}
          onChange={handleCheck}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledby}
        />
      </BodyCellPrimitive>
    );
  }
);

const FocusWrapper: React.FC<React.PropsWithChildren<FocusWrapperProps>> = ({
  children,
  css = {},
  onKeyDown,
  ...rest
}) => (
  <Box
    as="div"
    role="button"
    tabIndex={0}
    onKeyDown={triggerKeyHandler(onKeyDown)}
    css={{ outline: 'none', ...css }}
    {...rest}
  >
    {children}
  </Box>
);

type ComponentType = typeof TablePrimitive & DisplayNamed;
interface CompositeComponent extends ComponentType {
  RowSelectorCell: typeof RowSelectorCell & DisplayNamed;
  HeaderSelectorCell: typeof HeaderSelectorCell & DisplayNamed;
  Header: typeof Header & DisplayNamed;
  HeaderRow: typeof HeaderRow & DisplayNamed;
  HeaderCell: typeof HeaderCell & DisplayNamed;
  SortableHeaderCell: typeof SortableHeaderCell & DisplayNamed;
  Body: typeof Body & DisplayNamed;
  BodyRow: typeof BodyRow & DisplayNamed;
  BodyFocusableRow: typeof BodyFocusableRow & DisplayNamed;
  BodyCell: typeof BodyCell & DisplayNamed;
  FocusWrapper: typeof FocusWrapper & DisplayNamed;
}

const Table = TablePrimitive as CompositeComponent;
Table.Header = Header;
Table.HeaderRow = HeaderRow;
Table.HeaderCell = HeaderCell;
Table.SortableHeaderCell = SortableHeaderCell;
Table.Body = Body;
Table.BodyRow = BodyRow;
Table.BodyFocusableRow = BodyFocusableRow;
Table.BodyCell = BodyCell;
Table.RowSelectorCell = RowSelectorCell;
Table.HeaderSelectorCell = HeaderSelectorCell;
Table.FocusWrapper = FocusWrapper;

Table.displayName = 'Table';
Table.Header.displayName = 'Table.Header';
Table.HeaderRow.displayName = 'Table.HeaderRow';
Table.HeaderCell.displayName = 'Table.HeaderCell';
Table.SortableHeaderCell.displayName = 'Table.SortableHeaderCell';
Table.Body.displayName = 'Table.Body';
Table.BodyRow.displayName = 'Table.BodyRow';
Table.BodyFocusableRow.displayName = 'Table.BodyFocusableRow';
Table.BodyCell.displayName = 'Table.BodyCell';
Table.FocusWrapper.displayName = 'Table.FocusWrapper';

export { Table };
