import React from 'react';

import { PicnicCss } from '../../stitches.config';
import { Icon } from '../Icon';

type SortIconProps = {
  ascending: boolean;
  visible: boolean;
  css?: PicnicCss;
};

export const SortIcon = ({ ascending, visible, css, ...rest }: SortIconProps) => {
  if (!visible) {
    return null;
  }
  return (
    <Icon
      name="ArrowRight"
      size="small"
      css={{
        marginLeft: '$space1',
        ...css,
        ...({
          transform: ascending ? 'rotate(-90deg)' : 'rotate(90deg)',
          defaultTransition: ['transform'],
        } as PicnicCss),
      }}
      {...rest}
    />
  );
};
