import { styled, keyframes } from '../../stitches.config';

const placeHolderShimmer = keyframes({
  from: { backgroundPosition: '-600px 0' },
  to: { backgroundPosition: '100vw 0' },
});

const LoadingPlaceholder = styled('div', {
  position: 'relative',
  background: '$bgPlaceholder',
  backgroundImage: `
    linear-gradient(
      to right,
      $bgPlaceholder 0%,
      $bgPlaceholderAlt 20%,
      $bgPlaceholder 40%,
      $bgPlaceholder 100%
    )
  `,
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '600px 100%',
  borderRadius: '$radius1',

  variants: {
    variant: {
      shimmer: {
        animation: `2s linear infinite forwards ${placeHolderShimmer}`,
      },
      static: {},
    },
  },

  defaultVariants: {
    variant: 'shimmer',
  },
});
LoadingPlaceholder.displayName = 'LoadingPlaceholder';

export { LoadingPlaceholder };
