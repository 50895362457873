/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Headset = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M6.94 11.25a5.063 5.063 0 0 1 10.119 0h1.876a6.938 6.938 0 0 0-13.87 0H6.94Zm0 0H3v5.25h3.94v-5.25Zm10.12 0H21v5.25h-2.064v2.27h-.001v.357h-5.626V17.25h3.75v-6Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Headset);
export default Memo;
