import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { NotFound } from '@attentive/acore-utils';
import { MultiFactorAuth } from '@attentive/auth-ui';
import { Box, DEFAULT_THEME, PicnicCss, usePicnicStyles } from '@attentive/picnic';

import { AuthorizeApp } from './pages/AuthorizeApp';
import { AuthorizeZendeskApp } from './pages/AuthorizeZendeskApp';
import { Error } from './pages/Error';
import SignIn from './pages/SignIn';
import { Routes as AuthRoutes } from './utils/routes';

const rootStyles: PicnicCss = {
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
};

const App: React.FC = () => {
  usePicnicStyles(DEFAULT_THEME);
  const location = useLocation();
  const paths = ['/index.html', '/login', '/signin', '/'];
  const redirects = paths.flatMap((path) => {
    const zendeskPath = `/zendesk${path}`;
    return [
      <Route
        key={path}
        path={path}
        element={<Navigate to={`${AuthRoutes.Authorize}${location.search}`} replace />}
      />,
      <Route
        key={zendeskPath}
        path={zendeskPath}
        element={<Navigate to={`${AuthRoutes.ZendeskAuthorize}${location.search}`} replace />}
      />,
    ];
  });

  return (
    <Box css={rootStyles}>
      <Routes>
        {redirects}
        <Route path={AuthRoutes.Authorize} element={<SignIn />} />
        <Route path={AuthRoutes.Callback} element={<AuthorizeApp />} />
        <Route path={`${AuthRoutes.Mfa}/*`} element={<MultiFactorAuth />} />
        <Route path={AuthRoutes.Error} element={<Error />} />
        <Route path={AuthRoutes.NotFound} element={<NotFound />} />
        <Route path={AuthRoutes.ZendeskAuthorize} element={<SignIn zendesk />} />
        <Route path={AuthRoutes.ZendeskCallback} element={<AuthorizeZendeskApp />} />
        <Route path="*" element={<Navigate to={AuthRoutes.NotFound} />} />
      </Routes>
    </Box>
  );
};

export default App;
