import React from 'react';

const INVALID_LABEL_ERROR = 'SearchableSelect.Item should have a text node for the label';

export const getLabelText = (labelChildren: string | Array<string | React.ReactNode>) => {
  if (typeof labelChildren === 'string') return labelChildren;
  if (!Array.isArray(labelChildren)) {
    throw new Error(INVALID_LABEL_ERROR);
  }

  const foundLabel = labelChildren.find((element) => typeof element === 'string');

  if (typeof foundLabel !== 'string') {
    throw new Error(INVALID_LABEL_ERROR);
  }

  return foundLabel.trim();
};

const isPrimitive = (child: React.ReactNode): child is string | number =>
  typeof child === 'string' || typeof child === 'number';

export const getAllSearchableText = (labelChildren: React.ReactNode): string => {
  if (Array.isArray(labelChildren)) {
    const joinedLabel: string[] = [];

    React.Children.toArray(labelChildren).forEach((child) => {
      if (React.isValidElement(child)) {
        joinedLabel.push(getAllSearchableText(child));
      }
      if (isPrimitive(child)) {
        joinedLabel.push(child.toString());
      }
    });

    return joinedLabel.join(' ').trim();
  }

  if (isPrimitive(labelChildren)) {
    return labelChildren.toString();
  }

  if (React.isValidElement(labelChildren) && labelChildren?.props?.children) {
    return getAllSearchableText(labelChildren.props.children);
  }

  return '';
};
