import React, { ReactNode } from 'react';

import { Box, Heading, Icon, Wordmark } from '@attentive/picnic';

interface Props {
  children: ReactNode;
}

export const ChallengeUIComponent = ({ children }: Props) => {
  return (
    <Box
      css={{
        backgroundColor: '$yellow300',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        padding: '$space8',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Box
          css={{
            backgroundColor: '$yellow200',
            width: 560,
            padding: '$space8',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Heading variant="lg" css={{ mb: '$space4', display: 'flex', alignItems: 'center' }}>
            <Icon name="ShieldPerson" /> Authenticate to FIDO2
          </Heading>
          {children}
        </Box>
      </Box>
      <Wordmark css={{ width: '10vw', position: 'absolute', bottom: '$space8' }} />
    </Box>
  );
};
