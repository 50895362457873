// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/words.ts

import { unicodeWords } from '../.internal/unicodeWords';

const hasUnicodeWord = RegExp.prototype.test.bind(
  /[a-z][A-Z]|[A-Z]{2}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9 ]/
);

// eslint-disable-next-line no-control-regex
const reAsciiWord = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g;

function asciiWords(string: string) {
  return string.match(reAsciiWord);
}

/**
 * Splits `string` into an array of its words.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function words(string?: string, pattern?: string | RegExp): string[] {
  if (string === undefined) {
    return [];
  }
  if (pattern === undefined) {
    const result = hasUnicodeWord(string) ? unicodeWords(string) : asciiWords(string);
    return result || [];
  }
  return string.match(pattern) || [];
}
