/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const TextInputOutline = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.5 8.427h.879l.444.254v6.638l-.444.254H4.5v1.854h1.371l.879-.502.879.502H9v-1.854h-.879l-.444-.254V8.681l.444-.254H9V6.573H7.629l-.879.502-.879-.502H4.5v1.854Z" />
    <path
      fillRule="evenodd"
      d="M1.5 3.75h21v16.5h-21V3.75Zm2 2h17v12.5h-17V5.75Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(TextInputOutline);
export default Memo;
