import React from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { PicnicColorsToken } from '../../themes';
import { Box } from '../Box';

export enum LogoVariant {
  DEFAULT = 'default',
}

type LogoVariantMapping = {
  [key in LogoVariant]: { circleColor: PicnicColorsToken; textColor: PicnicColorsToken };
};

const logoVariants: LogoVariantMapping = {
  [LogoVariant.DEFAULT]: {
    textColor: '$iconDefault',
    circleColor: '$bgBrand',
  },
};

const Svg = styled('svg', {});

type SvgProps = React.ComponentProps<typeof Svg>;

interface LogomarkProps extends SvgProps {
  title?: string;
  variant?: LogoVariant;
  css?: PicnicCss;
}

const LogomarkSvg = ({ title, css, variant = LogoVariant.DEFAULT, ...props }: LogomarkProps) => {
  return (
    <Svg viewBox="0 0 64 64" css={css} {...props}>
      {title && <title>{title}</title>}
      <Box as="circle" cx="32" cy="32" r="32" css={{ fill: logoVariants[variant].circleColor }} />
      <Box
        as="path"
        d="M37.9447 19.5829L47.8558 38.2211C48.2725 38.9855 48.5334 39.8248 48.6236 40.6907C48.7138 41.5565 48.6315 42.4316 48.3813 43.2654C48.1312 44.0992 47.7183 44.8752 47.1664 45.5484C46.6145 46.2216 45.9346 46.7787 45.166 47.1875C44.3975 47.5963 43.5555 47.8487 42.6888 47.93C41.8221 48.0114 40.9479 47.9201 40.1167 47.6615C39.2855 47.4029 38.5138 46.982 37.8462 46.4233C37.1787 45.8646 36.6285 45.1791 36.2276 44.4064C36.2276 44.4064 30.4547 33.5852 28.982 30.7547C28.6535 30.1055 28.1886 29.5349 27.6192 29.082C27.0497 28.629 26.3891 28.3045 25.6827 28.1305C24.9762 27.9566 24.2404 27.9373 23.5258 28.074C22.8111 28.2107 22.1345 28.5002 21.5421 28.9227C21.4878 28.9812 21.4124 29.0159 21.3326 29.0189C21.2528 29.0219 21.175 28.9931 21.1165 28.9389C21.0579 28.8846 21.0233 28.8092 21.0202 28.7294C21.0172 28.6496 21.046 28.5718 21.1003 28.5133L26.4431 19.3591C27.0341 18.3462 27.8848 17.5095 28.9074 16.9354C29.9299 16.3613 31.0872 16.0706 32.2597 16.0934C33.4321 16.1163 34.5772 16.4517 35.5767 17.0652C36.5761 17.6786 37.3936 18.5478 37.9447 19.5829ZM22.5818 47.8966C24.0142 47.8966 25.4145 47.4719 26.6056 46.676C27.7966 45.8802 28.7249 44.7491 29.2731 43.4256C29.8213 42.1022 29.9647 40.646 29.6852 39.241C29.4058 37.8361 28.716 36.5456 27.7031 35.5327C26.6902 34.5198 25.3997 33.83 23.9947 33.5505C22.5898 33.2711 21.1336 33.4145 19.8101 33.9627C18.4867 34.5109 17.3556 35.4392 16.5598 36.6302C15.7639 37.8213 15.3391 39.2215 15.3391 40.654C15.3399 42.5746 16.1032 44.4164 17.4613 45.7744C18.8194 47.1325 20.6611 47.8958 22.5818 47.8966Z"
        css={{ fill: logoVariants[variant].textColor }}
      />
    </Svg>
  );
};

export const Logomark = React.memo(LogomarkSvg);
