/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const EnvelopeFilled = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M3 6.62V19.5h18V6.62l-9 9-9-9ZM20.998 4.5H3.002L12 13.498 20.998 4.5Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(EnvelopeFilled);
export default Memo;
