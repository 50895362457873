const safariOnly = (value: {}) => ({
  // Work around utils typing error
  content: 'picnicSafariOnly',
  // See https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none)': {
      ...value,
    },
  },
});

export default { safariOnly };
