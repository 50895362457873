import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.447 6.783c-1.382.72-2.76 1.44-4.151 2.166l1.259.714L14.49 7.33l-1.044-.547zm4.13 2.163l-1.822-.954-3.984 2.361c.57.322 1.122.636 1.672.948l4.134-2.355zM13.06 11.95l-1.672-.948v1.711l-1.21-.685v-1.713l-1.28-.726v4.955l4.162 2.34V11.95zm4.93 2.576v-4.95l-4.168 2.375v4.965l4.168-2.39zM8.145 8.7c1.761-.919 3.53-1.841 5.302-2.767l5.296 2.774v6.256l-5.264 3.019-5.334-2.998V8.7z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.704 3.731l-.494-1.19.78-.326 1.26 3.029-3.179.266-.07-.842 1.362-.116A8.287 8.287 0 0014.6 3.105c-2.494-.306-4.749.303-6.71 1.882-3.863 3.113-4.358 8.7-1.298 12.456 3.168 3.889 9.133 4.444 12.963.785l.58.602c-3.138 3.086-8.433 3.765-12.437.818-4.076-2.998-5.16-8.623-2.476-12.902 1.429-2.279 3.474-3.74 6.101-4.327 2.625-.586 5.084-.115 7.381 1.312z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const ReChargeWhite = React.memo(Icon);
