/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const BrandKit = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M22.196 11 17 2l-5.196 9h10.392ZM2 7h9v9H2V7ZM22 17a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z" />
  </svg>
);

const Memo = memo(BrandKit);
export default Memo;
