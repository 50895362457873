/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Pencil = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m21 6.471-3.471-3.47-2.316 2.315 3.471 3.471L21 6.471ZM17.622 9.849l-3.47-3.471-9.107 9.105 3.472 3.472 9.105-9.106ZM7.454 20.018l-3.472-3.472L3 21l4.454-.982Z" />
  </svg>
);

const Memo = memo(Pencil);
export default Memo;
