/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ConsolidatedCreatives = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M7.202 22.8h14.4V1.2h-14.4v21.6Zm12.6-1.8h-10.8V3h10.8v18Z"
      clipRule="evenodd"
    />
    <path d="M3.6 3h1.8v18H3.6V3ZM0 6h1.8v12H0V6Z" />
  </svg>
);

const Memo = memo(ConsolidatedCreatives);
export default Memo;
