import React from 'react';

import { impersonatePicnicComponent } from '../../utils/impersonate-picnic-component';
import { FormField as StyledFormField } from '../FormField';

import { useFormFieldName } from './FormField';

type Props = React.ComponentProps<typeof StyledFormField.Label>;

const Label = impersonatePicnicComponent((props: Props) => {
  const name = useFormFieldName();

  return <StyledFormField.Label {...props} htmlFor={name} />;
}, StyledFormField.Label);

export { Label };
