/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MessageBubbleDotsReversed = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M21 3.749H3v11.984h13.481L21 20.25V3.75Zm-9 7.23a1.237 1.237 0 1 1 0-2.476 1.237 1.237 0 0 1 0 2.475ZM6.787 9.74a1.237 1.237 0 1 0 2.475 0 1.237 1.237 0 0 0-2.475 0Zm9.188 1.237a1.237 1.237 0 1 1 0-2.475 1.237 1.237 0 0 1 0 2.475Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(MessageBubbleDotsReversed);
export default Memo;
