import React from 'react';

import { impersonatePicnicComponent } from '../../utils/impersonate-picnic-component';
import { FormField as StyledFormField } from '../FormField';

type Props = React.ComponentProps<typeof StyledFormField.HelperText>;

const HelperText = impersonatePicnicComponent((props: Props) => {
  return <StyledFormField.HelperText {...props} />;
}, StyledFormField.HelperText);

export { HelperText };
