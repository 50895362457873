import _commitRefreshSessionOrChallengeMutation from "./__generated__/commitRefreshSessionOrChallengeMutation.graphql";
import { commitMutation } from 'react-relay';
import { graphql } from '@attentive/data';
export const CommitRefreshSessionOrChallengeMutation = _commitRefreshSessionOrChallengeMutation;
export const commitRefreshSessionOrChallengeMutation = (environment, variables, onError, onCompleted) => {
  commitMutation(environment, {
    mutation: CommitRefreshSessionOrChallengeMutation,
    variables,
    onCompleted,
    onError
  });
};