/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Lightbulb = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.65 12.75C17.475 11.7 18 10.425 18 9c0-3.3-2.7-6-6-6S6 5.7 6 9c0 1.425.525 2.7 1.35 3.75l2.025 2.7v2.175h5.25v-2.1l2.025-2.775ZM14.625 19.125h-5.25V21h5.25v-1.875Z" />
  </svg>
);

const Memo = memo(Lightbulb);
export default Memo;
