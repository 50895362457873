import { rest, graphql } from 'msw';

import { MOCK_API_BASE_URL } from '@attentive/acore-utils';
import { extractRequestPersona } from '@attentive/mock-data';
import { MSW_ERROR_HEADER, parseMSWHeaders } from '@attentive/mock-data/src/msw-utils';

import {
  authUserPayloadMock,
  credentialsConnectionMock,
  googleConnectionMock,
  samlConnectionMock,
} from './mockData/auth.mock';

let API_URL = MOCK_API_BASE_URL;
if (!API_URL.endsWith('/')) {
  API_URL = `${API_URL}/`;
}

export const getConnectionMock = rest.get(`${API_URL}identity/connection-type`, (req, res, ctx) => {
  const email = req.url.searchParams.get('email');
  if (!email) {
    return res(ctx.status(400, 'please pass an email'));
  }

  if (email.startsWith('sso@') || email.startsWith('saml@')) {
    return res(ctx.json(samlConnectionMock));
  }
  if (email.startsWith('google@')) {
    return res(ctx.json(googleConnectionMock));
  }

  if (email.endsWith('@attentivemobile.com')) {
    return res(ctx.json(googleConnectionMock));
  }

  return res(ctx.json(credentialsConnectionMock));
});

export const getAuthUser = rest.get(`${API_URL}auth/user`, (_req, res, ctx) => {
  return res(ctx.json(authUserPayloadMock));
});

interface RefreshSessionPostBody {
  companyId?: number;
  gqlCompanyId?: string;
  externalCompanyId?: number;
}

export const getRefreshSession = rest.post<RefreshSessionPostBody>(
  `${API_URL}identity/refresh-session`,
  (req, res, ctx) => {
    try {
      const { companyId, gqlCompanyId } = req.body;
      const persona = extractRequestPersona(req.headers);
      return res(
        ctx.json({
          companyId: companyId ?? 1,
          gqlCompanyId: gqlCompanyId ?? 'MDc6Q29tcGFueTE',
          token: persona.id,
        })
      );
    } catch (err) {
      return res(ctx.status(403, 'Invalid mock persona'));
    }
  }
);

export interface MutationMSWErrorHeaders {
  [MSW_ERROR_HEADER]?: string;
}

export const generateZendeskSsoToken = graphql.mutation(
  'ZendeskLinkGenerateZendeskSSOTokenMutation',
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<MutationMSWErrorHeaders>(req.headers);
    const errorMessage = mswHeaders[MSW_ERROR_HEADER];
    const response = errorMessage
      ? ctx.errors([{ message: errorMessage }])
      : ctx.data({
          generateZendeskJwt: {
            jwt: 'zendesk token',
          },
        });
    return res(response);
  }
);

export const refreshSessionOrChallengeMock = graphql.mutation(
  'commitRefreshSessionOrChallengeMutation',
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<MutationMSWErrorHeaders>(req.headers);
    const errorMessage = mswHeaders[MSW_ERROR_HEADER];
    const response = errorMessage
      ? ctx.errors([{ message: errorMessage }])
      : ctx.data({
          refreshSessionOrChallenge: {
            __typename: 'RefreshSessionOrChallengeSuccess',
            response: {
              __typename: 'RefreshSessionResponse',
              company: { id: 'company-31337', internalId: 31337 },
              token: 'an token',
            },
          },
        });
    return res(response);
  }
);

export const mockCredentials = {
  email: 'email@example.com',
  password: 'password',
};

export const loginWithPassword = graphql.mutation(
  'UseLoginWithPasswordMutation',
  (req, res, ctx) => {
    const { input } = req.variables;
    const success =
      input.email === mockCredentials.email && input.password === mockCredentials.password;
    const response = success
      ? {
          __typename: 'LoginWithPasswordSuccessResponse',
          jwtToken: 'some token',
        }
      : {
          __typename: 'LoginWithPasswordFailureResponse',
          code: 'UNAUTHENTICATED',
          message: 'Wrong email or password.',
        };
    return res(ctx.data({ loginWithPassword: { response } }));
  }
);
