import { useFormikContext } from 'formik';
import React from 'react';
import { ValidationError } from 'yup';

import { Button as StyledButton } from '../Button';

import { useFormValidationSchema } from './use-form-validation-schema';

type Props = React.ComponentProps<typeof StyledButton>;

const SubmitButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const validationSchema = useFormValidationSchema();
  const { values, dirty, isSubmitting, isValid: formikIsValid } = useFormikContext();
  const [hasInitialMountValidationError, setHasInitialMountValidationError] = React.useState(false);
  const isValid = dirty ? formikIsValid : !hasInitialMountValidationError;

  // On mount we want to disable the button if the form is invalid.
  // Formik's validation is async. Generally this is fine, but if we use its
  // validation system on mount (Formik's `validateOnMount` prop), then RTL
  // will throw an act() error because the validation may finish after the test.
  // To get around this we use Yup directly and its sync validator for the
  // initial mount check. Once the form has been modified we fall back to
  // Formik's validation state.
  React.useEffect(() => {
    if (validationSchema) {
      try {
        validationSchema.validateSync(values);
      } catch (err) {
        if (err instanceof ValidationError) {
          setHasInitialMountValidationError(true);
        }
      }
    }
    // We only need to run this on mount regardless of deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledButton
      {...props}
      type="submit"
      disabled={!isValid || isSubmitting || props.disabled}
      loading={isSubmitting}
    />
  );
};

export { SubmitButton };
