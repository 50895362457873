import _commitVerifyChallengeMutation from "./__generated__/commitVerifyChallengeMutation.graphql";
import { commitMutation } from 'react-relay';
import { graphql } from '@attentive/data';
export const commitVerifyChallengeMutation = _commitVerifyChallengeMutation;
export const commitVerifyChallenge = (environment, variables, onError, onCompleted) => {
  commitMutation(environment, {
    mutation: commitVerifyChallengeMutation,
    variables,
    onCompleted,
    onError
  });
};