import { Field } from 'formik';
import React from 'react';

import { TextArea as StyledTextArea } from '../TextArea';

import { useFormFieldName, useFormFieldIsErrorShowing } from './FormField';
import { PropsWithoutField } from './utils';

type Props = PropsWithoutField<typeof StyledTextArea>;

const TextArea: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const name = useFormFieldName();
  const isErrorShowing = useFormFieldIsErrorShowing();

  return (
    <Field
      as={StyledTextArea}
      {...props}
      name={name}
      id={name}
      state={isErrorShowing ? 'error' : 'normal'}
    />
  );
};

export { TextArea };
