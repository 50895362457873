/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const CircleQuestion = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M20.999 11.996a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-10.39 4.261a1.253 1.253 0 1 0 2.504 0 1.253 1.253 0 0 0-2.505 0Zm1.42-9.774c-.986 0-1.785.344-2.374.858l-.002.002c-.605.543-.891 1.27-.906 2.178l-.004.225h2.383l.009-.213c.013-.333.1-.553.246-.699.165-.165.36-.245.648-.245.306 0 .498.083.618.214.124.134.205.357.205.708a1.1 1.1 0 0 1-.164.603c-.124.198-.374.478-.778.84l-.003.003c-.409.38-.714.771-.885 1.177-.172.406-.244.934-.244 1.58v.221h2.148l.042-.608a1.66 1.66 0 0 1 .524-1.018l.549-.506.006-.006c.429-.429.745-.816.93-1.186.185-.37.277-.771.277-1.199 0-.896-.285-1.61-.865-2.112-.567-.506-1.368-.817-2.36-.817Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(CircleQuestion);
export default Memo;
