/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const NotificationBell = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.1 15.8c-1.3-.1-1.6-4.6-1.6-5.4 0-2.2-1.5-4-3.5-4.6.2-.3.3-.6.3-1 .1-1-.7-1.8-1.7-1.8s-1.8.8-1.8 1.8c0 .4.1.7.3 1-2 .6-3.4 2.4-3.5 4.6 0 .8-.3 5.4-1.6 5.4H4v2h15v-2h-.9ZM9.3 20.2c.1.3.3.6.5.8.2.2.5.4.8.5.3.1.6.2 1 .2s.7-.1 1-.2c.3-.1.6-.3.8-.5.2-.2.4-.5.5-.8.1-.3.2-.6.2-1h-5c0 .4.1.7.2 1Z" />
  </svg>
);

const Memo = memo(NotificationBell);
export default Memo;
