import { media, MediaToken } from '../media';

type ResponsiveValue<T> = T | T[];
const responsiveRule = <T>(property: string, value: ResponsiveValue<T>) => {
  if (!Array.isArray(value)) {
    return {
      [property]: value,
    };
  }

  const mediaTokens = Object.keys(media).map((mediaToken) => `@${mediaToken}`) as MediaToken[];

  const [initial, ...conditions] = value;
  // Transform an array like
  // ['$space4', '$space8']
  // to a stitches css object like
  // { '@bp1': { p: '$space4' }, '@bp3': { p: '$space8' } }
  //
  // Inspired by Styled System array props https://styled-system.com/guides/array-props/
  const responsiveStyles = conditions.reduce((mediaRules, mediaValue, index) => {
    const key = mediaTokens[index];
    // HACK: Include --puResponsiveRule with breakpoint key to ensure unique hashes for
    // rulesets per media query so the dedupeText function in our custom insertion method
    // doesn't throw away CSS we need.
    mediaRules[key] = { '--puResponsiveRule': key, [property]: mediaValue };
    return mediaRules;
  }, {} as Record<MediaToken, {}>);
  const hasResponsiveStyles = conditions.length > 0;

  return {
    [property]: initial,
    ...(hasResponsiveStyles && { ...responsiveStyles }),
  };
};

export type { ResponsiveValue };
export { responsiveRule };
