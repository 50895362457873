import * as Tabs from '@radix-ui/react-tabs';
import React from 'react';

import { styled } from '../../stitches.config';

const Root = styled(Tabs.Root, {
  display: 'flex',
  flexDirection: 'column',
});

const List = styled(Tabs.List, {
  flexShrink: 0,
  display: 'flex',
  borderBottom: '$borderWidths$borderWidth1 solid $borderLoud',
});

const Tab = styled(Tabs.Trigger, {
  $$activeTabColor: '$colors$textSelectedToggle',
  flexShrink: 0,
  // Offset bottom padding for border.
  padding: '$space4 $space4 $space3 $space4',
  border: 'none',
  borderBottom: '$borderWidths$borderWidth3 solid transparent',
  color: '$textSubdued',
  backgroundColor: '$bgRow',
  // Ideally, we should be able to directly pull from Heading Small here, is that possible? If not, will have to recreate the font-feature-settings here.
  fontSize: '$fontSize3',
  letterSpacing: 'inherit',
  lineHeight: '$lineHeight7',
  fontWeight: '$bold',
  userSelect: 'none',
  cursor: 'pointer',

  '&[data-disabled]': {
    color: '$textDisabled',
    cursor: 'not-allowed',
  },

  '&:hover:not([data-disabled])': {
    color: '$$activeTabColor',
    backgroundColor: '$bgRowHover',
    borderBottomColor: 'currentColor',
  },

  '&[data-state="active"]': {
    color: '$$activeTabColor',
    borderBottomColor: 'currentColor',
  },

  focusVisible: '$focus',
});

const Panel = styled(Tabs.Content, {
  flexGrow: 1,
  pt: '$space6',

  focusVisible: '$focus',
});

interface TabGroupProps
  extends Omit<Tabs.TabsProps, 'orientation'>,
    React.ComponentProps<typeof Root> {}

const TabGroupComponent: React.FC<React.PropsWithChildren<TabGroupProps>> = ({
  children,
  ...props
}) => <Root {...props}>{children}</Root>;

type ComponentType = typeof TabGroupComponent;
interface CompositeComponent extends ComponentType {
  List: typeof List;
  Tab: typeof Tab;
  Panel: typeof Panel;
}

const TabGroup = TabGroupComponent as CompositeComponent;
TabGroup.List = List;
TabGroup.Tab = Tab;
TabGroup.Panel = Panel;

export { TabGroup };
