export enum AuthEndpoints {
  SEND_PASSWORD_RESET_EMAIL = '/auth/send-password-reset-email',
  SEND_ONBOARDING_WELCOME_EMAIL = '/auth/send-onboarding-welcome-email',
  VERIFY_EMAIL_TOKEN = '/auth/verify-email-token',
  VERIFY_CREATE_PASSWORD_TOKEN = '/auth/verify-create-password-token',
  CHANGE_PASSWORD = '/auth/change-password',
}

export enum Routes {
  Root = '/',
  GoogleSigninCallback = '/signin/google/callback',
  Signin = '/signin',
  SsoSigninCallBack = '/signin/sso/callback',
}

export const DEMO_EMAIL = 'brian@attentivemobile.com';
