import { styled } from '../../stitches.config';

const iconColorsVariants = {
  default: { fill: '$iconDefault' },
  subdued: { fill: '$iconSubdued' },
  success: { fill: '$iconSuccess' },
  warning: { fill: '$iconWarning' },
  critical: { fill: '$iconCritical' },
  error: { fill: '$iconCritical' },
  neutral: { fill: '$iconDefault' },
  info: { fill: '$iconInfo' },
  guidance: { fill: '$iconGuidance' },
  disabled: { fill: '$iconDisabled' },
  inverted: { fill: '$iconInverted' },
  decorative1: { fill: '$iconDecorative1' },
  decorative2: { fill: '$iconDecorative2' },
  decorative3: { fill: '$iconDecorative3' },
  decorative4: { fill: '$iconDecorative4' },
  inherit: { fill: 'currentColor' },
};

export const iconColors = Object.keys(iconColorsVariants);

export const IconComponent = styled('svg', {
  variants: {
    size: {
      extraSmall: {
        width: '$size4',
        height: '$size4',
      },
      small: {
        width: '$size5',
        height: '$size5',
      },
      medium: {
        width: '$size6',
        height: '$size6',
      },
      large: {
        width: '$size7',
        height: '$size7',
      },
    },
    color: iconColorsVariants,
  },
  defaultVariants: {
    size: 'medium',
    color: 'inherit',
  },
});

export type IconColor = keyof typeof iconColorsVariants;
