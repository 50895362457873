import {
  getConnectionMock,
  getAuthUser,
  getRefreshSession,
  generateZendeskSsoToken,
  loginWithPassword,
  refreshSessionOrChallengeMock,
} from './api.mock';

export { mockCredentials } from './api.mock';

export const handlers = [
  getConnectionMock,
  getAuthUser,
  getRefreshSession,
  generateZendeskSsoToken,
  loginWithPassword,
  refreshSessionOrChallengeMock,
];
