import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M 3.519531 1.921875 C 2.636719 1.921875 1.921875 2.636719 1.921875 3.519531 L 1.921875 20.480469 C 1.921875 21.363281 2.636719 22.078125 3.519531 22.078125 L 20.480469 22.078125 C 21.363281 22.078125 22.078125 21.363281 22.078125 20.480469 L 22.078125 3.519531 C 22.078125 2.636719 21.363281 1.921875 20.480469 1.921875 Z M 3.519531 0 L 20.480469 0 C 22.425781 0 24 1.574219 24 3.519531 L 24 20.480469 C 24 22.425781 22.425781 24 20.480469 24 L 3.519531 24 C 1.574219 24 0 22.425781 0 20.480469 L 0 3.519531 C 0 1.574219 1.574219 0 3.519531 0 Z M 16.113281 9.601562 L 15.390625 9.601562 C 15.25 9.601562 15.125 9.652344 15.023438 9.753906 C 14.929688 9.851562 14.867188 9.960938 14.867188 10.089844 C 14.863281 10.164062 14.851562 10.621094 14.828125 10.910156 C 14.75 11.742188 14.472656 12.410156 13.996094 12.917969 C 13.527344 13.421875 12.863281 13.675781 12 13.679688 C 11.140625 13.675781 10.472656 13.421875 10.003906 12.917969 C 9.527344 12.410156 9.25 11.742188 9.171875 10.910156 C 9.148438 10.621094 9.136719 10.164062 9.132812 10.089844 C 9.132812 9.960938 9.070312 9.851562 8.976562 9.753906 C 8.875 9.652344 8.75 9.601562 8.609375 9.601562 L 7.886719 9.601562 C 7.742188 9.601562 7.621094 9.652344 7.515625 9.753906 C 7.421875 9.851562 7.359375 9.941406 7.359375 10.105469 C 7.359375 10.105469 7.386719 10.855469 7.398438 11.003906 C 7.515625 12.332031 7.972656 13.390625 8.773438 14.179688 C 9.566406 14.960938 10.636719 15.351562 11.984375 15.359375 C 12.003906 15.359375 12.011719 15.359375 12.015625 15.359375 C 13.363281 15.351562 14.433594 14.960938 15.226562 14.179688 C 16.027344 13.390625 16.484375 12.332031 16.601562 11.003906 C 16.613281 10.855469 16.640625 10.105469 16.640625 10.105469 C 16.640625 9.941406 16.578125 9.851562 16.484375 9.753906 C 16.378906 9.652344 16.257812 9.601562 16.113281 9.601562 Z M 16.113281 9.601562"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export const SmileWhite = React.memo(Icon);
