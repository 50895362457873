import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { ReactElement } from 'react';

import { Box, BoxProps, Button, ButtonProps, ButtonBar, Separator } from '../../components';
import { compositeComponent } from '../../utils/composite-component';

import { Drawer, DrawerProps } from './Drawer';

const StandardDrawer: React.FC<React.PropsWithChildren<DrawerProps>> = (props) => (
  <Drawer {...props} />
);

const Header: React.FC<React.PropsWithChildren<BoxProps>> = ({ css, children, ...rest }) => {
  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '$space5 $space6',
        ...css,
      }}
      {...rest}
    >
      <DialogPrimitive.Title asChild>
        <Box css={{ alignSelf: 'center' }}>{children}</Box>
      </DialogPrimitive.Title>
      <Drawer.CloseButton
        css={{
          ml: '$space4',
          // Offset icon padding for better vertical alignment without focus ring.
          marginRight: '-$space2',
        }}
      />
    </Box>
  );
};

const Body: React.FC<React.PropsWithChildren<BoxProps>> = ({ css, children, ...rest }) => {
  return (
    <Box css={{ px: '$space6', pb: '$space6', overflowY: 'auto', ...css }} {...rest}>
      {children}
    </Box>
  );
};

const Footer: React.FC<React.PropsWithChildren<React.ComponentProps<typeof ButtonBar>>> = ({
  css,
  layout = 'auto',
  children,
  ...rest
}) => (
  <ButtonBar css={{ px: '$space6', py: '$space5', ...css }} layout={layout} {...rest}>
    {children}
  </ButtonBar>
);

const Close: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  return (
    <DialogPrimitive.Close asChild>
      <Button variant="subdued" {...props} />
    </DialogPrimitive.Close>
  );
};

const Content: React.FC<React.PropsWithChildren<React.ComponentProps<typeof Drawer.Content>>> = ({
  css,
  children,
  ...rest
}) => {
  let header: ReactElement | null = null;
  let body: ReactElement | null = null;
  let footer: ReactElement | null = null;

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    switch (child.type) {
      case Header:
        header = child;
        break;
      case Body:
        body = child;
        break;
      case Footer:
        footer = child;
        break;
      default:
        break;
    }
  });

  return (
    <Drawer.Content css={{ display: 'flex', ...css }} {...rest}>
      <Box
        css={{
          height: '100%',
          flex: 1,
          display: 'grid',
          gridTemplateRows: `
          auto
          1fr
          auto
          auto
          `,
        }}
      >
        {header}
        {body}
        <Separator />
        {footer}
      </Box>
    </Drawer.Content>
  );
};

const Trigger: React.FC<React.PropsWithChildren<{ children: React.ReactElement }>> = ({
  children,
}) => <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>;

const Namespace = compositeComponent(StandardDrawer, {
  Body,
  Close,
  Content,
  Footer,
  Header,
  Trigger,
});

export { Namespace as StandardDrawer };
