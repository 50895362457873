/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const TrafficCone = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.933 19.5h-18V21h18v-1.5Z" />
    <path
      fillRule="evenodd"
      d="M14.142 6.378 13.022 3h-2.043L9.858 6.378h4.284Zm.747 2.25H9.11l-1.243 3.747h8.264L14.89 8.628Zm1.99 5.997H7.121L6 18.003h12l-1.12-3.378Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(TrafficCone);
export default Memo;
