import { camelCase, kebabCase } from '@attentive/nodash';

import { findPersona } from './personas';

export const mswHeaderPrefix = 'msw-';

export const MSW_STORAGE_KEY = 'mock';

export const MSW_DELAY_HEADER = 'delay';

export const MSW_ERROR_HEADER = 'error';

export const MSW_PERSONA_AUTHORIZATION_HEADER = 'authorization';

export const MSW_BEARER_TOKEN_PREFIX = 'bearer ';

export const storeMSWHeaders = <T extends object>(headers: T) => {
  sessionStorage.setItem(MSW_STORAGE_KEY, JSON.stringify(headers));
};

export const parseMSWHeaders = <T extends object>(
  headers: Omit<Headers, 'getSetCookie' | 'forEach'>
) => {
  const parsedHeaders = {};

  for (const [header, value] of headers.entries()) {
    if (header.startsWith(mswHeaderPrefix)) {
      const key = camelCase(header.substr(mswHeaderPrefix.length));
      // @ts-ignore
      parsedHeaders[key] = JSON.parse(value);
    }
  }
  return parsedHeaders as T;
};

export const addMSWHeaders = (headers: Headers) => {
  let mockHeaders: Record<string, string> = {};
  try {
    mockHeaders = JSON.parse(sessionStorage.getItem(MSW_STORAGE_KEY) || '{}');
  } catch (err) {
    console.warn(`Unable to parse JSON data from ${MSW_STORAGE_KEY} in sessionStorage`);
    return {};
  }
  for (const [name, value] of Object.entries(mockHeaders)) {
    headers.set(`${mswHeaderPrefix}${kebabCase(name)}`, JSON.stringify(value));
  }
  return headers;
};

export const extractRequestPersona = (headers: Omit<Headers, 'getSetCookie' | 'forEach'>) => {
  const authHeader = headers.get(MSW_PERSONA_AUTHORIZATION_HEADER);

  if (!authHeader) {
    throw new Error('Expected MSW Authorization header');
  }
  if (!authHeader.toLowerCase().startsWith(MSW_BEARER_TOKEN_PREFIX)) {
    throw new Error(`Expected MSW Authorization header to look like "Bearer Account-Regular"`);
  }

  const id = authHeader.substr(MSW_BEARER_TOKEN_PREFIX.length);

  try {
    return findPersona(id);
  } catch (err) {
    throw new Error(`Unable to find persona using MSW Authorization header value`);
  }
};
