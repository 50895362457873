export const sharedDatePickerProps = {
  hideKeyboardShortcutsPanel: true,
  horizontalMonthPadding: 23,
  firstDayOfWeek: 1 as const,
};

export const DISPLAY_DATE_FORMAT = 'MMM D, YYYY';

// Standardizes date data to ISO 8601 standards
// https://en.wikipedia.org/wiki/ISO_8601
export const MOMENT_ISO_8601_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FNS_ISO_8601_DATE_FORMAT = 'yyyy-MM-dd';
