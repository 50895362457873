import { VariantProps } from '@stitches/react';
import React, { ComponentProps, VFC, createContext, useContext } from 'react';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { IconButton } from '../Button';
import { Icon, IconName, IconProps } from '../Icon';
import { IconColor } from '../Icon/StyledIconComponents';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

const IconColorContext = createContext<IconColor | undefined>(undefined);
const IconColorProvider = IconColorContext.Provider;
const useIconColor = (explicitOverride?: IconColor) => {
  const context = useContext(IconColorContext);
  return explicitOverride || context;
};

const ContainedLabelPrimitive = styled('div', {
  color: '$textDefault',
  fontWeight: '$bold',
  fontSize: '$fontSize1',
  lineHeight: '$lineHeight1',
  letterSpacing: '$letterSpacing1',
  display: 'inline-flex',
  minHeight: '$size5',
  borderRadius: '$radiusMax',
  border: '$borderWidths$borderWidth1 solid transparent',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  padding: '0 $space2',
  variants: {
    variant: {
      neutral: { backgroundColor: '$bgAccent', color: '$textDefault' },
      success: { backgroundColor: '$bgSuccessDefault', color: '$textSuccess' },
      informational: { backgroundColor: '$bgInformationalDefault', color: '$textInformational' },
      warning: { backgroundColor: '$bgWarningDefault', color: '$textWarning' },
      critical: { backgroundColor: '$bgCriticalDefault', color: '$textCritical' },
      decorative1: { backgroundColor: '$bgDecorative1Default', color: '$textDecorative1' },
      decorative2: { backgroundColor: '$bgDecorative2Default', color: '$textDecorative2' },
      decorative3: { backgroundColor: '$bgDecorative3Default', color: '$textDecorative3' },
      decorative4: { backgroundColor: '$bgDecorative4Default', color: '$textDecorative4' },
      overMedia: {
        backgroundColor: '$bgInverted',
        color: '$textInverted',
        borderColor: '$borderInverted',
      },
      magic: {
        backgroundImage: '$bgGradientMagic',
        backgroundColor: '$bgGradientMagicFallback',
        color: '$textDefault',
      },
    },
  },
  defaultVariants: {
    variant: 'neutral',
  },
});
export type ContainedLabelVariant = VariantProps<typeof ContainedLabelPrimitive>['variant'];

type ContainedLabelTooltipProps = {
  iconName: IconName;
  iconColor?: IconColor;
  description: string;
  side?: 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactNode;
};

const ContainedLabelIcon = ({ name, color, ...rest }: IconProps) => {
  const resolvedColor = useIconColor(color);
  return <Icon color={resolvedColor} name={name} size="small" {...rest} />;
};

const ContainedLabelTooltip = ({
  iconName,
  iconColor,
  description,
  children,
  side = 'bottom',
}: ContainedLabelTooltipProps) => {
  const resolvedIconColor = useIconColor(iconColor);
  return (
    <Tooltip>
      <Tooltip.Trigger>
        <IconButton
          size="extraSmall"
          iconName={iconName}
          iconColor={resolvedIconColor}
          description={description}
          variant="subdued"
        />
      </Tooltip.Trigger>
      <Tooltip.Content side={side}>
        <Text variant="micro">{children}</Text>
      </Tooltip.Content>
    </Tooltip>
  );
};

type IconColorDictionaryKey = Extract<NonNullable<ContainedLabelVariant>, string>;
const iconColorDictionary: Partial<Record<IconColorDictionaryKey, IconColor>> = {
  neutral: 'default',
  informational: 'info',
  overMedia: 'inverted',
};

const ContainedLabelComponent: VFC<ComponentProps<typeof ContainedLabelPrimitive>> = (props) => {
  const iconColor =
    iconColorDictionary[props.variant as IconColorDictionaryKey] || (props.variant as IconColor);

  return (
    <IconColorProvider value={iconColor}>
      <ContainedLabelPrimitive {...props} />
    </IconColorProvider>
  );
};

type ComponentType = typeof ContainedLabelComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Icon: typeof ContainedLabelIcon & DisplayNamed;
  Tooltip: typeof ContainedLabelTooltip & DisplayNamed;
}
const ContainedLabel = ContainedLabelComponent as CompositeComponent;
ContainedLabel.Icon = ContainedLabelIcon;
ContainedLabel.Tooltip = ContainedLabelTooltip;

ContainedLabel.displayName = 'ContainedLabel';
ContainedLabel.Icon.displayName = 'ContainedLabel.Icon';
ContainedLabel.Tooltip.displayName = 'ContainedLabel.Tooltip';

export { ContainedLabel };
