/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const World = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12.448 2.106 12 3l-.448.894h-.002l-.001-.002-.003-.001h-.002l.013.006.085.05c.08.05.206.135.362.259.313.247.747.652 1.187 1.257C14.063 6.662 15 8.705 15 12s-.937 5.338-1.809 6.537a6.4 6.4 0 0 1-1.187 1.257 4.073 4.073 0 0 1-.447.309l-.013.007h.002l.003-.002.002-.001s.002-.001.449.893l.448.894h.002l.003-.002.007-.004.018-.01a2.907 2.907 0 0 0 .224-.13c.138-.086.325-.213.544-.386a8.397 8.397 0 0 0 1.563-1.649C15.937 18.162 17 15.705 17 12s-1.063-6.162-2.191-7.713a8.394 8.394 0 0 0-1.563-1.65 6.054 6.054 0 0 0-.714-.486 2.459 2.459 0 0 0-.053-.03l-.019-.009-.007-.004-.003-.001h-.002Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M11.552 21.894 12 21l.448-.894h.002l.001.002.003.001h.002l-.013-.006a4.073 4.073 0 0 1-.447-.309 6.402 6.402 0 0 1-1.187-1.257C9.937 17.338 9 15.295 9 12s.937-5.338 1.809-6.537a6.4 6.4 0 0 1 1.187-1.257 4.053 4.053 0 0 1 .447-.309l.013-.007h-.002l-.003.002-.002.001S12.446 3.895 12 3l-.448-.894h-.002l-.003.002-.007.004-.018.01a4.004 4.004 0 0 0-.223.13 6.054 6.054 0 0 0-.545.386 8.397 8.397 0 0 0-1.563 1.649C8.063 5.838 7 8.295 7 12s1.063 6.162 2.191 7.713a8.4 8.4 0 0 0 1.563 1.65 6.066 6.066 0 0 0 .714.486l.053.03.019.009.007.004.005.002Z"
      clipRule="evenodd"
    />
    <path d="M20 8v2H4V8h16ZM20 14v2H4v-2h16Z" />
  </svg>
);

const Memo = memo(World);
export default Memo;
