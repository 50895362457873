/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Send = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M5.24 10.25 13.4 12 5 13.71l-3 6.72 19.2-7.86v-1.7L2 3l3.24 7.25Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Send);
export default Memo;
