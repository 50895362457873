/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Brackets = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M16.648 18.504c-.09.115-.25.21-.637.21h-1.475v2.274h1.738c.92 0 1.673-.214 2.185-.771.505-.55.698-1.349.698-2.337v-4.073c0-.383.055-.531.099-.59.023-.031.078-.088.323-.107H21v-2.242H19.58c-.245-.019-.3-.076-.323-.107-.044-.06-.099-.208-.099-.59V6.098c0-.988-.193-1.788-.698-2.337-.512-.557-1.266-.771-2.185-.771h-1.738v2.274h1.475c.387 0 .548.095.637.21.111.144.197.427.197.98v3.923c0 .687.2 1.238.544 1.612-.343.374-.544.925-.544 1.612v3.923c0 .553-.086.835-.197.98ZM7.352 5.474c.09-.116.25-.21.637-.21h1.475V2.99H7.726c-.919 0-1.672.214-2.185.771-.505.549-.698 1.349-.698 2.337v4.073c0 .382-.055.53-.099.59-.022.031-.078.088-.322.107H3v2.242h1.421c.245.019.3.076.323.107.044.059.099.207.099.59v4.073c0 .988.193 1.788.698 2.337.513.557 1.266.771 2.185.771h1.738v-2.274H7.99c-.387 0-.547-.095-.637-.21-.11-.145-.197-.427-.197-.98V13.6c0-.687-.2-1.238-.543-1.612.343-.374.543-.925.543-1.612V6.454c0-.553.086-.835.197-.98Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Brackets);
export default Memo;
