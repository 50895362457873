import React from 'react';

import { styled } from '../../stitches.config';

const StyledSpan = styled('span', {});

type EmojiProps = {
  label: string;
  children: React.ReactNode;
  decorational?: boolean;
} & React.ComponentProps<typeof StyledSpan>;

export const Emoji = ({ label, children, decorational = false, ...spanProps }: EmojiProps) => (
  <StyledSpan
    role="img"
    aria-label={label}
    {...(decorational ? { 'aria-hidden': 'true' } : {})}
    {...spanProps}
  >
    {children}
  </StyledSpan>
);
