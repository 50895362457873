import React from 'react';

interface ImpersonatedPicnicElement extends React.ReactElement {
  type: React.ReactElement['type'] & {
    impersonatingPicnicComponent?: React.ReactElement['type'];
  };
}

function impersonatePicnicComponent<P extends {}>(
  actor: React.ComponentType<React.PropsWithChildren<P>>,
  component: React.ComponentType<React.PropsWithChildren<unknown>>
) {
  return Object.assign(actor, {
    impersonatingPicnicComponent: component,
  });
}

function getImpersonatedPicnicComponent(element: ImpersonatedPicnicElement) {
  return element.type.impersonatingPicnicComponent || element.type;
}

export { impersonatePicnicComponent, getImpersonatedPicnicComponent };
