/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Logo = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m15.674 4.959 5.557 10.45a3.693 3.693 0 1 1-6.52 3.468s-3.236-6.067-4.062-7.654a2.834 2.834 0 0 0-4.171-1.028.169.169 0 1 1-.248-.229l2.996-5.133a3.693 3.693 0 0 1 6.448.126ZM7.061 20.834a4.06 4.06 0 1 0 0-8.122 4.06 4.06 0 0 0 0 8.122Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Logo);
export default Memo;
