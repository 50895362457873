import { VariantProps } from '@stitches/react';
import React from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { Box, BoxProps } from '../Box';
import { Button, ButtonProps } from '../Button';
import { Icon, PresentationalIconProps } from '../Icon';

// Expose a strongly typed Item component to use as public API for ButtonGroup children.
// This Item component is NOT directly rendered. The parent ButtonGroup component:
// - ensures that ButtonGroup children are all of type ButtonGroup.Item
// - renders a set of PrivateItem children that correspond to the public Item children
// - manages items' variants, sizes, positions, etc. internally
// Reference: https://twitter.com/markdalgleish/status/1370951759284162565
type ItemProps = Omit<ButtonProps, 'variant'>;
const Item: React.FC<React.PropsWithChildren<ItemProps>> = () => null;

type IconItemProps = PresentationalIconProps & {
  disabled?: boolean;
  iconCss?: PicnicCss;
};
const IconItem = Icon;

const PU_ZINDEX_ACTIVATED = 2;
const PU_ZINDEX_FOCUSED = 3;

const PrivateItem = styled(Button, {
  '&:focus-visible': {
    zIndex: PU_ZINDEX_FOCUSED,
  },
  '&:hover': {
    zIndex: PU_ZINDEX_ACTIVATED,
  },
  variants: {
    position: {
      first: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      middle: {
        marginLeft: '-1px',
        safariOnly: {
          marginLeft: '-2px',
        },
        borderRadius: 0,
      },
      last: {
        marginLeft: '-1px',
        safariOnly: {
          marginLeft: '-2px',
        },
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    active: {
      true: {
        zIndex: PU_ZINDEX_ACTIVATED,
        borderColor: 'transparent',
      },
      false: {},
    },
    disabledVisually: {
      true: {},
      false: {},
    },
    itemSizing: {
      auto: {},
      equal: {
        flex: 1,
        minWidth: 0,
      },
    },
  },
  compoundVariants: [
    {
      disabledVisually: false,
      active: true,
      css: {
        backgroundColor: '$bgToggleSelected',
        color: '$textInverted',

        '&:hover:enabled': {
          backgroundColor: '$bgToggleSelected',
          borderColor: 'transparent',
        },
        '&:hover:enabled:not(:active)': {
          backgroundColor: '$bgToggleSelected',
          borderColor: 'transparent',
        },
      },
    },
    {
      disabledVisually: false,
      active: false,
      css: {
        color: '$textDefault',
        '&:hover:enabled:not(:active)': {
          borderColor: '$borderActionBasic',
          backgroundColor: '$bgToggleHover',
        },
        '&:active:enabled': {
          borderColor: '$borderActionBasic',
          backgroundColor: '$bgTogglePressed',
        },
      },
    },
  ],
});

type PrivateItemPosition = VariantProps<typeof PrivateItem>['position'];

interface ButtonGroupProps extends BoxProps {
  activeItem?: number | null;
  size?: 'small' | 'medium';
  children: React.ReactNode;
  itemSizing?: 'auto' | 'equal';
}

const getItemPosition = (index: number, childrenCount: number): PrivateItemPosition => {
  if (index === 0) {
    return 'first';
  } else if (index === childrenCount - 1) {
    return 'last';
  }
  return 'middle';
};

const ButtonGroupComponent = ({
  children,
  size = 'small',
  itemSizing = 'auto',
  activeItem = 0,

  css: rootCss = {},
  ...rest
}: ButtonGroupProps) => {
  //filter out falsey children to allow ButtonGroup.Item to be rendered conditionally
  const filteredChildren = React.Children.toArray(children).filter((child) => !!child);

  return (
    <Box
      css={{
        display: 'inline-flex',
        position: 'relative',
        ...rootCss,
      }}
      {...rest}
    >
      {React.Children.map(filteredChildren, (child, index) => {
        if (!React.isValidElement(child)) {
          return;
        }

        const itemIsActive = index === activeItem;
        const itemVariant = itemIsActive ? 'default' : 'basic';
        const itemPosition = getItemPosition(index, filteredChildren.length);

        if (child.type === Item) {
          const { disabled, ...itemProps } = child.props;
          return (
            <PrivateItem
              {...itemProps}
              variant={itemVariant}
              size={size}
              position={itemPosition}
              active={itemIsActive}
              itemSizing={itemSizing}
              disabled={!!disabled}
              disabledVisually={!!disabled}
            />
          );
        }

        if (child.type === IconItem) {
          const {
            css: itemCss,
            description,
            name,
            disabled,
            iconCss,
            iconSize = 'small', // TO-DO: Icon size should change to 'medium' if Button Group is medium size
            ...itemProps
          } = child.props;
          return (
            <PrivateItem
              {...itemProps}
              variant={itemVariant}
              size={size}
              position={itemPosition}
              active={itemIsActive}
              disabled={!!disabled}
              disabledVisually={!!disabled}
              css={{
                ...itemCss,
                display: 'inline-flex',
              }}
            >
              <Icon
                mode="presentational"
                size={iconSize}
                description={description}
                name={name}
                css={iconCss}
              />
            </PrivateItem>
          );
        }

        throw new Error(
          'ButtonGroup children must be of type ButtonGroup.Item or ButtonGroup.IconItem'
        );
      })}
    </Box>
  );
};

type ComponentType = typeof ButtonGroupComponent & DisplayNamed;
export interface ButtonCompositeComponent extends ComponentType {
  Item: React.FC<React.PropsWithChildren<ItemProps>>;
  IconItem: React.FC<React.PropsWithChildren<IconItemProps>>;
}

const ButtonGroup = ButtonGroupComponent as ButtonCompositeComponent;
ButtonGroup.Item = Item;
ButtonGroup.IconItem = IconItem;

ButtonGroup.displayName = 'ButtonGroup';
ButtonGroup.Item.displayName = 'ButtonGroup.Item';

export { ButtonGroup };
