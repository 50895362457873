import * as PopoverPrimitive from '@radix-ui/react-popover';
import { useCombobox } from 'downshift';
import React, { useState, ChangeEvent } from 'react';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { Box } from '../Box';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { InputGroup } from '../InputGroup';
import { List } from '../List';
import { Text } from '../Text';
import { TextInput } from '../TextInput';

import { SelectItemsList } from './NestedListComponents';
import {
  ArrowWrapper,
  PublicSelectGroup,
  PublicSelectItem,
  PublicSelectIconItem,
  PublicSelectThirdPartyIconItem,
  Menu,
} from './StyledSelectComponents';
import { useItems } from './extractItemsHook';
import { useRecursiveMatch } from './search';
import { getAllSearchableText } from './select-helpers';
import { getItemPropsType, MenuItem, MenuItems, MenuItemValueType, SelectProps } from './types';

const itemToString = (item: MenuItem | null) => item?.label ?? '';

const PU_MENU_MIN_HEIGHT = 200;
const PU_MENU_CONTENT_OFFSET = 8;
const PU_MENU_MAX_HEIGHT = 400;

const StyledPopoverContent = styled(PopoverPrimitive.Content, {
  zIndex: '$layer4',
});

const SearchableSelectComponent = ({
  placeholder = 'Search',
  children = [],
  css = {},
  value,
  onChange,
  size = 'medium',
  state = 'normal',
  disabled = false,
  onSearchTermChange,
  ...rest
}: SelectProps) => {
  const inputGroupRef = React.useRef<HTMLDivElement>(null);

  const [term, setTerm] = useState('');

  const handleChange = onChange as (value: MenuItemValueType) => void;

  const items = useItems(children) as MenuItems;

  const getItem = (itemValue?: MenuItemValueType) => {
    if (itemValue !== undefined && itemValue !== null) {
      return items.find((item) => item.value === itemValue && !item.disabled) ?? null;
    }

    return null;
  };

  const filteredItems = useRecursiveMatch(items, term);
  const itemsLookup = filteredItems.reduce(
    (prev: Record<MenuItemValueType, { item: MenuItem; index: number }>, curr, index) => {
      prev[curr.value] = {
        item: curr,
        index,
      };
      return prev;
    },
    {}
  );

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getToggleButtonProps,
    getItemProps,
    selectedItem,
  } = useCombobox({
    itemToString,
    inputValue: term,
    items: filteredItems,
    selectedItem: getItem(value),

    onSelectedItemChange: ({ selectedItem: changedSelectedItem }) => {
      if (changedSelectedItem) {
        setTerm('');

        if (handleChange) {
          const val = changedSelectedItem.value as string;
          handleChange(val);
        }
      }
    },
    onStateChange: ({ type }) => {
      if (type === useCombobox.stateChangeTypes.InputBlur) {
        setTerm('');
        onSearchTermChange?.('');
      }
    },
  });

  const availableHeightForMenu =
    typeof window === 'undefined'
      ? 0
      : window.innerHeight -
        // get the size of element to its relative position
        (inputGroupRef?.current?.getBoundingClientRect().bottom || 0) -
        //to match the offset at top from the select button
        PU_MENU_CONTENT_OFFSET * 2;

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTerm(e.currentTarget.value);
    if (onSearchTermChange) {
      onSearchTermChange(e.currentTarget.value);
    }
    openMenu();
  };

  return (
    <PopoverPrimitive.Root open={isOpen}>
      <PopoverPrimitive.Trigger asChild>
        <InputGroup {...getComboboxProps({ ref: inputGroupRef })} css={{ width: '100%' }}>
          <TextInput
            value={term}
            placeholder={selectedItem ? getAllSearchableText(selectedItem.label) : placeholder}
            data-testid="searchable-select-input"
            // Downshift generates its own label props and assumes we're using
            // its `getLabelProps` on a label somewhere. But we have our own
            // labeling system (radix), so we don't want to include downshift's
            // non-existent labels because they interfere with a11y.
            {...getInputProps({ 'aria-labelledby': rest['aria-labelledby'] })}
            onClick={openMenu}
            onChange={handleSearchInputChange}
            size={size === 'medium' ? 'normal' : 'small'}
            state={state}
            css={{
              width: '100%',
              '&::placeholder': { color: selectedItem ? '$textDefault' : '$textSubdued' },
              ...css,
            }}
            disabled={disabled}
            {...rest}
          />
          <InputGroup.RightElement>
            <Button
              variant="subdued"
              disabled={disabled}
              {...getToggleButtonProps()}
              css={{ minHeight: 12, p: '$space2' }}
            >
              <ArrowWrapper variant={isOpen ? 'up' : 'down'}>
                <Icon
                  css={{ display: 'block' }}
                  name="ChevronDown"
                  size="small"
                  description={isOpen ? 'Arrow pointing up' : 'Arrow pointing down'}
                />
              </ArrowWrapper>
            </Button>
          </InputGroup.RightElement>
        </InputGroup>
      </PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <StyledPopoverContent
          sideOffset={PU_MENU_CONTENT_OFFSET}
          onOpenAutoFocus={(event) => {
            event.preventDefault();
          }}
        >
          <Menu
            {...getMenuProps({}, { suppressRefError: true })}
            css={{
              width: inputGroupRef?.current?.clientWidth,
              //Keeping the menu height between MIN and MAX, and a dynamic in between based on available space.
              maxHeight: Math.max(
                PU_MENU_MIN_HEIGHT,
                Math.min(availableHeightForMenu, PU_MENU_MAX_HEIGHT)
              ),
            }}
          >
            {filteredItems.length > 0 && (
              <List variant="unstyled">
                <SelectItemsList
                  highlightedIndex={highlightedIndex}
                  selectedItem={selectedItem as MenuItem}
                  getItemProps={getItemProps as getItemPropsType}
                  itemsLookup={itemsLookup}
                  size={size}
                >
                  {children}
                </SelectItemsList>
              </List>
            )}
            {filteredItems.length === 0 && (
              <Box css={{ p: '$space2' }}>
                <Text>No results</Text>
              </Box>
            )}
          </Menu>
        </StyledPopoverContent>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};

type ComponentType = typeof SearchableSelectComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Group: typeof PublicSelectGroup;
  Item: typeof PublicSelectItem;
  IconItem: typeof PublicSelectIconItem;
  ThirdPartyIconItem: typeof PublicSelectThirdPartyIconItem;
}

const SearchableSelect = SearchableSelectComponent as CompositeComponent;
SearchableSelect.Group = PublicSelectGroup;
SearchableSelect.Item = PublicSelectItem;
SearchableSelect.IconItem = PublicSelectIconItem;
SearchableSelect.ThirdPartyIconItem = PublicSelectThirdPartyIconItem;

SearchableSelect.displayName = 'SearchableSelect';
SearchableSelect.Group.displayName = 'SearchableSelect.Group';
SearchableSelect.Item.displayName = 'SearchableSelect.Item';
SearchableSelect.IconItem.displayName = 'SearchableSelect.IconItem';
SearchableSelect.ThirdPartyIconItem.displayName = 'SearchableSelect.ThirdPartyIconItem';

export { SearchableSelect };
