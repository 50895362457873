/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ArrowBottom = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m19.406 10.404 1.593 1.593-9 9-9-9 1.593-1.592 6.282 6.282V2.997h2.25v13.688l6.282-6.281Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(ArrowBottom);
export default Memo;
