import React from 'react';

import { styled, VariantProps } from '../../stitches.config';
import { ThirdPartyIcon, ThirdPartyIconProps } from '../Icon/ThirdPartyIcon';

const Circle = styled('div', {
  flexShrink: 0,
  borderRadius: '$radiusMax',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    color: {
      default: {
        backgroundColor: '$bgInverted',
      },
      inverted: {
        backgroundColor: '$bgDefault',
      },
    },
    size: {
      extraSmall: {
        width: '$size6',
        height: '$size6',
      },
      small: {
        width: '$size8',
        height: '$size8',
      },
      medium: {
        width: '$size10',
        height: '$size10',
      },
      large: {
        width: '$size12',
        height: '$size12',
      },
    },
  },
});

type Size = VariantProps<typeof Circle>['size'];
// Using VariantProps will include the stitches responsive object type, but we can only support the plain strings right now
// as we map them to different string values for the internal Icon component.
type Color = 'default' | 'inverted';

interface ThirdPartyIconCircleProps extends React.ComponentProps<typeof Circle> {
  iconName: ThirdPartyIconProps['name'];
  size?: Size;
  color?: Color;
}

export const ThirdPartyIconCircle: React.VFC<ThirdPartyIconCircleProps> = ({
  size = 'medium',
  color = 'default',
  iconName,
  ...rest
}) => {
  return (
    <Circle size={size} color={color} {...rest}>
      <ThirdPartyIcon name={iconName} size={size} />
    </Circle>
  );
};
