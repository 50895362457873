const maxLines = (lines: number) => {
  return {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: lines,
  };
};

export default { maxLines };
