import React, { ChangeEvent, useState } from 'react';
import { CountryCodeDropdown } from './CountryCodeDropdown';
import { Box, FormField, Stack, TextInput } from '@attentive/picnic';

interface Props {
  phoneNumberValue: string;
  onChange: (field: string, value: string) => void;
  error?: string;
}

export const PhoneAndCountryCodeInput = ({ phoneNumberValue, onChange, error }: Props) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState('+1');
  const [inputValue, setInputValue] = useState(phoneNumberValue);

  const handleCountryCodeChange = (value: string) => {
    setSelectedCountryCode(value);
    onChange('phone', `${value}${inputValue}`);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const justTheNumbers = value.slice().replace(/-/g, '');

    if (justTheNumbers !== '' && !/^[\d]+$/g.test(justTheNumbers)) {
      return;
    }

    onChange('phone', `${selectedCountryCode}${justTheNumbers}`);
    setInputValue(justTheNumbers);
  };

  return (
    <FormField>
      <Stack css={{ display: 'flex', width: '100%' }} spacing="$space2" direction="horizontal">
        <Box css={{ flex: 1 }}>
          <CountryCodeDropdown onChange={handleCountryCodeChange} />
        </Box>
        <Box css={{ flex: 3 }}>
          <TextInput aria-label="Phone" onChange={handleInputChange} value={inputValue} />
        </Box>
      </Stack>
      {error && <FormField.ErrorText>{error}</FormField.ErrorText>}
    </FormField>
  );
};
