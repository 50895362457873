/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MessageBubbleArrowRight = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M3 3.75h18v12H7.5L3 20.25V3.75Zm9.15 2.625L14.775 9h-8.4v1.5h8.4l-2.625 2.625h2.1l3.375-3.375-3.375-3.375h-2.1Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(MessageBubbleArrowRight);
export default Memo;
