// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/camelCase.ts

import { capitalize } from '../capitalize/capitalize';
import { words } from '../words/words';

/**
 * Converts `string` to [camel case](https://en.wikipedia.org/wiki/CamelCase).
 */
export const camelCase = (string: string): string =>
  words(string.replace(/['\u2019]/g, '')).reduce((result, word, index) => {
    // eslint-disable-next-line no-param-reassign
    word = word.toLowerCase();
    return result + (index ? capitalize(word) : word);
  }, '');
