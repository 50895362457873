/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Bell = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.125 7.575V5.25H15V3H9v2.25h1.875v2.325C6.45 8.1 3 11.925 3 16.5h18c0-4.575-3.45-8.4-7.875-8.925Z" />
    <path fillRule="evenodd" d="m21.375 21-2.25-2.25H4.875L2.625 21h18.75Z" clipRule="evenodd" />
  </svg>
);

const Memo = memo(Bell);
export default Memo;
