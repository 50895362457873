import { VariantProps } from '@stitches/react';
import React from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';

import { ListItem } from './ListItem';

interface ListProps {
  as?: 'ol' | 'ul';
  css?: PicnicCss;
  // XXX: Typing `children` as `React.ReactElement<typeof ListItem>` doesn't work
  children: React.ReactNode;
  variant?: VariantProps<typeof StyledListComponent>['variant'];
}

const StyledListComponent = styled('ul', {
  variants: {
    variant: {
      unstyled: {
        listStyleOverride: 'unstyled',
      },
    },
  },
});

const ListComponent = ({ as = 'ul', css, variant, children }: ListProps) => {
  return (
    <StyledListComponent as={as} css={css} variant={variant}>
      {children}
    </StyledListComponent>
  );
};

type ComponentType = typeof ListComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Item: typeof ListItem & DisplayNamed;
}

const List = ListComponent as CompositeComponent;
List.Item = ListItem;

List.displayName = 'List';
List.Item.displayName = 'List.Item';

export { List };
