import { VariantProps } from '@stitches/react';

import { styled } from '../../stitches.config';

/**
 * Badge component. Useful for providing inline or raised annotation of content.
 */
export const Badge = styled('em', {
  fontStyle: 'normal',
  boxSizing: 'border-box',
  display: 'inline-flex',
  placeItems: 'center',
  placeContent: 'center',
  fontSize: '$fontSize1',
  fontWeight: '$bold',
  textAlign: 'center',
  height: '$size4',
  minWidth: '$size4',
  borderRadius: '$radius2',
  padding: '$space1',
  backgroundColor: '$bgAccent',
  color: '$textDefault',
  variants: {
    variant: {
      active: {
        color: '$textInverted',
        backgroundColor: '$bgToggleSelected',
        border: '$borderWidths$borderWidth1 solid $borderInverted',
      },
      standard: {
        backgroundColor: '$bgInformationalDefault',
      },
      primary: {
        // PU-Fix: Consider changing background color later ("action" may be misleading). //
        backgroundColor: '$bgActionPrimary',
        color: '$textDefault',
      },
      error: {
        backgroundColor: '$bgCriticalAccent',
      },
      magic: {
        backgroundImage: '$bgGradientMagic',
        backgroundColor: '$bgGradientMagicFallback',
        color: '$textDefault',
      },
    },
    position: {
      inline: {},
      raised: {
        top: 0,
        right: 0,
        position: 'absolute',
        transform: 'translate(60%, -50%)',
      },
    },
  },
  defaultVariants: {
    variant: 'standard',
    position: 'raised',
  },
});

Badge.displayName = 'Badge';

export type BadgeVariant = VariantProps<typeof Badge>['variant'];
