import React from 'react';

import { styled, PicnicCss } from '../../stitches.config';

const DEFAULT_TEXT_AREA_ROWS = 2;

const textAreaStyles: PicnicCss = {
  backgroundColor: '$bgDefault',
  border: 'solid $borderWidths$borderWidth1 $borderInput',
  borderRadius: '$radius1',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  padding: '$space3 $space4',
  width: '100%',
  resize: 'vertical',
  fontSize: '$fontSize3',
  minHeight: `calc((${DEFAULT_TEXT_AREA_ROWS}em * $lineHeights$lineHeight4) + (2 * $borderWidths$borderWidth1) + (2 * $space$space3))`,
  lineHeight: '$lineHeight4',

  '&::placeholder': {
    color: '$textSubdued',
    opacity: 1,
  },
  focusVisible: '$focus',
  '&:focus-visible': {
    borderColor: '$borderInputHover',
  },
  '&:disabled': {
    color: '$textDisabled',
    borderColor: '$borderInputDisabled',
    '&::placeholder': {
      color: '$textDisabled',
    },
  },
  '&:hover:enabled:not(:focus-visible)': {
    borderColor: '$borderInputHover',
  },
};

const TextAreaStyled = styled('textarea', {
  ...textAreaStyles,
  variants: {
    state: {
      normal: { borderColor: '$borderInput' },
      error: { '&:not(:focus)': { borderColor: '$borderInputError' } },
    },
  },
  defaultVariants: {
    state: 'normal',
  },
});

export type TextAreaProps = React.ComponentProps<typeof TextAreaStyled>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const { cols = DEFAULT_TEXT_AREA_ROWS, children, ...rest } = props;
  return (
    <TextAreaStyled cols={cols} ref={ref} {...rest}>
      {children}
    </TextAreaStyled>
  );
});
