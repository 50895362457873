import {
  inSneakPreview,
  sneakPreviewCommitSha,
  sneakPreviewPathName,
} from '@attentive/sneak-preview-utils/sneak-preview';

import { EMAIL_REGEX } from './constants';
import { Routes } from './routes';

export enum LoginAuthParameter {
  RedirectUrl = 'redirect_uri',
  ClientId = 'client_id',
  State = 'state',
}

export enum ZendeskLoginAuthParameter {
  ReturnTo = 'return_to',
}

export const initSneakPreview = (location: Location) => {
  const isSneakPreview = inSneakPreview(location.pathname);

  if (isSneakPreview) {
    return sneakPreviewCommitSha(location.pathname);
  }

  return null;
};

export const getRedirectPath = (location: Location, params: URLSearchParams, zendesk: boolean) => {
  const redirBase = zendesk ? Routes.ZendeskCallback : Routes.Callback;
  const redir = `${redirBase}?${params.toString()}`;
  if (inSneakPreview(location.pathname)) {
    const commitSha = sneakPreviewCommitSha(location.pathname);
    return `/sneak-preview/${commitSha}${redir}`;
  }
  return redir;
};

export const updateFaviconForSneakPreview = () => {
  // When we are in sneak preview mode, we update the favicon to cotton candy.
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  if (favicon) {
    favicon.href = '/favicon.cotton-candy.png';
  }
};

export const baseName = () => {
  const { pathname } = window.location;
  const sneakPreview = inSneakPreview(pathname);
  if (sneakPreview) {
    const sneakPreviewPathSegment = sneakPreviewPathName(pathname);
    const commitSha = sneakPreviewCommitSha(pathname);
    return `/${sneakPreviewPathSegment}/${commitSha}`;
  }
  return '';
};

export const getMissingLoginParameters = (query: URLSearchParams): LoginAuthParameter[] => {
  return Object.values(LoginAuthParameter).filter((param) => !query.has(param));
};

export const setDocumentTitle = (title: string) => {
  document.title = `Attentive - ${title}`;
};

export const validateEmail = (email: string) => EMAIL_REGEX.test(email);
