import React from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { PicnicColorsToken } from '../../themes';

const Svg = styled('svg', {});

type SvgProps = React.ComponentProps<typeof Svg>;

interface WordmarkProps extends SvgProps {
  title?: string;
  color?: PicnicColorsToken | 'inherit';
  css?: PicnicCss;
}

const WordmarkSvg = ({ title, css, color = 'inherit', ...props }: WordmarkProps) => {
  return (
    <Svg viewBox="0 0 631.5 108.2" css={{ color, ...css }} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M625 31.9c0-2.4-1.7-4-4.5-4l-5.5.1.1 12.2h3V36h1.8l2.6 4.2h3.5l-3.1-4.7a4.08 4.08 0 002.1-3.6zm-5.1 1.6H618v-3.1h2.2a1.55 1.55 0 011.7 1.3v.1a1.77 1.77 0 01-2 1.7zM176.3 88.3c-5.1 0-8.6-4-8.6-9.9V51.5h21v-19h-20.95l-.2-21.8h-23.2l.2 72.6c0 14.7 9.8 24.7 24.2 24.7 7.6 0 14.8-1.1 20.5-3.7V85.8a27.32 27.32 0 01-12.95 2.5zm55.4-57c-21.6 0-38.2 16.8-38.2 39.1 0 22.1 16.6 37.5 39.5 37.5 12.9 0 25-4.6 32.4-14.1l-17.8-8.1c-3.6 3.3-9.1 4.6-14 4.6-8 0-16-4.1-17.1-12.5h53.1a35.74 35.74 0 001.2-9.2c0-22.9-16.7-37.3-39.05-37.3zm-15.2 31.8c.7-10 7.9-14.6 15.8-14.6 8.9 0 15.6 5 15.6 13.6v1zm-94.2 25.2c-5.1 0-8.6-4-8.6-9.9V51.5h21v-19h-20.95l-.2-21.8h-23.2l.2 72.6c0 14.7 9.8 24.7 24.2 24.7 7.6 0 14.8-1.1 20.5-3.7V85.8a27.09 27.09 0 01-12.95 2.5zm-81.5-57C20.9 31.3 0 44.4 0 70.8c0 22 14.8 37.4 33.5 37.4 10.7 0 18.8-4.3 24.1-11.9l.7 10.6h22.45V71.7c0-26.8-19.75-40.4-39.95-40.4zm0 56.6c-10.1 0-17.4-7.5-17.4-18.1s7.2-18.1 17.4-18.1c9.4 0 17.2 8.2 17.2 18.1s-7.8 18.1-17.2 18.1zm578.6-65.5c-7.3.1-12 4.8-11.9 11.8s4.9 11.6 12.2 11.5c7.1-.1 11.9-4.8 11.8-11.9s-4.9-11.5-12.1-11.4zm.3 21.3c-5.7.1-9.6-3.7-9.6-9.5s3.7-9.7 9.4-9.8 9.5 3.7 9.6 9.6c0 5.7-3.8 9.6-9.35 9.7zM506 32.6l-16.2 47-16.4-47h-25.2l30.2 74.2H501l30.2-74.2zm60.1-1.3c-21.6 0-38.2 16.8-38.2 39.1 0 22.1 16.6 37.5 39.5 37.5 12.9 0 25-4.6 32.4-14.1L582 85.7c-3.6 3.3-9.1 4.6-14 4.6-8 0-16-4.1-17.1-12.5H604a35.74 35.74 0 001.2-9.2c0-22.9-16.7-37.3-39.1-37.3zm16.2 31.8h-31.4c.7-10 7.9-14.6 15.8-14.6 8.9 0 15.6 5 15.6 13.6zM430.3 0c-8.2 0-14.4 5.5-14.4 13.2s6.2 13.4 14.4 13.4 14.4-5.6 14.4-13.4S438.6 0 430.3 0zM325 31.2A27.17 27.17 0 00301.5 44l-.8-11.4h-22.4v74.2h23.2V67.9c.5-9.3 8-16.4 16.2-16.4 9.2 0 13.4 6.9 13.4 15.6v39.6h23.1V63.5c0-18.8-11.45-32.3-29.2-32.3zm70.8 57.1c-5.1 0-8.6-4-8.6-9.9V51.5h21v-19h-21l-.2-21.8h-23.2l.2 72.6c0 14.7 9.8 24.7 24.2 24.7 7.6 0 14.8-1.1 20.5-3.7V85.8a27.09 27.09 0 01-12.9 2.5zm22.8-55.7v74.2H442V32.6a21.18 21.18 0 01-23.4 0z"
        fill="currentColor"
      />
    </Svg>
  );
};

export const Wordmark = React.memo(WordmarkSvg);
