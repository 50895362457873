/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const LaptopDisplay = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M6 6.75h12v7.5H6v-7.5Zm-1.5-1.5h15v10.5h-15V5.25Zm-2.4 12h19.875v1.5H2.1v-1.5Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(LaptopDisplay);
export default Memo;
