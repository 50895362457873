/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const FinanceBuilding = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.5 7.65V6.375L12 3.9 4.5 6.375V7.65h1.875v8.775h-.6L4.5 19.5h15l-1.275-3.15h-.6v-8.7H19.5Zm-11.25 8.7v-8.7h2.85v8.775H8.25v-.075Zm7.5 0H12.9v-8.7h2.85v8.7Z" />
  </svg>
);

const Memo = memo(FinanceBuilding);
export default Memo;
