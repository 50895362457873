import { styled } from '../../stitches.config';

const Card = styled('div', {
  position: 'relative',
  border: '$borderWidths$borderWidth1 solid $borderDefault',
  borderRadius: '$radius2',
  defaultTransition: ['transform', 'border', 'box-shadow', 'color'],
  backgroundColor: '$bgDefault',
  boxShadow: '$shadow1',
  padding: '$space8',
  '&:focus': {
    boxShadow: '$focus',
  },
  transform: 'translateZ(0)',
  variants: {
    interactive: {
      false: {},
      true: {
        '&:hover': {
          textDecoration: 'none',
          cursor: 'pointer',
          boxShadow: '$shadow3',
          transform: 'translateY(-2px)',
        },
        '&:active': {
          textDecoration: 'none',
          cursor: 'pointer',
          boxShadow: '$shadow1',
          transform: 'translateY(2px)',
        },
      },
    },
    active: {
      false: {},
      true: {
        border: '$borderWidths$borderWidth1 solid $borderSelectedToggle',
      },
    },
  },
  defaultVariants: {
    interactive: false,
    active: false,
  },
});

Card.displayName = 'Card';

export { Card };
