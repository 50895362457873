import React from 'react';

import { PicnicCss, styled } from '../../stitches.config';
import { Icon, IconName } from '../Icon';
import { InputGroup } from '../InputGroup';
import { Text } from '../Text';
import { textInputStyles } from '../TextInput';

const PickerWrapper = styled('button', {
  ...textInputStyles,
  display: 'flex',
  justifyContent: 'center',
  border: '$borderWidths$borderWidth1 solid $borderInput',
  cursor: 'pointer',
  position: 'relative',
  width: '100%',
  '&:hover': {
    borderColor: '$borderInputHover',
  },
  variants: {
    size: {
      small: { minHeight: '$size9', py: '$space1', px: '$space4', fontSize: '$fontSize2' },
      medium: { minHeight: '$size12', py: '$space1', px: '$space6', fontSize: '$fontSize3' },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

interface PickerButtonProps extends React.ComponentProps<typeof PickerWrapper> {
  iconName: IconName;
  description: string;
  textCss?: PicnicCss;
  iconCss?: PicnicCss;
}

export const PickerButton: React.FC<React.PropsWithChildren<PickerButtonProps>> = ({
  iconName,
  size,
  description,
  onClick,
  children,
  css = {},
  textCss = {},
  iconCss = {},
}) => {
  const textVariant = size === 'medium' ? 'body' : 'caption';
  return (
    // NOTE: Quite possible this is written wrong!
    <PickerWrapper size={size} onClick={onClick} css={css}>
      <Text variant={textVariant} css={textCss}>
        {children}
      </Text>
      <InputGroup.RightElement>
        <Icon name={iconName} size="small" description={description} css={iconCss} />
      </InputGroup.RightElement>
    </PickerWrapper>
  );
};
