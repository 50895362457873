import { QueryClient } from '@attentive/data/react-query';

const RETRY_DELAY = 1000;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false, //prevent automatic refetchs
      refetchIntervalInBackground: false, // if refetches are enabled, prevents them in the background
      refetchOnWindowFocus: false, // prevent refetch on window refocus
      refetchOnReconnect: false, // prevent refetch on reconnect
      retry: false, // prevent retries
      retryDelay: (retryAttempt: number) => retryAttempt * RETRY_DELAY, // Linear retry
    },
  },
});
