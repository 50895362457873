// Copied logic from StackOverflow, wrapped in try-catch and added generic.
// https://stackoverflow.com/a/38552302
export const parseJwt = <T>(token: string): T | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    return JSON.parse(jsonPayload) as T;
  } catch (e) {
    return null;
  }
};
