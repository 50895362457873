/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Cog = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m9.264 22-.398-3.2a3.763 3.763 0 0 1-.61-.3 8.224 8.224 0 0 1-.56-.375l-2.96 1.25L2 14.625l2.562-1.95a2.4 2.4 0 0 1-.025-.338v-.675c0-.108.009-.22.025-.337L2 9.375l2.736-4.75 2.96 1.25c.183-.133.374-.258.573-.375a5 5 0 0 1 .597-.3L9.264 2h5.472l.398 3.2c.216.083.42.183.61.3a8.2 8.2 0 0 1 .56.375l2.96-1.25L22 9.375l-2.562 1.95c.016.117.025.23.025.338v.675c0 .108-.017.22-.05.337l2.562 1.95-2.736 4.75-2.936-1.25a6.792 6.792 0 0 1-.572.375 4.99 4.99 0 0 1-.597.3l-.398 3.2H9.264Zm2.786-6.5c.962 0 1.782-.342 2.462-1.025A3.38 3.38 0 0 0 15.532 12a3.38 3.38 0 0 0-1.02-2.475A3.347 3.347 0 0 0 12.05 8.5c-.979 0-1.804.342-2.475 1.025-.672.683-1.008 1.508-1.008 2.475 0 .967.336 1.792 1.008 2.475.671.683 1.496 1.025 2.475 1.025Z" />
  </svg>
);

const Memo = memo(Cog);
export default Memo;
