export const REACT_18_ERROR_INFO = (shouldLogReactWarning: boolean) => {
  if (!shouldLogReactWarning) {
    return;
  }
  console.log(
    `%c⬆️⬆️  👋ATTENTIVE👋: We will be updating from ReactDOM.render to createRoot once React Query, React Router, and Radix dependencies are fully updated.\n
    %cSee https://attentivemobile.atlassian.net/wiki/spaces/ENG/pages/3229122563/React+18+Upgrade for more details and learn how to help with React Router 6 adoption!\n`,
    'font-weight: bold;',
    'font-weight: normal;'
  );
};
