// Types from https://github.com/DefinitelyTyped/DefinitelyTyped/blob/0919b905d898e86b4d932d6b18ab37c984bc7293/types/lodash/common/object.d.ts#L1097
// Function from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/get.ts
/* eslint-disable @typescript-eslint/no-explicit-any */

import { baseGet } from '../.internal/baseGet';

type Many<T> = T | readonly T[];
type PropertyName = string | number | symbol;
type PropertyPath = Many<PropertyName>;

interface NumericDictionary<T> {
  [index: number]: T;
}

type GetFieldTypeOfArrayLikeByKey<T extends unknown[], K> = K extends number
  ? T[K]
  : K extends `${infer N}`
  ? N extends number
    ? T[N]
    : undefined
  : K extends keyof T
  ? T[K]
  : undefined;

type GetFieldTypeOfStringByKey<T extends string, K> = K extends number
  ? T[K]
  : K extends `${infer N}`
  ? N extends number
    ? T[N]
    : undefined
  : K extends keyof T
  ? T[K]
  : undefined;

type GetFieldTypeOfNarrowedByKey<T, K> = T extends unknown[]
  ? GetFieldTypeOfArrayLikeByKey<T, K>
  : T extends string
  ? GetFieldTypeOfStringByKey<T, K>
  : K extends keyof T
  ? T[K]
  : K extends number
  ? `${K}` extends keyof T
    ? T[`${K}`]
    : undefined
  : K extends `${infer N}`
  ? N extends number
    ? N extends keyof T
      ? T[N]
      : undefined
    : undefined
  : undefined;

/** Internal. Assumes P is a dot-delimited path. */
type GetFieldTypeOfNarrowedByDotPath<T, P> = P extends `${infer L}.${infer R}`
  ? GetFieldType<GetFieldTypeOfNarrowedByKey<T, L>, R, 'DotPath'>
  : GetFieldTypeOfNarrowedByKey<T, P>;

/** Internal. This is a piece of GetFieldTypeOfNarrowedByLKR logic,
 *  assuming that Lc isn't to be ignored, and does not end with dot. */
type GetFieldTypeOfNarrowedByLcKR<T, Lc, K, R> = '' extends R
  ? GetFieldType<GetFieldTypeOfNarrowedByDotPath<T, Lc>, K, 'Key'>
  : R extends `.${infer Rc}`
  ? GetFieldType<GetFieldType<GetFieldTypeOfNarrowedByDotPath<T, Lc>, K, 'Key'>, Rc>
  : GetFieldType<GetFieldType<GetFieldTypeOfNarrowedByDotPath<T, Lc>, K, 'Key'>, R>;

/** Internal. Assumes T has been narrowed; L is a dot-delimited path,
 *  and should be ignored if an empty string; K is a key name; and R is
 *  a dot-delimetered path, to be ignored if an empty string. Also if
 *  L has a tail dot, or R has a front dot, these dots should be discarded,
 *  however when L or R is just a dot, they should be interpreted as empty
 *  key name (rather than ignored). */
type GetFieldTypeOfNarrowedByLKR<T, L, K, R> = '' extends L
  ? '' extends R
    ? GetFieldTypeOfNarrowedByKey<T, K>
    : R extends `.${infer Rc}`
    ? GetFieldType<GetFieldTypeOfNarrowedByKey<T, K>, Rc>
    : GetFieldType<GetFieldTypeOfNarrowedByKey<T, K>, R>
  : L extends `${infer Lc}.`
  ? GetFieldTypeOfNarrowedByLcKR<T, Lc, K, R>
  : GetFieldTypeOfNarrowedByLcKR<T, L, K, R>;

/** Internal. Assumes T has been narrowed. */
type GetFieldTypeOfNarrowed<T, X, XT extends 'DotPath' | 'Key' | 'Path'> = XT extends 'Key'
  ? GetFieldTypeOfNarrowedByKey<T, X>
  : XT extends 'DotPath'
  ? GetFieldTypeOfNarrowedByDotPath<T, X>
  : X extends `${infer L}['${infer K}']${infer R}`
  ? GetFieldTypeOfNarrowedByLKR<T, L, K, R>
  : X extends `${infer L}["${infer K}"]${infer R}`
  ? GetFieldTypeOfNarrowedByLKR<T, L, K, R>
  : X extends `${infer L}[${infer K}]${infer R}`
  ? GetFieldTypeOfNarrowedByLKR<T, L, K, R>
  : GetFieldTypeOfNarrowedByDotPath<T, X>;

/** Internal. Assumes T has been narrowed to a primitive type. */
type GetFieldTypeOfPrimitive<T, X, XT extends 'DotPath' | 'Key' | 'Path'> = Extract<
  T,
  string
> extends never
  ? T extends never
    ? never
    : undefined
  :
      | (Exclude<T, string> extends never ? never : undefined)
      | GetFieldTypeOfNarrowed<Extract<T, string>, X, XT>;

type GetFieldTypeOfObject<T, X, XT extends 'DotPath' | 'Key' | 'Path'> = Extract<
  T,
  unknown[]
> extends never
  ? GetFieldTypeOfNarrowed<T, X, XT>
  :
      | GetFieldTypeOfNarrowed<Exclude<T, unknown[]>, X, XT>
      | GetFieldTypeOfNarrowed<Extract<T, unknown[]>, X, XT>;

type GetFieldType<T, X, XT extends 'DotPath' | 'Key' | 'Path' = 'Path'> = Extract<
  T,
  object
> extends never
  ? GetFieldTypeOfPrimitive<T, X, XT>
  :
      | GetFieldTypeOfPrimitive<Exclude<T, object>, X, XT>
      | GetFieldTypeOfObject<Extract<T, object>, X, XT>;

/**
 * Gets the property value at path of object. If the resolved value is undefined the defaultValue is used
 * in its place.
 */
function get<TObject extends object, TKey extends keyof TObject>(
  object: TObject,
  path: TKey | [TKey]
): TObject[TKey];
/**
 * @see _.get
 */
function get<TObject extends object, TKey extends keyof TObject>(
  object: TObject | null | undefined,
  path: TKey | [TKey]
): TObject[TKey] | undefined;
/**
 * @see _.get
 */
function get<TObject extends object, TKey extends keyof TObject, TDefault>(
  object: TObject | null | undefined,
  path: TKey | [TKey],
  defaultValue: TDefault
): Exclude<TObject[TKey], undefined> | TDefault;
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1]
>(object: TObject, path: [TKey1, TKey2]): TObject[TKey1][TKey2];
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1]
>(object: TObject | null | undefined, path: [TKey1, TKey2]): TObject[TKey1][TKey2] | undefined;
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1],
  TDefault
>(
  object: TObject | null | undefined,
  path: [TKey1, TKey2],
  defaultValue: TDefault
): Exclude<TObject[TKey1][TKey2], undefined> | TDefault;
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1],
  TKey3 extends keyof TObject[TKey1][TKey2]
>(object: TObject, path: [TKey1, TKey2, TKey3]): TObject[TKey1][TKey2][TKey3];
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1],
  TKey3 extends keyof TObject[TKey1][TKey2]
>(
  object: TObject | null | undefined,
  path: [TKey1, TKey2, TKey3]
): TObject[TKey1][TKey2][TKey3] | undefined;
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1],
  TKey3 extends keyof TObject[TKey1][TKey2],
  TDefault
>(
  object: TObject | null | undefined,
  path: [TKey1, TKey2, TKey3],
  defaultValue: TDefault
): Exclude<TObject[TKey1][TKey2][TKey3], undefined> | TDefault;
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1],
  TKey3 extends keyof TObject[TKey1][TKey2],
  TKey4 extends keyof TObject[TKey1][TKey2][TKey3]
>(object: TObject, path: [TKey1, TKey2, TKey3, TKey4]): TObject[TKey1][TKey2][TKey3][TKey4];
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1],
  TKey3 extends keyof TObject[TKey1][TKey2],
  TKey4 extends keyof TObject[TKey1][TKey2][TKey3]
>(
  object: TObject | null | undefined,
  path: [TKey1, TKey2, TKey3, TKey4]
): TObject[TKey1][TKey2][TKey3][TKey4] | undefined;
/**
 * @see _.get
 */
function get<
  TObject extends object,
  TKey1 extends keyof TObject,
  TKey2 extends keyof TObject[TKey1],
  TKey3 extends keyof TObject[TKey1][TKey2],
  TKey4 extends keyof TObject[TKey1][TKey2][TKey3],
  TDefault
>(
  object: TObject | null | undefined,
  path: [TKey1, TKey2, TKey3, TKey4],
  defaultValue: TDefault
): Exclude<TObject[TKey1][TKey2][TKey3][TKey4], undefined> | TDefault;
/**
 * @see _.get
 */
function get<T>(object: NumericDictionary<T>, path: number): T;
/**
 * @see _.get
 */
function get<T>(object: NumericDictionary<T> | null | undefined, path: number): T | undefined;
/**
 * @see _.get
 */
function get<T, TDefault>(
  object: NumericDictionary<T> | null | undefined,
  path: number,
  defaultValue: TDefault
): T | TDefault;
/**
 * @see _.get
 */
function get<TDefault>(
  object: null | undefined,
  path: PropertyPath,
  defaultValue: TDefault
): TDefault;
/**
 * @see _.get
 */
function get(object: null | undefined, path: PropertyPath): undefined;
/**
 * @see _.get
 */
function get<TObject, TPath extends string>(
  data: TObject,
  path: TPath
): string extends TPath ? any : GetFieldType<TObject, TPath>;
/**
 * @see _.get
 */
function get<TObject, TPath extends string, TDefault = GetFieldType<TObject, TPath>>(
  data: TObject,
  path: TPath,
  defaultValue: TDefault
): Exclude<GetFieldType<TObject, TPath>, null | undefined> | TDefault;
/**
 * @see _.get
 */
function get(object: any, path: PropertyPath, defaultValue?: any): any;

/**
 * Gets the value at `path` of `object`. If the resolved value is
 * `undefined`, the `defaultValue` is returned in its place.
 */
function get<TObject, TPath extends string | any[], TDefault>(
  object: TObject,
  path: TPath,
  defaultValue?: TDefault
): any {
  const result = object == null ? undefined : baseGet(object, path);
  return result === undefined ? defaultValue : result;
}

export { get };
