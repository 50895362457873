import React, { FC, useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthSession } from '@attentive/acore-utils';
import { SignInLayout } from '@attentive/auth-ui';
import { useQuery } from '@attentive/data/react-query';
import { Banner, Box, Heading, LoadingIndicator, Wordmark } from '@attentive/picnic';

import { createAuthorizationCode } from '../utils/api';
import { LoginAuthParameter } from '../utils/app';
import { AUTH_PAGE_WIDTH } from '../utils/constants';
import { logError } from '../utils/loggerInstance';
import { Routes } from '../utils/routes';

const CREATE_AUTHORIZATION_CODE_QUERY = 'CREATE_AUTHORIZATION_CODE_QUERY';

interface ValidatedProps {
  clientId: string;
  redirectUri: string;
  state: string;
}

const AuthorizeValidApp: FC<ValidatedProps> = ({ clientId, redirectUri, state }) => {
  const { data, isLoading, error } = useQuery(
    [CREATE_AUTHORIZATION_CODE_QUERY, clientId, redirectUri, state],
    () => createAuthorizationCode(clientId, redirectUri, state),
    {
      onError: (originalException: Error) => {
        logError(originalException, { title: 'Error creating authorization code' });
      },
    }
  );

  useEffect(() => {
    if (data) {
      const url = new URL(redirectUri);
      url.searchParams.append('code', data.code);
      url.searchParams.append('timestamp', `${new Date().getTime()}`);
      url.searchParams.append('state', state);

      // TODO: when we have support for logging out we can re-use the token
      // in session storage. In the futre we may want login-ui to have a sign-out
      // page and hit it from client-ui after logging out. Currently each app will
      // have to log into attentive each time they need a token.
      AuthSession.clearStorage();

      window.location.replace(`${url}`);
    } else if (error) {
      AuthSession.clearStorage();
    }
  }, [error, data, redirectUri, state]);

  return (
    <SignInLayout>
      <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
        <Wordmark
          title="Attentive signin page"
          width="140px"
          css={{ mb: '$space12', display: 'block' }}
        />
        <Heading css={{ mb: '$space6' }} variant="page">
          Sign in
        </Heading>
        {isLoading && <LoadingIndicator />}
        {error && (
          <Banner variant="error">
            <Banner.Text>{error.message}</Banner.Text>
          </Banner>
        )}
      </Box>
    </SignInLayout>
  );
};

export const AuthorizeApp = () => {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const clientId = queryParams.get(LoginAuthParameter.ClientId);
  const oAuthRedirectUri = queryParams.get(LoginAuthParameter.RedirectUrl);
  const state = queryParams.get(LoginAuthParameter.State);

  if (!clientId || !oAuthRedirectUri || !state) {
    return <Navigate to={{ pathname: Routes.Error, search: queryParams.toString() }} replace />;
  }

  return <AuthorizeValidApp clientId={clientId} redirectUri={oAuthRedirectUri} state={state} />;
};
