export type MeridiemPeriod = 'AM' | 'PM';
export const MeridiemPeriods: readonly MeridiemPeriod[] = ['AM', 'PM'];

export const getMeridiemPeriod = (hours: number): MeridiemPeriod => (hours >= 12 ? 'PM' : 'AM');

export const sanitizeHours = (hours: number) => hours % 24;
export const sanitizeMinutes = (minutes: number) => minutes % 60;

// Converts 24 hour values to 12 hour values
export const convertHoursFromContinentalToMeridiem = (hours: number) => hours % 12 || 12;

export const convertHoursFromMeridiemToContinental = (
  hours: number,
  meridiemPeriod: MeridiemPeriod
) => (meridiemPeriod === 'AM' ? hours % 12 : (hours % 12) + 12);

export const parseTimeValue = (timeValue: string) => {
  const [selectedHours, selectedMinutes] = timeValue.split(':').map((value) => parseInt(value, 10));
  return { selectedHours, selectedMinutes };
};

export const generateTimeValue = (hours: number, minutes: number) => {
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  return `${paddedHours}:${paddedMinutes}`;
};
