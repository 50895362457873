/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Template = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M3 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Zm8.4 8.4a1 1 0 0 1 1-1H20a1 1 0 0 1 1 1v.4a1 1 0 0 1-1 1h-7.6a1 1 0 0 1-1-1v-.4Zm1 6.2a1 1 0 0 0-1 1v.4a1 1 0 0 0 1 1H20a1 1 0 0 0 1-1v-.4a1 1 0 0 0-1-1h-7.6Zm-1-2.6a1 1 0 0 1 1-1H20a1 1 0 0 1 1 1v.4a1 1 0 0 1-1 1h-7.6a1 1 0 0 1-1-1V16ZM4 11.4a1 1 0 0 0-1 1V20a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-7.6a1 1 0 0 0-1-1H4Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Template);
export default Memo;
