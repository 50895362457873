import GintoNordMedium from './assets/GintoNord-Medium.woff2';
import GintoNormalLight from './assets/GintoNormal-Light.woff2';
import GintoNormalMedium from './assets/GintoNormal-Medium.woff2';

export const loadPicnicFonts = () => {
  const fonts = (function () {
    // Short circuit if font APIs are not available. This is currently only necessary for Jest.
    if (!('fonts' in document)) {
      return [];
    }

    const gintoNord400 = new FontFace('Ginto Nord', `url(${GintoNordMedium}) format('woff2')`, {
      style: 'normal',
      weight: '400',
    });

    const gintoNormal400 = new FontFace(
      'Ginto Normal',
      `url(${GintoNormalLight}) format('woff2')`,
      {
        style: 'normal',
        weight: '400',
      }
    );

    const gintoNormal500 = new FontFace(
      'Ginto Normal',
      `url(${GintoNormalMedium}) format('woff2')`,
      {
        style: 'normal',
        weight: '500',
      }
    );

    return [gintoNord400, gintoNormal400, gintoNormal500];
  })();

  return Promise.all(
    fonts.map(async (font) => {
      await font.load();
      document.fonts.add(font);
    })
  );
};
