import React from 'react';

import { keyframes, PicnicCss } from '../../stitches.config';
import { VisuallyHidden } from '../../utils';
import { Box } from '../Box';

const pulsate = keyframes({
  '0%': {
    transform: 'scale(0)',
    opacity: 1,
  },
  '50%': {
    transform: 'scale(1)',
    opacity: 1,
  },
  '100%': {
    transform: 'scale(1)',
    opacity: 0,
  },
});

const dotStyles: PicnicCss = {
  boxSizing: 'content-box',
  display: 'inline-block',
  width: '$size1',
  height: '$size1',
  borderRadius: '$radiusMax',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'inherit',
};

export const LoadingIndicator = ({ css = {} }: { css?: PicnicCss }) => (
  <Box
    css={{
      display: 'inline-flex',
      alignItems: 'center',
      height: '1em',
      color: '$bgInverted',
      ...css,
    }}
    data-testid="loading-indicator"
  >
    <Box
      as="span"
      css={{ ...dotStyles, animation: `${pulsate} 1000ms ease-in-out infinite` }}
    ></Box>
    <Box
      as="span"
      css={{
        ...dotStyles,
        mx: '$space2',
        animation: `${pulsate} 1000ms ease-in-out infinite 300ms`,
      }}
    ></Box>
    <Box
      as="span"
      css={{
        ...dotStyles,
        animation: `${pulsate} 1000ms ease-in-out infinite 600ms`,
      }}
    ></Box>
    <VisuallyHidden role="status">Loading</VisuallyHidden>
  </Box>
);
