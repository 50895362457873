import { styled } from '../../stitches.config';

const Text = styled('p', {
  fontFamily: '$body',
  fontVariantNumeric: 'tabular-nums',
  variants: {
    variant: {
      lede: {
        fontSize: '$fontSize4',
        letterSpacing: '$letterSpacing0',
        lineHeight: '$lineHeight5',
      },
      body: {
        fontSize: '$fontSize3',
        letterSpacing: '$letterSpacing0',
        lineHeight: '$lineHeight7',
      },
      caption: {
        fontSize: '$fontSize2',
        letterSpacing: '$letterSpacing0',
        lineHeight: '$lineHeight6',
      },
      micro: {
        fontSize: '$fontSize1',
        letterSpacing: '$letterSpacing1',
        lineHeight: '$lineHeight4',
      },
    },
    color: {
      default: { color: '$textDefault' },
      subdued: { color: '$textSubdued' },
      inverted: { color: '$textInverted' },
      success: { color: '$textSuccess' },
      warning: { color: '$textWarning' },
      critical: { color: '$textCritical' },
      info: { color: '$textInformational' },
      decorative1: { color: '$textDecorative1' },
      decorative2: { color: '$textDecorative2' },
      decorative3: { color: '$textDecorative3' },
      decorative4: { color: '$textDecorative4' },
    },
    verticalAlignment: {
      default: {},
      center: { display: 'flex', alignItems: 'center' },
    },
  },
  defaultVariants: {
    variant: 'body',
  },
});

type TextProps = React.ComponentProps<typeof Text>;

Text.displayName = 'Text';

export type { TextProps };
export { Text };
