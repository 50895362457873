import { useMediaQuery } from '@react-hook/media-query';

type MediaKey = 'bp1' | 'bp2' | 'bp3' | 'bp4';
type MediaToken = `@${Extract<MediaKey, string>}`;

const bpWidths: { [key in MediaKey]: string } = {
  bp1: '640px',
  bp2: '768px',
  bp3: '1024px',
  bp4: '1280px',
};

const media: { [key in MediaKey]: string } = {
  bp1: `(min-width: ${bpWidths.bp1})`,
  bp2: `(min-width: ${bpWidths.bp2})`,
  bp3: `(min-width: ${bpWidths.bp3})`,
  bp4: `(min-width: ${bpWidths.bp4})`,
};

type Breakpoints = {
  atBp1: boolean;
  atBp2: boolean;
  atBp3: boolean;
  atBp4: boolean;
};

const useBreakpoints = (): Breakpoints => {
  const atBp1 = useMediaQuery(media.bp1);
  const atBp2 = useMediaQuery(media.bp2);
  const atBp3 = useMediaQuery(media.bp3);
  const atBp4 = useMediaQuery(media.bp4);
  return {
    atBp1,
    atBp2,
    atBp3,
    atBp4,
  };
};

export { media, bpWidths, useBreakpoints };
export type { MediaToken, Breakpoints };
