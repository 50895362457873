import React from 'react';

import { IconPrimitive, IconPrimitiveProps } from './ThirdPartyIconPrimitive';

interface PresentationalIconProps extends IconPrimitiveProps {
  mode?: 'presentational';
  description: string;
}

interface DecorativeIconProps extends IconPrimitiveProps {
  mode?: 'decorative';
  description?: never;
}

export type ThirdPartyIconProps = PresentationalIconProps | DecorativeIconProps;

export const ThirdPartyIcon = ({
  mode = 'decorative',
  css,
  description = '',
  ...props
}: ThirdPartyIconProps) => {
  const a11yProps: Partial<IconPrimitiveProps> = {
    ...(mode === 'presentational' && {
      title: description,
      'aria-label': description,
      role: 'img',
    }),
    ...(mode === 'decorative' && {
      'aria-hidden': true,
    }),
  };

  return <IconPrimitive {...a11yProps} css={css} {...props} />;
};
