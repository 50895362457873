import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import React from 'react';

import { styled } from '../../stitches.config';
import { RadixButtonInputProps } from '../../utils';
import { Box } from '../Box';
import { FormField } from '../FormField';
import { Icon, IconName } from '../Icon';

// This styling is duplicated in Select/NestedListComponents. Any styling changes applied here should also be applied there.
const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  appearance: 'none',
  backgroundColor: 'transparent',
  border: '2px solid transparent',
  padding: 0,
  flexShrink: 0,
  width: '$size5',
  height: '$size5',
  borderRadius: '$radius1',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  cursor: 'pointer',

  variants: {
    disabledVisually: {
      false: {
        focusVisible: '$focus',

        '&:hover': {
          borderColor: '$borderInputHover',
        },
      },
      true: {
        cursor: 'not-allowed',
      },
    },
    checkedVisually: {
      false: {},
      true: {
        color: '$iconInverted',
      },
    },
  },

  compoundVariants: [
    {
      disabledVisually: 'false',
      checkedVisually: 'false',
      css: {
        borderColor: '$borderInput',
        backgroundColor: '$bgDefault',
      },
    },
    {
      disabledVisually: 'false',
      checkedVisually: 'true',
      css: {
        borderColor: '$borderInputHover',
        backgroundColor: '$bgToggleSelected',
      },
    },
    {
      disabledVisually: 'true',
      checkedVisually: 'false',
      css: {
        borderColor: '$borderDefault',
        backgroundColor: '$bgAccent',
        color: '$iconDisabled',
      },
    },
    {
      disabledVisually: 'true',
      checkedVisually: 'true',
      css: {
        borderColor: '$borderDefault',
        backgroundColor: '$bgAccent',
        color: '$iconDisabled',
      },
    },
  ],

  defaultVariants: {
    disabledVisually: 'false',
    checkedVisually: 'false',
  },
});

type CheckboxItemProps = RadixButtonInputProps<
  React.ComponentProps<typeof StyledCheckbox>,
  boolean | 'indeterminate'
>;

const CheckboxItem: React.FC<React.PropsWithChildren<CheckboxItemProps>> = ({
  checked,
  disabled = false,
  onChange,
  ...rest
}) => {
  const iconName: IconName = checked === 'indeterminate' ? 'MinusSign' : 'Checkmark';
  return (
    <StyledCheckbox
      disabledVisually={disabled}
      checkedVisually={checked === false ? 'false' : 'true'}
      disabled={disabled}
      checked={checked}
      onCheckedChange={(c: boolean) => {
        onChange?.(c);
      }}
      {...rest}
    >
      <CheckboxPrimitive.Indicator>
        <Icon name={iconName} size="small" css={{ display: 'block' }} />
      </CheckboxPrimitive.Indicator>
    </StyledCheckbox>
  );
};

const CheckboxComponent: React.FC<React.PropsWithChildren<CheckboxItemProps>> = ({
  disabled,
  css,
  children,
  ...rest
}) => {
  return (
    <FormField.Label
      disabled={disabled}
      css={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        fontWeight: '$regular',
        ...css,
      }}
    >
      <CheckboxItem disabled={disabled} {...rest} />
      <Box as="span" css={{ ml: '$space2' }}>
        {children}
      </Box>
    </FormField.Label>
  );
};

type ComponentType = typeof CheckboxComponent;
interface CompositeComponent extends ComponentType {
  CheckboxItem: typeof CheckboxItem;
}

const Checkbox = CheckboxComponent as CompositeComponent;
Checkbox.CheckboxItem = CheckboxItem;

Checkbox.displayName = 'Checkbox';
Checkbox.CheckboxItem.displayName = 'Checkbox.CheckboxItem';

export { Checkbox };
