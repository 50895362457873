import { RecordSourceProxy } from 'relay-runtime';
import { v4 as uuidv4 } from 'uuid';

export const siteDataId = 'client:root:site';

type StoreValues = number | string | number[] | string[];

const isValidStoreValue = (value: StoreValues, key: string) => {
  if (value && !Array.isArray(value) && typeof value === 'object') {
    // TODO: log this error with Datadog RUM
    console.error(`Attempted to store invalid object '${JSON.stringify(value)}' for key ${key}`);
    return false;
  }
  return true;
};

export const createSite = (relayStore: RecordSourceProxy) => {
  const site = relayStore.create(siteDataId, 'Site');
  relayStore.getRoot().setLinkedRecord(site, 'site');
};

export const setSiteRecordProperties = (
  relayStore: RecordSourceProxy,
  dataId: string,
  values: object = {}
) => {
  const record = relayStore.get(dataId);
  if (record == null) {
    throw new Error(`The Relay store doesn't have ${dataId} record`);
  }

  for (const [key, value] of Object.entries(values)) {
    record.setValue(value, key);
  }
};

export const createSiteRecord = (
  relayStore: RecordSourceProxy,
  dataId: string,
  recordType: string,
  name: string,
  values: object = {}
) => {
  const record = relayStore.create(dataId, recordType);

  for (const [key, value] of Object.entries(values)) {
    if (isValidStoreValue(value, key)) {
      record.setValue(value, key);
    }
  }

  const site = relayStore.getRoot().getLinkedRecord('site');

  if (site === null) {
    throw new Error(`The Relay store doesn't have a client:root:site record`);
  }

  site.setLinkedRecord(record, name);
};

export const createSiteRecords = (
  relayStore: RecordSourceProxy,
  recordType: string,
  name: string,
  values: object[] = [],
  parentDataId: string = siteDataId
) => {
  const parent = relayStore.get(parentDataId);
  if (!parent) {
    throw new Error(`The Relay store doesn't have a ${parentDataId} record`);
  }

  const records = values.map((value) => {
    // eslint-disable-next-line no-plusplus
    const dataId = `client:root:${recordType}:${uuidv4()}`;
    const record = relayStore.create(dataId, recordType);
    setSiteRecordProperties(relayStore, dataId, value);

    return record;
  });

  parent.setLinkedRecords(records, name);

  return records;
};

export const updateSiteRecord = (
  relayStore: RecordSourceProxy,
  dataId: string,
  name: string,
  value: StoreValues
) => {
  const record = relayStore.get(dataId);
  if (record == null) {
    throw new Error(`The Relay store doesn't have ${dataId} record`);
  }

  if (isValidStoreValue(value, name)) {
    record.setValue(value, name);
  }
};
