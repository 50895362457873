import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        fill="url(#paint0_linear)"
        d="M2.645 11.38l8.742-5.792c.345-.22.883-.22 1.227 0l8.743 5.792c.524.344.524.91 0 1.254l-1.172.772a9.693 9.693 0 00-8.191-4.509c-3.447 0-6.467 1.807-8.19 4.51l-1.173-.773c-.51-.344-.51-.924.014-1.255zm9.349-.07c-2.606 0-4.881 1.38-6.177 3.42l2.013 1.338a4.842 4.842 0 014.15-2.344 4.86 4.86 0 014.15 2.344l2.014-1.338a7.219 7.219 0 00-6.15-3.42zm0 4.84c-.91 0-1.71.51-2.137 1.255l1.351.883c.22.179.483.29.786.29.303 0 .58-.111.786-.29l1.351-.883a2.453 2.453 0 00-2.137-1.255z"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="12"
          x2="12"
          y1="18.577"
          y2="5.423"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1C205" />
          <stop offset=".331" stopColor="#93C94B" />
          <stop offset=".486" stopColor="#70C05A" />
          <stop offset=".751" stopColor="#12B259" />
          <stop offset=".903" stopColor="#24AD69" />
          <stop offset="1" stopColor="#24AD69" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const KlaviyoColor = React.memo(Icon);
