// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/isSymbol.ts
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getTag } from './getTag';

/**
 * Checks if `value` is classified as a `Symbol` primitive or object.
 */
export function isSymbol(value: any): boolean {
  const type = typeof value;
  return (
    type === 'symbol' || (type === 'object' && value != null && getTag(value) === '[object Symbol]')
  );
}
