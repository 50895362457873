import { PicnicCss } from '../../stitches.config';

export const dateRangePickerCssOverrides: PicnicCss = {
  // the styles below adds margin to leave room for the arrows
  // we add these here instead of datepicker-global-css so they won't apply to the single day picker

  // this is the hidden label on the left that animates in when you click left
  '.CalendarMonthGrid_month__horizontal:nth-of-type(1) .CalendarMonth_caption': {
    ml: '$space10',
  },
  // this is the left month label
  '.CalendarMonthGrid_month__horizontal:nth-of-type(2) .CalendarMonth_caption': {
    ml: '$space10',
  },
  // this is the right month label
  '.CalendarMonthGrid_month__horizontal:nth-last-child(2) .CalendarMonth_caption': {
    mr: '$space10',
  },
  // this is the hidden label on the right that animates in when you click right
  '.CalendarMonthGrid_month__horizontal:nth-of-type(4) .CalendarMonth_caption': {
    mr: '$space10',
  },
};

export const globalDatePickerCss: PicnicCss = {
  '.CalendarDay': {
    boxSizing: 'border-box',
    cursor: 'pointer',
    fontSize: '$fontSize3',
    textAlign: 'center',
  },
  '.CalendarDay:active': {
    outline: 0,
  },
  '.CalendarDay__defaultCursor': {
    cursor: 'default',
  },
  '.CalendarDay__default': {
    border: '$borderWidths$borderWidth1 solid $borderActionBasic',
    color: '$textDefault',
    background: '$bgToggle',
  },
  '.CalendarDay__default:hover': {
    background: '$bgToggleHover',
    color: 'inherit',
  },
  '.CalendarDay__highlighted_calendar': {
    background: '$bgActionPrimary',
    color: '$textDefault',
  },
  '.CalendarDay__highlighted_calendar:active, .CalendarDay__highlighted_calendar:hover': {
    background: '$bgActionPrimaryHover',
    color: '$textDefault',
  },
  '.CalendarDay__selected_span': {
    background: '$bgToggleHover',
    color: '$textDefault',
  },
  '.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover': {
    background: '$bgToggleSelected',
    border: '$borderWidths$borderWidth1 solid $bgToggleSelected',
    color: '$textInverted',
  },
  '.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover': {
    background: '$bgToggleSelected',
    border: '$borderWidths$borderWidth1 double $bgToggleSelected',
    color: '$textInverted',
  },
  '.CalendarDay__hovered_span': {
    background: '$bgToggleHover',
    color: '$textPrimary',
    transition: '100ms ease-in',
  },
  '.CalendarDay__hovered_span:hover': {
    background: '$bgToggleSelected',
    border: '$borderWidths$borderWidth1 double $bgToggleSelected',
    color: '$textInverted',
  },
  '.CalendarDay__hovered_span:active': {
    background: '$bgToggleSelected',
    border: '$borderWidths$borderWidth1 double $bgToggleSelected',
    color: '$textInverted',
  },
  '.CalendarDay__blocked_calendar,.CalendarDay__blocked_calendar:active, .CalendarDay__blocked_calendar:hover':
    {
      background: `linear-gradient(
        45deg,
        $bgAccentDark 12.5%,
        $bgDefault 12.5%,
        $bgDefault 50%,
        $bgAccentDark 50%,
        $bgAccentDark 62.5%,
        $bgDefault 62.5%,
        $bgDefault 100%
      )`,
      backgroundSize: '5.5px 5.5px',
      color: '$textDisabled',
      cursor: 'not-allowed',
    },
  '.CalendarDay__hovered_start_first_possible_end': {
    background: '$bgToggleHover',
  },
  '.CalendarDay__hovered_start_blocked_min_nights': {
    background: '$bgToggleHover',
  },
  '.CalendarMonth': {
    background: '$bgDefault',
    textAlign: 'center',
    verticalAlign: 'top',
    userSelect: 'none',
  },
  '.CalendarMonth_table': {
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },
  '.CalendarMonth_verticalSpacing': {
    borderCollapse: 'separate',
  },
  '.CalendarMonth_caption': {
    color: '$textDefault',
    fontSize: '$fontSize4',
    textAlign: 'center',
    paddingTop: '$space8',
    paddingBottom: 44,
    captionSide: 'initial',
  },
  '.CalendarMonth_caption__verticalScrollable': {
    paddingTop: 12,
    paddingBottom: 8,
  },
  '.CalendarMonthGrid': {
    background: '$bgDefault',
    textAlign: 'left',
    zIndex: 0,
  },
  '.CalendarMonthGrid__animating': {
    zIndex: 1,
  },
  '.CalendarMonthGrid__horizontal': {
    position: 'absolute',
    left: 9,
  },
  '.CalendarMonthGrid__vertical': {
    margin: '0 auto',
  },
  '.CalendarMonthGrid__vertical_scrollable': {
    margin: '0 auto',
    overflowY: 'scroll',
  },
  '.CalendarMonthGrid_month__horizontal': {
    display: 'inline-block',
    verticalAlign: 'top',
    minHeight: '100%',
  },
  '.CalendarMonthGrid_month__hideForAnimation': {
    position: 'absolute',
    zIndex: -1,
    opacity: 0,
    pointerEvents: 'none',
  },
  '.CalendarMonthGrid_month__hidden': {
    visibility: 'hidden',
  },
  '.DayPickerNavigation': {
    position: 'relative',
    zIndex: 3,
  },
  '.DayPickerNavigation__horizontal': {
    height: 0,
  },
  '.DayPickerNavigation__verticalDefault': {
    position: 'absolute',
    width: '100%',
    height: 52,
    bottom: 0,
    left: 0,
  },
  '.DayPickerNavigation__verticalScrollableDefault': {
    position: 'relative',
  },
  '.DayPickerNavigation_button': {
    cursor: 'pointer',
    userSelect: 'none',
    border: 0,
    padding: 0,
    margin: 0,
  },
  '.DayPickerNavigation_button__default': {
    border: '$borderWidths$borderWidth1 solid $borderActionBasic',
    backgroundColor: '$bgDefault',
    color: '$textDefault',
  },
  '.DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:hover': {
    background: '$bgActionBasicHover',
  },
  '.DayPickerNavigation_button__default:active': {
    background: '$bgActionBasicPressed',
  },
  '.DayPickerNavigation_button__disabled': {
    cursor: 'default',
    border: '$borderWidths$borderWidth1 solid $borderActionBasicDisabled',
  },
  '.DayPickerNavigation_button__disabled:focus, .DayPickerNavigation_button__disabled:hover': {
    border: '$borderWidths$borderWidth1 solid $borderActionBasicDisabled',
  },
  '.DayPickerNavigation_button__disabled:active': {
    background: '0 0',
  },
  '.DayPickerNavigation_button__horizontalDefault': {
    position: 'absolute',
    top: 32,
    lineHeight: 0.78,
    borderRadius: '$radius1',
    height: '$size9',
    width: '$size9',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.DayPickerNavigation_leftButton__horizontalDefault': {
    left: 32,
  },
  '.DayPickerNavigation_rightButton__horizontalDefault': {
    right: 32,
  },

  '.DayPickerNavigation_button__verticalDefault': {
    padding: 5,
    background: '$bgDefault',
    boxShadow: '$shadow1',
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
    height: '100%',
    width: '50%',
  },
  '.DayPickerNavigation_nextButton__verticalDefault': {
    borderLeft: 0,
  },
  '.DayPickerNavigation_nextButton__verticalScrollableDefault': {
    width: '100%',
  },
  '.DayPickerNavigation_svg__horizontal': {
    height: 16,
    width: 16,
    fill: '$textDefault',
    display: 'block',
  },

  '.DayPickerNavigation_svg__vertical': {
    height: 42,
    width: 42,
    fill: '$iconDefault',
  },
  '.DayPickerNavigation_svg__disabled': {
    visibility: 'visible',
    fill: '$iconDisabled',
  },
  '.DayPicker': {
    background: '$bgDefault',
    position: 'relative',
    textAlign: 'left',
  },
  '.DayPicker__horizontal': {
    background: '$bgDefault',
  },
  '.DayPicker__verticalScrollable': {
    height: '100%',
  },
  '.DayPicker__hidden': {
    visibility: 'hidden',
  },
  '.DayPicker_portal__horizontal': {
    boxShadow: 'none',
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  '.DayPicker_portal__vertical': {
    position: 'initial',
  },
  '.DayPicker_focusRegion': {
    outline: 0,
  },
  '.DayPicker_calendarInfo__horizontal, .DayPicker_wrapper__horizontal': {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  '.DayPicker_weekHeaders': {
    position: 'relative',
  },
  '.DayPicker_weekHeaders__horizontal': {
    marginLeft: 9,
  },
  '.DayPicker_weekHeader': {
    color: '$textDefault',
    position: 'absolute',
    top: 80,
    zIndex: 2,
    textAlign: 'left',
  },
  '.DayPicker_weekHeader__vertical': {
    left: '50%',
  },
  '.DayPicker_weekHeader__verticalScrollable': {
    top: 0,
    display: 'table-row',
    borderBottom: '$borderWidths$borderWidth1 solid $borderLoud',
    background: '$bgDefault',
    marginLeft: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
  },
  '.DayPicker_weekHeader_ul': {
    listStyle: 'none',
    margin: '1px 0',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: '$fontSize2',
  },
  '.DayPicker_weekHeader_li': {
    display: 'inline-block',
    textAlign: 'center',
  },
  '.DayPicker_transitionContainer': {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '$radius1',
    minHeight: '361px',
    defaultTransition: ['height'],
    '&:before': {
      content: '""',
      left: 0,
      top: 0,
      width: 100,
      height: 70,
      position: 'absolute',
      zIndex: 2,
      background:
        'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 65%, rgba(255,255,255,0) 100%)',
    },
    '&:after': {
      content: '""',
      right: 0,
      top: 0,
      width: 100,
      height: 70,
      position: 'absolute',
      zIndex: 2,
      background:
        'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 65%, rgba(255,255,255,0) 100%)',
    },
  },
  '.DayPicker_transitionContainer:before, .DayPicker_transitionContainer:after': {
    background: 'transparent',
  },
  '.DayPicker_transitionContainer__vertical': {
    width: '100%',
  },
  '.DayPicker_transitionContainer__verticalScrollable': {
    paddingTop: 20,
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    overflowY: 'scroll',
  },
  // PICNIC FOCUS OVERRIDES
  '.CalendarDay:focus, .DayPickerNavigation_rightButton__horizontalDefault:focus, .DayPickerNavigation_leftButton__horizontalDefault:focus':
    {
      outline: 'none',
      boxShadow: '$focus',
    },
  '.CalendarDay:focus:not(:focus-visible), .DayPickerNavigation_rightButton__horizontalDefault:focus:not(:focus-visible), .DayPickerNavigation_leftButton__horizontalDefault:focus:not(:focus-visible)':
    {
      boxShadow: 'none',
    },
  '.CalendarDay:focus-visible, .DayPickerNavigation_rightButton__horizontalDefault:focus-visible, .DayPickerNavigation_leftButton__horizontalDefault:focus-visible':
    {
      boxShadow: '$focus',
    },
};
