/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MessageBubbleArrowLeft = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M3 3.75h18v12H7.5L3 20.25V3.75Zm8.85 9.375L9.225 10.5h8.4V9h-8.4l2.625-2.625h-2.1L6.375 9.75l3.375 3.375h2.1Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(MessageBubbleArrowLeft);
export default Memo;
