import React, { MouseEventHandler, Ref } from 'react';
import 'react-dates/initialize';

import { IconButton } from '../Button';
import { InputGroup } from '../InputGroup';
import { TextInput } from '../TextInput';

interface DatePickerInputProps extends React.ComponentProps<typeof InputGroup> {
  disabled?: boolean;
  value: string;
  placeholder?: string;
  description?: string;
  size: 'small' | 'normal';
  state: 'error' | 'normal';
  inputGroupRef?: Ref<HTMLInputElement>;
}

const DatePickerInputClear: React.FC<
  React.PropsWithChildren<{ onClick?: MouseEventHandler; disabled?: boolean }>
> = ({ onClick, disabled = false }) => (
  <IconButton
    iconName="X"
    description="Clear date"
    size="small"
    onClick={onClick}
    disabled={disabled}
  />
);

const DatePickerInputControl = React.forwardRef<HTMLButtonElement, DatePickerInputProps>(
  (props, ref) => {
    const {
      value,
      disabled = false,
      placeholder,
      size,
      state,
      description = 'Open date picker',
      inputGroupRef,
      ...rest
    } = props;

    let clearButton: React.ReactElement | null = null;

    React.Children.forEach(props.children, (child) => {
      if (!React.isValidElement(child)) {
        return;
      }

      if (child.type === DatePickerInputClear) {
        clearButton = child;
      }
    });
    return (
      <InputGroup {...rest}>
        <TextInput
          value={value}
          size={size}
          disabled={disabled}
          placeholder={placeholder}
          ref={inputGroupRef}
          readOnly
          state={state}
        />
        <InputGroup.RightElement css={{ pr: '$space0' }}>
          {clearButton}
          <IconButton
            iconName="Calendar"
            description={description}
            size="small"
            disabled={disabled}
            ref={ref}
          />
        </InputGroup.RightElement>
      </InputGroup>
    );
  }
);

type DisplayNamed = { displayName?: string };
type ComponentType = typeof DatePickerInputControl & DisplayNamed;

interface CompositeComponent extends ComponentType {
  ClearButton: typeof DatePickerInputClear & DisplayNamed;
}

const DatePickerInput = DatePickerInputControl as CompositeComponent;
DatePickerInput.ClearButton = DatePickerInputClear;

DatePickerInput.displayName = 'DatePickerInput';
DatePickerInput.ClearButton.displayName = 'DatePickerInput.ClearButton';

export { DatePickerInput };
