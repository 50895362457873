import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        id="path0"
        stroke="none"
        fill="#0494d4"
        fillRule="evenodd"
        d="M 11.625 0.112 C 4.774 0.367 -0.343 6.239 0.294 13.119 C 0.897 19.652 6.647 24.441 13.152 23.829 C 20.479 23.139 25.414 15.883 23.415 8.74 C 22.798 6.54 21.669 4.733 19.877 3.075 C 17.775 1.129 14.63 -0.001 11.625 0.112"
      />
      <path
        id="path1"
        d="M 9.988 7.389 C 11.721 7.627 13.148 9.055 13.521 10.929 C 13.618 11.414 13.613 11.329 13.618 13.118 L 13.623 14.735 L 14.337 14.735 C 14.893 14.735 15.084 14.73 15.204 14.712 C 16.376 14.54 17.258 13.547 17.384 12.26 C 17.393 12.164 17.397 11.369 17.397 9.945 C 17.397 7.563 17.394 7.703 17.48 7.561 C 17.591 7.381 17.689 7.35 18.162 7.35 C 18.674 7.35 18.78 7.39 18.893 7.628 L 18.929 7.704 L 18.933 9.943 C 18.937 12.267 18.935 12.37 18.88 12.737 C 18.6 14.609 17.269 16.142 15.605 16.513 C 15.232 16.595 15.239 16.595 13.703 16.595 L 12.28 16.595 L 12.217 16.563 C 12.123 16.514 12.04 16.42 11.996 16.313 L 11.956 16.218 L 11.949 13.906 L 11.942 11.593 L 11.911 11.442 C 11.676 10.27 10.876 9.434 9.826 9.257 C 8.472 9.029 7.187 9.997 6.942 11.427 C 6.931 11.492 6.918 11.568 6.913 11.593 C 6.907 11.618 6.899 12.671 6.894 13.936 L 6.887 16.234 L 6.856 16.312 C 6.755 16.561 6.662 16.595 6.111 16.595 L 5.673 16.595 L 5.596 16.554 C 5.513 16.508 5.442 16.429 5.394 16.33 L 5.363 16.264 L 5.363 13.906 C 5.363 12.057 5.367 11.517 5.38 11.412 C 5.67 9.159 7.257 7.508 9.28 7.359 C 9.413 7.349 9.988 7.389 9.988 7.389"
        stroke="none"
        fill="#FFFFFF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const NarvarColor = React.memo(Icon);
