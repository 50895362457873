import { processGoogleCallback, processSsoCallback } from './callback';
import { Routes } from './constants';

export async function initAuthLoginCallback(location: Location) {
  if (location.pathname.endsWith(Routes.GoogleSigninCallback)) {
    await processGoogleCallback(location);
  }
  if (location.pathname.endsWith(Routes.SsoSigninCallBack)) {
    await processSsoCallback(location);
  }
}
