/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Accessibility = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0-12.281a1.312 1.312 0 1 0 0-2.625 1.312 1.312 0 0 0 0 2.625Zm4.5.937v1.5h-3.375v1.813l2.417 4.187-1.299.75L12 14.02l-2.243 3.885-1.3-.75 2.418-4.187v-1.813H7.5v-1.5h9Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Accessibility);
export default Memo;
