import React from 'react';

import { PicnicCss, styled } from '../../stitches.config';

// Stack displays a single column with a row gap.
const stackLayoutCss: PicnicCss = {
  display: 'grid',
  gap: '$space4',
};

// Stretch displays children in equally sized columns with a column gap.
const stretchLayoutCss: PicnicCss = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
  gap: '$space4',
};

// Auto displays right justified without .
const autoLayoutCss: PicnicCss = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',

  [`> * ~ *`]: {
    ml: '$space4',
  },
};

// Legacy displays a 50% width container right justified, with each child equally sized.
const legacyLayoutContainerCss: PicnicCss = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
};

const legacyLayoutCss: PicnicCss = {
  ...stretchLayoutCss,
  width: '50%',
};

// Stitches has a bug preventing reliable use of responsive variants, so this
// hardcodes responsive styling for the common use case where the component stretches
// over most of the screen.
// https://github.com/modulz/stitches/issues/483
const responsiveLayoutContainerCss: PicnicCss = {
  '@bp3': legacyLayoutContainerCss,
};

const responsiveLayoutCss: PicnicCss = {
  ...stackLayoutCss,

  '@bp1': stretchLayoutCss,

  '@bp3': legacyLayoutCss,
};

const ButtonBarFlexParent = styled('div', {
  variants: {
    layout: {
      stack: {},
      stretch: {},
      auto: {},
      legacy: legacyLayoutContainerCss,
      responsive: responsiveLayoutContainerCss,
    },
  },
});

const ButtonBarContainer = styled('div', {
  variants: {
    layout: {
      stack: stackLayoutCss,
      stretch: stretchLayoutCss,
      auto: autoLayoutCss,
      legacy: legacyLayoutCss,
      responsive: responsiveLayoutCss,
    },
  },
});

export const ButtonBar: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof ButtonBarContainer>>
> = ({ layout = 'responsive', children, ...rest }) => {
  // TODO: Clean up implementation. Currently using grid to evenly space buttons,
  // so we have to control the width of the container to properly size them.
  // It may be cleaner to set the css prop on the child elements themselves.
  const customLegacyCss: PicnicCss =
    layout === 'legacy' && React.Children.count(children) === 1
      ? {
          width: '25%',
        }
      : {};
  const customReponsiveCss: PicnicCss =
    layout === 'responsive' && React.Children.count(children) === 1
      ? {
          '@bp3': {
            width: '25%',
          },
        }
      : {};

  return (
    <ButtonBarFlexParent layout={layout} {...rest}>
      <ButtonBarContainer layout={layout} css={{ ...customLegacyCss, ...customReponsiveCss }}>
        {children}
      </ButtonBarContainer>
    </ButtonBarFlexParent>
  );
};
