import * as PopoverPrimitive from '@radix-ui/react-popover';
import moment, { Moment } from 'moment';
import React, { ReactNode } from 'react';
import {
  DayOfWeekShape,
  DayPickerSingleDateController,
  DayPickerSingleDateControllerShape,
} from 'react-dates';

import { PicnicCss, styled } from '../../stitches.config';

import { DatePickerInput } from './DatePickerInput';
import { DISPLAY_DATE_FORMAT, MOMENT_ISO_8601_DATE_FORMAT } from './constants';
import { convertMomentToString, convertStringToMoment } from './utils';

const PU_DATEPICKER_BOX_SHADOW = '$shadow2';
const PU_DATEPICKER_FONT_SIZE = '$fontSize2';
const PU_DATEPICKER_BG_COLOR = '$bgDefault';
const PU_DATEPICKER_BORDER = '1px solid $borderLoud';

const StyledPopover = styled(PopoverPrimitive.Content, {
  backgroundColor: PU_DATEPICKER_BG_COLOR,
  borderRadius: '$radius1',
  border: PU_DATEPICKER_BORDER,
  boxShadow: PU_DATEPICKER_BOX_SHADOW,
  fontSize: PU_DATEPICKER_FONT_SIZE,
  overflow: 'hidden',
  zIndex: '$layer4',

  '&:focus': {
    outline: 'none',
  },
});

interface DatePickerProps {
  css?: PicnicCss;
  value: string | null;
  isDayBlocked?: (isoDate: string) => boolean;
  onChange?: (date: string) => void;
  firstDayOfWeek?: DayOfWeekShape;
}

type DatePickerControlProps = DatePickerProps &
  Partial<
    Pick<
      DayPickerSingleDateControllerShape,
      | 'firstDayOfWeek'
      | 'noNavNextButton'
      | 'noNavPrevButton'
      | 'onPrevMonthClick'
      | 'onNextMonthClick'
      | 'monthFormat'
      | 'dayAriaLabelFormat'
    >
  >;

const DatePickerControl = ({
  value,
  isDayBlocked,
  onChange,
  firstDayOfWeek = 1,
  ...rest
}: DatePickerControlProps) => {
  // This date is coming from the react-dates package itself
  const handleIsDayBlocked = (date: Moment) => {
    const dateToIso = convertMomentToString(moment(date));
    // Received date should only throw an error if something is wrong with the react-dates package itself
    if (dateToIso === null) throw new Error('Received invalid date');

    if (isDayBlocked) {
      return isDayBlocked(dateToIso);
    }

    return false;
  };

  const dateValue = convertStringToMoment(value);
  const handleDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    if (onChange) {
      onChange(date.format(MOMENT_ISO_8601_DATE_FORMAT));
    }
  };

  return (
    <DayPickerSingleDateController
      focused
      date={dateValue}
      isDayBlocked={isDayBlocked && handleIsDayBlocked}
      initialVisibleMonth={() => dateValue || moment()}
      onDateChange={handleDateChange}
      onFocusChange={() => null}
      hideKeyboardShortcutsPanel
      horizontalMonthPadding={24}
      firstDayOfWeek={firstDayOfWeek}
      {...rest}
    />
  );
};

type DatePickerPopoverProps = React.ComponentProps<typeof PopoverPrimitive.Content>;

const DatePickerPopover = ({ children, ...rest }: DatePickerPopoverProps) => {
  return (
    <PopoverPrimitive.Portal>
      <StyledPopover align="end" aria-label="date picker" {...rest}>
        {children}
      </StyledPopover>
    </PopoverPrimitive.Portal>
  );
};

type PopoverContentProps = Pick<
  PopoverPrimitive.PopoverContentProps,
  'side' | 'sideOffset' | 'align' | 'alignOffset'
>;

export interface DatePickerComponentProps
  extends PopoverPrimitive.PopoverProps,
    DatePickerProps,
    PopoverContentProps {
  defaultOpen?: boolean;
  disabled?: boolean;
  placeholder?: string;
  size?: 'small' | 'normal';
  state?: 'error' | 'normal';
}

export const DatePickerComponent = ({
  css = {},
  value,
  placeholder,
  size = 'normal',
  state = 'normal',
  defaultOpen,
  disabled = false,
  isDayBlocked,
  side,
  sideOffset,
  align = 'start',
  alignOffset,
  onChange,
  onOpenChange,
  firstDayOfWeek = 1,
}: DatePickerComponentProps) => {
  const [open, setOpen] = React.useState(defaultOpen);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    setOpen(false);
  }, [value]);

  React.useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const displayDate = value ? moment(value).format(DISPLAY_DATE_FORMAT) : '';

  return (
    <PopoverPrimitive.Root
      open={open}
      onOpenChange={(isOpen) => {
        if (!disabled) {
          setOpen(isOpen);
        }

        if (onOpenChange) {
          onOpenChange(isOpen);
        }
      }}
    >
      <PopoverPrimitive.Anchor asChild>
        <PopoverPrimitive.Trigger asChild>
          <DatePickerInput
            css={css}
            disabled={disabled}
            size={size}
            value={displayDate}
            placeholder={placeholder}
            inputGroupRef={inputRef}
            state={state}
            data-testid="picnic-date-picker-input"
          />
        </PopoverPrimitive.Trigger>
      </PopoverPrimitive.Anchor>
      <DatePickerPopover
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
      >
        <DatePickerControl
          value={value}
          onChange={(d) => {
            setOpen(false);
            if (onChange) {
              onChange(d);
            }
          }}
          isDayBlocked={isDayBlocked}
          firstDayOfWeek={firstDayOfWeek}
        />
      </DatePickerPopover>
    </PopoverPrimitive.Root>
  );
};

interface DatePickerRootProps extends PopoverPrimitive.PopoverProps {
  children: ReactNode;
}

export const DatePickerRoot = ({
  open,
  defaultOpen,
  children,
  onOpenChange,
}: DatePickerRootProps) => {
  return (
    <PopoverPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      {children}
    </PopoverPrimitive.Root>
  );
};

const DatePickerTrigger: React.FC<React.PropsWithChildren<{ children: React.ReactElement }>> = ({
  children,
}) => <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>;

type DisplayNamed = { displayName?: string };

type ComponentType = typeof DatePickerComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Root: typeof DatePickerRoot & DisplayNamed;
  Trigger: typeof DatePickerTrigger & DisplayNamed;
  Input: typeof DatePickerInput & DisplayNamed;
  Popover: typeof DatePickerPopover & DisplayNamed;
  Control: typeof DatePickerControl & DisplayNamed;
}

const DatePicker = DatePickerComponent as CompositeComponent;
DatePicker.Root = DatePickerRoot;
DatePicker.Trigger = DatePickerTrigger;
DatePicker.Input = DatePickerInput;
DatePicker.Popover = DatePickerPopover;
DatePicker.Control = DatePickerControl;

DatePicker.displayName = 'DatePicker';
DatePicker.Root.displayName = 'DatePicker.Root';
DatePicker.Trigger.displayName = 'DatePicker.Trigger';
DatePicker.Input.displayName = 'DatePicker.Input';
DatePicker.Popover.displayName = 'DatePicker.Popover';
DatePicker.Control.displayName = 'DatePicker.Control';

export { DatePicker };
