import React from 'react';

import { TabPanelTransformerSlot, TabListTransformerSlot } from './slots';

export const extractChildren = (children: React.ReactNode) => {
  const panelComponents: React.ReactElement[] = [];
  let tabListComponent: React.ReactElement | undefined;

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    switch (child.type) {
      case TabPanelTransformerSlot:
        panelComponents.push(child);
        break;
      case TabListTransformerSlot:
        tabListComponent = child;
        break;
      default:
        throw new Error(
          `${child.type} is not a valid child of TabGroupTransformer. Children must be of type TabGroupTransformer.List or TabGroupTransformer.Panel`
        );
    }
  });

  return { panelComponents, tabListComponent };
};

export const getActivePanelContent = (currentValue: string, panels: React.ReactElement[]) => {
  const activePanel = panels.find((child) => {
    return child.props.value === currentValue;
  });

  if (!activePanel) {
    return;
  }

  return activePanel.props.children;
};
