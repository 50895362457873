/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Wheel = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.311 1.48 12 5.23l1.689-3.75H10.31Z" />
    <path
      fillRule="evenodd"
      d="m12 8.883-2.18-4.84a9.357 9.357 0 1 0 11.537 9.101c0-4.418-3.061-8.12-7.177-9.102L12 8.883Zm-6.923 1.142A7.62 7.62 0 0 1 8.882 6.22L12 13.143l6.923 3.118a7.62 7.62 0 0 1-3.805 3.806L12 13.143l-6.923-3.118Zm0 6.237a7.62 7.62 0 0 0 3.805 3.805L12 13.143l-6.923 3.119Zm13.846-6.237L12 13.143l3.118-6.923a7.62 7.62 0 0 1 3.805 3.805Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Wheel);
export default Memo;
