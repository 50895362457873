// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/.internal/castPath.ts

import { isKey } from './isKey';
import { stringToPath } from './stringToPath';

/**
 * Casts `value` to a path array if it's not one.
 */
export function castPath(value: any, object: object): Array<any> {
  if (Array.isArray(value)) {
    return value;
  }
  return isKey(value, object) ? [value] : stringToPath(value);
}
