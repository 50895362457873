import { ThemeManifest, theme2021 } from './theme-2021';
/**
 * Theme tokens reference
 * @property borderStyles    - border-style
 * @property borderWidths    - border-width
 * @property colors          - color, background-color, border-color
 * @property fonts           - font-family
 * @property fontSizes       - font-sizes
 * @property fontWeights     - font-weight
 * @property letterSpacings  - letterSpacing
 * @property lineHeights     - line-height
 * @property radii           - border-radius
 * @property shadows         - box-shadow, text-shadow
 * @property sizes           - width, height, min-width, max-width, min-height, max-height
 * @property space           - margin, padding, grid-gap
 * @property transitions     - transition
 * @property zIndices        - zIndices
 */
export const themeDark: ThemeManifest = {
  ...theme2021,
  colors: {
    ...theme2021.colors,
    // Functional Tokens
    bgDefault: '$grayscale900',
    bgActionBasic: '$grayscale900',
    bgInformationalDefault: '$cloveBrown800',
    bgBrand: '$yellow300_40',
    bgRow: '$grayscale900',
    bgRowHover: '$grayscale800',
    bgRowSelected: '$grayscale700',
    bgRowPressed: '$grayscale700',
    bgWarningDefault: '$aperolOrange700',
    bgWarningAccent: '$aperolOrange800',
    textDefault: '$grayscale0',
    textInverted: '$grayscale900',
    textLink: '$grayscale0,',
  },
};
