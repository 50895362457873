/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const BagDollar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 4.507A3 3 0 0 1 15 7.5H9a3 3 0 0 1 3-2.993Zm0-1.5A4.5 4.5 0 0 0 7.5 7.5H5.25V21h13.5V7.5H16.5A4.5 4.5 0 0 0 12 3.007Zm.562 14.943c1.182-.2 1.89-.987 1.89-2.109 0-.963-.526-1.57-1.793-2.057l-.72-.284c-.659-.274-1.003-.517-1.003-1.003 0-.527.395-.821 1.186-.821.79 0 1.439.294 2.017.77v-1.287a3.364 3.364 0 0 0-1.577-.623V9.75h-1.124v.793c-1.186.184-1.87.944-1.87 2.025 0 .942.527 1.57 1.652 1.996l.74.284c.83.355 1.064.578 1.064 1.044 0 .588-.396.922-1.206.922-.862 0-1.652-.365-2.27-.882v1.288c.574.429 1.227.67 1.89.75v.78h1.124v-.8Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(BagDollar);
export default Memo;
