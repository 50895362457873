/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Eye = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 14.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" />
    <path
      fillRule="evenodd"
      d="M1.5 12c.825-.6 1.5-1.2 2.25-1.8C6.525 7.875 8.85 6 11.85 6h.3c3 0 5.325 1.875 8.1 4.2.675.6 1.425 1.2 2.25 1.8-.825.6-1.5 1.2-2.25 1.8-2.775 2.325-5.1 4.2-8.1 4.2h-.3c-3 0-5.325-1.875-8.1-4.2C3 13.2 2.325 12.6 1.5 12ZM12 16.125c2.25 0 4.125-1.875 4.125-4.125S14.25 7.875 12 7.875 7.875 9.75 7.875 12 9.75 16.125 12 16.125Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Eye);
export default Memo;
