/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Heart = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M11.993 7.196c-1.2-2.325-3.825-3.3-6.15-2.25-2.4 1.125-3.525 4.2-2.475 6.825.6 1.5 3.6 4.575 6.075 6.9l2.55 2.325 2.55-2.4c2.4-2.325 5.4-5.4 6.075-6.9 1.05-2.625 0-5.625-2.475-6.825-2.325-1.05-4.95 0-6.15 2.325Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Heart);
export default Memo;
