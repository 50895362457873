import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        fill="#fff"
        d="M15.28 4.195s-.23.066-.609.181a4.806 4.806 0 00-.296-.723c-.427-.822-1.068-1.266-1.824-1.266-.05 0-.099 0-.165.017-.016-.033-.049-.05-.066-.083-.328-.361-.756-.526-1.265-.51-.986.034-1.973.74-2.762 2.006-.559.888-.986 2.006-1.101 2.877-1.134.345-1.923.592-1.94.608-.575.18-.591.197-.657.74-.099.41-1.595 12-1.595 12l12.427 2.152V4.162c-.066.017-.115.017-.148.033zm-2.877.888c-.658.197-1.381.427-2.088.641.197-.773.592-1.545 1.052-2.055.181-.18.427-.394.707-.526.28.592.345 1.397.329 1.94zM11.07 2.469c.23 0 .428.05.592.148-.263.132-.526.345-.773.592-.624.674-1.1 1.726-1.298 2.729l-1.726.526c.345-1.562 1.677-3.945 3.205-3.995zM9.148 11.51c.066 1.052 2.844 1.282 3.008 3.764.115 1.956-1.035 3.288-2.696 3.386-2.005.132-3.106-1.052-3.106-1.052L6.78 15.8s1.101.838 1.989.773c.575-.033.789-.51.773-.838-.083-1.381-2.351-1.3-2.499-3.567-.131-1.89 1.118-3.814 3.88-3.995 1.068-.066 1.61.197 1.61.197l-.624 2.367s-.707-.328-1.546-.263c-1.216.083-1.232.855-1.216 1.036zm3.912-6.624c0-.493-.066-1.2-.296-1.792.757.148 1.118.986 1.283 1.496-.296.082-.625.18-.987.296zM15.838 22.129L21 20.847S18.78 5.839 18.764 5.74a.192.192 0 00-.18-.164c-.083 0-1.53-.033-1.53-.033s-.887-.855-1.216-1.183v17.769z"
      />
    </svg>
  );
};

export const ShopifyWhite = React.memo(Icon);
