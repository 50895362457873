import { API } from '@attentive/acore-utils';

import { AuthEndpoints } from './constants';
import { logAuthError } from './errors';
import { retryRequest } from './utils';

export const sendPasswordResetEmail = async (email: string) => {
  try {
    await retryRequest('send password reset email', () =>
      // GMRU: POST /auth/send-password-reset-email
      API.post(AuthEndpoints.SEND_PASSWORD_RESET_EMAIL, {
        email,
      })
    );
    return true;
  } catch (error) {
    logAuthError(error);
    throw error;
  }
};

export const sendOnboardingWelcomeEmail = async (email: string, externalId: string) => {
  try {
    await retryRequest('send onboading welcome email', () =>
      // GMRU: POST /auth/send-onboarding-welcome-email
      API.post(AuthEndpoints.SEND_ONBOARDING_WELCOME_EMAIL, {
        email,
        externalId,
        boomerang: false,
      })
    );
    return true;
  } catch (error) {
    logAuthError(error);
    throw error;
  }
};
interface ValidateResetTokenResponse {
  email: string;
}

export const validateResetToken = async (token: string) => {
  try {
    return await retryRequest('validate reset token', () =>
      // GMRU: GET /auth/verify-email-token
      API.get<ValidateResetTokenResponse>(`${AuthEndpoints.VERIFY_EMAIL_TOKEN}?token=${token}`)
    );
  } catch (error) {
    logAuthError(error);
    throw error;
  }
};

interface VerifyCreatePasswordTokenResponse {
  userEmail: string;
  redirectToSignin: boolean;
  hasExpiredToken: boolean;
  companyId: number;
}

export const verifyCreatePasswordToken = async (token: string) => {
  try {
    return await retryRequest('validate create pw token', () =>
      // GMRU: GET /auth/verify-create-password-token
      API.get<VerifyCreatePasswordTokenResponse>(
        `${AuthEndpoints.VERIFY_CREATE_PASSWORD_TOKEN}?token=${token}`
      )
    );
  } catch (error) {
    logAuthError(error);
    throw error;
  }
};

export const resetPassword = async (password: string, token: string) => {
  try {
    await retryRequest('reset password', () =>
      // GMRU: PUT /auth/change-password
      API.put(`${AuthEndpoints.CHANGE_PASSWORD}?token=${token}`, {
        password,
      })
    );
    return true;
  } catch (error) {
    logAuthError(error);
    throw error;
  }
};
