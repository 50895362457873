/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MagnifyingGlass = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M14.25 9.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-.588 5.501a6.75 6.75 0 1 1 1.591-1.593L21 19.404l-1.592 1.592-5.745-5.745Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(MagnifyingGlass);
export default Memo;
