import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <g clipPath="url(#clip0_68_5763)">
        <path
          d="M18.9192 9.1626H10.1734C10.0068 9.1626 9.87183 9.2976 9.87183 9.46414V10.4828C9.87183 10.6493 10.0068 10.7843 10.1734 10.7843H18.9192C19.0857 10.7843 19.2207 10.6493 19.2207 10.4828V9.46414C19.2207 9.2976 19.0857 9.1626 18.9192 9.1626Z"
          fill="#52BD94"
        />
        <path
          d="M13.1346 13.2158H4.38882C4.22229 13.2158 4.08728 13.3508 4.08728 13.5174V14.536C4.08728 14.7026 4.22229 14.8376 4.38882 14.8376H13.1346C13.3012 14.8376 13.4362 14.7026 13.4362 14.536V13.5174C13.4362 13.3508 13.3012 13.2158 13.1346 13.2158Z"
          fill="#52BD94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.20979 10.2729C5.28584 10.2925 5.36656 10.282 5.43503 10.2435C5.5035 10.205 5.55441 10.1415 5.57709 10.0663C6.06008 8.54945 7.09265 7.26794 8.47206 6.47339C9.85147 5.67884 11.4781 5.42862 13.0325 5.77188C13.1077 5.78753 13.1859 5.77367 13.2512 5.73319C13.3164 5.6927 13.3635 5.62869 13.3828 5.5544L13.6467 4.56961C13.6571 4.53 13.6594 4.48868 13.6534 4.44816C13.6474 4.40764 13.6333 4.36875 13.6118 4.33384C13.5904 4.29893 13.5621 4.26873 13.5287 4.24504C13.4953 4.22136 13.4574 4.20469 13.4174 4.19604C11.4533 3.75313 9.39435 4.06688 7.65129 5.07471C5.90823 6.08254 4.60913 7.71038 4.01307 9.63357C4.00169 9.67214 3.99817 9.7126 4.00273 9.75255C4.00729 9.7925 4.01983 9.83113 4.0396 9.86614C4.05938 9.90115 4.08598 9.93183 4.11784 9.95636C4.14971 9.98088 4.18617 9.99876 4.22507 10.0089L5.20979 10.2729Z"
          fill="#52BD94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1015 13.727C18.0255 13.7073 17.9448 13.7178 17.8763 13.7563C17.8079 13.7948 17.757 13.8583 17.7343 13.9335C17.2513 15.4504 16.2188 16.7319 14.8393 17.5265C13.4599 18.3211 11.8333 18.5713 10.2788 18.228C10.2037 18.2123 10.1254 18.2262 10.0602 18.2667C9.99503 18.3072 9.94789 18.3712 9.92856 18.4454L9.66477 19.4302C9.65436 19.4699 9.65209 19.5112 9.65808 19.5517C9.66407 19.5922 9.67821 19.6311 9.69964 19.666C9.72107 19.7009 9.74935 19.7311 9.78277 19.7548C9.81619 19.7785 9.85406 19.7952 9.8941 19.8038C11.8582 20.2467 13.9171 19.933 15.6602 18.9251C17.4033 17.9173 18.7024 16.2895 19.2984 14.3663C19.3098 14.3277 19.3133 14.2872 19.3088 14.2473C19.3042 14.2073 19.2917 14.1687 19.2719 14.1337C19.2521 14.0987 19.2255 14.068 19.1936 14.0435C19.1618 14.019 19.1253 14.0011 19.0864 13.9909L18.1015 13.727Z"
          fill="#52BD94"
        />
        <path
          d="M16.2766 7.38185C16.7677 7.38185 17.1658 6.98376 17.1658 6.49268C17.1658 6.00161 16.7677 5.60352 16.2766 5.60352C15.7855 5.60352 15.3875 6.00161 15.3875 6.49268C15.3875 6.98376 15.7855 7.38185 16.2766 7.38185Z"
          fill="#52BD94"
        />
        <path
          d="M7.03443 18.3965C7.52551 18.3965 7.9236 17.9984 7.9236 17.5073C7.9236 17.0163 7.52551 16.6182 7.03443 16.6182C6.54336 16.6182 6.14526 17.0163 6.14526 17.5073C6.14526 17.9984 6.54336 18.3965 7.03443 18.3965Z"
          fill="#52BD94"
        />
      </g>
      <defs>
        <clipPath id="clip0_68_5763">
          <rect width="15.3113" height="16" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SegmentIOColor = React.memo(Icon);
