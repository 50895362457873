import { GraphQLSingularResponse, PayloadData, PayloadError } from 'relay-runtime';

/**
 * GQLError is not a 1-to-1 representation of the actual GraphQLError type from graphql
 * Some typings are off, i.e. extensions is actually - extensions?: Maybe<{ [key: string]: any }>
 * Type Reference - https://github.com/graphql/graphql-js/blob/main/src/error/GraphQLError.ts
 */
export interface GQLError {
  message: string;
  path?: Array<string | number>;
  locations: Array<{
    line: number;
    column: number;
  }>;
  extensions?: {
    code: string;
    description: string;
    classification: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originalError?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  positions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  source?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes?: any;
}

export interface GQLSingularResponse {
  data?: PayloadData;
  errors?: GQLError[];
  extensions?: Record<string, unknown>;
}

export type GQLServerIncrementalResponse = GraphQLSingularResponse & {
  label: string;
  path: Array<string | number>;
};

export interface GQLServerResponse {
  data?: PayloadData;
  errors?: PayloadError[];
  extensions?: Record<string, unknown>;
  incremental?: GQLServerIncrementalResponse[];
  hasNext?: boolean;
}

// Relay implements an older version of the defer spec than our server does.
// Convert a response from the server into the format which Relay expects.
export const convertServerResponseToRelaySpec = (
  input: GQLServerResponse
): GraphQLSingularResponse[] => {
  const { data, errors, extensions, incremental = [], hasNext = false } = input;
  const responses: GraphQLSingularResponse[] = [];
  if (data !== undefined || errors || extensions) {
    responses.push({
      data: data as PayloadData,
      errors,
      extensions,
    });
  }
  for (const incrementalResponse of incremental) {
    responses.push(incrementalResponse);
  }
  responses.forEach((response, i) => {
    // Relay expects the final response in a stream to have the extension { is_final: true }
    const isFinal = !hasNext && i === responses.length - 1;
    const resExtensions = response.extensions || (response.extensions = {});
    resExtensions.is_final = isFinal;
  });
  if (!responses.length && !hasNext) {
    // If we don't have a final response to add that extension to, make an empty one
    responses.push({ data: null, extensions: { is_final: true } });
  }
  return responses;
};
