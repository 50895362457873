import { useField } from 'formik';
import React from 'react';

import { compositeComponent } from '../../utils/composite-component';
import { MultiSelect as StyledMultiSelect } from '../Select';

import { useFormFieldIsErrorShowing, useFormFieldName } from './FormField';
import { PropsWithoutField } from './utils';

type Props = PropsWithoutField<typeof StyledMultiSelect>;

const MultiSelect: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const name = useFormFieldName();
  const isErrorShowing = useFormFieldIsErrorShowing();
  const [field, , { setValue }] = useField(name);

  return (
    <StyledMultiSelect
      {...props}
      {...field}
      state={isErrorShowing ? 'error' : 'normal'}
      onChange={setValue}
    />
  );
};

const Namespace = compositeComponent(MultiSelect, {
  Group: StyledMultiSelect.Group,
  Item: StyledMultiSelect.Item,
});

export { Namespace as MultiSelect };
