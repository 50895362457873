import { useHydrateAtoms } from 'jotai/utils';

import { AnyWritableAtom, Config } from '@attentive/acore-utils';
import { zendeskBaseUrlAtom } from '@attentive/auth-core';

export const useHydrateEnvVarAtoms = () => {
  const atomsToUpdate: Array<[AnyWritableAtom, unknown]> = [];

  atomsToUpdate.push([zendeskBaseUrlAtom, Config.get('zendeskBaseUrl')]);

  useHydrateAtoms(atomsToUpdate);
};
