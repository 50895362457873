import React from 'react';
import { PolymorphicComponent } from 'react-polymorphic-box';

import { styled } from '../../stitches.config';
import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';
import { Link, LinkProps } from '../Link';
import { Text } from '../Text';

// Expose a strongly typed Item component to use as public API for Breadcrumbs children.
// This Item component is NOT directly rendered. The parent Breadcrumbs component:
// - ensures that Breadcrumbs children are all of type Breadcrumbs.Item
// - renders a set of PrivateItem children that correspond to the public Item children
// - manages items' variants internally
// Reference: https://twitter.com/markdalgleish/status/1370951759284162565
const Item: PolymorphicComponent<LinkProps, 'a'> = () => null;

const StyledItemLink = styled(Link, {
  variants: {
    itemVariant: {
      current: {
        color: '$textDefault',
        textDecoration: 'none',
        fontWeight: '$bold',
      },
      ancestor: {},
    },
  },
});

interface PrivateItemProps extends LinkProps {
  itemVariant: 'current' | 'ancestor';
}

const PrivateItem = ({ children, ...props }: PrivateItemProps) => {
  if (props.itemVariant === 'current') {
    return (
      <Text variant="caption" css={{ fontWeight: '$bold' }}>
        {children}
      </Text>
    );
  }
  return (
    <StyledItemLink {...props}>
      <Text variant="caption">{children}</Text>
    </StyledItemLink>
  );
};

const BreadcrumbsComponent = ({ css, children }: BoxProps) => {
  return (
    <Box as="nav" css={{ display: 'inline-flex', alignItems: 'center', ...css }}>
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          return;
        }

        if (child.type === Item) {
          const itemCount = React.Children.count(children);
          const itemVariant = index === itemCount - 1 ? 'current' : 'ancestor';
          const needsSeparator = index < itemCount - 1;

          return (
            <>
              <PrivateItem {...child.props} itemVariant={itemVariant} />
              {needsSeparator && (
                <Icon name="ChevronRight" size="extraSmall" css={{ mx: '$space1' }} />
              )}
            </>
          );
        }

        throw new Error('Breadcrumbs children must be of type Breadcrumbs.Item');
      })}
    </Box>
  );
};

type DisplayNamed = { displayName?: string };
type ComponentType = typeof BreadcrumbsComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Item: typeof Item & DisplayNamed;
}

const Breadcrumbs = BreadcrumbsComponent as CompositeComponent;
Breadcrumbs.Item = Item;

Breadcrumbs.displayName = 'Breadcrumbs';
Breadcrumbs.Item.displayName = 'Breadcrumbs.Item';

export { Breadcrumbs };
