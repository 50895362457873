/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ChevronStart = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.075 21.6V2.4h2.4v19.2h-2.4ZM20.4 4.57l-1.65-1.575-9 9 9 9 1.65-1.575-7.425-7.425L20.4 4.57Z" />
  </svg>
);

const Memo = memo(ChevronStart);
export default Memo;
