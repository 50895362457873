import React from 'react';

import { PicnicCss } from '../../stitches.config';
import { DisplayNamed } from '../../storybook';
import { Box } from '../Box';
import { ButtonGroup } from '../ButtonGroup';
import { Text, TextProps } from '../Text';

interface PaginatorProps {
  totalItems: number;
  maxItemsPerPage: number;
  onOffsetChange: (offset: number) => void;
  offset: number;
  hasStartEndButtons?: boolean;
  disabled?: boolean;
  css?: PicnicCss;
}

enum PaginationDirection {
  NEXT = 'NEXT',
  LAST = 'LAST',
  PREV = 'PREV',
  FIRST = 'FIRST',
}

export interface PaginationMetadata {
  pageIndex: number;
  itemsPerPage: number;
  totalItems?: number;
}

const getLabelText = ({ pageIndex, itemsPerPage, totalItems }: PaginationMetadata) => {
  const offsetStart = pageIndex * itemsPerPage;
  const offsetEnd = (pageIndex + 1) * itemsPerPage;
  let start, end, total;

  if (totalItems !== undefined) {
    start = totalItems > 0 ? offsetStart + 1 : 0;
    end =
      offsetEnd <= totalItems
        ? offsetEnd
        : offsetEnd - (itemsPerPage - (totalItems % itemsPerPage));
    total = totalItems;
  } else {
    start = offsetStart + 1;
    end = offsetEnd;
    total = 'many';
  }

  return `Viewing ${start.toLocaleString()}-${end.toLocaleString()} of ${total.toLocaleString()}`;
};

const PaginatorLabel: React.VFC<TextProps & PaginationMetadata> = ({
  pageIndex,
  itemsPerPage,
  totalItems,
  ...rest
}) => {
  const labelText = getLabelText({
    pageIndex,
    itemsPerPage,
    totalItems,
  });

  return <Text {...rest}>{labelText}</Text>;
};

// if hasStartEndButtons is true, force consumer component to define loadFirst and loadLast
// if hasStartEndButtons is false, disallow loadFirst and loadLast to be defined
export interface PaginatorButtonGroupCommonProps {
  hasStartEndButtons?: boolean;
  disabled?: boolean;
  hasNext: boolean;
  hasPrevious: boolean;
  loadNext: () => void;
  loadPrevious: () => void;
  css?: PicnicCss;
}
export interface PaginatorButtonGroupPropsWithStartEndButtons
  extends PaginatorButtonGroupCommonProps {
  hasStartEndButtons: true;
  loadLast: () => void;
  loadFirst: () => void;
}
export interface PaginatorButtonGroupPropsWithoutStartEndButtons
  extends PaginatorButtonGroupCommonProps {
  hasStartEndButtons?: false;
  loadLast?: never;
  loadFirst?: never;
}
type PaginatorButtonGroupProps =
  | PaginatorButtonGroupPropsWithoutStartEndButtons
  | PaginatorButtonGroupPropsWithStartEndButtons;
const PaginatorButtonGroup: React.VFC<PaginatorButtonGroupProps> = ({
  hasNext,
  hasPrevious,
  loadNext,
  loadPrevious,
  hasStartEndButtons = false,
  disabled = false,
  loadFirst,
  loadLast,
  ...rest
}) => {
  return (
    <ButtonGroup
      // Stop the ButtonGroup from highlighting either of the elements as "active".
      activeItem={null}
      {...rest}
    >
      {hasStartEndButtons && (
        <ButtonGroup.IconItem
          name="ChevronStart"
          description="First Page"
          disabled={disabled || !hasPrevious}
          onClick={loadFirst}
        />
      )}
      <ButtonGroup.IconItem
        name={hasStartEndButtons ? 'ChevronLeft' : 'ArrowLeft'}
        description="Previous Page"
        disabled={disabled || !hasPrevious}
        onClick={loadPrevious}
      />
      <ButtonGroup.IconItem
        name={hasStartEndButtons ? 'ChevronRight' : 'ArrowRight'}
        description="Next Page"
        disabled={disabled || !hasNext}
        onClick={loadNext}
      />
      {hasStartEndButtons && (
        <ButtonGroup.IconItem
          name="ChevronEnd"
          description="Last Page"
          disabled={disabled || !hasNext}
          onClick={loadLast}
        />
      )}
    </ButtonGroup>
  );
};

const PaginatorComponent = ({
  maxItemsPerPage,
  totalItems,
  offset,
  onOffsetChange,
  hasStartEndButtons = false,
  disabled = false,
  css,
}: PaginatorProps) => {
  const totalPages = Math.ceil(totalItems / maxItemsPerPage);

  const hasPrevious = offset !== 0;
  const hasNext = offset !== totalPages - 1 && totalPages !== 0;

  const handlePaginate = (type: PaginationDirection) => {
    switch (type) {
      case PaginationDirection.FIRST: {
        onOffsetChange(0);
        break;
      }
      case PaginationDirection.LAST: {
        onOffsetChange(totalPages - 1);
        break;
      }
      case PaginationDirection.NEXT: {
        onOffsetChange(offset + 1);
        break;
      }
      case PaginationDirection.PREV: {
        onOffsetChange(offset - 1);
        break;
      }
    }
  };

  const commonButtonGroupProps: Pick<
    PaginatorButtonGroupProps,
    'loadPrevious' | 'loadNext' | 'hasNext' | 'hasPrevious' | 'disabled'
  > = {
    loadPrevious: () => handlePaginate(PaginationDirection.PREV),
    loadNext: () => handlePaginate(PaginationDirection.NEXT),
    hasNext,
    hasPrevious,
    disabled,
  };

  return (
    <Box
      css={{
        display: 'inline-flex',
        alignItems: 'center',
        ...css,
      }}
    >
      <PaginatorLabel
        pageIndex={offset}
        itemsPerPage={maxItemsPerPage}
        totalItems={totalItems}
        css={{ display: 'inline', marginRight: '$space4' }}
      />
      {hasStartEndButtons && (
        <PaginatorButtonGroup
          hasStartEndButtons={true}
          loadFirst={() => handlePaginate(PaginationDirection.FIRST)}
          loadLast={() => handlePaginate(PaginationDirection.LAST)}
          {...commonButtonGroupProps}
        />
      )}
      {!hasStartEndButtons && (
        <PaginatorButtonGroup hasStartEndButtons={false} {...commonButtonGroupProps} />
      )}
    </Box>
  );
};

type ComponentType = typeof PaginatorComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Label: typeof PaginatorLabel & DisplayNamed;
  ButtonGroup: typeof PaginatorButtonGroup & DisplayNamed;
}

const Paginator = PaginatorComponent as CompositeComponent;
Paginator.Label = PaginatorLabel;
Paginator.ButtonGroup = PaginatorButtonGroup;

Paginator.displayName = 'Paginator';
Paginator.Label.displayName = 'Paginator.Label';
Paginator.ButtonGroup.displayName = 'Paginator.ButtonGroup';

export type { PaginatorProps };
export { Paginator };
