/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Link = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="m14.807 12.863 3.1-3.1a2.599 2.599 0 0 0-.006-3.671 2.599 2.599 0 0 0-3.671-.006l-1.318 1.317L11.26 5.75l1.317-1.317a4.937 4.937 0 0 1 6.978.006 4.937 4.937 0 0 1 .006 6.977l-3.1 3.1a4.937 4.937 0 0 1-7.567-.715l1.71-1.71a2.599 2.599 0 0 0 4.203.773Z" />
    <path d="m9.194 11.122-3.1 3.1a2.599 2.599 0 0 0 .006 3.671 2.6 2.6 0 0 0 3.67.006l1.318-1.317 1.653 1.653-1.317 1.317a4.937 4.937 0 0 1-6.978-.006 4.937 4.937 0 0 1-.006-6.977l3.1-3.1a4.937 4.937 0 0 1 7.567.715l-1.71 1.711a2.599 2.599 0 0 0-4.203-.773Z" />
  </svg>
);

const Memo = memo(Link);
export default Memo;
