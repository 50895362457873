import { AuthFlowStrategy } from '@attentive/data';
import { PersonaType } from '@attentive/mock-data';

import { parseJwt } from './utils';

// NOTE: this constants is duplicated in libs/data/src/relay-environment.ts otherwise there is a
// circular dependency
const MOCK_AUTH_TOKEN_STORAGE_KEY = 'mock-auth-token';

// NOTE: this constants is duplicated in libs/test-utils/playwright/mock-messages.ts otherwise there is a
// circular dependency
const ATTENTIVE_AUTH_TOKEN_STORAGE_KEY = 'attentive-auth-token';
const AUTH_FLOW_STRATEGY_STORAGE_KEY = 'auth-flow-strategy';
const ATTENTIVE_DEVICE_IDENTIFIER_STORAGE_KEY = 'attentive-device-identifier';

interface AttentiveAuthToken {
  userEmail: string;
  userId: number;
}

type StrategyEventListener = (strategy: AuthFlowStrategy | null) => void;
type StorageEventListener = (ev: StorageEvent) => void;

const strategyEventListeners = new Map<StrategyEventListener, StorageEventListener>();

/**
 * AuthSession singleton.
 *
 * This singleton allows access to the current user token, and login "strategy" used to authenticate.
 * Backed by local storage.
 */
export abstract class AuthSession {
  /**
   * Returns the "strategy" which the user used to authenticate.
   * Can be
   *  - "internal", if the user signed in through some internal flow
   *  - null, if the user is not signed in
   */
  public static retrieveStrategy(): AuthFlowStrategy | null {
    return localStorage.getItem(AUTH_FLOW_STRATEGY_STORAGE_KEY) as AuthFlowStrategy | null;
  }

  /**
   * Returns the session's current token. May be null if the user is not signed in.
   */
  public static retrieveToken(): string | null {
    const strategy = this.retrieveStrategy();
    if (strategy === AuthFlowStrategy.Internal) {
      return sessionStorage.getItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY);
    } else if (strategy === AuthFlowStrategy.Mock) {
      return sessionStorage.getItem(MOCK_AUTH_TOKEN_STORAGE_KEY);
    }
    return null;
  }

  public static retrieveTokenForGraph(): string | null {
    const strategy = this.retrieveStrategy();
    if (strategy === AuthFlowStrategy.Internal) {
      const sessionToken = sessionStorage.getItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY);
      const localToken = localStorage.getItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY);
      return sessionToken ? sessionToken : localToken;
    } else if (strategy === AuthFlowStrategy.Mock) {
      return sessionStorage.getItem(MOCK_AUTH_TOKEN_STORAGE_KEY);
    }
    return null;
  }

  public static retrieveTokenDecoded(): AttentiveAuthToken | null {
    const token = AuthSession.retrieveToken();
    if (!token) {
      return null;
    }

    return parseJwt<AttentiveAuthToken>(token);
  }

  /**
   * Returns the localstorage token. Will be null if the user is not signed in.
   */
  public static retrieveGlobalInternalFlowToken(): string | null {
    const strategy = this.retrieveStrategy();
    if (strategy === AuthFlowStrategy.Internal) {
      return localStorage.getItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY);
    } else if (strategy === AuthFlowStrategy.Mock) {
      return sessionStorage.getItem(MOCK_AUTH_TOKEN_STORAGE_KEY);
    }
    throw new Error(
      `Tried fetching the global token when the strategy was ${JSON.stringify(strategy)}`
    );
  }

  /**
   * Returns a device identifier provided by the server, used to remember this device for MFA
   */
  public static retrieveDeviceIdentifier(): string | null {
    return localStorage.getItem(ATTENTIVE_DEVICE_IDENTIFIER_STORAGE_KEY);
  }

  /**
   * Persists the strategy used to sign in. After this call, we assume (and enforce) that the user is authenticating through this strategy.
   * @param strategy Whether the user has signed in through an "internal" login endpoint.
   */
  public static persistStrategy(strategy: AuthFlowStrategy) {
    localStorage.setItem(AUTH_FLOW_STRATEGY_STORAGE_KEY, strategy);
    localStorage.removeItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY);

    // Asynchronously notify listeners in the current tab that the strategy has changed.
    for (const listener of strategyEventListeners.keys()) {
      Promise.resolve().then(() => listener(strategy));
    }
  }

  /**
   * Persists the token used by this session.
   * @param strategy Whether the user has signed in through an "internal" login endpoint.
   * @param token The token currently associated with the session.
   */
  public static persistToken(strategy: AuthFlowStrategy, token: string) {
    const currentStrategy = this.retrieveStrategy();
    if (strategy !== currentStrategy) {
      throw new Error(
        `Received a(n) ${JSON.stringify(strategy)} token for a(n) ${JSON.stringify(
          currentStrategy
        )} session.`
      );
    }
    if (strategy === AuthFlowStrategy.Internal) {
      sessionStorage.setItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY, token);
      localStorage.setItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY, token);
    } else if (strategy === AuthFlowStrategy.Mock) {
      sessionStorage.setItem(MOCK_AUTH_TOKEN_STORAGE_KEY, token);
    }
  }

  /**
   * Persists the device identifier provided by the server
   * @param deviceIdentifier An opaque string identifying this device
   */
  public static persistDeviceIdentifier(deviceIdentifier: string) {
    localStorage.setItem(ATTENTIVE_DEVICE_IDENTIFIER_STORAGE_KEY, deviceIdentifier);
  }

  /**
   * Clear all session state.
   */
  public static clearStorage() {
    localStorage.removeItem(AUTH_FLOW_STRATEGY_STORAGE_KEY);

    sessionStorage.removeItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY);
    localStorage.removeItem(ATTENTIVE_AUTH_TOKEN_STORAGE_KEY);

    sessionStorage.removeItem(MOCK_AUTH_TOKEN_STORAGE_KEY);

    // Asynchronously notify listeners in the current tab that the strategy has changed.
    for (const listener of strategyEventListeners.keys()) {
      Promise.resolve().then(() => listener(null));
    }
  }

  /**
   * Set up a function that will be called when the user's current strategy changes.
   * @param listener The function to run when the strategy has changed.
   */
  public static addStrategyEventListener(listener: StrategyEventListener) {
    // The window "storage" event fires when localStorage changes from ANOTHER window/tab.
    // We need to rely on a "storage" event listener to react to changes from other tabs,
    // and manually trigger this listener on changes from the current tab.
    const storageListener: StorageEventListener = (ev) => {
      if (ev.storageArea === localStorage && ev.key === AUTH_FLOW_STRATEGY_STORAGE_KEY) {
        listener(ev.newValue as AuthFlowStrategy | null);
      }
    };
    strategyEventListeners.set(listener, storageListener);
    window.addEventListener('storage', storageListener);
  }

  /**
   * Remove an event listener which was previously added through addStrategyEventListener.
   * @param listener The listener to remove
   */
  public static removeStrategyEventListener(listener: StrategyEventListener) {
    const storageListener = strategyEventListeners.get(listener);
    if (storageListener) {
      window.removeEventListener('storage', storageListener);
    }
    strategyEventListeners.delete(listener);
  }
}

export const setTestAuthPersona = (persona: PersonaType) => {
  AuthSession.persistStrategy(AuthFlowStrategy.Mock);
  AuthSession.persistToken(AuthFlowStrategy.Mock, persona.id);
};
