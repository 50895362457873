import React from 'react';
import { useLocation, Routes, Route, Navigate } from 'react-router-dom';

import { AuthSession } from '@attentive/acore-utils';
import { AuthFlowStrategy } from '@attentive/data';

import { SignInLayout } from '../../components/SignInLayout';
import { CodeVerificationForm, CodeVerificationSetupForm } from '../CodeVerification';
import { SetupMfaForm } from '../SetupMFA';
import { PreferredMfaMethod } from '../CodeVerification/__generated__/codeVerificationHooksSendMfaOneTimePasscodeMutation.graphql';

function MultiFactorAuth() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mfaToken = searchParams.get('mfaToken');
  const emailHint = searchParams.get('email');
  const smsHint = searchParams.get('sms');
  const method = searchParams.get('method');
  const isConfiguringMfa = searchParams.get('initMfa') === 'true';
  const redir = searchParams.get('redir') || '/';

  const handleVerify = (token: string) => {
    AuthSession.persistStrategy(AuthFlowStrategy.Internal);
    AuthSession.persistToken(AuthFlowStrategy.Internal, token);
    window.location.assign(redir);
  };

  return (
    <SignInLayout>
      <Routes>
        <Route
          index
          element={
            isConfiguringMfa ? (
              <Navigate to={`setup?${searchParams}`} replace />
            ) : (
              <Navigate to={`verify-code?${searchParams}`} replace />
            )
          }
        />

        <Route
          path="setup/*"
          element={<SetupMfaForm mfaToken={mfaToken || ''} deviceIdentifier="" />}
        />

        <Route
          path="verify-setup-code/*"
          element={
            mfaToken ? (
              <CodeVerificationSetupForm
                mfaToken={mfaToken}
                deviceIdentifier=""
                onVerify={handleVerify}
                smsHint={smsHint}
              />
            ) : (
              <Navigate to={`setup?${searchParams}`} replace />
            )
          }
        />

        <Route
          path="verify-code/*"
          element={
            mfaToken ? (
              <CodeVerificationForm
                mfaToken={mfaToken}
                deviceIdentifier=""
                mfaType={method as PreferredMfaMethod}
                emailHint={emailHint}
                smsHint={smsHint}
                onVerify={handleVerify}
              />
            ) : (
              <Navigate to={`setup?${searchParams}`} replace />
            )
          }
        />
      </Routes>
    </SignInLayout>
  );
}

export { MultiFactorAuth };
