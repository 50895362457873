import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import React from 'react';

import { styled } from '../../stitches.config';
import { Box } from '../Box';

// Placeholder components used to extract the correct prop typings.
const Img = styled('img', {});
const Video = styled('video', {});

type PolymorphicProps =
  | ({
      as?: 'img';
    } & React.ComponentProps<typeof Img>)
  | ({
      as: 'video';
    } & React.ComponentProps<typeof Video>);

export type ResponsiveImageProps = PolymorphicProps & Pick<AspectRatio.AspectRatioProps, 'ratio'>;

export const ResponsiveImage = ({ ratio, css, as = 'img', ...rest }: ResponsiveImageProps) => (
  <Box css={css}>
    <AspectRatio.Root ratio={ratio}>
      <Box as={as} css={{ objectFit: 'cover', width: '100%', height: '100%' }} {...rest} />
    </AspectRatio.Root>
  </Box>
);
