/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ArrowLeftCircle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M3 12c0-4.95 4.05-9 9-9s9 4.05 9 9-4.05 9-9 9-9-4.05-9-9Zm9.05 4.8L7.196 12l4.854-4.8 1.15 1.137-2.892 2.86H16.8v1.606h-6.49l2.89 2.86-1.15 1.137Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(ArrowLeftCircle);
export default Memo;
