/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Download = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3 21h18v-2.25H3V21ZM18.075 11.025 16.5 9.45l-3.375 3.3V3h-2.25v9.75L7.5 9.45l-1.575 1.575L12 17.1l6.075-6.075Z" />
  </svg>
);

const Memo = memo(Download);
export default Memo;
