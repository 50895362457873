/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const MessageBubbleDots = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M3 3.749h18v11.984H7.519L3 20.25V3.75Zm9 7.23a1.237 1.237 0 1 0 0-2.476 1.237 1.237 0 0 0 0 2.475Zm5.212-1.238a1.237 1.237 0 1 1-2.474 0 1.237 1.237 0 0 1 2.474 0Zm-9.187 1.237a1.237 1.237 0 1 0 0-2.475 1.237 1.237 0 0 0 0 2.475Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(MessageBubbleDots);
export default Memo;
