/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Filter = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M21 4.5h-9.97a3 3 0 0 0-5.562 0H3v2.249h2.468a3 3 0 0 0 5.562 0H21V4.5ZM8.25 6.374a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM21 17.251h-9.97a3 3 0 0 0-5.562 0H3V19.5h2.468a3 3 0 0 0 5.562 0H21V17.25ZM8.25 19.125a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM3 13.124h9.97a3 3 0 0 0 5.562 0H21v-2.248h-2.468a3 3 0 0 0-5.562 0H3v2.248Zm12.75-1.874a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Filter);
export default Memo;
