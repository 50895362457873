import React, { useRef, useEffect, useState, useCallback, ReactElement } from 'react';

import { styled } from '../../stitches.config';
import { Box, BoxProps } from '../Box';
import { Separator, SeparatorProps } from '../Separator';

const Content = styled(Box, {
  gridArea: 'content',
  overflowY: 'auto',
});

const Footer = styled(Box, {
  gridArea: 'footer',

  variants: {
    emphasized: {
      true: {
        boxShadow: '0 -16px 16px -16px $colors$grayscale900_24',
      },
    },
  },
});

const FooterLayoutSeparator = ({ css = {} }: SeparatorProps) => (
  <Separator
    css={{
      gridArea: 'separator',
      ...css,
    }}
  />
);

interface FooterLayoutProps extends BoxProps {
  includeSeparator?: boolean;
}

const FooterLayoutComponent: React.FC<React.PropsWithChildren<FooterLayoutProps>> = ({
  children,
  includeSeparator = true,
  css = {},
  ...props
}) => {
  const [separatorEmphasized, setSeparatorEmphasized] = useState(false);
  const scrollTargetRef = useRef<HTMLDivElement>();

  const emphasizeSeparator = useCallback(() => {
    if (!includeSeparator) {
      return;
    }

    const target = scrollTargetRef.current;
    if (!target) {
      return;
    }

    const scrollTop = target.scrollTop;
    const elementHeight = target.scrollHeight - target.offsetHeight;

    // Rounds the values up to account for scrollTop returning a floating value and how it is rounded
    // (would round to the nearest 0.5 instead of the nearest 0 digit ex 0.74 => 0.5)
    if (elementHeight !== 0 && Math.ceil(scrollTop) < elementHeight) {
      setSeparatorEmphasized(true);
    } else {
      setSeparatorEmphasized(false);
    }
  }, [includeSeparator, scrollTargetRef]);

  useEffect(() => {
    if (!includeSeparator) {
      return;
    }
    const target = scrollTargetRef.current;
    if (!target) {
      return;
    }

    emphasizeSeparator();
    target.addEventListener('scroll', emphasizeSeparator, { passive: true });
    return () => {
      target.removeEventListener('scroll', emphasizeSeparator);
    };
  }, [includeSeparator, scrollTargetRef, emphasizeSeparator]);

  let content: ReactElement | null = null;
  let footer: ReactElement | null = null;

  React.Children.forEach(children, (child) => {
    /*
      TODO: Add more stringent validation of children:
        - only allow a valid input type component as "other" child
        - Only allow max 1 of each of the expected components
    */
    if (!React.isValidElement(child)) {
      return;
    }

    switch (child.type) {
      case Content:
        content = child;
        break;
      case Footer:
        footer = child;
        break;
      default:
        break;
    }
  });

  return (
    <Box
      css={{
        ...css,
        display: 'grid',
        overflow: 'hidden',
        gridTemplateAreas: `
          "content"
          "separator"
          "footer"
        `,
        gridTemplateRows: `
          1fr
          auto
          auto
        `,
      }}
      {...props}
    >
      {content && <Content {...(content as ReactElement).props} ref={scrollTargetRef} />}
      {includeSeparator && <FooterLayoutSeparator />}
      {footer && <Footer {...(footer as ReactElement).props} emphasized={separatorEmphasized} />}
    </Box>
  );
};

type FooterLayoutComponentType = typeof FooterLayoutComponent;
interface CompositeFooterLayoutComponent extends FooterLayoutComponentType {
  Content: typeof Content;
  Footer: typeof Footer;
}

const FooterLayout = FooterLayoutComponent as CompositeFooterLayoutComponent;
FooterLayout.Content = Content;
FooterLayout.Footer = Footer;

export { FooterLayout };
