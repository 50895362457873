/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ClockStrikethrough = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m19.42 3 1.57 1.57L4.57 20.99 3 19.42l2.05-2.05a8.96 8.96 0 0 1-1.27-4.59 9 9 0 0 1 9-9c1.68 0 3.25.47 4.59 1.27L19.42 3Zm-5.64 5.64V7.28h-2v3.36l2-2Z"
      clipRule="evenodd"
    />
    <path d="M18.29 11.78h-1.36l3.59-3.59a8.96 8.96 0 0 1 1.27 4.59 9 9 0 0 1-9 9c-1.68 0-3.25-.47-4.59-1.27l6.73-6.73h3.36v-2Z" />
  </svg>
);

const Memo = memo(ClockStrikethrough);
export default Memo;
