import React, { useCallback } from 'react';

export const useTimePickerKeyboardNav = <T extends string | number>(
  dropdownValue: T,
  setDropdownValue: (newValue: T) => void,
  arrayValues: T[]
): ((event: React.KeyboardEvent) => void) => {
  return useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        const index = arrayValues.findIndex((value) => value === dropdownValue);
        if (index > 0) {
          setDropdownValue(arrayValues[index - 1]);
        }
      }
      if (event.key === 'ArrowDown') {
        const index = arrayValues.findIndex((value) => value === dropdownValue);
        if (index < arrayValues.length - 1) {
          setDropdownValue(arrayValues[index + 1]);
        }
      }
    },
    [dropdownValue, setDropdownValue, arrayValues]
  );
};
