import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Text } from '@attentive/picnic';

import { ChallengeUIComponent } from './ChallengeUIComponent';

export const CompanyTransitionChallenge = () => {
  return (
    <ChallengeUIComponent>
      <Text css={{ mb: '$space6' }}>
        Authentication is required before receiving client account access.
      </Text>
      <Link
        css={{ mb: '$space4' }}
        href="https://attentivemobile.atlassian.net/wiki/spaces/ISD/pages/3903553541/Attentive+UI+MFA"
      >
        Help
      </Link>
      <Link as={RouterLink} to="/signin">
        Return to sign in
      </Link>
    </ChallengeUIComponent>
  );
};
