/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const BagArrowRight = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 4.5c1.65 0 3 1.35 3 3H9c0-1.65 1.35-3 3-3ZM12 3a4.513 4.513 0 0 0-4.5 4.5H5.25V21h13.5V7.5H16.5C16.5 5.025 14.475 3 12 3Zm-.75 7.875 2.625 2.625H7.35V15h6.6l-2.7 2.625h2.1l3.375-3.375-3.375-3.375h-2.1Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(BagArrowRight);
export default Memo;
