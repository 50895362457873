/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const PieChart = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M10.124 3.224C6.074 4.05 2.999 7.65 2.999 12c0 4.95 4.05 9 9 9s9-4.05 9-9c0-.975-.15-1.95-.45-2.775l-10.425 6v-12ZM19.799 7.5l-7.8 4.5V3c3.3 0 6.225 1.8 7.8 4.5Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(PieChart);
export default Memo;
