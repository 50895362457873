import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import React from 'react';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';

const StepNumber = styled('div', {
  display: 'inline-flex',
  placeItems: 'center',
  placeContent: 'center',
  fontSize: '$fontSize2',
  lineHeight: 1,
  height: '$size6',
  width: '$size6',
  borderRadius: '$radiusMax',

  variants: {
    variant: {
      active: {
        backgroundColor: '$bgToggleSelected',
        color: '$textInverted',
        border: '$borderWidths$borderWidth1 solid $bgToggleSelected',
      },
      incompleted: {
        backgroundColor: '$bgToggleDefault',
        border: '$borderWidths$borderWidth1 solid $borderLoud',
      },
    },
  },
});

const StepLabel = styled('div', {
  'button&': {
    cursor: 'pointer',
    background: 'none',
    border: 0,
    padding: 0,
    margin: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  variants: {
    fontSize: {
      medium: {
        fontSize: '$fontSize3',
      },
      small: {
        fontSize: '$fontSize2',
      },
    },
  },
  defaultVariants: {
    fontSize: 'medium',
  },
});

const ListItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
});
type ListItemProps = React.ComponentProps<typeof ListItem>;

// Expose a strongly typed Step component to use as public API for StepTracker children.
// This Step component is NOT directly rendered. The parent StepTracker component:
// - ensures that StepTracker children are all of type StepTracker.Step
// - renders a set of PrivateStep children that correspond to the public Step children
// - manages steps' numbers and variants internally
// Reference: https://twitter.com/markdalgleish/status/1370951759284162565
const Step: React.FC<React.PropsWithChildren<ListItemProps>> = () => null;

interface PrivateStepProps extends ListItemProps {
  number: number;
  variant: 'completed' | 'active' | 'incompleted';
  fontSize?: 'small' | 'medium';
  layout?: 'inline' | 'stacked';
  isActive: boolean;
  children: React.ReactNode;
}

const variantToA11yLabel: Record<PrivateStepProps['variant'], string> = {
  active: 'Current',
  completed: 'Completed',
  incompleted: '',
};

const PrivateStep = ({
  children,
  number,
  variant,
  fontSize,
  isActive,
  layout,
  css = {},
  ...rest
}: PrivateStepProps) => (
  <ListItem
    css={{
      margin: layout === 'stacked' ? '$space1 $space0' : undefined,
      ...css,
    }}
    aria-current={isActive ? 'step' : undefined}
    {...rest}
  >
    <Box
      role="presentation"
      aria-hidden="true"
      css={{
        height: '$size6',
        width: '$size6',
        display: 'inline-flex',
        mr: '$space2',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {variant === 'completed' ? (
        <Icon name="Checkmark" size="medium" />
      ) : (
        <StepNumber variant={variant}>{number}</StepNumber>
      )}
    </Box>
    <StepLabel
      fontSize={fontSize}
      css={isActive ? { fontWeight: '$bold' } : {}}
      {...(rest.onClick !== undefined ? { as: 'button' } : {})}
    >
      {variantToA11yLabel[variant] ? (
        <VisuallyHidden>{variantToA11yLabel[variant]}: </VisuallyHidden>
      ) : null}
      {children}
    </StepLabel>
  </ListItem>
);

const Separator = styled('div', {
  width: '$size8',
  height: '1px',
  mx: '$space2',
  backgroundColor: '$borderDefault',
});

interface StepTrackerProps extends BoxProps {
  activeStep?: number;
  fontSize?: 'small' | 'medium';
  layout?: 'inline' | 'stacked';
  children: React.ReactNode;
}

const getStepVariant = (index: number, activeStep: number): PrivateStepProps['variant'] => {
  if (index < activeStep) {
    return 'completed';
  } else if (index === activeStep) {
    return 'active';
  }
  return 'incompleted';
};

const StepTrackerComponent = ({
  activeStep = 0,
  fontSize,
  css = {},
  layout = 'inline',
  children,
}: StepTrackerProps) => {
  const isInline = layout === 'inline';
  return (
    <Box
      as="ol"
      css={{
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: isInline ? 'row' : 'column',
        alignItems: isInline ? 'center' : 'flex-start',
        ...css,
      }}
    >
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          return;
        }

        if (child.type === Step) {
          const variant = getStepVariant(index, activeStep);
          const isActive = activeStep === index;
          const needsSeparator = index < React.Children.count(children) - 1;

          return (
            <>
              <PrivateStep
                {...child.props}
                number={index + 1}
                variant={variant}
                fontSize={fontSize}
                isActive={isActive}
                layout={layout}
              />
              {isInline && needsSeparator && <Separator css={{ flexShrink: 0 }} />}
            </>
          );
        }

        throw new Error('StepTracker children must be of type StepTracker.Step');
      })}
    </Box>
  );
};

type ComponentType = typeof StepTrackerComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Step: typeof Step & DisplayNamed;
}

const StepTracker = StepTrackerComponent as CompositeComponent;
StepTracker.Step = Step;

StepTracker.displayName = 'StepTracker';
StepTracker.Step.displayName = 'StepTracker.Step';

export { StepTracker };
