/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const BookOpen = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M20.999 17.418V4.514c-1.098-.04-2.367.065-3.687.34-1.883.39-3.504 1.03-4.563 1.748v12.904c1.072-.718 2.68-1.357 4.563-1.748 1.32-.274 2.589-.379 3.687-.34Zm-18 0V4.514c1.098-.04 2.366.065 3.687.34 1.883.39 3.504 1.03 4.563 1.748v12.904c-1.072-.718-2.68-1.357-4.563-1.748-1.32-.274-2.589-.379-3.687-.34Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(BookOpen);
export default Memo;
