/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Compass = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M9.932 12.248a4.52 4.52 0 0 1-1.851-1.785l-2.498 9.323.724.194-.389 1.45.726.194.388-1.45.724.195 2.176-8.121Zm4.136 0a4.52 4.52 0 0 0 1.851-1.785l2.498 9.323-.724.194.389 1.45-.726.194-.388-1.45-.724.195-2.176-8.121ZM12 11.25a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-1.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
      clipRule="evenodd"
    />
    <path d="M12.75 1.5h-1.5v2.25h1.5V1.5Z" />
  </svg>
);

const Memo = memo(Compass);
export default Memo;
