import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React, { createContext, useContext } from 'react';

import { keyframes, styled, PicnicCss } from '../../stitches.config';
import { compositeComponent } from '../../utils/composite-component';
import { getImpersonatedPicnicComponent } from '../../utils/impersonate-picnic-component';
import { Box } from '../Box';
import { Icon } from '../Icon';

type AccordionVariants = 'error' | 'info' | 'neutral' | 'warning' | 'decorative3';
const VariantContext = createContext<AccordionVariants>('neutral');
const VariantContextProvider = VariantContext.Provider;
const useVariant = () => {
  return useContext(VariantContext);
};

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const close = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledItem = styled(AccordionPrimitive.Item, {
  borderWidth: '$borderWidth1',
  borderStyle: 'solid',

  '& + &': {
    borderTop: 'none',
  },

  '&:first-child': {
    borderTopLeftRadius: '$radius2',
    borderTopRightRadius: '$radius2',
  },

  '&:last-child': {
    borderBottomLeftRadius: '$radius2',
    borderBottomRightRadius: '$radius2',
  },

  variants: {
    variant: {
      error: {
        backgroundColor: '$bgCriticalDefault',
        borderColor: '$bgCriticalAccent',
      },
      info: {
        backgroundColor: '$bgInformationalDefault',
        borderColor: '$bgInformationalAccent',
      },
      neutral: {
        backgroundColor: '$bgDefault',
        borderColor: '$borderDefault',
      },
      warning: {
        backgroundColor: '$bgWarningDefault',
        borderColor: '$bgWarningAccent',
      },
      decorative3: {
        backgroundColor: '$bgDecorative3Default',
        borderColor: '$bgDecorative3Accent',
      },
    },
  },
});

const StyledHeaderIcon = styled(Icon, {
  mr: '$space3',
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '$fontSize3',
  fontWeight: '$bold',
  padding: '$space4 $space6',
  cursor: 'pointer',

  defaultTransition: ['box-shadow'],
  focusVisible: '$focus',
  '&:focus-within': {
    // Without this the bottom of the focus ring is hidden behind other
    // accordion items that are considered higher in the stack due to their
    // later placement in the DOM.
    position: 'relative',
    zIndex: 1,
  },
});

const StyledContent = styled(AccordionPrimitive.Content, {
  background: '$bgDefault',
  overflow: 'hidden',
  // Because we apply a background to this element we need its border radius
  // to match its parent otherwise the corners overflow (we can't set
  // `overflow: hidden` on the parent without cutting off the focus ring).
  // This is only relevant to the last item because its content intersects with
  // the bottom border radius. The content never intersects with the accordion's
  // top border radius, so we don't want any top radius here. Because these
  // are nested within an accordion item we can't easily use `:last-child`,
  // hence the reliance on `inherit`.
  borderRadius: 'inherit',
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',

  '&[data-state="open"]': {
    animation: `${open} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${close} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
});

const StyledContentBody = styled('div', {
  borderTopWidth: '$borderWidth1',
  borderTopStyle: 'solid',
  padding: '$space4 $space6',
  fontSize: '$fontSize2',

  variants: {
    variant: {
      error: {
        borderColor: '$bgCriticalAccent',
      },
      info: {
        borderColor: '$bgInformationalAccent',
      },
      neutral: {
        borderColor: '$borderDefault',
      },
      warning: {
        borderColor: '$bgWarningAccent',
      },
      decorative3: {
        borderColor: '$bgDecorative3Accent',
        backgroundColor: '$bgDecorative3Default',
      },
    },
  },
});

const StyledTriggerIcon = styled(Icon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',

  '[data-state=open] &': {
    transform: 'rotate(0deg)',
  },
  '[data-state=closed] &': {
    transform: 'rotate(180deg)',
  },
});

const TriggerIcon: React.VFC = () => {
  return <StyledTriggerIcon size="extraSmall" name="ChevronUp" mode="decorative" />;
};

const Item: React.FC<React.PropsWithChildren<React.ComponentProps<typeof StyledItem>>> = (
  props
) => {
  const variant = useVariant();

  return <StyledItem {...props} variant={variant} />;
};

const HeaderIcon: React.VFC<React.ComponentProps<typeof Icon>> = (props) => {
  const variant = useVariant();

  return <StyledHeaderIcon size="small" color={variant} {...props} />;
};

const Header: React.FC<React.PropsWithChildren<React.ComponentProps<typeof StyledTrigger>>> = ({
  children,
  ...rest
}) => {
  let icon;
  const otherChildren: React.ReactNode[] = [];
  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      otherChildren.push(child);
      return;
    }

    const type = getImpersonatedPicnicComponent(child);

    switch (type) {
      case HeaderIcon:
        icon = child;
        break;
      default:
        otherChildren.push(child);
    }
  });

  return (
    <AccordionPrimitive.Header asChild>
      <Box>
        <StyledTrigger {...rest}>
          <Box css={{ display: 'flex', alignItems: 'center', flex: 1, mr: '$space3' }}>
            {icon}
            {otherChildren}
          </Box>
          <TriggerIcon />
        </StyledTrigger>
      </Box>
    </AccordionPrimitive.Header>
  );
};

const Content: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof StyledContentBody>>
> = ({ children, ...rest }) => {
  const variant = useVariant();

  return (
    <StyledContent>
      {/*
        We need a intermediate content wrapper because radix otherwise has
        issues calculating the height of an element with a border, padding, etc.
       */}
      <StyledContentBody {...rest} variant={variant}>
        {children}
      </StyledContentBody>
    </StyledContent>
  );
};

type AccordionProps = (
  | AccordionPrimitive.AccordionSingleProps
  | AccordionPrimitive.AccordionMultipleProps
) & {
  variant: AccordionVariants;
  css?: PicnicCss;
};

const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  variant,
  children,
  ...props
}) => {
  return (
    <VariantContextProvider value={variant}>
      <AccordionPrimitive.Root {...props}>{children}</AccordionPrimitive.Root>
    </VariantContextProvider>
  );
};

const Namespace = compositeComponent(Accordion, {
  Item,
  Header,
  HeaderIcon,
  Content,
});

export { Namespace as Accordion };
