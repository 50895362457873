import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M20.3536 19.7659C19.9367 19.7659 19.6243 20.0791 19.6243 20.497C19.6243 20.9149 19.9367 21.2543 20.3536 21.2281C20.7443 21.2281 21.0829 20.9149 21.0829 20.497C21.0829 20.0791 20.7705 19.7659 20.3536 19.7659ZM20.3536 21.0977C20.015 21.0977 19.7548 20.8625 19.7548 20.497C19.7548 20.1576 20.0155 19.8963 20.3536 19.8963C20.6921 19.8963 20.9528 20.1576 20.9528 20.497C20.9528 20.8625 20.6921 21.0977 20.3536 21.0977Z"
        fill="#002E6E"
      />
      <path
        d="M20.6445 20.3204C20.6445 20.1758 20.5241 20.0791 20.3798 20.0791H20.0652V20.875H20.2356V20.5857H20.3316L20.4763 20.875H20.6927L20.5002 20.5613C20.5967 20.5134 20.6445 20.4167 20.6445 20.3204ZM20.3223 20.4411H20.2316V20.2237H20.3223C20.4232 20.2237 20.4737 20.2481 20.4737 20.3204C20.4737 20.4167 20.3975 20.4411 20.3223 20.4411Z"
        fill="#002E6E"
      />
      <path
        d="M6.50959 13.3957V7.68252H8.79931V13.3828C8.79931 14.2696 9.51534 14.9874 10.4 14.9874C11.2846 14.9874 12.0002 14.2696 12.0002 13.3828C12.0002 12.496 11.2846 11.7786 10.4 11.7786H9.7105V9.49566H10.4C12.5468 9.49566 14.2904 11.2303 14.2904 13.3824C14.2904 15.5216 12.5468 17.2696 10.4 17.2696C8.25277 17.2824 6.50959 15.5478 6.50959 13.3957ZM16.5018 13.8522C17.2174 13.8522 17.8028 14.4391 17.8028 15.1432C17.8028 15.8605 17.2174 16.4341 16.5018 16.4341C15.7862 16.4341 15.2007 15.8477 15.2007 15.1432C15.2007 14.4262 15.7733 13.8522 16.5018 13.8522ZM16.5018 10.3696C17.2174 10.3696 17.8028 10.9566 17.8028 11.6611C17.8028 12.3784 17.2174 12.952 16.5018 12.952C15.7862 12.952 15.2007 12.3651 15.2007 11.6611C15.2007 10.9566 15.7733 10.3696 16.5018 10.3696ZM22.5 12C22.5 6.20831 17.8028 1.49999 11.9998 1.49999C6.19715 1.49999 1.5 6.19589 1.5 12V22.5H11.9998C17.79 22.5 22.5 17.7917 22.5 12Z"
        fill="#002E6E"
      />
    </svg>
  );
};

export const BazaarvoiceColor = React.memo(Icon);
