import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { ReactElement, useContext } from 'react';

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  ButtonBar,
  Separator,
  ResponsiveImage,
  ResponsiveImageProps,
  Heading,
} from '../../components';
import { DisplayNamed } from '../../storybook/utils';

import { Dialog } from './Dialog';

type LayoutVariant = 'standard' | 'image';

const LayoutVariantContext = React.createContext<LayoutVariant | undefined>(undefined);

const StandardDialogComponent: React.FC<
  React.PropsWithChildren<React.ComponentProps<typeof Dialog>>
> = (props) => <Dialog {...props} />;

const Header: React.FC<React.PropsWithChildren<BoxProps>> = ({ css, children, ...rest }) => {
  const layoutVariant = useContext(LayoutVariantContext);

  if (layoutVariant === 'image') {
    return (
      <Box css={{ p: '$space6', pb: '0' }}>
        <DialogPrimitive.Title asChild>{children}</DialogPrimitive.Title>
      </Box>
    );
  }

  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '$space5 $space6',
        ...css,
      }}
      {...rest}
    >
      <DialogPrimitive.Title asChild>
        <Box css={{ alignSelf: 'center' }}>{children}</Box>
      </DialogPrimitive.Title>
      <Dialog.CloseButton
        css={{
          ml: '$space4',
          // Offset icon padding for better vertical alignment without focus ring.
          marginRight: '-$space2',
        }}
      />
    </Box>
  );
};

const DialogHeading: React.FC<React.ComponentProps<typeof Heading>> = (props) => (
  <Heading variant="md" {...props} />
);

const DialogTrigger: React.FC<React.PropsWithChildren<{ children: React.ReactElement }>> = ({
  children,
}) => <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>;

const HeroImage = ({ css, ...rest }: ResponsiveImageProps) => (
  <ResponsiveImage css={css} ratio={16 / 9} {...rest} />
);

const Body: React.FC<React.PropsWithChildren<BoxProps>> = ({ css, children, ...rest }) => {
  const layoutVariant = useContext(LayoutVariantContext);

  if (layoutVariant === 'image') {
    return (
      <Box
        css={{
          p: '$space3 $space6 $space2 $space6',
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box css={{ px: '$space6', py: '$space6', overflowY: 'auto', ...css }} {...rest}>
      {children}
    </Box>
  );
};

const Footer: React.FC<React.PropsWithChildren<React.ComponentProps<typeof ButtonBar>>> = ({
  css,
  layout = 'stretch',
  children,
  ...rest
}) => (
  <ButtonBar css={{ px: '$space6', py: '$space5', ...css }} layout={layout} {...rest}>
    {children}
  </ButtonBar>
);

const Close: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  return (
    <DialogPrimitive.Close asChild>
      <Button {...props} />
    </DialogPrimitive.Close>
  );
};

const Content: React.FC<React.PropsWithChildren<React.ComponentProps<typeof Dialog.Content>>> = ({
  css,
  children,
  ...rest
}) => {
  let header: ReactElement | null = null;
  let heroImage: ReactElement | null = null;
  let body: ReactElement | null = null;
  let footer: ReactElement | null = null;

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    switch (child.type) {
      case Header:
        header = child;
        break;
      case HeroImage:
        heroImage = child;
        break;
      case Body:
        body = child;
        break;
      case Footer:
        footer = child;
        break;
      default:
        break;
    }
  });

  if (heroImage) {
    return (
      <LayoutVariantContext.Provider value="image">
        <Dialog.Content css={{ display: 'flex', maxWidth: '612px', ...css }} {...rest}>
          <Box
            css={{
              flex: 1,
              display: 'grid',
              gridTemplateRows: `
               1fr
               auto
               auto
              `,
            }}
          >
            <Box css={{ overflowY: 'auto' }}>
              {heroImage}
              <Separator />
              {header}
              {body}
            </Box>
            {footer}
          </Box>
          <Dialog.CloseButton
            css={{
              position: 'absolute',
              top: '$space2',
              right: '$space2',
            }}
          />
        </Dialog.Content>
      </LayoutVariantContext.Provider>
    );
  }

  const headerSeparator = header ? <Separator /> : null;
  const footerSeparator = footer ? <Separator /> : null;

  return (
    <LayoutVariantContext.Provider value="standard">
      <Dialog.Content css={{ display: 'flex', ...css }} {...rest}>
        <Box
          css={{
            flex: 1,
            display: 'grid',
            gridTemplateRows: `
            auto
            auto
            1fr
            auto
            auto
            `,
          }}
        >
          {header}
          {headerSeparator}
          {body}
          {footerSeparator}
          {footer}
        </Box>
      </Dialog.Content>
    </LayoutVariantContext.Provider>
  );
};

type ComponentType = typeof StandardDialogComponent;
interface CompositeComponent extends ComponentType {
  Content: typeof Content;
  Header: typeof Header;
  Heading: typeof DialogHeading;
  HeroImage: typeof HeroImage & DisplayNamed;
  Body: typeof Body;
  Footer: typeof Footer;
  Trigger: typeof DialogTrigger & DisplayNamed;
  Close: typeof Close;
}

const StandardDialog = StandardDialogComponent as CompositeComponent;
StandardDialog.Content = Content;
StandardDialog.Header = Header;
StandardDialog.Heading = DialogHeading;
StandardDialog.HeroImage = HeroImage;
StandardDialog.Body = Body;
StandardDialog.Footer = Footer;
StandardDialog.Trigger = DialogTrigger;
StandardDialog.Close = Close;

StandardDialog.displayName = 'StandardDialog';
StandardDialog.Content.displayName = 'StandardDialog.Content';
StandardDialog.Header.displayName = 'StandardDialog.Header';
StandardDialog.Heading.displayName = 'StandardDialog.Heading';
StandardDialog.HeroImage.displayName = 'StandardDialog.HeroImage';
StandardDialog.Body.displayName = 'StandardDialog.Body';
StandardDialog.Footer.displayName = 'StandardDialog.Footer';
StandardDialog.Trigger.displayName = 'StandardDialog.Trigger';
StandardDialog.Close.displayName = 'StandardDialog.Close';

export { StandardDialog };
