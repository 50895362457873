import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { useToast, ToastType } from '@attentive/acore-utils';
import { Box, Form, Heading, Text, Wordmark } from '@attentive/picnic';

import { AUTH_PAGE_WIDTH } from '../../constants';
import { RequestDemo } from '../../components/RequestDemo';
import { AuthOptionButton } from '../../components/AuthOptionButton';
import { CODE_LENGTH, CODE_TIME_LIMIT } from './codeConstants';

import { useSendMfaOneTimePasscode, useVerifyMfaOneTimePasscode } from './codeVerificationHooks';
import { RememberDeviceCheck } from './RememberDeviceCheck';
import { PreferredMfaMethod } from './__generated__/codeVerificationHooksSendMfaOneTimePasscodeMutation.graphql';

export type CodeVerificationFormProps = {
  mfaToken: string;
  deviceIdentifier: string;
  mfaType: PreferredMfaMethod;
  emailHint?: string | null;
  smsHint?: string | null;
  onVerify: (jwt: string) => void;
};

const codeVerificationFormSchema = Yup.object().shape({
  verificationCode: Yup.string().max(CODE_LENGTH).required('A verification code is required.'),
});

export const CodeVerificationForm = ({
  mfaToken,
  deviceIdentifier,
  mfaType,
  emailHint,
  smsHint,
  onVerify,
}: CodeVerificationFormProps) => {
  const [makeToast] = useToast();

  const { sendMfaOneTimePasscode, isSendingCode } = useSendMfaOneTimePasscode();
  const { verifyMfaOneTimePasscode, isVerifyingCode } = useVerifyMfaOneTimePasscode();

  const [selectedMfaType, setSelectedMfaType] = useState(mfaType);
  const [rememberDevice, setRememberDevice] = useState<boolean | 'indeterminate'>(false);
  const resendVerificationCode = (selection: PreferredMfaMethod) => {
    sendMfaOneTimePasscode(
      {
        deviceIdentifier,
        mfaToken,
        preferredMfaMethod: selection,
      },
      () => {
        makeToast({
          type: ToastType.Success,
          text: 'Verification code sent.',
        });
      },
      (message) => {
        makeToast({
          type: ToastType.Error,
          text: message,
        });
      }
    );
  };

  useEffect(() => {
    sendMfaOneTimePasscode(
      {
        deviceIdentifier,
        mfaToken,
        preferredMfaMethod: selectedMfaType,
      },
      () => {},
      (message) => {
        makeToast({
          type: ToastType.Error,
          text: message,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeVerificationMethod = (mfaChoice: PreferredMfaMethod) => {
    setSelectedMfaType(mfaChoice);
    resendVerificationCode(mfaChoice);
  };

  return (
    <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
      <Form
        validationSchema={codeVerificationFormSchema}
        initialValues={{ verificationCode: '' }}
        onSubmit={async (values, formHelpers) => {
          verifyMfaOneTimePasscode(
            {
              oneTimePasscode: values.verificationCode,
              mfaToken,
              rememberDevice: Boolean(rememberDevice),
            },
            (data) => {
              const { jwt, error } = data.verifyMfaOneTimePasscode || {};
              if (jwt) {
                onVerify(jwt);
              } else if (error) {
                formHelpers.setErrors({ verificationCode: error });
              }
            },
            () => {
              if (values.verificationCode) {
                formHelpers.setErrors({
                  verificationCode: 'Invalid code. Please re-enter a valid code or resend code.',
                });
              } else {
                formHelpers.setErrors({
                  verificationCode: 'This code is no longer valid. Please select resend code.',
                });
              }
            }
          );
        }}
      >
        <Wordmark title="Attentive" width="140px" css={{ mb: '$space12', display: 'block' }} />
        <Heading as="h1" css={{ mb: '$space6' }} variant="page">
          Two-Factor Authentication
        </Heading>
        <Text css={{ mb: '$space6' }}>
          {selectedMfaType === 'PREFERRED_MFA_METHOD_EMAIL' && (
            <>
              {!emailHint && (
                <>
                  Please enter the verification code that we sent to your email to finish logging
                  in. The code is valid for {CODE_TIME_LIMIT} minutes.
                </>
              )}
              {emailHint && (
                <>
                  Please enter the verification code we sent to email{' '}
                  <Text as="span" css={{ fontWeight: '$bold' }}>
                    {emailHint}
                  </Text>{' '}
                  to finish logging in. The code is valid for {CODE_TIME_LIMIT} minutes.
                </>
              )}
            </>
          )}
          {selectedMfaType === 'PREFERRED_MFA_METHOD_SMS' && (
            <>
              {!smsHint && (
                <>
                  Please enter the verification code that we sent to your sms number to finish
                  logging in. The code is valid for {CODE_TIME_LIMIT} minutes.
                </>
              )}
              {smsHint && (
                <>
                  Please enter the verification code we sent to the number ending in{' '}
                  <Text as="span" css={{ fontWeight: '$bold' }}>
                    {smsHint}
                  </Text>{' '}
                  to finish logging in. The code is valid for {CODE_TIME_LIMIT} minutes.
                </>
              )}
            </>
          )}
        </Text>
        <Form.FormField name="verificationCode">
          <Form.Label>Verification Code</Form.Label>
          <Form.TextInput placeholder="XXXXXX" maxLength={CODE_LENGTH} />
        </Form.FormField>
        <RememberDeviceCheck onChange={setRememberDevice} value={Boolean(rememberDevice)} />

        <Form.SubmitButton
          css={{ width: '100%', marginTop: '$space6' }}
          loading={isSendingCode || isVerifyingCode}
        >
          Sign In
        </Form.SubmitButton>
      </Form>
      <Box css={{ display: 'grid', gap: '$space2', marginTop: '$space6' }}>
        <AuthOptionButton onClick={() => resendVerificationCode(selectedMfaType)}>
          Resend code
        </AuthOptionButton>
        {selectedMfaType === 'PREFERRED_MFA_METHOD_EMAIL' && (
          <AuthOptionButton
            onClick={() => handleChangeVerificationMethod('PREFERRED_MFA_METHOD_SMS')}
          >
            Send a text instead
          </AuthOptionButton>
        )}
        {selectedMfaType === 'PREFERRED_MFA_METHOD_SMS' && (
          <AuthOptionButton
            onClick={() => handleChangeVerificationMethod('PREFERRED_MFA_METHOD_EMAIL')}
          >
            Send an email instead
          </AuthOptionButton>
        )}
      </Box>
      <RequestDemo />
    </Box>
  );
};
