import React from 'react';

import { PicnicCss } from '../../stitches.config';
import { Box } from '../Box';

const TableScrollWrapper: React.FC<React.PropsWithChildren<{ css?: PicnicCss }>> = ({
  children,
  css,
}) => {
  return <Box css={{ maxWidth: '100vw', overflowX: 'auto', ...css }}>{children}</Box>;
};

export { TableScrollWrapper };
