import { ApiDataSource, isMockDataSource } from '@attentive/data';
import { addMSWHeaders } from '@attentive/mock-data';
import { AuthSession } from '../AuthSession';
import { NetworkError } from '../Logger';

const parseBody = <BodyType>(string: string) => {
  try {
    const body: BodyType = JSON.parse(string);
    return body;
  } catch (err) {
    return null;
  }
};

export interface Response<T> {
  body: T | null;
  bodyText: string;
  status: number;
  statusText: string;
  headers: Headers;
}

interface APIOptions extends Omit<RequestInit, 'headers'> {
  json?: boolean;
  headers?: Record<string, string>;
  signal?: AbortSignal;
}

declare global {
  interface Window {
    ACORE_API_URL: string;
  }
}

let apiBaseUrl: string | null = null;
let moduleApiDataSource: ApiDataSource | null = null;

// This should be updated to `https://example.com` in the future.
// This should only be used for tests and not in production.
export const MOCK_API_BASE_URL = 'https://ui-api-devel.attentivemobile.com';

export const initRestApi = ({
  baseUrl,
  apiDataSource,
}: {
  baseUrl: string;
  apiDataSource: ApiDataSource;
}) => {
  // Trim trailing '/' from the end of the URL
  if (baseUrl.endsWith('/')) {
    apiBaseUrl = baseUrl.slice(0, -1);
  } else {
    apiBaseUrl = baseUrl;
  }

  moduleApiDataSource = apiDataSource;

  // XXX: This is only needed for legacy-admin-ui. Remove when legacy-admin-ui is dead 💀
  window.ACORE_API_URL = `${apiBaseUrl}/`;
};

/**
 * API static class
 *
 * @static
 * @deprecated Data fetching in Client UI should be done using GraphQL. Accessing any data through our REST APIs is deprecated.
 *  https://attentivemobile.atlassian.net/wiki/spaces/ENG/pages/3132391450/GraphQL
 */
export abstract class API {
  /**
   * Gets the API URL defined by either the session storage or from the default environment.
   *
   * @returns string
   * @static
   * @public
   */
  public static getAPIUrl() {
    // TODO: remove the fallback here. We never want a API URL fallback as it can easily lead to
    // unexpected behaviour (e.g. a test sets `apiBaseUrl` but another doesn't).
    // https://attentivemobile.atlassian.net/browse/UP-924
    if (isMockDataSource(moduleApiDataSource as ApiDataSource)) {
      return MOCK_API_BASE_URL;
    }

    if (!apiBaseUrl) {
      throw new Error('apiBaseUrl not initialized');
    }

    return apiBaseUrl;
  }

  /**
   * Makes a `fetch` call to the API using a path.
   *
   * @param {string} url
   * @param {APIOptions} options
   *
   * @returns `Promise<Result>`
   * @static
   * @public
   */
  public static async fetch<ResponseBodyType>(
    url: string,
    options: APIOptions = {}
  ): Promise<Response<ResponseBodyType>> {
    const headers = new Headers();

    const token = AuthSession.retrieveToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (isMockDataSource(moduleApiDataSource as ApiDataSource)) {
      // Pass along MSW headers from session storage in non-production environments
      addMSWHeaders(headers);
    }

    if (options.json) {
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
    }

    if (options.headers) {
      Object.keys(options.headers).forEach((key) => {
        if (options?.headers?.[key]) {
          headers.set(key, `${options.headers[key]}`);
        }
      });
    }

    let response;
    try {
      response = await fetch(`${this.getAPIUrl()}${url}`, {
        credentials: 'include',
        ...options,
        headers,
      });
    } catch (err) {
      throw new NetworkError(err.message, url);
    }
    const bodyText = await response.text();
    const contentType = response.headers.get('content-type');
    const body =
      contentType && /application\/json/.test(contentType)
        ? parseBody<ResponseBodyType>(bodyText)
        : null;

    return {
      body,
      bodyText,
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
    };
  }

  /**
   * Makes a `GET` request to the API given the path and the payload.
   *
   * @param {string} url
   * @param {any} payload
   * @param {APIOptions} options
   *
   * @returns `Promise<Result>`
   * @static
   * @public
   */
  // A payload could be in any shape
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async get<ResponseBodyType>(url: string, payload?: any, options: APIOptions = {}) {
    const json = options.json !== undefined ? options.json : true;
    return this.fetch<ResponseBodyType>(url, {
      json,
      method: 'GET',
      body: payload && json ? JSON.stringify(payload) : payload,
      ...options,
    });
  }

  /**
   * Makes a `POST` request to the API given the path and the payload.
   *
   * @param {string} url
   * @param {any} payload
   * @param {APIOptions} options
   *
   * @returns `Promise<Result>`
   * @static
   * @public
   */
  // A payload could be in any shape
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async post<ResponseBodyType>(url: string, payload?: any, options: APIOptions = {}) {
    const json = options.json !== undefined ? options.json : true;
    return this.fetch<ResponseBodyType>(url, {
      json,
      method: 'POST',
      body: payload && json ? JSON.stringify(payload) : payload,
      ...options,
    });
  }

  /**
   * Makes a `PUT` request to the API given the path and the payload.
   *
   * @param {string} url
   * @param {any} payload
   * @param {APIOptions} options
   *
   * @returns `Promise<Result>`
   * @static
   * @public
   */
  // A payload could be in any shape
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async put<ResponseBodyType>(url: string, payload?: any, options: APIOptions = {}) {
    const json = options.json !== undefined ? options.json : true;
    return this.fetch<ResponseBodyType>(url, {
      json,
      method: 'PUT',
      body: payload && json ? JSON.stringify(payload) : payload,
      ...options,
    });
  }

  /**
   * Makes a `PATCH` request to the API given the path and the payload.
   *
   * @param {string} url
   * @param {any} payload
   * @param {APIOptions} options
   *
   * @returns `Promise<Result>`
   * @static
   * @public
   */
  // A payload could be in any shape
  public static async patch<ResponseBodyType>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any,
    options: APIOptions = {}
  ) {
    const json = options.json !== undefined ? options.json : true;
    return this.fetch<ResponseBodyType>(url, {
      json,
      method: 'PATCH',
      body: payload && json ? JSON.stringify(payload) : payload,
      ...options,
    });
  }

  /**
   * Makes a `DELETE` request to the API given the path and the payload.
   *
   * @param {string} url
   * @param {any} payload
   * @param {APIOptions} options
   *
   * @returns `Promise<Result>`
   * @static
   * @public
   */
  public static async delete<ResponseBodyType>(
    url: string,
    // A payload could be in any shape
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any,
    options: APIOptions = {}
  ) {
    const json = options.json !== undefined ? options.json : true;
    return this.fetch<ResponseBodyType>(url, {
      json,
      method: 'DELETE',
      body: payload && json ? JSON.stringify(payload) : payload,
      ...options,
    });
  }
}
