import { Role, Scope } from '@attentive/data';

export interface PersonaType {
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly isSuperUser: boolean;
  readonly roles: Role[];
  readonly phone: string;
}

const remyPersona: PersonaType = {
  id: 'Account-Regular',
  firstName: 'Remy',
  lastName: 'Regular-User',
  isSuperUser: false,
  roles: [Role.RoleClientAccount],
  email: 'remy.regular-user@example.com',
  phone: '111-111-1111',
};

const carlPersona: PersonaType = {
  id: 'Account-Concierge',
  firstName: 'Carl',
  lastName: 'Concierge-User',
  isSuperUser: false,
  roles: [Role.RoleConcierge],
  email: 'carl.concierge-user@example.com',
  phone: '222-222-2222',
};

// TODO: Sally shouldn't be a super user as we don't have super user's anymore.
const sallyPersona: PersonaType = {
  id: 'Account-Superuser',
  firstName: 'Sally',
  lastName: 'Super-User',
  isSuperUser: true,
  roles: [Role.RoleAttentiveAccount],
  email: 'sally.super-user@example.com',
  phone: '333-333-3333',
};

type RoleScopeMapping = { [P in Role]?: Scope[] };
const roleScopeMapping: RoleScopeMapping = {
  [Role.RoleAttentiveAccount]: [Scope.ScopeAllAccounts],
};

interface Personas {
  readonly Remy: typeof remyPersona;
  readonly Carl: typeof carlPersona;
  readonly Sally: typeof sallyPersona;
}

export const Persona: Personas = {
  Remy: remyPersona,
  Carl: carlPersona,
  Sally: sallyPersona,
};

export const findPersona = (idToFind: string): PersonaType => {
  const persona = Object.values(Persona).find(({ id }) => id === idToFind);
  if (!persona) {
    throw new Error(`Can not find Mock persona with id: "${idToFind}"`);
  }
  return persona;
};

export const rolesForPersona = (persona: PersonaType) => {
  return persona.roles;
};

export const mockedScopesForRoles = (roles: Role[]) => {
  const scopeSet = new Set<Scope>();
  for (const role of roles) {
    for (const scope of roleScopeMapping[role] ?? []) {
      scopeSet.add(scope);
    }
  }
  return [...scopeSet];
};
