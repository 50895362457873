import React from 'react';

import { IconComponent } from './StyledIconComponents';
import { thirdPartyIconSet, ThirdPartyIconName, IconSvgProps } from './icon-set';

export interface IconPrimitiveProps extends React.ComponentProps<typeof IconComponent> {
  name: ThirdPartyIconName;
}

export const IconPrimitive = ({
  name,
  css,
  ...props
}: Omit<IconPrimitiveProps & IconSvgProps, 'color'>) => (
  <IconComponent
    // eslint-disable-next-line import/namespace
    as={thirdPartyIconSet[name]}
    css={css}
    {...props}
  />
);
