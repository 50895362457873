import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        d="M12.9698 2H6V7.85797H12.9698C13.5838 7.85797 14.0817 8.35582 14.0817 8.96983C14.0817 9.58384 13.5838 10.0817 12.9698 10.0817H6V18.5616C6 20.3539 7.37737 21.9138 9.18621 21.9968C11.0946 22.0797 12.6711 20.553 12.6711 18.6778V15.9563H12.9864C16.8364 15.9563 19.9563 12.8364 19.9563 8.98642C19.9397 5.11983 16.8032 2 12.9698 2"
        fill="#F7941E"
      />
    </svg>
  );
};

export const PunchhColor = React.memo(Icon);
