/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Sync = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m12 4.5 1.275-1.275L12 1.95 8.55 5.4 12 8.85l1.275-1.275L12 6.3c3.15 0 5.625 2.55 5.625 5.625 0 1.575-.675 3-1.725 4.05l1.275 1.275c1.35-1.35 2.25-3.225 2.25-5.325C19.5 7.875 16.125 4.5 12 4.5ZM15.45 18.6 12 15.15l-1.275 1.275L12 17.7a5.617 5.617 0 0 1-5.625-5.625c0-1.575.675-3 1.725-4.05l-1.35-1.35C5.4 8.025 4.5 9.9 4.5 12c0 4.125 3.375 7.5 7.5 7.5l-1.275 1.275L12 22.05l3.45-3.45Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Sync);
export default Memo;
