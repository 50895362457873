/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ShieldPerson = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M16.125 4.65C14.475 4.275 12.975 3.675 12 3c-.975.675-2.475 1.275-4.125 1.65-1.2.225-2.4.375-3.375.3v6c0 3.225 1.575 6.15 4.2 7.875L12 21l3.3-2.175c2.625-1.725 4.2-4.725 4.2-7.875v-6c-1.05.075-2.175-.075-3.375-.3ZM12 6.375a3.169 3.169 0 0 1 3.15 3.15 3.169 3.169 0 0 1-3.15 3.15 3.169 3.169 0 0 1-3.15-3.15A3.169 3.169 0 0 1 12 6.375Zm2.475 11.175L12 19.2l-2.475-1.65c-.675-.45-1.275-.975-1.725-1.575A5.952 5.952 0 0 1 12 14.25c1.65 0 3.15.675 4.2 1.725-.525.6-1.05 1.125-1.725 1.575Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(ShieldPerson);
export default Memo;
