import React, { useState } from 'react';
import { getList } from 'country-list-with-dial-code-and-flag';

import { Box, Emoji, SearchableSelect, Text } from '@attentive/picnic';

interface Props {
  onChange: (code: string) => void;
}

export const CountryCodeDropdown = ({ onChange }: Props) => {
  // This value must include a country identifier beyond just the code because the numeric codes may not be unique. We should do this better - this is just quick.
  const [selectedString, setSelectedString] = useState('+1 US');
  const allCountries = getList();

  return (
    <SearchableSelect
      value={selectedString}
      onChange={(valueString: string) => {
        const codeOnly = valueString.split(' ')[0];
        setSelectedString(valueString);
        onChange(codeOnly);
      }}
      css={{ maxWidth: 150, minWidth: 135 }}
    >
      {allCountries
        .sort((a, b) => parseInt(a.dial_code) - parseInt(b.dial_code))
        .map((country) => {
          if (country.code === 'SJ') {
            // Svalbard (archipelago) and Jan Mayen (Island), hold their own ISO numbers, but recognize the sovereignty of Norway and share a flag and country code. https://en.wikipedia.org/wiki/Svalbard_and_Jan_Mayen#:~:text=Neither%20Svalbard%20nor%20Jan%20Mayen,Norwegian%20Ministry%20of%20Foreign%20Affairs. Filtering out the dupe.
            return null;
          }
          const uniqueCountryStr = `${country.dial_code} ${country.code}`;
          return (
            <SearchableSelect.Item key={uniqueCountryStr} value={uniqueCountryStr}>
              <Box
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  boxSizing: 'content-box',
                }}
              >
                <Text css={{ whiteSpace: 'nowrap' }}>
                  <Emoji css={{ mr: '$space1' }} label={country.name}>
                    {country.flag}
                  </Emoji>
                  {country.dial_code}
                </Text>
              </Box>
            </SearchableSelect.Item>
          );
        })}
    </SearchableSelect>
  );
};
