import * as DialogPrimitive from '@radix-ui/react-dialog';
import React from 'react';

import { styled, keyframes } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { Optional } from '../../utils';
import { Box, BoxProps } from '../Box';
import { Button, ButtonProps, IconButton, IconButtonProps } from '../Button';

interface DialogProps extends DialogPrimitive.DialogProps {
  children: React.ReactNode;
  includeOverlay?: boolean;
}

const IncludeOverlayContext = React.createContext<boolean>(true);
const IncludeOverlayProvider = IncludeOverlayContext.Provider;
const useIncludeOverlay = () => {
  const includeOverlay = React.useContext(IncludeOverlayContext);
  return includeOverlay;
};

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const DIALOG_MIN_WIDTH = '320px';

const DialogOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: '$bgOverlay',

  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: '$layer2',

  animation: `${fadeIn} 100ms`,

  variants: {
    layout: {
      dialog: {
        position: 'fixed',
      },
      portalled: {
        position: 'absolute',
      },
    },
  },
  defaultVariants: {
    layout: 'dialog',
  },
});

const StyledContent = styled(DialogPrimitive.Content, {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: DIALOG_MIN_WIDTH,
  backgroundColor: '$bgDefault',
  borderRadius: '$radius2',
  border: '1px solid $borderLoud',
  boxShadow: '$shadow4',
  // Will want to reconsider max height and max width for responsiveness
  maxHeight: 'calc(100% - 2 * $sizes$size16)',
  maxWidth: 'calc(100% - 2 * $sizes$size16)',
  overflow: 'auto',
  zIndex: '$layer2',

  '&:focus': {
    outline: 'none',
  },

  variants: {
    layout: {
      dialog: {
        position: 'fixed',
      },
      portalled: {
        position: 'absolute',
      },
    },
  },
  defaultVariants: {
    layout: 'dialog',
  },
});

const UnstyledContent = styled(DialogPrimitive.Content, {
  zIndex: '$layer2',

  '&:focus': {
    outline: 'none',
  },
});

type DialogContentProps = BoxProps &
  DialogPrimitive.DialogContentProps & {
    styling?: 'default' | 'unstyled';
    portalContainer?: DialogPrimitive.DialogPortalProps['container'];
  };

const DialogContent = React.forwardRef(
  (
    { children, styling = 'default', portalContainer, ...rest }: DialogContentProps,
    forwardedRef
  ) => {
    const includeOverlay = useIncludeOverlay();
    const ContentComponent = styling === 'unstyled' ? UnstyledContent : StyledContent;
    const layoutVariant = portalContainer ? 'portalled' : 'dialog';

    const content = (
      <ContentComponent
        aria-describedby={undefined}
        layout={layoutVariant}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(rest as any)}
        ref={forwardedRef}
      >
        {children}
      </ContentComponent>
    );

    return (
      <DialogPrimitive.Portal container={portalContainer}>
        {includeOverlay ? <DialogOverlay layout={layoutVariant}>{content}</DialogOverlay> : content}
      </DialogPrimitive.Portal>
    );
  }
);

const DialogTrigger: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  return <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>;
};

const DialogClose = (props: ButtonProps) => (
  <DialogPrimitive.Close asChild>
    <Button {...props} />
  </DialogPrimitive.Close>
);
type DialogCloseButtonProps = Omit<Optional<IconButtonProps, 'iconName' | 'description'>, 'as'>;

const DialogCloseButton = ({
  size = 'small',
  variant = 'subdued',
  iconName = 'X',
  description = 'Close',
  ...rest
}: DialogCloseButtonProps) => (
  <DialogPrimitive.Close asChild>
    <IconButton
      variant={variant}
      size={size}
      iconName={iconName}
      description={description}
      {...rest}
    />
  </DialogPrimitive.Close>
);

interface DialogHeaderProps extends BoxProps {
  children: React.ReactNode;
}

const DialogHeader = ({ children, css = {}, ...props }: DialogHeaderProps) => {
  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        ...css,
      }}
      {...props}
    >
      <DialogPrimitive.Title asChild>
        <Box css={{ alignSelf: 'center' }}>{children}</Box>
      </DialogPrimitive.Title>
      <DialogCloseButton css={{ ml: '$space3' }} />
    </Box>
  );
};

const DialogComponent = ({ children, includeOverlay = true, ...props }: DialogProps) => {
  return (
    <IncludeOverlayProvider value={includeOverlay}>
      <DialogPrimitive.Root {...props}>{children}</DialogPrimitive.Root>
    </IncludeOverlayProvider>
  );
};

type ComponentType = typeof DialogComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Header: typeof DialogHeader & DisplayNamed;
  Close: typeof DialogClose & DisplayNamed;
  CloseButton: typeof DialogCloseButton & DisplayNamed;
  Trigger: typeof DialogTrigger & DisplayNamed;
  Content: typeof DialogContent & DisplayNamed;
}

const Dialog = DialogComponent as CompositeComponent;
Dialog.Header = DialogHeader;
Dialog.Close = DialogClose;
Dialog.CloseButton = DialogCloseButton;
Dialog.Trigger = DialogTrigger;
Dialog.Content = DialogContent;

Dialog.displayName = 'Dialog';
Dialog.Header.displayName = 'Dialog.Header';
Dialog.Close.displayName = 'Dialog.Close';
Dialog.CloseButton.displayName = 'Dialog.CloseButton';
Dialog.Trigger.displayName = 'Dialog.Trigger';
Dialog.Content.displayName = 'Dialog.Content';

export { Dialog };
