import * as PopoverPrimitive from '@radix-ui/react-popover';
import React from 'react';

import { TIMEZONE_NAMES, Timezone } from '@attentive/locale-utils';

import { styled } from '../../stitches.config';
import { compositeComponent } from '../../utils';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { InputGroup } from '../InputGroup';
import { Select } from '../Select';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { TextInput } from '../TextInput';

import { TimePickerSelectionRow } from './TimePickerSelectionRow';
import { useTimePickerKeyboardNav } from './useTimePickerKeyboardNav';
import {
  MeridiemPeriod,
  MeridiemPeriods,
  convertHoursFromContinentalToMeridiem,
  convertHoursFromMeridiemToContinental,
  generateTimeValue,
  getMeridiemPeriod,
  parseTimeValue,
  sanitizeHours,
  sanitizeMinutes,
} from './utils';

const PU_TIMEPICKER_BOX_SHADOW = '$shadow2';
const PU_TIMEPICKER_FONT_SIZE = '$fontSize2';
const PU_TIMEPICKER_BG_COLOR = '$bgDefault';
const PU_TIMEPICKER_BORDER = '1px solid $borderLoud';
const PU_MENU_CONTENT_OFFSET = 8;
const PU_MENU_MIN_HEIGHT = 250;
const PU_MENU_MAX_HEIGHT = 460;

const StyledPopover = styled(PopoverPrimitive.Content, {
  backgroundColor: PU_TIMEPICKER_BG_COLOR,
  borderRadius: '$radius1',
  border: PU_TIMEPICKER_BORDER,
  boxShadow: PU_TIMEPICKER_BOX_SHADOW,
  fontSize: PU_TIMEPICKER_FONT_SIZE,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '$space3 $space3 $space0 $space3',
  zIndex: '$layer4',
  '&:focus': {
    outline: 'none',
  },
});

const TimePickerSelectionColumn = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
});

const TimePickerSelectionRows = styled(Box, {
  overflow: 'auto',
});

const timePickerHourValues = Array.from({ length: 12 }, (_, i) => i + 1);

type TimezoneContextType = {
  selectedTimezone: string | undefined;
  onChange: (newTimezone: string) => void;
  disabled?: boolean;
};
const TimezoneComponentContext = React.createContext<TimezoneContextType>({
  selectedTimezone: Timezone.EST,
  onChange: () => {},
  disabled: false,
});

const TimezoneComponent: React.FC = ({ children }) => {
  const { selectedTimezone, onChange, disabled } =
    React.useContext<TimezoneContextType>(TimezoneComponentContext);

  return (
    <Select
      value={selectedTimezone}
      onChange={onChange}
      data-testid="timezone-select"
      placeholder="Select a timezone"
      disabled={disabled}
      css={{ flex: 2 }}
    >
      {children ||
        Object.entries(Timezone).map(([timezoneKey, timezoneValue]) => (
          <Select.Item key={timezoneKey} value={timezoneValue}>
            {TIMEZONE_NAMES[timezoneValue].default}
          </Select.Item>
        ))}
    </Select>
  );
};

export type TimePickerProps = {
  hours?: number;
  minutes?: number;
  minutesStep?: number;
  timezone?: string;
  onChange?: (hours: number, minutes: number, timezone: string | undefined) => void;
  disabled?: boolean;
  state?: 'normal' | 'error';
  ariaLabel?: string;
};

const TimePicker: React.FC<React.PropsWithChildren<TimePickerProps>> = ({
  hours = 12,
  minutes = 0,
  minutesStep = 5,
  timezone,
  onChange,
  disabled,
  state,
  ariaLabel,
  children,
}) => {
  const sanitizedHours = sanitizeHours(hours);
  const sanitizedMinutes = sanitizeMinutes(minutes);
  const sanitizedMinutesStep = sanitizeMinutes(minutesStep);

  const timePickerMinuteValues = Array.from(
    { length: 60 / sanitizedMinutesStep },
    (_, i) => i * sanitizedMinutesStep
  );

  const [hasLocalError, setHasLocalError] = React.useState<boolean>(false);
  const [selectedTimezone, setSelectedTimezone] = React.useState<string | undefined>(timezone);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const buttonRef = React.useRef<HTMLInputElement>(null);

  const [timeValue, setTimeValue] = React.useState(
    generateTimeValue(sanitizedHours, sanitizedMinutes)
  );
  const { selectedHours, selectedMinutes } = parseTimeValue(timeValue);

  const handleInputGroupClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (disabled) return;
    setOpenDropdown(!openDropdown);
  };

  const commitTimeValue = (newTimeValue: string, newTimezone: string | undefined) => {
    setTimeValue(newTimeValue);
    setSelectedTimezone(newTimezone);
  };

  const onHourChange = (newHours: number | string) => {
    const adjustedHours = convertHoursFromMeridiemToContinental(
      Number(newHours),
      getMeridiemPeriod(selectedHours)
    );
    commitTimeValue(generateTimeValue(adjustedHours, selectedMinutes), selectedTimezone);
  };

  const onMinuteChange = (newMinutes: number | string) => {
    commitTimeValue(generateTimeValue(selectedHours, Number(newMinutes)), selectedTimezone);
  };

  const onMeridiemPeriodChange = (newPeriod: MeridiemPeriod) => {
    const adjustedHours = (selectedHours % 12) + (newPeriod === 'PM' ? 12 : 0);
    commitTimeValue(generateTimeValue(adjustedHours, selectedMinutes), selectedTimezone);
  };

  const triggerOnChange = (
    newHours: number,
    newMinutes: number,
    newTimezone: string | undefined
  ) => {
    if (newMinutes % sanitizedMinutesStep !== 0) {
      setHasLocalError(true);
      return;
    }

    setHasLocalError(false);

    onChange?.(newHours, newMinutes, newTimezone);
  };

  const onSelectTimezoneChange = (newTimezone: string) => {
    commitTimeValue(timeValue, newTimezone);
    triggerOnChange(selectedHours, selectedMinutes, newTimezone);
  };

  const handleHourKeyDown = useTimePickerKeyboardNav(
    convertHoursFromContinentalToMeridiem(selectedHours),
    onHourChange,
    timePickerHourValues
  );

  const handleMinuteKeyDown = useTimePickerKeyboardNav(
    selectedMinutes,
    onMinuteChange,
    timePickerMinuteValues
  );

  const handlePeriodKeyDown = useTimePickerKeyboardNav(
    getMeridiemPeriod(selectedHours),
    (periodSelection) => onMeridiemPeriodChange(periodSelection as MeridiemPeriod),
    [...MeridiemPeriods]
  );

  const handleDropdownEnterKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      const newState = !openDropdown;
      setOpenDropdown(newState);
      if (!newState) {
        triggerOnChange(selectedHours, selectedMinutes, selectedTimezone);
      }
    }
  };

  const handleInputIconEnterKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      if (!openDropdown) {
        setOpenDropdown(true);
      }
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenDropdown(isOpen);
      triggerOnChange(selectedHours, selectedMinutes, selectedTimezone);
    }
  };

  const handleBlur = () => {
    setOpenDropdown(false);
    triggerOnChange(selectedHours, selectedMinutes, selectedTimezone);
  };

  const handleTimePickerTextInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === 'Enter') {
      triggerOnChange(selectedHours, selectedMinutes, selectedTimezone);
    }

    // For accessibility, we want to open the dropdown when the user presses the screen reader shortcut
    if (event.ctrlKey && event.altKey && event.shiftKey && event.key === 'ArrowDown') {
      setOpenDropdown(true);
    }
  };

  const availableHeightForMenu =
    typeof window === 'undefined'
      ? 0
      : window.innerHeight -
        // get the size of element to its relative position
        (buttonRef?.current?.getBoundingClientRect().bottom || 0) -
        //to match the offset at top from the select button
        PU_MENU_CONTENT_OFFSET * 2;

  React.useEffect(() => {
    if (hours < 0 || hours > 23) {
      console.warn('hours prop must be between 0 and 23');
    }

    if (minutes < 0 || minutes > 59) {
      console.warn('minutes prop must be between 0 and 59');
    }

    if (minutesStep < 1 || minutesStep > 59) {
      console.warn('minutesStep prop must be between 1 and 59');
    }
  }, [hours, minutes, minutesStep]);

  React.useEffect(() => {
    setTimeValue(generateTimeValue(sanitizedHours, sanitizedMinutes));
  }, [sanitizedHours, sanitizedMinutes]);

  return (
    <Stack direction="horizontal">
      <Box
        aria-label={`Selected time is: ${convertHoursFromContinentalToMeridiem(
          selectedHours
        )}:${selectedMinutes} ${getMeridiemPeriod(selectedHours)}`}
        aria-live="polite"
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: '$space1',
          flex: 3,
        }}
      >
        <PopoverPrimitive.Root open={openDropdown} onOpenChange={handleOpenChange}>
          <PopoverPrimitive.Anchor asChild>
            <PopoverPrimitive.Trigger asChild>
              <InputGroup>
                <TextInput
                  type="time"
                  ref={buttonRef}
                  value={timeValue}
                  onChange={(e) => commitTimeValue(e.target.value, selectedTimezone)}
                  onKeyDown={handleTimePickerTextInputKeyDown}
                  onBlur={handleBlur}
                  aria-label={ariaLabel}
                  state={hasLocalError ? 'error' : state}
                  step={sanitizedMinutesStep * 60}
                  disabled={disabled}
                  css={{
                    flexDirection: 'row',
                    '&::-webkit-calendar-picker-indicator': {
                      background: 'none',
                      pointerEvents: 'none',
                      display: 'none',
                    },
                  }}
                />

                <InputGroup.RightElement
                  onKeyDown={handleInputIconEnterKeyDown}
                  onClick={handleInputGroupClicked}
                >
                  <InputGroup.InlineButton disabled={disabled} aria-label="Select a time">
                    <Icon
                      name="Clock"
                      description="Clock icon"
                      aria-hidden="true"
                      size="small"
                      tabIndex={-1}
                    />
                  </InputGroup.InlineButton>
                </InputGroup.RightElement>
              </InputGroup>
            </PopoverPrimitive.Trigger>
          </PopoverPrimitive.Anchor>
          <PopoverPrimitive.Portal>
            <StyledPopover
              side="bottom"
              align="end"
              sideOffset={PU_MENU_CONTENT_OFFSET}
              role="dialog"
              aria-modal="true"
              onKeyDown={handleDropdownEnterKeyDown}
              css={{
                maxHeight: Math.max(
                  PU_MENU_MIN_HEIGHT,
                  Math.min(availableHeightForMenu, PU_MENU_MAX_HEIGHT)
                ),
              }}
            >
              <Box css={{ display: 'flex', overflow: 'hidden', gap: '$space3' }}>
                <TimePickerSelectionColumn aria-labelledby="hourHeading">
                  <Heading
                    id="hourHeading"
                    variant="subheading"
                    color="subdued"
                    css={{ mb: '$space2' }}
                  >
                    Hour
                  </Heading>
                  <TimePickerSelectionRows
                    role="listbox"
                    tabIndex={0}
                    onKeyDown={handleHourKeyDown}
                    aria-activedescendant={`hour-selection-rows-${selectedHours}`}
                  >
                    {timePickerHourValues.map((hour) => (
                      <TimePickerSelectionRow
                        key={`hour-${hour}`}
                        id={`hour-selection-rows-${hour}`}
                        value={String(hour).padStart(2, '0')}
                        selected={hour === convertHoursFromContinentalToMeridiem(selectedHours)}
                        onChange={onHourChange}
                      />
                    ))}
                  </TimePickerSelectionRows>
                </TimePickerSelectionColumn>
                <TimePickerSelectionColumn aria-labelledby="minuteHeading">
                  <Heading
                    id="minuteHeading"
                    variant="subheading"
                    color="subdued"
                    css={{ mb: '$space2' }}
                  >
                    Minute
                  </Heading>
                  <TimePickerSelectionRows
                    role="listbox"
                    tabIndex={0}
                    onKeyDown={handleMinuteKeyDown}
                    aria-activedescendant={`minute-selection-rows-${selectedMinutes}`}
                  >
                    {timePickerMinuteValues.map((minute) => (
                      <TimePickerSelectionRow
                        key={`minute-${minute}`}
                        id={`minute-selection-rows-${minute}`}
                        value={String(minute).padStart(2, '0')}
                        selected={minute === selectedMinutes}
                        onChange={onMinuteChange}
                      />
                    ))}
                  </TimePickerSelectionRows>
                </TimePickerSelectionColumn>
                <TimePickerSelectionColumn aria-labelledby="periodHeading">
                  <Heading
                    id="periodHeading"
                    variant="subheading"
                    color="subdued"
                    css={{ mb: '$space2' }}
                  >
                    Period
                  </Heading>
                  <TimePickerSelectionRows
                    role="listbox"
                    tabIndex={0}
                    onKeyDown={handlePeriodKeyDown}
                    aria-activedescendant={`period-selection-rows-${getMeridiemPeriod(
                      selectedHours
                    )}`}
                  >
                    {MeridiemPeriods.map((period) => (
                      <TimePickerSelectionRow
                        key={period}
                        id={`period-selection-rows-${period}`}
                        value={period}
                        selected={period === getMeridiemPeriod(selectedHours)}
                        onChange={(newValue: string) =>
                          onMeridiemPeriodChange(newValue as MeridiemPeriod)
                        }
                      />
                    ))}
                  </TimePickerSelectionRows>
                </TimePickerSelectionColumn>
              </Box>
            </StyledPopover>
          </PopoverPrimitive.Portal>
        </PopoverPrimitive.Root>
        {hasLocalError && (
          <Text color="critical">
            Selected time must be in increments of {sanitizedMinutesStep} minutes.
          </Text>
        )}
      </Box>

      <TimezoneComponentContext.Provider
        value={{ selectedTimezone, onChange: onSelectTimezoneChange, disabled }}
      >
        {children}
      </TimezoneComponentContext.Provider>
    </Stack>
  );
};

const TimePickerNamespace = compositeComponent(TimePicker, {
  Timezone: TimezoneComponent,
  TimezoneItem: Select.Item,
});

export { TimePickerNamespace as TimePicker };
