import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 2 32 24" {...props}>
      {title && <title>{title}</title>}
      <path d="M21.3333 22.2664V29.0222L32 22.2664V15.5111L21.3333 22.2664Z" fill="white" />
      <path d="M10.6667 22.2664V29.0222L21.3333 22.2664V15.5111L10.6667 22.2664Z" fill="white" />
      <path d="M0 22.2664V29.0222L10.6667 22.2664V15.5111L0 22.2664Z" fill="white" />
      <path d="M32 2L21.3333 8.75556V15.5111L32 8.75556V2Z" fill="white" />
      <path d="M21.3333 2L10.6667 8.75556V15.5111L21.3333 8.75556V2Z" fill="white" />
      <path d="M10.6667 2L0 8.75556V15.5111L10.6667 8.75556V2Z" fill="white" />
    </svg>
  );
};

export const MParticleWhite = React.memo(Icon);
