import _codeVerificationHooksVerifyMfaOneTimePasscodeMutation from "./__generated__/codeVerificationHooksVerifyMfaOneTimePasscodeMutation.graphql";
import _codeVerificationHooksSendMfaOneTimePasscodeMutation from "./__generated__/codeVerificationHooksSendMfaOneTimePasscodeMutation.graphql";
import { graphql } from '@attentive/data';
import { AuthSession } from '@attentive/acore-utils';
import { useMutation } from 'react-relay';
import SendMfaOneTimePasscodeMutation from './__generated__/codeVerificationHooksSendMfaOneTimePasscodeMutation.graphql';
import VerifyMfaOneTimePasscodeMutation from './__generated__/codeVerificationHooksVerifyMfaOneTimePasscodeMutation.graphql';
import { logger } from '../../common/logger';
const defaultVerificationErrorCode = 'Verification failed to send, please try again or reach out to whiteglove@attentive.com.';
_codeVerificationHooksSendMfaOneTimePasscodeMutation;
_codeVerificationHooksVerifyMfaOneTimePasscodeMutation;
export const useSendMfaOneTimePasscode = () => {
  const [commit, isSendingCode] = useMutation(SendMfaOneTimePasscodeMutation);

  const sendMfaOneTimePasscode = (variables, onCompleted, onError) => commit({
    variables: {
      input: { ...variables
      }
    },
    onCompleted: (resp, errors) => {
      var _resp$sendMfaOneTimeP, _resp$sendMfaOneTimeP2;

      if (errors && (errors === null || errors === void 0 ? void 0 : errors.length) > 0) {
        logger.logError(new Error(errors[0].message));
        onError(defaultVerificationErrorCode);
      } else if ((_resp$sendMfaOneTimeP = resp.sendMfaOneTimePasscode) !== null && _resp$sendMfaOneTimeP !== void 0 && _resp$sendMfaOneTimeP.error) {
        logger.logError(new Error(resp.sendMfaOneTimePasscode.error));
        onError(resp.sendMfaOneTimePasscode.error);
      } else if (!((_resp$sendMfaOneTimeP2 = resp.sendMfaOneTimePasscode) !== null && _resp$sendMfaOneTimeP2 !== void 0 && _resp$sendMfaOneTimeP2.successful)) {
        logger.logError(new Error('sendMfaOneTimePasscode mutation failed with successful=false'));
        onError(defaultVerificationErrorCode);
      } else {
        onCompleted();
      }
    },
    onError: error => {
      logger.logError(error);
      onError(defaultVerificationErrorCode);
    }
  });

  return {
    sendMfaOneTimePasscode,
    isSendingCode
  };
};
export const useVerifyMfaOneTimePasscode = () => {
  const [commit, isVerifyingCode] = useMutation(VerifyMfaOneTimePasscodeMutation);

  const verifyMfaOneTimePasscode = (variables, onCompleted, onError) => commit({
    variables: {
      input: { ...variables
      }
    },
    onCompleted: (data, errors) => {
      var _data$verifyMfaOneTim;

      const deviceId = (_data$verifyMfaOneTim = data.verifyMfaOneTimePasscode) === null || _data$verifyMfaOneTim === void 0 ? void 0 : _data$verifyMfaOneTim.deviceId;

      if (deviceId) {
        AuthSession.persistDeviceIdentifier(deviceId);
      }

      if (errors && (errors === null || errors === void 0 ? void 0 : errors.length) > 0) {
        onError(errors.map(error => error.message).join(', '));
      } else {
        onCompleted(data);
      }
    },
    onError: error => {
      logger.logError(error);
      onError(error.message);
    }
  });

  return {
    verifyMfaOneTimePasscode,
    isVerifyingCode
  };
};