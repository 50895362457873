import { attentiveAuthClient } from './AttentiveAuthClient';
import { ConnectionType } from './AuthClient';

export enum StrategyType {
  CREDENTIALS = 'CREDENTIALS',
  GOOGLE = 'GOOGLE',
  SAML = 'SAML',
}

interface CredentialsStrategy {
  type: StrategyType.CREDENTIALS;
}
interface GoogleStrategy {
  type: StrategyType.GOOGLE;
  connectionId: string;
}
interface SamlStrategy {
  type: StrategyType.SAML;
  connectionName: string;
}
export type Strategy = CredentialsStrategy | GoogleStrategy | SamlStrategy;

export class LoginStrategyClient {
  constructor() {}

  public getStrategy(email: string): Promise<Strategy> {
    return this.getInternalStrategy(email);
  }

  private async getInternalStrategy(email: string): Promise<Strategy> {
    const connection = await attentiveAuthClient.getConnection(email);

    if (connection.connectionType === ConnectionType.CREDENTIALS) {
      return { type: StrategyType.CREDENTIALS };
    }
    if (connection.connectionType === ConnectionType.SAML) {
      return {
        type: StrategyType.SAML,
        connectionName: connection.connectionName,
      };
    }
    if (connection.connectionType === ConnectionType.GOOGLE) {
      return {
        type: StrategyType.GOOGLE,
        connectionId: connection.connectionId,
      };
    }
    throw new Error(`Unrecognized connection ${JSON.stringify(connection)}`);
  }
}
