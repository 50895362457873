export const GRAPHQL_REQUEST_FAILURE_ERROR_CODE = 'AQRF-500-72';
export const GRAPHQL_PERMISSION_DENIED_ERROR_CODE = 'AQPD-403-72';
export const GRAPHQL_INCOMPLETE_DATA_ERROR_CODE = 'AQID-422-08';
export const GRAPHQL_SCALAR_DESERIALIZATION_ERROR_CODE = 'AQSD-400-14';

export class GraphqlRequestFailureError extends Error {
  constructor(public url: string, public operationName: string, err: Error) {
    super(`Graphql request failure: the "${operationName}" to ${url} failed: ${err}`);
    this.name = 'GraphqlRequestFailureError';
  }
}

export class GraphqlPermissionDeniedError extends Error {
  constructor(message: string, public path?: string[]) {
    super(
      `Graphql permission denied: ${message}${
        path && path.length > 0 ? ` at "${path.join('.')}"` : ''
      }`
    );
    this.name = 'GraphqlPermissionDeniedError';
  }
}

export class GraphqlIncompleteDataError extends Error {
  constructor(message: string) {
    super(`Graphql incomplete data: ${message}`);
    this.name = 'GraphqlIncompleteDataError';
  }
}

type ScalarTypes = 'DateTime';

export class GraphqlScalarDeserializationError extends Error {
  constructor(public scalarType: ScalarTypes, msg: string) {
    super(`Graphql ${scalarType} scalar deserialization: ${msg}`);
    this.name = 'GraphqlScalarDeserializationError';
  }
}
