/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const DotsHorizontal = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.875 10.123a1.875 1.875 0 1 1 0 3.75 1.875 1.875 0 0 1 0-3.75ZM13.874 11.998a1.875 1.875 0 1 0-3.75 0 1.875 1.875 0 0 0 3.75 0ZM21 11.998a1.875 1.875 0 1 0-3.75 0 1.875 1.875 0 0 0 3.75 0Z" />
  </svg>
);

const Memo = memo(DotsHorizontal);
export default Memo;
