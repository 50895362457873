/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const List = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M8.251 10.876v2.248H21v-2.248H8.251ZM8.251 4.5v2.249H21V4.5H8.251ZM8.251 17.251V19.5H21V17.25H8.251ZM4.5 7.124a1.5 1.5 0 1 0 0-2.999 1.5 1.5 0 0 0 0 2.999ZM4.5 13.5a1.5 1.5 0 1 0-.001-3 1.5 1.5 0 0 0 0 3ZM4.5 19.875a1.5 1.5 0 1 0-.001-2.999 1.5 1.5 0 0 0 0 2.998Z" />
  </svg>
);

const Memo = memo(List);
export default Memo;
