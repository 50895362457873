import React from 'react';

import { styled } from '../../stitches.config';
import { Text } from '../Text';

const StyledTimePickerSelectionRow = styled('div', {
  cursor: 'pointer',
  height: '38px',
  width: '63px',
  borderRadius: '$radius1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '$bgActionBasicHover',
  },
  variants: {
    selected: {
      true: {
        color: '$textInverted',
        backgroundColor: '$bgToggleSelected',
        '&:hover': {
          backgroundColor: '$bgToggleSelected',
        },
      },
      false: {},
    },
  },
});

type TimePickerSelectionRowProps = {
  id: string;
  value: string;
  selected: boolean;
  onChange: (newValue: string) => void;
};

export const TimePickerSelectionRow = ({
  id,
  value,
  selected,
  onChange,
}: TimePickerSelectionRowProps) => {
  const itemRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (selected && itemRef.current) {
      itemRef.current.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      itemRef.current.focus();
    }
  }, [selected]);

  return (
    <StyledTimePickerSelectionRow
      ref={itemRef}
      id={id}
      selected={selected}
      aria-selected={selected}
      onClick={() => onChange(value)}
      role="option"
    >
      <Text>{value}</Text>
    </StyledTimePickerSelectionRow>
  );
};
