import React from 'react';

import { Optional } from '../../utils';
import { IconButton, IconButtonProps } from '../Button';
import { Popover, PopoverProps } from '../Popover';

type PopoverContentProps = React.ComponentProps<typeof Popover.Content>;

interface IconPopoverProps
  extends Omit<Optional<IconButtonProps, 'iconName' | 'description'>, 'as'>,
    Pick<PopoverContentProps, 'side' | 'align' | 'alignOffset'>,
    Pick<PopoverProps, 'onOpenChange' | 'open'> {}

export const IconPopover: React.FC<React.PropsWithChildren<IconPopoverProps>> = ({
  children,
  iconName = 'CircleQuestion',
  description = 'More information',
  variant = 'subdued',
  size = 'medium',
  side = 'top',
  align = 'start',
  alignOffset = 2,
  open,
  onOpenChange,
  ...rest
}) => {
  return (
    <Popover onOpenChange={onOpenChange} open={open}>
      <Popover.Trigger>
        <IconButton
          iconName={iconName}
          description={description}
          size={size}
          variant={variant}
          {...rest}
        />
      </Popover.Trigger>
      <Popover.Content side={side} align={align} alignOffset={alignOffset} showCloseButton={true}>
        {children}
      </Popover.Content>
    </Popover>
  );
};
