import { Response, DatadogLogger, ProjectNames } from '@attentive/acore-utils';

let datadogLogger: DatadogLogger | null = null;

export const initializeAuthCoreLogger = (projectName: ProjectNames) => {
  datadogLogger = new DatadogLogger({ projectName, libName: 'auth-core' });
};

// A sentinel error, which client-ui can catch to identify that auth is redirecting
// and not in an actual faulty state
export class AuthRedirecting extends Error {
  public constructor() {
    super('Now redirecting...');
  }
}

interface AuthErrorDetails {
  statusCode?: number;
  cause?: Error;
}

export class AuthError extends Error {
  public readonly reason: string;
  public statusCode?: number;
  public cause?: Error;
  public constructor(
    description: string,
    reason: string,
    { statusCode, cause }: AuthErrorDetails = {}
  ) {
    super(`${description}: ${reason}`, { cause });
    this.reason = reason;
    this.name = 'AuthError';
    this.statusCode = statusCode;
    this.cause = cause;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromResponse(description: string, response: Response<any>): AuthError {
    const reason: string = response.body?.message || response.bodyText;
    return new AuthError(description, reason, { statusCode: response.status });
  }

  public static fromError(description: string, error: Error) {
    if (error instanceof AuthError) {
      return error;
    }
    return new AuthError(description, error.message, { cause: error });
  }
}

export const getErrorCodeFromResponse = (response: Response<unknown>): string | null => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (response.body as any)?.code ?? null;
};

export class InvalidPasswordError extends AuthError {
  public messageDetails: string[];
  public constructor(
    description: string,
    reason: string,
    messages: string[],
    details: AuthErrorDetails = {}
  ) {
    super(description, reason, details);
    this.messageDetails = messages;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromResponse(description: string, response: Response<any>): InvalidPasswordError {
    const fullMessage: string = response.body?.message || response.bodyText;
    const messageLines = fullMessage.split('\n');
    const reason = messageLines[0];
    const messageDetails = messageLines
      .filter((line) => line.startsWith(' - '))
      .map((line) => line.substring(' - '.length));
    return new InvalidPasswordError(description, reason, messageDetails, {
      statusCode: response.status,
    });
  }

  public static fromGqlError(description: string, message: string) {
    const messageLines = message.split('\n');
    const reason = messageLines[0];
    const messageDetails = messageLines
      .filter((line) => line.startsWith(' - '))
      .map((line) => line.substring(' - '.length));
    return new InvalidPasswordError(description, reason, messageDetails, {
      statusCode: 401,
    });
  }
}
export class NeedsPasswordResetError extends AuthError {
  public constructor(
    description: string,
    reason: string,
    { statusCode, cause }: AuthErrorDetails = {}
  ) {
    super(description, reason, { statusCode, cause });
    this.name = 'NeedsPasswordResetError';
  }

  public static fromResponse(
    description: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: Response<any>
  ): NeedsPasswordResetError {
    const reason: string = response.body?.message || response.bodyText;
    return new NeedsPasswordResetError(description, reason, {
      statusCode: response.status,
    });
  }

  public static fromGqlError(description: string, message: string) {
    return new NeedsPasswordResetError(description, message, {
      statusCode: 401,
    });
  }
}

export const checkForUnavailableAuthenticatorError = (error: Error): boolean => {
  return error && error.message === 'UNAVAILABLE: Account does not have an active authenticator';
};

export const checkForUnauthenticatedError = (error: Error): error is AuthError => {
  return error instanceof AuthError && (error.statusCode === 401 || error.statusCode === 403);
};

const checkForFailedToFetchError = (error: Error) => {
  const realError: Error | undefined = error instanceof AuthError ? error.cause : error;
  return realError && realError.message.toLowerCase().includes('failed to fetch');
};

const shouldIgnoreError = (error: Error) => {
  return (
    checkForUnauthenticatedError(error) || // 401s+403s are expected and common responses
    checkForFailedToFetchError(error) || // "failed to fetch" is frequent and noisy
    error instanceof InvalidPasswordError // weak passwords are user error
  );
};

export function logAuthError(error: Error) {
  if (shouldIgnoreError(error)) {
    return;
  }

  datadogLogger?.logError(error);
}
