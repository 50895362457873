/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Magic = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m16 11.051-3.178-3.177L2.156 18.539l3.178 3.178L15.999 11.05Zm-4.16 2.038 2.038-2.038-1.056-1.056-2.038 2.038 1.056 1.056Z"
      clipRule="evenodd"
    />
    <path d="M17.997 2.216a4.383 4.383 0 0 0 3.66 3.66 4.383 4.383 0 0 0-3.66 3.659 4.383 4.383 0 0 0-3.66-3.66 4.383 4.383 0 0 0 3.66-3.659ZM9.71 2.216a3.37 3.37 0 0 0 2.812 2.813A3.37 3.37 0 0 0 9.71 7.84 3.37 3.37 0 0 0 6.897 5.03 3.37 3.37 0 0 0 9.71 2.216ZM19.219 11.094a2.92 2.92 0 0 0 2.437 2.438 2.92 2.92 0 0 0-2.437 2.437 2.92 2.92 0 0 0-2.438-2.438 2.92 2.92 0 0 0 2.438-2.437Z" />
  </svg>
);

const Memo = memo(Magic);
export default Memo;
