import { useEffect, useMemo, useState } from 'react';

import { AuthSession } from '@attentive/acore-utils';
import { AuthFlowStrategy } from '@attentive/data';

import { attentiveAuthClient } from './AttentiveAuthClient';
import { AuthClient } from './AuthClient';
import { LoginStrategyClient } from './LoginStrategyClient';
import { mockAuthClient } from './MockAuthClient';
import { unauthenticatedAuthClient } from './UnauthenticatedAuthClient';

export const getAuthClient = (authFlowStrategy: AuthFlowStrategy | null): AuthClient => {
  if (authFlowStrategy === AuthFlowStrategy.Internal) {
    return attentiveAuthClient;
  } else if (authFlowStrategy === AuthFlowStrategy.Mock) {
    return mockAuthClient;
  }
  return unauthenticatedAuthClient;
};

const useCurrentAuthFlowStrategy = () => {
  const [strategy, setStrategy] = useState(AuthSession.retrieveStrategy());
  useEffect(() => {
    AuthSession.addStrategyEventListener(setStrategy);
    return () => {
      AuthSession.removeStrategyEventListener(setStrategy);
    };
  }, []);
  return strategy;
};

/**
 * Retrieves the "auth client" for the user's current session, if any.
 */
export const useAuthClient = () => {
  const authFlowStrategy = useCurrentAuthFlowStrategy();
  return getAuthClient(authFlowStrategy);
};

export const useLoginStrategyClient = () => {
  return useMemo(() => new LoginStrategyClient(), []);
};
