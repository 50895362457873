import { useField } from 'formik';
import React from 'react';

import { compositeComponent } from '../../utils/composite-component';
import { Switch as StyledSwitch } from '../Switch';

import { useFormFieldName } from './FormField';
import { PropsWithoutField } from './utils';

type Props = PropsWithoutField<typeof StyledSwitch>;

const Switch: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const name = useFormFieldName();
  const [{ value, ...field }, , { setValue }] = useField(name);

  return <StyledSwitch {...props} {...field} id={name} checked={value} onChange={setValue} />;
};

const Namespace = compositeComponent(Switch, {
  SwitchItem: StyledSwitch.SwitchItem,
});

export { Namespace as Switch };
