import { meros } from 'meros/browser';
import { GraphQLSingularResponse, Observable, RequestParameters, Variables } from 'relay-runtime';

import { addMSWHeaders } from './mock-data-utils';
import { datadogRum } from '@datadog/browser-rum';

import {
  convertServerResponseToRelaySpec,
  GQLServerResponse,
  GQLSingularResponse,
} from './graphql-data-types';
import { GraphqlRequestFailureError } from './errors';
import { ApiDataSource, isMockDataSource } from './api-data-source';

const createAsyncObservable = <T>(source: (next: (data: T) => void) => Promise<void>) => {
  return Observable.create<T>((sink) => {
    source(sink.next).then(sink.complete).catch(sink.error);
  });
};
const areSame = <T>(first: T, second: unknown): second is T => {
  return first === second;
};

async function* responseToStream(response: Response): AsyncGenerator<GraphQLSingularResponse[]> {
  const responseOrAsyncGen = await meros<GQLServerResponse>(response, { multiple: true });
  if (areSame(response, responseOrAsyncGen)) {
    // response was not streamed
    yield convertServerResponseToRelaySpec(await response.json());
    return;
  }
  for await (const responses of responseOrAsyncGen) {
    yield responses.flatMap((res) => {
      if (res.json) {
        return convertServerResponseToRelaySpec(res.body);
      }
      return [];
    });
  }
}

export type GraphqlTokenCallback = () => string | null;

export interface GraphQLOptions {
  tokenCallback?: GraphqlTokenCallback;
  apiDataSource?: ApiDataSource;
  onUnauthenticated?: () => void;
}

export function fetchUsingGraphQL(
  graphqlGatewayURL: string,
  options: GraphQLOptions | undefined,
  operation: string | null | undefined,
  variables: Variables
): Observable<GraphQLSingularResponse> {
  let gatewayUrl = graphqlGatewayURL;
  const { tokenCallback, apiDataSource } = options || {};
  const headers: HeadersInit = new Headers();
  headers.set('Content-Type', 'application/json');

  if (isMockDataSource(apiDataSource)) {
    // Pass along MSW headers from session storage in non-production environments
    addMSWHeaders(headers);
  }

  const operationName = operation?.match(/(?:query\s|mutation\s)(\w+)/)?.[1] ?? 'unknown';

  gatewayUrl = `${gatewayUrl}?opname=${operationName}`;

  if (tokenCallback) {
    headers.set('Authorization', `bearer ${tokenCallback()}`);
  }

  const responseObservable = createAsyncObservable<GraphQLSingularResponse>(async (next) => {
    let response: Response;
    try {
      response = await fetch(gatewayUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          query: operation,
          variables,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      throw new GraphqlRequestFailureError(gatewayUrl, operationName, err);
    }

    for await (const results of responseToStream(response)) {
      results.forEach(next);
    }
  });

  return responseObservable.do({
    next: (responseData) => {
      const errors = (responseData as GQLSingularResponse).errors || [];

      const traceId = responseData.extensions?.traceId ?? null;

      if (errors.some((err) => err.extensions?.code === 'UNAUTHENTICATED')) {
        options?.onUnauthenticated?.();
      }

      if (errors.length > 0) {
        datadogRum.addAction(`Graphql Error: ${operationName}`, {
          traceId,
        });

        const traceMsg = `View logs and trace information for this error in Datadog at https://app.datadoghq.com/apm/trace/${traceId}`;

        console.group(
          `GraphQL gateway returned ${errors.length} error(s). ${traceId ? traceMsg : ''}`
        );
        errors.forEach((err) => {
          console.warn(
            err.message,
            err.path ? `path: "${err.path.join('.')}"` : '',
            err.locations ? 'locations:' : '',
            err.locations,
            err.extensions ? 'extensions:' : '',
            err.extensions,
            traceId ? 'traceId:' : '',
            traceId
          );
        });
        console.groupEnd();
      }
    },
    error: (error) => {
      console.error('Unexpected error occurred!', error);
    },
  });
}

export function fetchUsingRelay(graphqlApiUrl: string, options?: GraphQLOptions) {
  return (params: RequestParameters, variables: Variables) => {
    return fetchUsingGraphQL(graphqlApiUrl, options, params.text, variables);
  };
}
