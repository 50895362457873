import { AuthSession } from '@attentive/acore-utils';

import { AuthClient, VerifySessionResponse } from './AuthClient';
import { AuthError } from './errors';
import { buildLogoutRedirectUri } from './utils';

/**
 * An AuthClient to use when the user is not currently signed in.
 */
export class UnauthenticatedAuthClient implements AuthClient {
  public async verifySession(): Promise<VerifySessionResponse> {
    throw new AuthError('Could not verify session', 'You have been signed out.', {
      statusCode: 401,
    });
  }

  public logout(logoutReason: string | null) {
    AuthSession.clearStorage();
    window.location.assign(buildLogoutRedirectUri(window.location, logoutReason));
  }
}

export const unauthenticatedAuthClient = new UnauthenticatedAuthClient();
