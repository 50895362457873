import React from 'react';

import { Select, TabGroup } from '../../components';

import { TabGroupTransformerProps } from './types';

const TabGroupComponent: React.FC<React.PropsWithChildren<TabGroupTransformerProps>> = ({
  value,
  onChange,
  children,
  ...rest
}) => {
  const mappedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    return <TabGroup.Tab {...child.props} />;
  });

  return (
    <TabGroup value={value} onValueChange={onChange} {...rest}>
      <TabGroup.List>{mappedChildren}</TabGroup.List>
    </TabGroup>
  );
};

const SelectComponent: React.FC<React.PropsWithChildren<TabGroupTransformerProps>> = ({
  value,
  onChange,
  children,
  ...rest
}) => {
  const mappedChildren = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    return <Select.Item {...child.props} />;
  });

  return (
    <Select value={value} onChange={onChange} {...rest}>
      {mappedChildren}
    </Select>
  );
};

const getListComponent = (mode: TabGroupTransformerProps['mode']) => {
  switch (mode) {
    case 'tabs':
      return TabGroupComponent;
    case 'select':
      return SelectComponent;
    default:
      throw new Error(
        `'${mode}' is not a valid mode of TabGroupTransformer. Available modes are 'tabs' and 'select'.`
      );
  }
};

export const TabListTransformer: React.FC<React.PropsWithChildren<TabGroupTransformerProps>> = ({
  mode,
  ...rest
}) => {
  const ListComponent = getListComponent(mode);

  return <ListComponent {...rest} />;
};
