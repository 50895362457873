import {
  Form as FormikForm,
  Formik,
  FormikConfig,
  FormikProps,
  FormikValues,
  useFormikContext,
} from 'formik';
import React from 'react';
import { AnyObjectSchema } from 'yup';

import { styled } from '../../stitches.config';
import { compositeComponent } from '../../utils/composite-component';

import { Checkbox } from './Checkbox';
import { DatePicker } from './DatePicker';
import { ErrorText } from './ErrorText';
import { FormField } from './FormField';
import { HelperText } from './HelperText';
import { Label } from './Label';
import { MultiSelect } from './MultiSelect';
import { RadioGroup } from './RadioGroup';
import { ResetButton } from './ResetButton';
import { SearchableSelect } from './SearchableSelect';
import { Select } from './Select';
import { SubmitButton } from './SubmitButton';
import { Switch } from './Switch';
import { TextArea } from './TextArea';
import { TextInput } from './TextInput';
import { FormValidationProvider } from './use-form-validation-schema';

const StyledFormikForm = styled(FormikForm, {});

export type FormSubmitHandler<V> = FormikConfig<V>['onSubmit'];

type FormikFormProps = React.ComponentProps<typeof StyledFormikForm>;
type FormProps<V extends FormikValues> = Omit<FormikFormProps, 'onSubmit'> & {
  initialValues: FormikConfig<V>['initialValues'];
  validate?: FormikConfig<V>['validate'];
  validationSchema?: AnyObjectSchema;
  onSubmit: FormSubmitHandler<V>;
  enableReinitialize?: FormikProps<V>['enableReinitialize'];
  validateOnMount?: FormikProps<V>['validateOnMount'];
};

function useForm<V extends FormikValues>() {
  const formContext = useFormikContext<V>();
  return formContext;
}

function Form<V extends FormikValues>({
  initialValues,
  validate,
  validationSchema,
  onSubmit,
  children,
  enableReinitialize,
  validateOnMount,
  ...rest
}: FormProps<V>) {
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
      validateOnMount={validateOnMount}
    >
      <FormValidationProvider value={validationSchema || null}>
        <StyledFormikForm {...rest}>{children}</StyledFormikForm>
      </FormValidationProvider>
    </Formik>
  );
}

const Namespace = compositeComponent(Form, {
  FormField,
  Label,
  Checkbox,
  DatePicker,
  ErrorText,
  HelperText,
  MultiSelect,
  RadioGroup,
  SearchableSelect,
  Select,
  Switch,
  TextArea,
  TextInput,
  ResetButton,
  SubmitButton,
});

export { Namespace as Form, useForm };
