import React from 'react';

import { TabListTransformer } from './TabListTransformer';
import { TabListTransformerSlot, TabPanelTransformerSlot, TabTransformerSlot } from './slots';
import { TabGroupTransformerProps } from './types';
import { extractChildren, getActivePanelContent } from './utils';

/**
 * Component which exposes the same API as Picnic TabGroup but can render as either a TabGroup or a Select.
 * Intended to be used in different modes at different screen sizes.
 */
const TabGroupTransformerComponent: React.FC<React.PropsWithChildren<TabGroupTransformerProps>> = ({
  value,
  mode = 'tabs',
  onChange = () => {},
  children,
  ...rest
}) => {
  const { tabListComponent, panelComponents } = extractChildren(children);
  const activePanelContent = getActivePanelContent(value, panelComponents);

  return (
    <>
      {tabListComponent && (
        <TabListTransformer mode={mode} onChange={onChange} value={value} {...rest}>
          {tabListComponent.props.children}
        </TabListTransformer>
      )}
      {/**
       * TODO: Improve accessibility roles for tab panel.
       * Currently panels are rendered directly with no additional accessibility properties.
       * Need to figure out the correct roles for the "Select" powered mode and implement,
       * as well as integrate the panels into the base "TabGroup" for the "tabs" mode.
       */}
      {activePanelContent}
    </>
  );
};

type ComponentType = typeof TabGroupTransformerComponent;
interface TabGroupTransformerCompositeComponent extends ComponentType {
  Tab: typeof TabTransformerSlot;
  List: typeof TabListTransformerSlot;
  Panel: typeof TabPanelTransformerSlot;
}

const TabGroupTransformer = TabGroupTransformerComponent as TabGroupTransformerCompositeComponent;
TabGroupTransformer.Tab = TabTransformerSlot;
TabGroupTransformer.List = TabListTransformerSlot;
TabGroupTransformer.Panel = TabPanelTransformerSlot;

export { TabGroupTransformer };
