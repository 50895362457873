import * as LabelPrimitive from '@radix-ui/react-label';
import React from 'react';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { getImpersonatedPicnicComponent } from '../../utils/impersonate-picnic-component';
import { BoxProps, Box } from '../Box';
import { IconPopover } from '../IconPopover';
import { Text, TextProps } from '../Text';

const LabelStyled = styled(LabelPrimitive.Root, Text, {
  display: 'block',
  color: '$textDefault',
  fontWeight: '$bold',

  variants: {
    disabled: {
      true: {
        color: '$textDisabled',
        cursor: 'not-allowed',
      },
    },
  },
});

const HelperStyled = styled(Text, {
  variants: {
    disabled: {
      true: {
        color: '$textDisabled',
        cursor: 'not-allowed',
      },
    },
  },
});

type LabelProps = React.ComponentProps<typeof LabelStyled> & {
  disabled?: boolean;
  requirement?: 'none' | 'required' | 'optional';
};

interface HelperProps extends TextProps {
  disabled?: boolean;
}

const Label: React.FC<LabelProps> = ({
  disabled = false,
  requirement = 'none',
  children,
  ...rest
}) => (
  <LabelStyled role="label" disabled={disabled} {...rest}>
    {children}
    {requirement === 'required' && (
      <Box aria-hidden as="span" css={{ color: '$textCritical' }}>
        &thinsp;*
      </Box>
    )}
    {requirement === 'optional' && (
      <Box aria-hidden as="span">
        &thinsp;(optional)
      </Box>
    )}
  </LabelStyled>
);

const HelperText: React.FC<React.PropsWithChildren<HelperProps>> = ({ ...rest }) => (
  <HelperStyled variant="caption" {...rest} />
);

const ErrorText: React.FC<React.PropsWithChildren<TextProps>> = ({ css, ...rest }) => (
  <Text variant="caption" css={{ color: '$textCritical', ...css }} {...rest} />
);

const StyledIconPopover: React.FC<
  React.PropsWithChildren<Omit<React.ComponentProps<typeof IconPopover>, 'size'>>
> = ({ css, ...rest }) => {
  return (
    <IconPopover
      alignOffset={-4}
      css={{
        width: '$size6',
        height: '$size6',
        padding: 0,
        // Improves the vertical alignment of the icon with the Label text.
        marginTop: '-1px',
        ...css,
      }}
      {...rest}
    />
  );
};

interface FormFieldProps extends BoxProps {
  layout?: 'vertical' | 'horizontal';
}

const FormFieldComponent: React.FC<React.PropsWithChildren<FormFieldProps>> = ({
  layout = 'vertical',
  children,
  css,
  ...rest
}) => {
  let label: React.ReactElement | null = null;
  let helperText: React.ReactElement | null = null;
  let errorText: React.ReactElement | null = null;
  let iconPopover: React.ReactElement | null = null;
  const otherChildren: React.ReactElement[] = [];

  React.Children.forEach(children, (child) => {
    /*
      TODO: Add more stringent validation of children:
        - only allow a valid input type component as "other" child
        - Only allow max 1 of each of the expected components
    */
    if (!React.isValidElement(child)) {
      return;
    }

    const type = getImpersonatedPicnicComponent(child);

    switch (type) {
      case Label:
        label = React.cloneElement(child, {
          css: {
            alignSelf: 'stretch',
            ...(child.props.css || {}),
          },
        });
        break;
      case HelperText:
        helperText = child;
        break;
      case ErrorText:
        errorText = child;
        break;
      case StyledIconPopover:
        iconPopover = child;
        break;
      default:
        otherChildren.push(child);
    }
  });

  if (layout === 'horizontal') {
    return (
      <Box role="group" css={{ display: 'flex', ...css }} {...rest}>
        {otherChildren}
        {(label || helperText || errorText) && (
          <Box css={{ ml: '$space2' }}>
            {label && (
              <Box
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: helperText || errorText ? '$space1' : '$space0',
                }}
              >
                {label}
                {iconPopover && <Box css={{ ml: '$space1' }}>{iconPopover}</Box>}
              </Box>
            )}
            {helperText}
            {errorText}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box role="group" css={css} {...rest}>
      {label && (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            mb: helperText ? '$space1' : '$space2',
          }}
        >
          {label}
          {iconPopover && <Box css={{ ml: '$space1' }}>{iconPopover}</Box>}
        </Box>
      )}
      {helperText && <Box css={{ mb: '$space2' }}>{helperText}</Box>}
      {otherChildren}
      {errorText && <Box css={{ mt: '$space2' }}>{errorText}</Box>}
    </Box>
  );
};

type ComponentType = typeof FormFieldComponent & DisplayNamed;
export interface FormFieldCompositeComponent extends ComponentType {
  Label: typeof Label & DisplayNamed;
  HelperText: typeof HelperText & DisplayNamed;
  ErrorText: typeof ErrorText & DisplayNamed;
  IconPopover: typeof StyledIconPopover;
}
const FormField = FormFieldComponent as FormFieldCompositeComponent;
FormField.Label = Label;
FormField.HelperText = HelperText;
FormField.ErrorText = ErrorText;
FormField.IconPopover = StyledIconPopover;

FormField.displayName = 'FormField';
FormField.Label.displayName = 'FormField.Label';
FormField.HelperText.displayName = 'FormField.HelperText';
FormField.ErrorText.displayName = 'FormField.ErrorText';
FormField.IconPopover.displayName = 'FormField.IconPopover';

export type { LabelProps, HelperProps, FormFieldProps };
export { FormField };
