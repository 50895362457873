import { commitLocalUpdate, fetchQuery } from 'relay-runtime';
import { useRelayEnvironment } from 'react-relay';
import { createSiteRecords } from '@attentive/data';
import { useCallback } from 'react';
import AcoreUtilsToastsQuery, {
  AcoreUtilsToastsQuery as AcoreUtilsToastsQueryType,
} from './__generated__/AcoreUtilsToastsQuery.graphql';
import { ToastType } from './Toast';

type ToastOptions = {
  type?: ToastType;
  title?: string;
  text: string;
};

export type CreateToast = (toast: ToastOptions) => void;

const useToast = () => {
  const relayEnvironment = useRelayEnvironment();

  const create: CreateToast = useCallback(
    (toast) => {
      commitLocalUpdate(relayEnvironment, (relayStore) => {
        fetchQuery<AcoreUtilsToastsQueryType>(
          relayEnvironment,
          AcoreUtilsToastsQuery,
          {},
          { fetchPolicy: 'store-or-network' }
        ).subscribe({
          next: (data) => {
            createSiteRecords(relayStore, 'Toast', 'toasts', [...data.site.toasts, toast]);
          },
        });
      });
    },
    [relayEnvironment]
  );

  return [create];
};

export { useToast };
