/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Document = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M4.5 3h8.961v6.032H19.5V21h-15V3Zm10.464 4.53h4.501l-4.501-4.502V7.53Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Document);
export default Memo;
