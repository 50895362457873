import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import React from 'react';

import { styled } from '../../stitches.config';
import { Box } from '../Box';
import { FormField } from '../FormField';

const childWithMargin = '> * + *';

const StyledRoot = styled(RadioGroupPrimitive.Root, {
  display: 'flex',

  variants: {
    layout: {
      horizontal: {
        flexDirection: 'row',

        [childWithMargin]: {
          ml: '$space4',
        },
      },
      vertical: {
        flexDirection: 'column',
        alignItems: 'flex-start',

        [childWithMargin]: {
          mt: '$space4',
        },
      },
    },
  },
  defaultVariants: {
    layout: 'horizontal',
  },
});

const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  borderRadius: '$radiusMax',
  width: '10px',
  height: '10px',
  backgroundColor: '$bgToggleSelected',
});

const StyledItem = styled(RadioGroupPrimitive.Item, {
  appearance: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  flexShrink: 0,

  width: '$size5',
  height: '$size5',
  borderRadius: '$radiusMax',
  padding: '0',

  cursor: 'pointer',

  backgroundColor: '$bgDefault',
  // Change to borderWidth2
  border: '2px solid',
  borderColor: '$borderInput',
  '&[data-state="checked"]:not(:disabled)': {
    borderColor: '$borderInputHover',
  },

  focusVisible: '$focus',

  '&:disabled': {
    cursor: 'not-allowed',
    [`& ${StyledIndicator}`]: {
      backgroundColor: '$textDisabled',
    },
  },
});

export interface RadioGroupProps
  extends Omit<
    React.ComponentProps<typeof StyledRoot>,
    // Expose only "onChange" for consistent API with other inputs.
    'onValueChange' | 'onChange'
  > {
  onChange?: (value: string) => void;
}

const RadioGroupComponent: React.FC<React.PropsWithChildren<RadioGroupProps>> = ({
  onChange,
  ...rest
}) => {
  return (
    <StyledRoot
      onValueChange={(c: string) => {
        onChange?.(c);
      }}
      {...rest}
    />
  );
};

type RadioItemProps = Omit<React.ComponentProps<typeof StyledItem>, 'onCheckedChange' | 'onChange'>;

const RadioItem: React.FC<React.PropsWithChildren<RadioItemProps>> = (props) => (
  <StyledItem {...props}>
    <StyledIndicator />
  </StyledItem>
);

const Radio: React.FC<
  React.PropsWithChildren<
    RadioItemProps & {
      // Children are required to render as the label for the toggle.
      children: React.ReactNode;
    }
  >
> = ({ children, css, disabled, ...rest }) => {
  return (
    <FormField.Label
      disabled={disabled}
      css={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        fontWeight: '$regular',
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...css,
      }}
    >
      <RadioItem disabled={disabled} {...rest} />
      <Box as="span" css={{ ml: '$space2' }}>
        {children}
      </Box>
    </FormField.Label>
  );
};

type ComponentType = typeof RadioGroupComponent;

interface CompositeComponent extends ComponentType {
  RadioItem: typeof RadioItem;
  Radio: typeof Radio;
  RadioItemWrapper: typeof StyledItem;
}

const RadioGroup = RadioGroupComponent as CompositeComponent;
RadioGroup.Radio = Radio;
RadioGroup.RadioItem = RadioItem;
RadioGroup.RadioItemWrapper = StyledItem;

RadioGroup.displayName = 'RadioGroup';
RadioGroup.Radio.displayName = 'RadioGroup.Radio';
RadioGroup.RadioItem.displayName = 'RadioGroup.RadioItem';
RadioGroup.RadioItemWrapper.displayName = 'RadioGroup.RadioItemWrapper';

export { RadioGroup };
