import React from 'react';
import { Environment, RelayEnvironmentProvider } from 'react-relay';
import { BrowserRouter } from 'react-router-dom';

import { QueryClientProvider } from '@attentive/data/react-query';

import App from './App';
import { useHydrateEnvVarAtoms } from './env-vars';
import { queryClient } from './queryClient';
import { baseName } from './utils/app';

interface AppProvidersProps {
  relayEnvironment: Environment;
}

export const AppProviders: React.FC<AppProvidersProps> = ({ relayEnvironment }) => {
  useHydrateEnvVarAtoms();

  return (
    <React.StrictMode>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <BrowserRouter basename={baseName()}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </RelayEnvironmentProvider>
    </React.StrictMode>
  );
};
