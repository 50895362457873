import { composeRefs } from '@radix-ui/react-compose-refs';
import React, { useRef, forwardRef, InputHTMLAttributes } from 'react';

import { PicnicCss } from '../../stitches.config';
import { Box } from '../Box';
import { Button, ButtonProps } from '../Button';

type InputType = InputHTMLAttributes<HTMLInputElement>;
type ItemProps = Omit<InputType, 'size'>;
interface FileInputProps extends ItemProps {
  loading?: boolean;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  css?: PicnicCss;
  multiple?: boolean;
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ children, variant, size, disabled, loading, id, css, multiple, ...rest }, ref) => {
    const disableInteraction = disabled || loading;
    const labelId = id || 'button-file';
    const localRef = useRef<HTMLInputElement | null>(null);

    return (
      <Box as="label" htmlFor={labelId} css={{ cursor: 'pointer', display: 'inline-block' }}>
        <input
          multiple={multiple ?? false}
          type="file"
          id={labelId}
          data-testid={labelId}
          disabled={disableInteraction}
          ref={composeRefs(localRef, ref)}
          hidden
          {...rest}
        />
        <Button
          variant={variant}
          size={size}
          disabled={!!disabled}
          loading={loading}
          css={{
            pointerEvents: disableInteraction ? 'none' : 'auto',
            ...css,
          }}
          onKeyUp={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              e.preventDefault();
              localRef.current?.click();
            }
          }}
          onClick={(e) => {
            e.preventDefault();
            localRef.current?.click();
          }}
        >
          {children}
        </Button>
      </Box>
    );
  }
);
