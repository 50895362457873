/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const TextInput = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M22.5 3.75h-21v16.5h21V3.75Zm-18 4.445h.94l.615.351v6.908l-.615.35H4.5v1.391h1.31l.94-.537.94.537H9v-1.39h-.94l-.615-.351V8.546l.615-.35H9V6.804H7.69l-.94.537-.94-.537H4.5v1.39Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(TextInput);
export default Memo;
