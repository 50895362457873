import moment, { Moment } from 'moment';
import React from 'react';

import { Box } from '../Box';
import { Select } from '../Select';

import { generateYears } from './utils';

// eslint-disable-next-line import/no-named-as-default-member
const monthsShort = moment.monthsShort();

interface DateRangePickerControlMonthElementProps {
  month: Moment;
  onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void;
  onYearSelect: (currentMonth: Moment, newYearVal: string) => void;
  isVisible: boolean;
  minExpectedYear?: number;
  maxExpectedYear?: number;
}

export const DateRangePickerControlMonthElement: React.FC<
  React.PropsWithChildren<DateRangePickerControlMonthElementProps>
> = ({ month, onMonthSelect, onYearSelect, minExpectedYear, maxExpectedYear }) => {
  const year = month.year();

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
      }}
      data-testid={`date-range-picker-${month.format('MMMM').toLowerCase()}-month-dropdown`}
    >
      <Select
        value={month.month()}
        onChange={(newMonth: string) => {
          onMonthSelect(month, newMonth);
        }}
        size="small"
        css={{ mr: '$space2' }}
        aria-label={`Change month from ${month.format('MMMM')}.`}
        data-picnic-id="date-picker-select-month"
      >
        {monthsShort.map((label, value) => (
          <Select.Item key={label} value={value}>
            {label}
          </Select.Item>
        ))}
      </Select>
      <Select
        value={month.year()}
        onChange={(newYear: string) => {
          onYearSelect(month, newYear);
        }}
        size="small"
        aria-label={`Change year from ${year}.`}
        data-picnic-id="date-picker-select-year"
      >
        {generateYears(year, minExpectedYear, maxExpectedYear).map((y: number) => (
          <Select.Item key={y} value={y}>
            {y}
          </Select.Item>
        ))}
      </Select>
    </Box>
  );
};
