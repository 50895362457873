import type * as Stitches from '@stitches/react';

const focusVisible = (value: Stitches.PropertyValue<'boxShadow'>) => {
  return {
    // Work around utils typing error
    content: 'picnicFocusVisible',
    '&:focus': {
      outline: 'none',
      boxShadow: value,
    },
    '&:focus:not(:focus-visible)': {
      boxShadow: 'none',
    },
    '&:focus-visible': {
      boxShadow: value,
    },
  };
};

export default { focusVisible };
