import { useField } from 'formik';
import React from 'react';

import { compositeComponent } from '../../utils/composite-component';
import { Checkbox as StyledCheckbox } from '../Checkbox';

import { useFormFieldName } from './FormField';
import { PropsWithoutField } from './utils';

type CheckboxProps = PropsWithoutField<typeof StyledCheckbox>;
type CheckboxItemProps = PropsWithoutField<typeof StyledCheckbox.CheckboxItem>;

const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = (props) => {
  const name = useFormFieldName();
  const [{ value, ...field }, , { setValue }] = useField(name);

  return <StyledCheckbox {...props} {...field} id={name} checked={value} onChange={setValue} />;
};

const CheckboxItem: React.FC<React.PropsWithChildren<CheckboxItemProps>> = (props) => {
  const name = useFormFieldName();
  const [{ value, ...field }, , { setValue }] = useField(name);

  return (
    <StyledCheckbox.CheckboxItem
      {...props}
      {...field}
      id={name}
      checked={value}
      onChange={setValue}
    />
  );
};

const Namespace = compositeComponent(Checkbox, {
  CheckboxItem,
});

export { Namespace as Checkbox };
