/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Menu = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3 10.876v2.248h18v-2.248H3ZM3 4.5v2.249h18V4.5H3ZM3 17.251V19.5h18V17.25H3Z" />
  </svg>
);

const Memo = memo(Menu);
export default Memo;
