/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Timer = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.75 4.575V3.75h1.5v-1.5h-4.5v1.5h1.5v.825c-4.2.375-7.5 3.9-7.5 8.175A8.226 8.226 0 0 0 12 21a8.226 8.226 0 0 0 8.25-8.25c0-4.275-3.3-7.8-7.5-8.175ZM12 19.5c-3.75 0-6.75-3-6.75-6.75S8.25 6 12 6s6.75 3 6.75 6.75-3 6.75-6.75 6.75Z" />
    <path
      fillRule="evenodd"
      d="M6.75 12.75A5.218 5.218 0 0 0 12 18a5.218 5.218 0 0 0 5.25-5.25A5.218 5.218 0 0 0 12 7.5v5.25H6.75Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Timer);
export default Memo;
