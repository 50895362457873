import React, { useState } from 'react';
import * as Yup from 'yup';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useToast, ToastType, PhoneAndCountryCodeInput } from '@attentive/acore-utils';
import { Box, Form, Link, Heading, Text, Wordmark, useForm } from '@attentive/picnic';

import { AUTH_PAGE_WIDTH } from '../../constants';
import { RequestDemo } from '../../components/RequestDemo';

import { useSetAccountMfaPreferencesMutation } from './SetupMfaForm';
import { useSendMfaOneTimePasscode } from '../CodeVerification/codeVerificationHooks';

export type SetupMfaProps = {
  mfaToken: string;
  deviceIdentifier: string;
};

type SetupMfaFormValues = {
  phone?: string;
};

const setupFormSchema: Yup.SchemaOf<SetupMfaFormValues> = Yup.object().shape({
  phone: Yup.string()
    .required('A valid phone number is required.')
    .matches(/\d+/, 'Phone number is invalid.'),
});

const MfaMethodInput = ({ error }: { error?: string }) => {
  const {
    values: { phone },
    setFieldValue,
  } = useForm<SetupMfaFormValues>();

  return (
    <Box css={{ marginTop: '$space3' }}>
      <PhoneAndCountryCodeInput
        phoneNumberValue={phone || ''}
        onChange={setFieldValue}
        error={error}
      />
    </Box>
  );
};

export const SetupMfaForm = ({ mfaToken, deviceIdentifier }: SetupMfaProps) => {
  const [makeToast] = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setAccountMfaPreferences, isLoading } = useSetAccountMfaPreferencesMutation();
  const { sendMfaOneTimePasscode } = useSendMfaOneTimePasscode();
  const [formError, setFormError] = useState<string>();

  const initialValues: Required<SetupMfaFormValues> = {
    phone: '',
  };

  const routeToVerificationView = () => {
    navigate(`/2fa/verify-setup-code?${searchParams}`);
  };

  const onError = (message: string) => {
    makeToast({
      type: ToastType.Error,
      text: 'Something went wrong',
    });
    // TODO: improve error handling
    console.debug(`submission failed due to ${message}`);
  };

  const submitMFAForm = async (values: SetupMfaFormValues) => {
    setAccountMfaPreferences(
      {
        mfaToken,
        mfaPhoneNumber: values.phone,
        preferredOtpDeliveryMethod: 'MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_SMS',
      },
      () => {
        sendMfaOneTimePasscode(
          {
            deviceIdentifier,
            mfaToken,
            preferredMfaMethod: 'PREFERRED_MFA_METHOD_SMS',
          },
          () => {
            routeToVerificationView();
          },
          (message) => {
            setFormError(message);
          }
        );
      },
      onError
    );
  };

  return (
    <Box css={{ width: '100%', maxWidth: AUTH_PAGE_WIDTH }}>
      <Form
        validationSchema={setupFormSchema}
        initialValues={initialValues}
        onSubmit={submitMFAForm}
      >
        <Wordmark title="Attentive" width="140px" css={{ mb: '$space12', display: 'block' }} />
        <Heading as="h1" css={{ mb: '$space6' }} variant="page">
          Secure your account with Two-Factor Authentication
        </Heading>

        <Text css={{ mb: '$space4' }}>
          We are securing each account with two-factor authentication (2FA). This gives protective
          control of your account.{' '}
          <Link href="https://help.attentivemobile.com/hc/en-us/articles/14208870889876-Set-up-two-factor-authentication-2FA-">
            Learn more
          </Link>
        </Text>
        <Text css={{ mb: '$space4' }}>
          We will send you a verification code in order to continue logging into your account.
        </Text>

        <Box css={{ padding: '$space2 0 $space6' }}>
          <MfaMethodInput error={formError} />
        </Box>
        <Form.SubmitButton css={{ width: '100%' }} loading={isLoading}>
          Send verification code
        </Form.SubmitButton>
      </Form>
      <RequestDemo />
    </Box>
  );
};
