/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SmileOutline = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 3.938a8.062 8.062 0 1 0 0 16.124 8.062 8.062 0 0 0 0-16.125ZM2.062 12c0-5.488 4.45-9.938 9.938-9.938s9.938 4.45 9.938 9.938-4.45 9.938-9.938 9.938S2.062 17.488 2.062 12Z"
      clipRule="evenodd"
    />
    <path d="M9.278 10.04a1.27 1.27 0 1 0 0-2.54 1.27 1.27 0 0 0 0 2.54ZM14.722 10.04a1.27 1.27 0 1 0 0-2.54 1.27 1.27 0 0 0 0 2.54Z" />
    <path
      fillRule="evenodd"
      d="M6.375 12.182H8.19l.015.165a3.81 3.81 0 0 0 7.59 0l.014-.165h1.816l-.013.193a5.625 5.625 0 0 1-11.224 0l-.013-.193Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(SmileOutline);
export default Memo;
