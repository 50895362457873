import React from 'react';

import { styled } from '../../stitches.config';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';

const PU_STITCHES_BUG_OVERRIDE = 'underline !important';

const StyledButton = styled(Button, {
  display: 'inline-flex',
  alignItems: 'center',
  position: 'relative',
  textDecoration: PU_STITCHES_BUG_OVERRIDE,

  focusVisible: '$focus',
});

const ChevronWrapper = styled('span', {
  marginLeft: '$space2',
  fill: 'currentColor',
  transform: 'rotate(-180deg)',
  defaultTransition: ['transform'],

  variants: {
    variant: {
      up: {},
      down: {
        transform: 'rotate(0)',
      },
    },
  },

  defaultVariants: {
    variant: 'down',
  },
});

interface DropdownMenuButtonProps extends ButtonProps {
  'data-state'?: 'open' | 'closed'; // comes from Radix DropdownMenu.Trigger
}

// This function maps the button size to the correct icon size
const getIconSize = (size?: string | {}) => {
  switch (size) {
    case 'small':
      return 'extraSmall';
    case 'medium':
      return 'small';
    case 'large':
      return 'medium';
    default:
      return 'small';
  }
};

export const DropdownMenuButton = React.forwardRef<HTMLButtonElement, DropdownMenuButtonProps>(
  (props, ref) => {
    const { children, 'data-state': dataState = 'closed', css, size = 'medium', ...rest } = props;

    return (
      <StyledButton
        variant="subdued"
        css={css}
        ref={ref}
        data-state={dataState}
        size={size}
        {...rest}
      >
        {children}
        <ChevronWrapper variant={dataState === 'open' ? 'up' : 'down'}>
          <Icon
            css={{ display: 'block' }}
            name="ChevronDown"
            size={getIconSize(size)}
            description={dataState === 'open' ? 'Arrow pointing up' : 'Arrow pointing down'}
          />
        </ChevronWrapper>
      </StyledButton>
    );
  }
);
