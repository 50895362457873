/**
 * Placeholder components which are never directly rendered. Used to define "slots" in the parent component's children.
 */

interface TabTransformerProps {
  value: string;
  disabled?: boolean;
}

export const TabTransformerSlot: React.FC<React.PropsWithChildren<TabTransformerProps>> = () => {
  throw new Error('TabGroupTransformer.Tab must be used within TabGroupTransformer.List');
};

interface TabPanelTransformerProps {
  value: string;
}

export const TabPanelTransformerSlot: React.FC<
  React.PropsWithChildren<TabPanelTransformerProps>
> = () => {
  throw new Error('TabGroupTransformer.Panel must be used within TabGroupTransformer');
};

export const TabListTransformerSlot: React.FC<React.PropsWithChildren<unknown>> = () => {
  throw new Error('TabGroupTransformer.List must be used within TabGroupTransformer');
};
