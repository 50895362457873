/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Dollar = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M16.875 15.15c0-1.95-1.05-3.15-3.6-4.125L11.85 10.5c-1.35-.525-2.025-1.05-2.025-2.025 0-1.05.825-1.65 2.4-1.65s2.85.6 4.05 1.575V5.85a7.673 7.673 0 0 0-3.15-1.275V3h-2.25v1.575c-2.4.375-3.75 1.875-3.75 4.05 0 1.875 1.05 3.15 3.3 3.975l1.5.6c1.65.675 2.1 1.125 2.1 2.1 0 1.2-.825 1.875-2.4 1.875-1.725 0-3.3-.75-4.575-1.8v2.55c1.125.825 2.475 1.35 3.75 1.5V21h2.25v-1.575c2.4-.45 3.825-2.025 3.825-4.275Z" />
  </svg>
);

const Memo = memo(Dollar);
export default Memo;
