import React, { useRef, FC, useEffect } from 'react';

import { PicnicCss } from '../..';
import { Box } from '../Box';
import { LoadingIndicator } from '../LoadingIndicator';

interface ContinuousScrollProps {
  onLoadMore: () => void;
  isLoading: boolean;
  hasMore: boolean;
  direction?: 'vertical' | 'horizontal';
  threshold?: 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9 | 1;
  css?: PicnicCss;
}

export const ContinuousScroll: FC<React.PropsWithChildren<ContinuousScrollProps>> = ({
  onLoadMore,
  isLoading,
  hasMore,
  threshold = 0.1,
  direction = 'vertical',
  css,
  children,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const verticalScroll = direction === 'vertical';

  useEffect(() => {
    const scrollRoot = scrollRef.current;
    const targetRoot = targetRef.current;

    if (!scrollRoot) {
      return;
    }

    const rootMargin = verticalScroll
      ? scrollRoot.getBoundingClientRect().bottom * threshold
      : scrollRoot.getBoundingClientRect().right * threshold;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore && !isLoading) {
          onLoadMore();
        }
      },
      {
        root: scrollRoot,
        rootMargin: `${rootMargin.toString()}px`,
      }
    );
    if (targetRoot) {
      observer.observe(targetRoot);
      return () => observer.unobserve(targetRoot);
    }
  }, [hasMore, isLoading, onLoadMore, threshold, scrollRef, targetRef, verticalScroll]);

  return (
    <Box
      ref={scrollRef}
      css={{
        ...css,
        overflow: 'auto',
        display: 'flex',
        flexDirection: verticalScroll ? 'column' : 'row',
      }}
      data-testid="continuous scroll area"
      tabIndex={0}
    >
      {children}
      <Box ref={targetRef}>
        {isLoading && (
          <Box
            css={{
              height: verticalScroll ? 40 : 'unset',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '$space2 0',
            }}
          >
            <LoadingIndicator
              css={{
                writingMode: verticalScroll ? 'unset' : 'vertical-rl',
                textOrientation: verticalScroll ? 'unset' : 'mixed',
                height: verticalScroll ? 'inherit' : 'fit-content',
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
