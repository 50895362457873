/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const FrownOutline = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M12 3.938a8.063 8.063 0 1 0 0 16.125 8.063 8.063 0 0 0 0-16.125ZM2.062 12c0-5.488 4.45-9.937 9.938-9.937 5.489 0 9.938 4.449 9.938 9.937 0 5.489-4.45 9.938-9.938 9.938S2.062 17.488 2.062 12Z"
      clipRule="evenodd"
    />
    <path d="M9.279 10.04a1.27 1.27 0 1 0 0-2.54 1.27 1.27 0 0 0 0 2.54ZM14.722 10.04a1.27 1.27 0 1 0 0-2.54 1.27 1.27 0 0 0 0 2.54Z" />
    <path
      fillRule="evenodd"
      d="M17.513 16.5h-1.871a3.812 3.812 0 0 0-7.283 0H6.488a5.627 5.627 0 0 1 11.025 0Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(FrownOutline);
export default Memo;
