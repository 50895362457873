/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const DropdownSelect = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M5.25 2.25h16.5v16.5H5.25V2.25Zm3.197 6.302L9.553 7.54l3.947 4.306 3.947-4.306 1.106 1.013-5.053 5.513-5.053-5.513Z"
      clipRule="evenodd"
    />
    <path d="M4 4.5V20h15.5v2H2V4.5h2Z" />
  </svg>
);

const Memo = memo(DropdownSelect);
export default Memo;
