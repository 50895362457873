import {
  enterSneakPreview,
  inSneakPreview,
  sneakPreviewCommitSha,
} from '@attentive/sneak-preview-utils/sneak-preview';

import { attentiveAuthClient } from './AttentiveAuthClient';
import { Routes } from './constants';
import { AuthRedirecting } from './errors';

interface AuthRedirectMetadata {
  state: string;
  redirectPath?: string;
  sneakPreviewCommitSha?: string;
  externalCompanyId?: string;
}

const displayError = (location: Location, message: string): never => {
  const redirectPath = inSneakPreview(location.pathname)
    ? `/sneak-preview/${sneakPreviewCommitSha(location.pathname)}${Routes.Signin}`
    : Routes.Signin;
  const url = new URL(redirectPath, location.origin);
  url.search = location.search;
  url.searchParams.append('error', message);
  location.assign(url.toString());
  throw new AuthRedirecting();
};

export const getRedirectViaSSO = (state: string) => {
  const authRedirectMetadataStringified: string | null =
    sessionStorage.getItem('AUTH_REDIRECT_METADATA');
  if (authRedirectMetadataStringified === null) {
    return displayError(location, 'State is invalid');
  }

  // We compare the state query parameter with the state that we generated via the loginWithSSO
  // in the AttentiveAuthClient. If the states do not match, we reject the request
  // https://auth0.com/docs/protocols/state-parameters#set-and-compare-state-parameter-values
  const authRedirectMetadata: AuthRedirectMetadata = JSON.parse(authRedirectMetadataStringified);
  if (authRedirectMetadata.state !== state) {
    return displayError(location, 'State does not match');
  }
  return authRedirectMetadata.redirectPath || Routes.Root;
};

export const getCommitSha = () => {
  const authRedirectMetadataStringified: string | null =
    sessionStorage.getItem('AUTH_REDIRECT_METADATA');
  if (authRedirectMetadataStringified === null) {
    return '';
  }
  const authRedirectMetadata: AuthRedirectMetadata = JSON.parse(authRedirectMetadataStringified);
  return authRedirectMetadata.sneakPreviewCommitSha || '';
};

export const getRedirectViaLoginWithPassword = (redir: string | null) => {
  return redir || Routes.Root;
};

export const getRedirectUrl = (qs: URLSearchParams) => {
  if (qs.get('state')) {
    const state: string = qs.get('state') || '';
    return getRedirectViaSSO(state);
  } else if (qs.get('redir')) {
    return getRedirectViaLoginWithPassword(qs.get('redir'));
  }
  return Routes.Root;
};

export const processGoogleCallback = async (location: Location) => {
  const params = new URLSearchParams(location.hash.substring(1));
  const state = params.get('state') || '';
  const commitSha = getCommitSha();

  if (commitSha && !inSneakPreview(location.pathname)) {
    enterSneakPreview(location, commitSha);
    throw new AuthRedirecting();
  }

  const token = params.get('id_token');
  if (!token) {
    return displayError(location, 'Missing auth token from request');
  }
  const redir = getRedirectViaSSO(state);
  // we clear the AUTH_REDIRECT_METADATA for subsequent logins
  sessionStorage.removeItem('AUTH_REDIRECT_METADATA');
  try {
    await attentiveAuthClient.loginWithGoogleToken(token, redir);
  } catch (error) {
    return displayError(location, error.message);
  }
  throw new AuthRedirecting();
};

export const processSsoCallback = async (location: Location) => {
  const params = new URLSearchParams(location.search);
  const state = params.get('state') || '';
  const commitSha = getCommitSha();

  if (commitSha && !inSneakPreview(location.pathname)) {
    enterSneakPreview(location, commitSha);
    throw new AuthRedirecting();
  }

  const token = params.get('access_token');
  if (!token) {
    return displayError(location, 'Missing auth token from request');
  }

  const redirectPath = getRedirectViaSSO(state);
  // we clear the AUTH_REDIRECT_METADATA for subsequent logins
  sessionStorage.removeItem('AUTH_REDIRECT_METADATA');
  attentiveAuthClient.processSsoCallback(token, redirectPath);
  throw new AuthRedirecting();
};
