import React from 'react';

function compositeComponent<
  T extends React.ComponentType<React.PropsWithChildren<React.ComponentProps<T>>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  U extends Record<string, React.ComponentType<React.PropsWithChildren<any>>>
>(component: T, children: U): T & U {
  const namespace = Object.assign(component, children);

  Object.keys(children).forEach((child) => {
    namespace[child].displayName = `${component.displayName}.${child}`;
  });

  return namespace;
}

export { compositeComponent };
