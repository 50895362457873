import React from 'react';
import { FormField, Checkbox, Box } from '@attentive/picnic';

interface Props {
  onChange: (checked: boolean | 'indeterminate') => void;
  value: boolean;
}

export const RememberDeviceCheck = ({ onChange, value }: Props) => {
  return (
    <Checkbox checked={value} onChange={onChange} css={{ mt: '$space2' }}>
      <FormField.HelperText>
        <b>Trust this device for 10 days.</b>
        <Box>Select only if this is not a shared device.</Box>
      </FormField.HelperText>
    </Checkbox>
  );
};
