/**
 * @generated SignedSource<<b9ffe0be7add8761d352c3ac6e6a569a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type VerifyMfaOneTimePasscodeInput = {
  mfaToken: string;
  oneTimePasscode: string;
  rememberDevice?: boolean | null;
};
export type codeVerificationHooksVerifyMfaOneTimePasscodeMutation$variables = {
  input: VerifyMfaOneTimePasscodeInput;
};
export type codeVerificationHooksVerifyMfaOneTimePasscodeMutation$data = {
  readonly verifyMfaOneTimePasscode: {
    readonly deviceId: string | null;
    readonly error: string | null;
    readonly jwt: string | null;
  } | null;
};
export type codeVerificationHooksVerifyMfaOneTimePasscodeMutation = {
  response: codeVerificationHooksVerifyMfaOneTimePasscodeMutation$data;
  variables: codeVerificationHooksVerifyMfaOneTimePasscodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VerifyMfaOneTimePasscodePayload",
    "kind": "LinkedField",
    "name": "verifyMfaOneTimePasscode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jwt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "codeVerificationHooksVerifyMfaOneTimePasscodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "codeVerificationHooksVerifyMfaOneTimePasscodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d928ed28ca5e6f5b9efcaa75bda29afa",
    "id": null,
    "metadata": {},
    "name": "codeVerificationHooksVerifyMfaOneTimePasscodeMutation",
    "operationKind": "mutation",
    "text": "mutation codeVerificationHooksVerifyMfaOneTimePasscodeMutation(\n  $input: VerifyMfaOneTimePasscodeInput!\n) {\n  verifyMfaOneTimePasscode(input: $input) {\n    jwt\n    error\n    deviceId\n  }\n}\n"
  }
};
})();

(node as any).hash = "f2eb5275cde6f69c3355b980e19656f9";

export default node;
