/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ChevronEnd = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20.4 21.6V2.4H18v19.2h2.4ZM3.075 4.57l1.65-1.575 9 9-9 9-1.65-1.575 7.424-7.425L3.075 4.57Z" />
  </svg>
);

const Memo = memo(ChevronEnd);
export default Memo;
