import { API, Response } from '@attentive/acore-utils';

export interface CreateAuthorizationCodeResponseDto {
  state: string;
  code: string;
}

export interface CreateAuthorizationCodeRequestDto {
  responseType: 'code';
  clientId: string;
  redirectUri: string;
  state: string;
  entityType: 'user';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorMessage(response: Response<any>): string {
  const { body, bodyText } = response;
  if (body && body.message) {
    if (body.error) {
      return `${body.error}: ${body.message}`;
    }
    return body.message;
  }
  return bodyText;
}

const AUTHORIZATION_CODES_ENDPOINT = '/authorization-codes';

export const createAuthorizationCode = async (
  clientId: string,
  redirectUri: string,
  state: string
) => {
  const apiPayload: CreateAuthorizationCodeRequestDto = {
    responseType: 'code',
    clientId,
    redirectUri,
    state,
    entityType: 'user',
  };

  // GMRU: POST /authorization-codes
  const response = await API.post<CreateAuthorizationCodeResponseDto>(
    AUTHORIZATION_CODES_ENDPOINT,
    apiPayload
  );
  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
};
