/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Delete = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="m12 10.157-6.581-6.58L3.576 5.42l6.58 6.58-6.58 6.58 1.844 1.844 6.58-6.58 6.58 6.58 1.843-1.843-6.58-6.58 6.581-6.582-1.843-1.843-6.582 6.581ZM10.58 12 4 5.42 10.58 12Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(Delete);
export default Memo;
