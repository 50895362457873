export enum ApiDataSource {
  Real = 'real',
  Mock = 'mock',
}

export const isMockDataSource = (dataSource: ApiDataSource | undefined) => {
  if (typeof process !== 'undefined') {
    return (
      process.env.API_DATA_SOURCE === ApiDataSource.Mock ||
      process.env.STORYBOOK_API_DATA_SOURCE === ApiDataSource.Mock ||
      dataSource === ApiDataSource.Mock
    );
  }
  return (
    import.meta.env.API_DATA_SOURCE === ApiDataSource.Mock ||
    import.meta.env.STORYBOOK_API_DATA_SOURCE === ApiDataSource.Mock ||
    dataSource === ApiDataSource.Mock
  );
};
