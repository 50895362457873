import { useField } from 'formik';
import React from 'react';

import { DatePicker as StyledDatePicker } from '../DatePicker';

import { useFormFieldIsErrorShowing, useFormFieldName } from './FormField';
import { PropsWithoutField } from './utils';

type Props = PropsWithoutField<typeof StyledDatePicker>;

const DatePicker: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const name = useFormFieldName();
  const isErrorShowing = useFormFieldIsErrorShowing();
  const [field, , { setValue, setTouched }] = useField(name);

  return (
    <StyledDatePicker
      {...props}
      {...field}
      state={isErrorShowing ? 'error' : 'normal'}
      onChange={setValue}
      onOpenChange={(isOpen) => {
        // We want to wait until the date picker is closed to mark the input
        // as touched, otherwise it'll validate as soon as the user opens it.
        if (!isOpen) {
          setTouched(true);
        }
      }}
    />
  );
};

export { DatePicker };
