import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <g fill="#25b3e2" fillRule="evenodd">
        <path
          d="M4.9,7.1c-1,0-1.9,0.3-2.7,0.8c-0.8,0.5-1.4,1.3-1.8,2.2C0,11-0.1,12,0.1,13c0.2,0.9,0.7,1.8,1.3,2.5
	c0.7,0.7,1.6,1.1,2.5,1.3c0.9,0.2,1.9,0.1,2.8-0.3c0.9-0.4,1.7-1,2.2-1.8C9.5,13.9,9.8,13,9.8,12c0-1.3-0.5-2.5-1.4-3.5
	C7.4,7.6,6.2,7.1,4.9,7.1L4.9,7.1z M4.9,14.6c-0.5,0-1-0.2-1.4-0.4c-0.4-0.3-0.8-0.7-1-1.2c-0.2-0.5-0.2-1-0.1-1.5
	c0.1-0.5,0.3-1,0.7-1.3c0.4-0.4,0.8-0.6,1.3-0.7c0.5-0.1,1,0,1.5,0.1c0.5,0.2,0.9,0.5,1.2,0.9C7.3,11,7.5,11.5,7.5,12
	c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.6-0.6,0.8c-0.2,0.2-0.5,0.4-0.8,0.6C5.6,14.5,5.2,14.6,4.9,14.6L4.9,14.6z"
        />
        <path
          d="M19.1,7.1c-1,0-1.9,0.3-2.7,0.8c-0.8,0.5-1.4,1.3-1.8,2.2C14.2,11,14.1,12,14.3,13c0.2,0.9,0.7,1.8,1.3,2.5
	c0.7,0.7,1.6,1.1,2.5,1.3c0.9,0.2,1.9,0.1,2.8-0.3c0.9-0.4,1.7-1,2.2-1.8C23.7,13.9,24,13,24,12c0-1.3-0.5-2.5-1.4-3.5
	C21.6,7.6,20.4,7.1,19.1,7.1L19.1,7.1z M19.1,14.6c-0.5,0-1-0.2-1.4-0.4c-0.4-0.3-0.8-0.7-0.9-1.2c-0.2-0.5-0.2-1-0.1-1.5
	c0.1-0.5,0.3-1,0.7-1.3c0.4-0.4,0.8-0.6,1.3-0.7c0.5-0.1,1,0,1.5,0.1c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.4,0.9,0.4,1.4
	c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.6-0.6,0.8c-0.2,0.2-0.5,0.4-0.8,0.6C19.8,14.5,19.4,14.6,19.1,14.6L19.1,14.6z"
        />
        <path d="M13.2,4.6h-2.3v14.7h2.3V4.6z" />
      </g>
    </svg>
  );
};

export const OloColor = React.memo(Icon);
