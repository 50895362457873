import { createContext, useContext } from 'react';
import { AnyObjectSchema } from 'yup';

const FormValidationContext = createContext<AnyObjectSchema | null>(null);
const FormValidationProvider = FormValidationContext.Provider;
const useFormValidationSchema = () => {
  const validationSchema = useContext(FormValidationContext);
  return validationSchema;
};

export { FormValidationProvider, useFormValidationSchema };
