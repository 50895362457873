import * as ProgressPrimitive from '@radix-ui/react-progress';
import React from 'react';

import { styled } from '../../stitches.config';

const StyledProgress = styled(ProgressPrimitive.Root, {
  position: 'relative',
  overflow: 'hidden',
  background: '$bgAccent',
  borderRadius: '20px',
  width: '100%',
  height: '$size3',
  boxShadow: '0px 0px 0px 1px inset $colors$borderDefault',
});

const StyledIndicator = styled(ProgressPrimitive.Indicator, {
  borderRadius: '20px',
  width: '100%',
  height: '100%',
  transition: 'transform 250ms cubic-bezier(0.65, 0, 0.35, 1)',
  variants: {
    variant: {
      success: {
        backgroundColor: '$iconSuccess',
      },
      warning: {
        backgroundColor: '$bgWarningAccent',
      },
      error: {
        backgroundColor: '$iconCritical',
      },
    },
  },
});

export type ProgressBarProps = {
  total: number;
  value: number;
  variant?: 'success' | 'warning' | 'error';
};

export const ProgressBar = ({ total, value, variant = 'success' }: ProgressBarProps) => {
  const progressPercentage = Math.min((value / total) * 100, 100);

  return (
    <StyledProgress max={total} value={value}>
      <StyledIndicator
        variant={variant}
        style={{
          transform: `translateX(-${100 - progressPercentage}%)`,
        }}
      />
    </StyledProgress>
  );
};
