/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SendStrikethrough = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.99 4.57 3.57 20.99V21L2 19.43l2.01-2.01 1.17-2.64 1.84-.37 2.26-2.26-3.86-.83-3.24-7.25 10.77 4.41L18.43 3 20 4.57h-.01ZM9.01 18.7l8.4-8.39 3.97 1.63v1.69L9.01 18.7Z" />
  </svg>
);

const Memo = memo(SendStrikethrough);
export default Memo;
