export { Persona, findPersona, rolesForPersona, mockedScopesForRoles } from './personas';
export type { PersonaType } from './personas';
import { graphql } from 'msw';
export { createAccount, createConnection, createNode, createNodes } from './mock-data-generators';
export {
  mswHeaderPrefix,
  storeMSWHeaders,
  parseMSWHeaders,
  addMSWHeaders,
  extractRequestPersona,
  MSW_STORAGE_KEY,
  MSW_DELAY_HEADER,
  MSW_PERSONA_AUTHORIZATION_HEADER,
  MSW_BEARER_TOKEN_PREFIX,
} from './msw-utils';
export { resolveTestQuery } from './relay-utils';
export type {
  GQLTypeNames,
  GQLConnectionTypeNames,
  GraphQLTypeProperties,
  GraphQLNode,
  MockConnection,
  RefetchableNodeResponse,
} from './types';
/**
 * @deprecated You should create mocks using mswGraphl
 * mocks would be provided automatically by mock-data
 * more details in the @attentive/mock-data Readme
 */
export const mswGraphql = graphql;
export { rest as mswRest } from 'msw';
export type {
  MockedRequest as MSWRequest,
  RestHandler as MSWRestHandler,
  GraphQLHandler as MSWGraphQLHandler,
  GraphQLRequest as MSWGraphQLRequest,
} from 'msw';

export {
  getDataOverride,
  MockDataOverrideProvider,
  MockDataOverrideDecorator,
} from './server/dataOverrideStore';
export { store as mockDataStore } from './server/store';
export { setupMockHandlers } from './server/setup-mock-handlers';
