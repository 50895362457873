import React from 'react';

import { styled } from '../../stitches.config';
import { Box } from '../Box';
import { Heading } from '../Heading';
import { Icon, IconName, ThirdPartyIcon, ThirdPartyIconName } from '../Icon';
import { Text } from '../Text';

import { PublicSelectGroup, PublicSelectItem, SelectItem } from './StyledSelectComponents';
import {
  IconNameOptions,
  MultiSelectItemsListProps,
  SelectItemsListProps,
  SelectItemTypes,
} from './types';

const iconCss = { marginRight: '$space2', flexShrink: 0 };

// This is largely duplicated from the Checkbox component. Downshift provides click-handling for items so we only need an icon to indicate if the item has been selected.
// Using Checkbox with downshift causes multiple state changes to occur on a single click.
const StyledCheckbox = styled('div', {
  appearance: 'none',
  backgroundColor: 'transparent',
  border: '2px solid transparent',
  padding: 0,
  flexShrink: 0,
  width: '$size5',
  height: '$size5',
  borderRadius: '$radius1',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  cursor: 'pointer',
  marginRight: '$space2',

  variants: {
    disabledVisually: {
      false: {
        focusVisible: '$focus',

        '&:hover': {
          borderColor: '$borderInputHover',
        },
      },
      true: {
        cursor: 'not-allowed',
      },
    },
    checkedVisually: {
      false: {},
      true: {
        color: '$iconInverted',
        borderWidth: '0',
      },
    },
  },

  compoundVariants: [
    {
      disabledVisually: 'false',
      checkedVisually: 'false',
      css: {
        borderColor: '$borderInput',
        backgroundColor: '$bgDefault',
      },
    },
    {
      disabledVisually: 'false',
      checkedVisually: 'true',
      css: {
        borderColor: '$borderInputHover',
        backgroundColor: '$bgToggleSelected',
      },
    },
    {
      disabledVisually: 'true',
      checkedVisually: 'false',
      css: {
        borderColor: '$borderDefault',
        backgroundColor: '$bgAccent',
        color: '$iconDisabled',
      },
    },
    {
      disabledVisually: 'true',
      checkedVisually: 'true',
      css: {
        borderColor: '$borderDefault',
        backgroundColor: '$bgAccent',
        color: '$iconDisabled',
      },
    },
  ],

  defaultVariants: {
    disabledVisually: 'false',
    checkedVisually: 'false',
  },
});

const CheckboxIcon = ({
  disabled,
  checked,
  name,
  ...rest
}: {
  disabled?: boolean;
  checked: boolean;
  name: string;
}) => {
  return (
    <StyledCheckbox
      checkedVisually={checked ? 'true' : 'false'}
      aria-checked={checked ? 'true' : 'false'}
      aria-label={name}
      disabledVisually={disabled ? 'true' : 'false'}
      role="checkbox"
      {...rest}
    >
      {checked && <Icon name="Checkmark" size="small" css={{ display: 'block' }} />}
    </StyledCheckbox>
  );
};

const renderIcon = (name?: IconNameOptions, type?: SelectItemTypes) => {
  if (type === SelectItemTypes.ICON) {
    return <Icon name={name as IconName} size="medium" css={iconCss} />;
  }

  if (type === SelectItemTypes.THIRD_PARTY_ICON) {
    return <ThirdPartyIcon name={name as ThirdPartyIconName} size="medium" css={iconCss} />;
  }

  return null;
};

export const SelectItemsList: React.FC<React.PropsWithChildren<SelectItemsListProps>> = ({
  children,
  highlightedIndex,
  selectedItem,
  getItemProps,
  itemsLookup,
  size,
}) => {
  const textVariant = size === 'medium' ? 'body' : 'caption';
  const childNodes = React.Children.toArray(children).reduce((acc: React.ReactNode[], child) => {
    const filteredItem = React.isValidElement(child) && itemsLookup[child.props.value];
    if (filteredItem) {
      const { item, index: itemIndex } = filteredItem;
      const icon = renderIcon(item.name, item.type);

      acc.push(
        <SelectItem
          key={`${item.value}`}
          highlighted={highlightedIndex === itemIndex}
          selected={selectedItem?.value === item.value}
          {...getItemProps({ item, index: itemIndex, disabled: item.disabled })}
          disabledVisually={item.disabled}
          disabled={item.disabled}
          aria-disabled={item.disabled}
          css={item.css}
        >
          {icon}
          <Text variant={textVariant} verticalAlignment="center" as="span">
            {item.label}
          </Text>
        </SelectItem>
      );
    } else if (React.isValidElement(child) && child.props.children && child.props.label) {
      const options = React.Children.toArray(child.props.children)
        .map((groupChild) => {
          const filteredGroupChild =
            React.isValidElement(groupChild) && itemsLookup[groupChild.props.value];
          if (filteredGroupChild) {
            const { item, index: itemIndex } = filteredGroupChild;
            const icon = renderIcon(item.name, item.type);

            return (
              <SelectItem
                key={`${item.value}`}
                highlighted={highlightedIndex === itemIndex}
                selected={selectedItem?.value === item.value}
                {...getItemProps({ item, index: itemIndex, disabled: item.disabled })}
                disabledVisually={item.disabled}
                disabled={item.disabled}
                aria-disabled={item.disabled}
                css={item.css}
              >
                {icon}
                <Text variant={textVariant} verticalAlignment="center" as="span">
                  {item.label}
                </Text>
              </SelectItem>
            );
          }
          return undefined;
        })
        .filter(Boolean);

      acc.push(
        <Box key={child.props.label} as="ul" css={{ display: 'contents' }}>
          <Heading
            as="li"
            role="group"
            variant="subheading"
            css={{
              color: '$textSubdued',
              paddingBottom: '$space1',
              padding: '$space3',
              textTransform: 'uppercase',
              ...child.props.css,
            }}
          >
            {child.props.label}
          </Heading>
          {options}
        </Box>
      );
    } else {
      acc.push(child);
    }

    return acc;
  }, []);
  // Without this fragment, we have to return an extraneous div
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{childNodes}</>;
};

export const MultiSelectItemsList: React.FC<React.PropsWithChildren<MultiSelectItemsListProps>> = ({
  children,
  highlightedIndex,
  value,
  getItemProps,
  itemsLookup,
  size,
}) => {
  const textVariant = size === 'medium' ? 'body' : 'caption';
  const selectedValues = new Set(value);
  const childNodes = React.Children.toArray(children).reduce((acc: React.ReactNode[], child) => {
    const filteredItem = React.isValidElement(child) && itemsLookup[child.props.value];
    if (filteredItem) {
      const { item, index: itemIndex } = filteredItem;
      acc.push(
        <SelectItem
          key={item.value}
          selected={highlightedIndex === itemIndex}
          aria-label={item.label}
          disabledVisually={item.disabled}
          disabled={item.disabled}
          aria-disabled={item.disabled}
          css={item.css}
          {...getItemProps({ item, index: itemIndex, disabled: item.disabled })}
        >
          <CheckboxIcon
            disabled={item.disabled}
            checked={selectedValues.has(item.value as string)}
            name={item.label}
            data-testid={item.label}
          />
          <Text variant={textVariant}>{item.label}</Text>
        </SelectItem>
      );
    } else if (React.isValidElement(child) && child.props.children && child.props.label) {
      const options = React.Children.toArray(child.props.children)
        .map((groupChild) => {
          const filteredGroupChild =
            React.isValidElement(groupChild) && itemsLookup[groupChild.props.value];
          if (filteredGroupChild) {
            const { item, index: itemIndex } = filteredGroupChild;
            return (
              <SelectItem
                key={item.value}
                selected={highlightedIndex === itemIndex}
                aria-label={item.label}
                disabledVisually={item.disabled}
                disabled={item.disabled}
                aria-disabled={item.disabled}
                css={item.css}
                {...getItemProps({ item, index: itemIndex, disabled: item.disabled })}
              >
                <CheckboxIcon
                  disabled={item.disabled}
                  checked={selectedValues.has(item.value as string)}
                  name={item.label}
                  data-testid={item.label}
                />
                <Text variant={textVariant}>{item.label}</Text>
              </SelectItem>
            );
          }
          return undefined;
        })
        .filter(Boolean);

      acc.push(
        <Box
          data-testid={`optgroup-${child.props.label}`}
          key={child.props.label}
          as="ul"
          css={{ display: 'contents' }}
        >
          <Heading
            as="li"
            role="group"
            variant="subheading"
            css={{
              color: '$textSubdued',
              paddingBottom: '$space1',
              padding: '$space3',
              textTransform: 'uppercase',
              ...child.props.css,
            }}
          >
            {child.props.label}
          </Heading>
          {options}
        </Box>
      );
    } else if (
      React.isValidElement(child) &&
      child.type !== PublicSelectGroup &&
      child.type !== PublicSelectItem
    ) {
      acc.push(child);
    }

    return acc;
  }, []);
  // Without this fragment, we have to return an extraneous div
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{childNodes}</>;
};
