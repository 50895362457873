import React from 'react';

import { IconSvgProps } from '../iconProps';

const Icon = ({ title, ...props }: IconSvgProps) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      {title && <title>{title}</title>}
      <path
        fill="#F9A31A"
        fillRule="evenodd"
        d="M 24.444 11.085 C 24.444 4.9 18.81 -0.045 11.972 -0.045 C 5.048 -0.045 -0.493 4.9 -0.493 11.085 C -0.493 16.277 3.479 20.562 8.836 21.8 L 11.972 25.424 L 15.115 21.8 C 20.47 20.562 24.444 16.277 24.444 11.085 Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M 7.274 7.636 C 6.857 7.33 6.857 6.946 7.274 6.791 L 11.159 4.646 C 11.577 4.416 12.271 4.416 12.619 4.646 L 16.575 6.791 C 16.991 7.022 16.991 7.407 16.575 7.636 L 12.686 9.784 C 12.271 10.014 11.577 10.014 11.23 9.784 L 7.274 7.636 Z M 12.202 11.857 C 12.202 11.396 12.551 10.704 12.965 10.473 L 17.061 8.248 C 17.48 8.021 17.824 8.248 17.824 8.786 L 17.824 13.082 C 17.824 13.541 17.48 14.233 17.061 14.464 L 12.965 16.611 C 12.551 16.842 12.202 16.611 12.202 16.073 L 12.202 11.857 Z M 6.648 14.464 C 6.234 14.233 5.885 13.617 5.885 13.082 L 5.885 8.786 C 5.885 8.326 6.234 8.021 6.648 8.248 L 10.671 10.473 C 11.089 10.704 11.437 11.315 11.437 11.857 L 11.437 16.151 C 11.437 16.611 11.089 16.916 10.671 16.685 L 6.648 14.464 Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const AftershipColor = React.memo(Icon);
