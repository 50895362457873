import React from 'react';

import { styled, VariantProps } from '../../stitches.config';
import { Icon, IconProps } from '../Icon';
import { IconColor } from '../Icon/StyledIconComponents';

export const Circle = styled('div', {
  flexShrink: 0,
  borderRadius: '$radiusMax',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    color: {
      default: {
        backgroundColor: '$bgInverted',
      },
      inverted: {
        backgroundColor: '$bgDefault',
      },
      brand: {
        backgroundColor: '$bgBrand',
      },
      success: {
        backgroundColor: '$bgSuccessDefault',
      },
      warning: {
        backgroundColor: '$bgWarningDefault',
      },
      critical: {
        backgroundColor: '$bgCriticalDefault',
      },
      decorative1: {
        backgroundColor: '$bgDecorative1Default',
      },
      decorative2: {
        backgroundColor: '$bgDecorative2Default',
      },
      decorative3: {
        backgroundColor: '$bgDecorative3Default',
      },
      decorative4: {
        backgroundColor: '$bgDecorative4Default',
      },
      disabled: {
        backgroundColor: '$bgPlaceholder',
      },
      magic: {
        backgroundImage: '$bgGradientMagic',
        backgroundColor: '$bgGradientMagicFallback',
      },
    },
    size: {
      extraSmall: {
        width: '$size6',
        height: '$size6',
      },
      small: {
        width: '$size8',
        height: '$size8',
      },
      medium: {
        width: '$size10',
        height: '$size10',
      },
      large: {
        width: '$size12',
        height: '$size12',
      },
    },
  },
});

type Size = VariantProps<typeof Circle>['size'];
// Using VariantProps will include the stitches responsive object type, but we can only support the plain strings right now
// as we map them to different string values for the internal Icon component.
type Color =
  | 'default'
  | 'inverted'
  | 'brand'
  | 'success'
  | 'warning'
  | 'critical'
  | 'decorative1'
  | 'decorative2'
  | 'decorative3'
  | 'decorative4'
  | 'disabled'
  | 'magic';

interface IconCircleProps extends React.ComponentProps<typeof Circle> {
  iconName: IconProps['name'];
  size?: Size;
  color?: Color;
}

const getIconColor = (color: Color): IconColor => {
  switch (color) {
    case 'default':
      return 'inverted';
    case 'inverted':
    case 'brand':
    case 'magic':
      return 'default';
    default:
      return color;
  }
};

export const IconCircle: React.VFC<IconCircleProps> = ({
  size = 'medium',
  color = 'default',
  iconName,
  ...rest
}) => {
  return (
    <Circle size={size} color={color} {...rest}>
      <Icon name={iconName} size={size} color={getIconColor(color)} />
    </Circle>
  );
};
