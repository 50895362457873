import { AuthSession } from '@attentive/acore-utils';

import { AuthClient, VerifySessionInput } from './AuthClient';
import { AuthError } from './errors';
import { buildLogoutRedirectUri } from './utils';

export class MockAuthClient implements AuthClient {
  public async verifySession(input: VerifySessionInput) {
    const token = AuthSession.retrieveToken();

    if (token === null) {
      throw new AuthError('Could not verify session', `Mock user logged out`, { statusCode: 401 });
    }

    return {
      companyId: input.companyId ?? 'MDc6Q29tcGFueTE',
      companyRestId: input.companyRestId || 1,
    };
  }

  public logout(logoutReason: string | null) {
    AuthSession.clearStorage();
    window.location.assign(buildLogoutRedirectUri(window.location, logoutReason));
  }
}

export const mockAuthClient = new MockAuthClient();
