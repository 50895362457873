import { UseSelectGetItemPropsOptions } from 'downshift';
import React from 'react';

import { PicnicCss } from '../../stitches.config';
import { ButtonProps } from '../Button';
import { IconName, ThirdPartyIconName } from '../Icon';

import { MultiSelectWrapper } from './StyledSelectComponents';

export enum SelectItemTypes {
  DEFAULT = 'default',
  ICON = 'icon',
  THIRD_PARTY_ICON = 'thirdPartyIcon',
}

export type IconNameOptions = IconName | ThirdPartyIconName;

export type MenuItemValueType = string | number;
export type MenuItem = {
  value: MenuItemValueType;
  label: string;
  disabled?: boolean;
  css?: PicnicCss;
  name?: IconNameOptions;
  type?: SelectItemTypes;
};
export type MenuItems = MenuItem[];

export type MappedChildren = React.ReactNode & {
  props: {
    value: MenuItemValueType;
    children: React.ReactNode;
  };
};

// Allowing `any` here as this matches the type spec in downshift library
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type getItemPropsType = (options: UseSelectGetItemPropsOptions<MenuItem>) => any;

export interface PublicSelectGroupProps {
  label: string;
  disabled?: boolean;
  css?: PicnicCss;
}

export interface PublicSelectItemProps {
  value: MenuItemValueType;
  disabled?: boolean;
  css?: PicnicCss;
  icon?: React.ReactNode;
}

export interface PublicSelectIconItemProps extends PublicSelectItemProps {
  name: IconName;
}

export interface PublicSelectThirdPartyIconItemProps extends PublicSelectItemProps {
  name: ThirdPartyIconName;
}

type SizeType = 'medium' | 'small';

export interface SelectProps extends Omit<ButtonProps, 'onChange' | 'variant'> {
  align?: 'start' | 'end';
  onChange:
    | ((value: MenuItemValueType) => void)
    | ((value: string) => void)
    | ((value: number) => void);
  state?: 'normal' | 'error';
  placeholder?: string;
  value?: MenuItemValueType;
  size?: SizeType;
  selectedLines?: 'one-line' | 'two-lines';
  onSearchTermChange?: (value: string) => void;
}

export type MultiSelectProps = {
  children: React.ReactNode;
  value:
    | string[]
    | number[]
    | MenuItemValueType[]
    | readonly string[]
    | readonly number[]
    | readonly MenuItemValueType[];
  onChange:
    | ((value: MenuItemValueType[]) => void)
    | ((value: number[]) => void)
    | ((value: string[]) => void);
  placeholder?: string;
  size?: React.ComponentProps<typeof MultiSelectWrapper>['size'];
  state?: React.ComponentProps<typeof MultiSelectWrapper>['state'];
  disabled?: boolean;
  css?: PicnicCss;
  maxTokens?: number;
  errors?: boolean[] | string[];
  className?: string;
};

export type SelectItemsListProps = {
  highlightedIndex: number;
  selectedItem: MenuItem;
  getItemProps: getItemPropsType;
  itemsLookup: Record<string, { item: MenuItem; index: number }>;
  size?: SizeType;
};

export type MultiSelectItemsListProps = {
  value: MenuItemValueType[] | readonly MenuItemValueType[];
  highlightedIndex: number;
  getItemProps: getItemPropsType;
  itemsLookup: Record<string, { item: MenuItem; index: number }>;
  size?: React.ComponentProps<typeof MultiSelectWrapper>['size'];
};
