import React from 'react';

import { styled, PicnicCss } from '../../stitches.config';
import { DisplayNamed } from '../../storybook';

export const textInputStyles: PicnicCss = {
  backgroundColor: '$bgDefault',
  border: '$borderWidths$borderWidth1 solid $borderInput',
  borderRadius: '$radius1',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  width: '100%',
  color: '$textDefault',
  lineHeight: '$lineHeight2',
  '&::placeholder': {
    color: '$textSubdued',
    opacity: 1,
  },
  focusVisible: '$inputFocus',
  '&:disabled': {
    color: '$textDisabled',
    borderColor: '$borderInputDisabled',
    cursor: 'not-allowed',
    '&::placeholder': {
      color: '$textDisabled',
    },
  },
  '&:hover:enabled:not(:focus-visible)': {
    borderColor: '$borderInputHover',
  },
};

const TextInputPrimitive = styled('input', {
  ...textInputStyles,

  variants: {
    state: {
      normal: { borderColor: '$borderInput' },
      error: { '&:not(:focus)': { borderColor: '$borderInputError' } },
    },
    size: {
      small: { minHeight: '$size9', fontSize: '$fontSize2', padding: '0 $space3' },
      normal: { minHeight: '$size12', fontSize: '$fontSize3', padding: '0 $space4' },
    },
  },
  defaultVariants: {
    state: 'normal',
    size: 'normal',
  },
});

type ComponentType = typeof TextInputPrimitive;
export type TextInputProps = React.ComponentProps<ComponentType>;

const TextInputComponent = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => (
  <TextInputPrimitive ref={ref} {...props} />
));

const TextInput = TextInputComponent as ComponentType & DisplayNamed;
TextInput.displayName = 'TextInput';

export { TextInput };
