import { useMemo, useEffect } from 'react';
import { useFragment } from 'react-relay';
import { v4 as uuidv4 } from 'uuid';
import { datadogRum } from '@datadog/browser-rum';
import { Role } from '@attentive/data';

import { LazyMfeKeys, AppKeys, LibNames, VendorNames } from '../types';
import { useProjectName } from './ProjectNameContext';
import DatadogUserAcoreUtilsFragment_query, {
  DatadogUserAcoreUtilsFragment_query$key,
  DatadogUserAcoreUtilsFragment_query$data,
} from './__generated__/DatadogUserAcoreUtilsFragment_query.graphql';

export const CLIENT_UI_ERROR_ID = uuidv4();

export type ProjectNames = LazyMfeKeys | AppKeys;

interface DatadogInitConfig {
  commitSha: string;
  env: string;
  restApiUrl: string;
  graphqlApiOrigin: string;
  appName: AppKeys;
  datadogApplicationId: string;
  datadogClientToken: string;
  appDocumentBuildTimestamp: string;
  loggingEnabled: boolean;
  sessionSampleRate: number;
}

let loggingEnabled = false;
let initializedDatadog = false;

export const SET_LOGGING_ENABLED_TEST_ONLY = () => {
  loggingEnabled = true;
};

export const initDatadog = ({
  commitSha,
  env,
  restApiUrl,
  graphqlApiOrigin,
  appName,
  datadogApplicationId,
  datadogClientToken,
  appDocumentBuildTimestamp,
  loggingEnabled: loggingEnabledParam,
  sessionSampleRate,
}: DatadogInitConfig) => {
  if (initializedDatadog) {
    throw new Error('Datadog has already been initialized');
  }

  initializedDatadog = true;
  loggingEnabled = loggingEnabledParam;

  if (loggingEnabledParam) {
    datadogRum.init({
      applicationId: datadogApplicationId,
      clientToken: datadogClientToken,
      site: 'datadoghq.com',
      service: appName,
      version: commitSha,
      env,
      sessionSampleRate,
      sessionReplaySampleRate: sessionSampleRate,
      // This is for compatibility with the feature flag
      // Otherwise DD will use the sample rate to determine
      // If replay should be enabled
      startSessionReplayRecordingManually: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'allow',
      trackResources: true,
      trackLongTasks: true,
      allowedTracingUrls: [
        { match: restApiUrl, propagatorTypes: ['datadog'] },
        { match: graphqlApiOrigin, propagatorTypes: ['datadog'] },
      ],
    });
    datadogRum.setGlobalContextProperty('appDocumentBuildTimestamp', appDocumentBuildTimestamp);
  }
};

export const addDatadogContext = (key: string, context: unknown) => {
  if (loggingEnabled) {
    datadogRum.setGlobalContextProperty(key, context);
  }
};

export const enableSessionReplay = () => {
  datadogRum.startSessionReplayRecording();
};

type DatadogUserContext = {
  id?: string;
  email?: string;
  roles?: Role[];
  company?: DatadogUserAcoreUtilsFragment_query$data['company'];
};

export const setDatadogUser = (user: DatadogUserContext) => {
  if (loggingEnabled) {
    datadogRum.setUser(user);
  }
};

// h/t to https://stackoverflow.com/a/48244432/16489094
// creates a partial type of type T, where one or more properties must be assigned
type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];
interface AllLoggingFacets {
  projectName: ProjectNames;
  libName: LibNames;
  vendorName: VendorNames;
}
type LoggingFacets = AtLeastOne<AllLoggingFacets>;

export enum Severity {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface ErrorDetails {
  /** A short, human-readable summary of the problem that SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization. */
  title?: string;
  /** A React component "stack trace", which is provided by react error boundaries */
  componentStack?: string;
  severity?: Severity;
  slotName?: string;
  resource?: string;
}

export interface StandardMutationErrorDetails {
  title: string;
  message: string;
  status: string;
  traceId?: string | null;
  resource?: string;
}

export enum DatadogContextErrorType {
  NETWORK = 'NETWORK',
  SERVER = 'SERVER',
  UI = 'UI',
  LIB = 'LIB',
  VENDOR = 'VENDOR',
}

interface DatadogNetworkContext {
  kind: DatadogContextErrorType.NETWORK;
  http: {
    resource: string;
  };
  details?: ErrorDetails;
}

interface DatadogServerContext {
  kind: DatadogContextErrorType.SERVER;
  http: {
    status: number;
    resource: string;
  };
  details?: ErrorDetails;
}

interface DatadogGqlContext {
  kind: DatadogContextErrorType.SERVER;
  gql: {
    code: string;
    resource: string;
  };
  details?: ErrorDetails;
}

interface DatadogLibContext {
  kind: DatadogContextErrorType.LIB;
  details?: ErrorDetails;
}

interface DatadogUIContext {
  kind: DatadogContextErrorType.UI;
  details?: ErrorDetails;
}

interface DatadogVendorContext {
  kind: DatadogContextErrorType.VENDOR;
  details?: ErrorDetails;
}

type DatadogContext =
  | DatadogNetworkContext
  | DatadogServerContext
  | DatadogGqlContext
  | DatadogLibContext
  | DatadogUIContext
  | DatadogVendorContext;

export const logDatadogError = (
  projectName: ProjectNames,
  err: Error,
  context?: { error: ErrorDetails }
) => {
  if (loggingEnabled) {
    let errorMessage = String(err);
    if (err instanceof Error && err.message) {
      errorMessage = err.message;
    }

    datadogRum.addError(err, {
      ...context,
      error: {
        severity: Severity.ERROR,
        kind: DatadogContextErrorType.UI,
        message: errorMessage,
        ...context?.error,
      },
      clientUiErrorId: CLIENT_UI_ERROR_ID,
      project_name: projectName,
      projectName,
      error_message: errorMessage,
    });
  }
};

const normalizeErrorMessage = (message: string | Error) => {
  return message instanceof Error ? message.message : message;
};

export class AttentiveError extends Error {
  public projectName: ProjectNames | null;
  public libName: LibNames | null;
  public vendorName: VendorNames | null;

  constructor(message: string | Error, loggingFacets: LoggingFacets) {
    super(normalizeErrorMessage(message));
    this.projectName = loggingFacets.projectName || null;
    this.libName = loggingFacets.libName || null;
    this.vendorName = loggingFacets.vendorName || null;
  }
}

export class NetworkError extends Error {
  public projectName: ProjectNames | null;
  public libName: LibNames | null;
  public vendorName: VendorNames | null;

  constructor(message: string | Error, public resource: string, loggingFacets?: LoggingFacets) {
    super(normalizeErrorMessage(message));
    this.name = 'NetworkError';
    this.projectName = loggingFacets?.projectName || null;
    this.libName = loggingFacets?.libName || null;
    this.vendorName = loggingFacets?.vendorName || null;
  }
}

export class ServerError extends Error {
  public projectName: ProjectNames | null;
  public libName: LibNames | null;
  public vendorName: VendorNames | null;

  constructor(
    message: string | Error,
    public status: number,
    public resource: string,
    loggingFacets?: LoggingFacets
  ) {
    super(normalizeErrorMessage(message));
    this.name = 'ServerError';
    this.projectName = loggingFacets?.projectName || null;
    this.libName = loggingFacets?.libName || null;
    this.vendorName = loggingFacets?.vendorName || null;
  }
}

export class LibError extends Error {
  public projectName: ProjectNames | null;
  public libName: LibNames;
  public vendorName: VendorNames | null;

  constructor(
    message: string | Error,
    libName: LibNames,
    loggingFacets?: Omit<LoggingFacets, 'libName'>
  ) {
    super(normalizeErrorMessage(message));
    this.name = 'LibError';
    this.projectName = loggingFacets?.projectName || null;
    this.libName = libName;
    this.vendorName = loggingFacets?.vendorName || null;
  }
}

export class UiError extends Error {
  public projectName: ProjectNames | null;
  public libName: LibNames | null;
  public vendorName: VendorNames | null;

  constructor(message: string | Error, loggingFacets?: LoggingFacets) {
    super(normalizeErrorMessage(message));
    this.name = 'UiError';
    this.projectName = loggingFacets?.projectName || null;
    this.libName = loggingFacets?.libName || null;
    this.vendorName = loggingFacets?.vendorName || null;
  }
}

export class StandardMutationError extends Error {
  public details: StandardMutationErrorDetails;
  public projectName: ProjectNames | null;
  public libName: LibNames | null;
  public vendorName: VendorNames | null;

  constructor(
    message: string,
    details: StandardMutationErrorDetails,
    loggingFacets?: LoggingFacets
  ) {
    super(normalizeErrorMessage(message));
    this.name = 'StandardMutationError';
    this.details = details;
    this.projectName = loggingFacets?.projectName || null;
    this.libName = loggingFacets?.libName || null;
    this.vendorName = loggingFacets?.vendorName || null;
  }
}

export class VendorError extends Error {
  public projectName: ProjectNames | null;
  public libName: LibNames | null;
  public vendorName: VendorNames;

  constructor(
    message: string | Error,
    vendorName: VendorNames,
    loggingFacets?: Omit<LoggingFacets, 'vendorName'>
  ) {
    super(normalizeErrorMessage(message));
    this.name = 'VendorError';
    this.projectName = loggingFacets?.projectName || null;
    this.libName = loggingFacets?.libName || null;
    this.vendorName = vendorName;
  }
}

type DatadogLoggerErrors = AttentiveError | NetworkError | ServerError | LibError | UiError | Error;

// TODO: remove `| ProjectNames` when https://attentivemobile.atlassian.net/browse/UP-989 is done
type DatadogConstructorOptions = LoggingFacets | ProjectNames;

/**
 * Logging for an MFE:
 *  `const logger = new DatadogLogger({ projectName: 'project-ui })`
 *
 * Logging for a library:
 *  `const logger = new DatadogLogger({ libName: 'lib-name' })
 *
 * Logging for a library in an MFE:
 *  Note: use the hook `useLibLogger` instead as it will know the name of the project it is rendered in
 *  `const logger = new DatadogLogger({ projectName: 'project-ui', libName: 'lib-name' })
 */

export class DatadogLogger {
  private projectName: ProjectNames | null;
  private libName: LibNames | null;

  /**
   * @param {LoggingFacets} options logging for an MFE: { projectName: 'project-ui' }. Logging in a lib: { libName: 'lib-name' }
   */
  constructor(options: DatadogConstructorOptions) {
    if (typeof options === 'string') {
      this.projectName = options;
      this.libName = null;
    } else {
      this.projectName = options.projectName || null;
      this.libName = options.libName || null;
    }
  }

  private logDatadogError = (err: DatadogLoggerErrors, context: DatadogContext) => {
    if (!loggingEnabled) {
      return;
    }

    const { kind, details = {}, ...ctx } = context;

    // Default to ERROR if no severity was specified
    if (!details.severity) {
      details.severity = Severity.ERROR;
    }

    let projectName = this.projectName;
    let libName = this.libName;
    let vendorName;
    // If there is a `projectName` on the error then we use that error's projectName
    if ('projectName' in err && err.projectName !== null) {
      projectName = err.projectName;
    }
    // If there is a `libName` on the error then we use that error's libName
    if ('libName' in err && err.libName !== null) {
      libName = err.libName;
    }
    // If there is a `vendorName` on the error then we use that error's vendorName
    if ('vendorName' in err && err.vendorName !== null) {
      vendorName = err.vendorName;
    }

    datadogRum.addError(err, {
      ...ctx,
      error: {
        ...details,
        kind,
        message: err.message,
      },
      clientUiErrorId: CLIENT_UI_ERROR_ID,
      projectName,
      libName,
      vendorName,
    });
  };

  public logStandardMutationError = (
    err: StandardMutationError,
    resource: string,
    details?: StandardMutationErrorDetails
  ) => {
    this.logDatadogError(err, {
      kind: DatadogContextErrorType.SERVER,
      gql: {
        code: err.details.status,
        resource,
      },
      details,
    });
  };

  public logNetworkError = (err: Error, resource: string, details?: ErrorDetails) => {
    this.logDatadogError(err, {
      kind: DatadogContextErrorType.NETWORK,
      http: {
        resource,
      },
      details,
    });
  };

  public logServerError = (
    err: Error,
    status: number,
    resource: string,
    details?: ErrorDetails
  ) => {
    this.logDatadogError(err, {
      kind: DatadogContextErrorType.SERVER,
      http: {
        status,
        resource,
      },
      details,
    });
  };

  public logUiError = (err: Error, details?: ErrorDetails) => {
    this.logDatadogError(err, {
      kind: DatadogContextErrorType.UI,
      details,
    });
  };

  public logLibError = (err: Error, details?: ErrorDetails) => {
    this.logDatadogError(err, {
      kind: DatadogContextErrorType.LIB,
      details,
    });
  };

  public logVendorError = (err: VendorError, details?: ErrorDetails) => {
    this.logDatadogError(err, {
      kind: DatadogContextErrorType.VENDOR,
      details,
    });
  };

  public logError = (err: Error, details?: ErrorDetails) => {
    if (err instanceof NetworkError) {
      this.logNetworkError(err, err.resource, details);
    } else if (err instanceof ServerError) {
      this.logServerError(err, err.status, err.resource, details);
    } else if (err instanceof LibError) {
      this.logLibError(err, details);
    } else if (err instanceof VendorError) {
      this.logVendorError(err, details);
    } else {
      this.logUiError(err, details);
    }
  };

  public logAction = (actionName: string, data?: object) => {
    if (!loggingEnabled) {
      return;
    }

    const projectName = this.projectName;
    const libName = this.libName;

    datadogRum.addAction(actionName, {
      ...data,
      projectName,
      libName,
    });
  };
}

export const useProjectLogger = () => {
  const projectName = useProjectName();

  return useMemo(() => {
    return new DatadogLogger({ projectName });
  }, [projectName]);
};

export const useLibLogger = (libName: LibNames) => {
  const projectName = useProjectName();

  return useMemo(() => {
    return new DatadogLogger({ projectName, libName });
  }, [libName, projectName]);
};

export const useSyncDatadogUserFragmentData = (
  queryData: DatadogUserAcoreUtilsFragment_query$key
) => {
  const data = useFragment<DatadogUserAcoreUtilsFragment_query$key>(
    DatadogUserAcoreUtilsFragment_query,
    queryData
  );

  useEffect(() => {
    setDatadogUser({
      id: data.viewer?.account?.id,
      email: data.viewer?.account?.email,
      roles: data.viewer?.roles?.roles as Role[],
      company: data.company,
    });
  }, [data]);
};
