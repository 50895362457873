/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const ArrowRightCircle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M21 12c0 4.95-4.05 9-9 9s-9-4.05-9-9 4.05-9 9-9 9 4.05 9 9Zm-9.05-4.8 4.854 4.8-4.854 4.8-1.15-1.137 2.892-2.86H7.2v-1.606h6.49l-2.89-2.86L11.95 7.2Z"
      clipRule="evenodd"
    />
  </svg>
);

const Memo = memo(ArrowRightCircle);
export default Memo;
