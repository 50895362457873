export const SNEAK_PREVIEW_STORAGE_KEY = 'magic-preview-link-sha';
const IN_SNEAK_PREVIEW_RE = /^\/(sneak-preview)\//;
const VALID_SNEAK_PREVIEW_RE = /^\/(sneak-preview)\/([a-zA-Z0-9]{7})\//;
const SNEAK_PREVIEW_REDIRECT = /\?redir=\/(sneak-preview)\/([a-zA-Z0-9]{7})/;

export const inSneakPreview = (url: string) => {
  return IN_SNEAK_PREVIEW_RE.test(url);
};

export const inSneakPreviewRedirect = (url: string) => {
  return SNEAK_PREVIEW_REDIRECT.test(url);
};

export const sneakPreviewPathName = (sneakPreviewUrl: string) => {
  const matches = sneakPreviewUrl.match(VALID_SNEAK_PREVIEW_RE);

  if (!matches) {
    throw new Error(`Unable to sneak-preview path segment from url: ${sneakPreviewUrl}`);
  }

  return matches[1];
};

export const sneakPreviewCommitSha = (sneakPreviewUrl: string): string => {
  const matches = sneakPreviewUrl.match(VALID_SNEAK_PREVIEW_RE);

  if (!matches) {
    throw new Error(`Unable to parse commit sha from url: ${sneakPreviewUrl}`);
  }

  return matches[2];
};

export const isResumingSneakPreview = () => {
  return Boolean(sessionStorage.getItem(SNEAK_PREVIEW_STORAGE_KEY));
};

export const sneakPreviewCommitShaInSessionStorage = () => {
  return sessionStorage.getItem(SNEAK_PREVIEW_STORAGE_KEY);
};

export const escapeFromSneakPreview = (location: Location) => {
  const matches = location.pathname.match(VALID_SNEAK_PREVIEW_RE);

  if (!matches) {
    location.assign('/');
    return;
  }

  const nonSneakPreviewUrl = location.href.replace(matches[0], '/');
  location.assign(nonSneakPreviewUrl);
};

export const enterSneakPreview = (location: Location, commitSha: string) => {
  if (VALID_SNEAK_PREVIEW_RE.test(location.pathname)) {
    throw new Error(`Nothing to do - the current URL ${location.href} is already a sneak preview.`);
  }
  const sneakPreviewPathname = `/sneak-preview/${commitSha}${location.pathname}`;

  location.pathname = sneakPreviewPathname;
};

export const redirectAppToUiDocsSneakPreview = (
  project: string,
  target: string,
  version: string,
  redir: string
) => {
  window.location.replace(
    `https://ui-docs.attentivemobile.com/sneak-preview/app/${project}/${target}/${version}?${new URLSearchParams(
      {
        redir,
      }
    )}`
  );
};
