/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Cart = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3 12h18l-2.25 9H5.25L3 12ZM18.375 8.25 15.75 3H13.5l2.625 5.25h-8.25L10.5 3H8.25L5.625 8.25H1.5v2.25h21V8.25h-4.125Z" />
  </svg>
);

const Memo = memo(Cart);
export default Memo;
