import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, { useEffect, useRef } from 'react';

import { styled, keyframes } from '../../stitches.config';
import { Optional } from '../../utils';
import { compositeComponent } from '../../utils/composite-component';
import { Box, BoxProps } from '../Box';
import { IconButton, IconButtonProps } from '../Button';

export const DRAWER_ANIMATION_INTERVAL_MS = 300;

const slideIn = keyframes({
  from: { transform: 'translate3d(100%,0,0)' },
  to: { transform: 'translate3d(0,0,0)' },
});

const slideOut = keyframes({
  from: { transform: 'translate3d(0,0,0)' },
  to: { transform: 'translate3d(100%,0,0)' },
});

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const fadeOut = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const StyledContent = styled(DialogPrimitive.Content, {
  zIndex: '$layer2',
  position: 'fixed',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '$bgDefault',
  width: 'fit-content',
  boxShadow: '$shadow4',
  willChange: 'transform',

  '&[data-state="open"]': {
    animation: `${slideIn} ${DRAWER_ANIMATION_INTERVAL_MS}ms ease-in-out`,
  },

  '&[data-state="closed"]': {
    animation: `${slideOut} ${DRAWER_ANIMATION_INTERVAL_MS}ms ease-in-out`,
  },

  '&:focus': {
    outline: 'none',
  },
});

const DrawerOverlay = styled(DialogPrimitive.Overlay, {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: '$layer2',
  position: 'fixed',

  '&[data-state="open"]': {
    animation: `${fadeIn} ${DRAWER_ANIMATION_INTERVAL_MS}ms ease-in-out`,
  },

  '&[data-state="closed"]': {
    animation: `${fadeOut} ${DRAWER_ANIMATION_INTERVAL_MS}ms ease-in-out`,
  },
});

const IncludeOverlayContext = React.createContext<boolean>(true);
const IncludeOverlayProvider = IncludeOverlayContext.Provider;
const useIncludeOverlay = () => {
  const includeOverlay = React.useContext(IncludeOverlayContext);
  return includeOverlay;
};

type CloseButtonProps = Omit<Optional<IconButtonProps, 'iconName' | 'description'>, 'as'>;

const CloseButton: React.VFC<CloseButtonProps> = ({
  size = 'small',
  variant = 'subdued',
  iconName = 'X',
  description = 'Close',
  ...rest
}) => (
  <DialogPrimitive.Close asChild>
    <IconButton
      description={description}
      iconName={iconName}
      size={size}
      variant={variant}
      {...rest}
    />
  </DialogPrimitive.Close>
);

export interface HeaderProps extends BoxProps {
  children: React.ReactNode;
}

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  children,
  css = {},
  ...props
}) => {
  return (
    <Box
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        ...css,
      }}
      {...props}
    >
      <DialogPrimitive.Title asChild>
        <Box css={{ alignSelf: 'center', overflow: 'hidden' }}>{children}</Box>
      </DialogPrimitive.Title>
      <CloseButton css={{ ml: '$space3' }} />
    </Box>
  );
};

export type ContentProps = BoxProps & DialogPrimitive.DialogContentProps;

const Content = React.forwardRef(({ children, css, ...rest }: ContentProps, forwardedRef) => {
  const includeOverlay = useIncludeOverlay();

  const content = (
    <StyledContent
      onInteractOutside={(e: Event) => {
        if (!includeOverlay) {
          e.preventDefault();
        }
      }}
      aria-describedby={undefined}
      css={css}
      ref={forwardedRef}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(rest as any)}
    >
      {children}
    </StyledContent>
  );

  return includeOverlay ? <DrawerOverlay>{content}</DrawerOverlay> : content;
});

const Trigger: React.FC<React.PropsWithChildren<{ children: React.ReactElement }>> = ({
  children,
}) => {
  return <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>;
};

export interface DrawerProps extends DialogPrimitive.DialogProps {
  children: React.ReactNode;
  includeOverlay?: boolean;
  onCloseFinish?: () => void;
}

const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = ({
  open,
  includeOverlay = true,
  onCloseFinish,
  children,
  ...props
}) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    if (!open && onCloseFinish) {
      const timeout = window.setTimeout(() => {
        onCloseFinish();
      }, DRAWER_ANIMATION_INTERVAL_MS);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <IncludeOverlayProvider value={includeOverlay}>
      <DialogPrimitive.Root open={open} modal={includeOverlay} {...props}>
        {children}
      </DialogPrimitive.Root>
    </IncludeOverlayProvider>
  );
};

const Namespace = compositeComponent(Drawer, {
  CloseButton,
  Content,
  Header,
  Trigger,
});

export { Namespace as Drawer };
