import { useField } from 'formik';
import React from 'react';

import { impersonatePicnicComponent } from '../../utils/impersonate-picnic-component';
import { FormField as StyledFormField } from '../FormField';

type Props = React.ComponentProps<typeof StyledFormField.ErrorText> & {
  name: string;
};

const ErrorText = impersonatePicnicComponent(({ name, ...rest }: Props) => {
  const [, meta] = useField(name);

  return meta.error ? (
    <StyledFormField.ErrorText {...rest}>{meta.error}</StyledFormField.ErrorText>
  ) : null;
}, StyledFormField.ErrorText);

export { ErrorText };
