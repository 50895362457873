import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { PicnicColorsToken } from '../../themes';
import { Box } from '../Box';

interface ArrowProps {
  width?: number;
  height?: number;
  edgeOffset?: number;
  fillColor?: PicnicColorsToken;
}

const ArrowWithBorder = (props: ArrowProps) => {
  const { width = 10, height = 5, edgeOffset = 0, fillColor = 'black', ...arrowProps } = props;

  const boxWidth = width + edgeOffset * 2;

  return (
    <Box css={{ width: boxWidth, height, marginTop: -4 }}>
      <svg
        {...arrowProps}
        width={width}
        height={height}
        viewBox="0 0 30 10"
        preserveAspectRatio="none"
        style={{ display: 'block', margin: '0 auto' }}
      >
        <Box as="path" d="M0,0 L15,10 L30,0" css={{ fill: fillColor }} />
      </svg>
    </Box>
  );
};

const StyledContent = styled(TooltipPrimitive.Content, {
  maxWidth: 300,
  borderRadius: '$radius2',
  padding: '$space2 $space3',
  pointerEvents: 'none',
  fontSize: '$fontSize2',
  zIndex: '$layer4',

  variants: {
    variant: {
      normal: {
        backgroundColor: '$bgTooltip',
        color: '$textInverted',
      },
      danger: {
        backgroundColor: '$bgCriticalDefault',
        color: '$textPrimary',
      },
    },
  },

  defaultVariants: {
    variant: 'normal',
  },
});

const TooltipArrow = ({ fillColor }: { fillColor: PicnicColorsToken }) => (
  <TooltipPrimitive.Arrow asChild>
    <ArrowWithBorder width={21} height={10} edgeOffset={8} fillColor={fillColor} />
  </TooltipPrimitive.Arrow>
);

const TooltipContent = ({
  variant,
  children,
  ...props
}: React.ComponentProps<typeof StyledContent>) => {
  let arrowFillColor: PicnicColorsToken = '$bgTooltip';
  if (variant === 'danger') {
    arrowFillColor = '$bgCriticalDefault';
  }

  return (
    <TooltipPrimitive.Portal>
      <StyledContent variant={variant} {...props}>
        {children}
        <TooltipArrow fillColor={arrowFillColor} />
      </StyledContent>
    </TooltipPrimitive.Portal>
  );
};

const TooltipTrigger: React.FC<React.PropsWithChildren<{ children: React.ReactElement }>> = ({
  children,
}) => <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>;

const TooltipProvider = (props: TooltipPrimitive.TooltipProviderProps) => (
  <TooltipPrimitive.Provider delayDuration={300} {...props} />
);

type TooltipProps = {
  children: React.ReactNode;
} & Pick<
  React.ComponentProps<typeof TooltipPrimitive.Root>,
  'open' | 'defaultOpen' | 'onOpenChange' | 'delayDuration'
>;

export const TooltipComponent = ({ children, ...rootProps }: TooltipProps) => (
  <TooltipPrimitive.Root {...rootProps}>{children}</TooltipPrimitive.Root>
);

type ComponentType = typeof TooltipComponent & DisplayNamed;
interface CompositeComponent extends ComponentType {
  Provider: typeof TooltipProvider & DisplayNamed;
  Trigger: typeof TooltipTrigger & DisplayNamed;
  Content: typeof TooltipContent & DisplayNamed;
}

const Tooltip = TooltipComponent as CompositeComponent;
Tooltip.Provider = TooltipProvider;
Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;

Tooltip.displayName = 'Tooltip';
Tooltip.Provider.displayName = 'Tooltip.Provider';
Tooltip.Trigger.displayName = 'Tooltip.Trigger';
Tooltip.Content.displayName = 'Tooltip.Content';

export { Tooltip };
