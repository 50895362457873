export enum DateFormat {
  ISO_8601 = 'ISO_8601',
  SHORT_DATE = 'SHORT_DATE',
  SHORT_DATE_YY = 'SHORT_DATE_YY',
  SHORT_DATE_WITH_TIME = 'SHORT_DATE_WITH_TIME',
  SHORT_DATE_YY_WITH_TIME = 'SHORT_DATE_YY_WITH_TIME',
  SHORT_MONTH = 'SHORT_MONTH',
  SHORT_MONTH_WITH_TIME = 'SHORT_MONTH_WITH_TIME',
  LONG_MONTH = 'LONG_MONTH',
  SHORT_MONTH_WITH_LOCAL_TIME = 'SHORT_MONTH_WITH_LOCAL_TIME',
  TIME_WITH_TIMEZONE = 'TIME_WITH_TIMEZONE',
  TIME_24_HR = 'TIME_24_HR',
  TIME_RELATIVE = 'TIME_RELATIVE',
  LONG_DATE = 'LONG_DATE',
  LONG_DATE_RELATIVE = 'LONG_DATE_RELATIVE',
  LONG_DATE_TIMEZONE = 'LONG_DATE_TIMEZONE',
  SHORT_DATE_WITH_LOCAL_TIME = 'SHORT_DATE_WITH_LOCAL_TIME',
  SHORT_MONTH_WITH_TIMEZONE_AT_LOCAL_TIME = 'SHORT_MONTH_WITH_TIMEZONE_AT_LOCAL_TIME',
  LONG_MONTH_WITH_ORDINAL_DAY = 'LONG_MONTH_WITH_ORDINAL_DAY',
  WEEKDAY_MONTH_DAY = 'WEEKDAY_MONTH_DAY',
  SHORT_TIMEZONE = 'SHORT_TIMEZONE',
  LONG_TIMEZONE = 'LONG_TIMEZONE',
}

export enum Timezone {
  EST = 'America/New_York',
  CST = 'America/Chicago',
  MST = 'America/Denver',
  PST = 'America/Los_Angeles',
  GMT = 'Europe/London',
  NST = 'America/St_Johns',
  AST = 'America/Halifax',
  AWT = 'Australia/Perth',
  ACWT = 'Australia/Eucla',
  ACT = 'Australia/Darwin',
  AETB = 'Australia/Brisbane',
  AET = 'Australia/Sydney',
  LHT = 'Australia/Lord_Howe',
  CEST = 'Europe/Paris',
  AZOST = 'Atlantic/Azores',
  // ACT is overloaded with Australia being the primary
  ACT1 = 'America/Rio_Branco',
  AMT = 'America/Manaus',
  BRT = 'America/Sao_Paulo',
  FNT = 'America/Noronha',
  PT = 'America/Tijuana',
  MT = 'America/Hermosillo',
  CT = 'America/Mexico_City',
  ET = 'America/Cancun',
  NZDT = 'Pacific/Auckland',
}
