import * as PopoverPrimitive from '@radix-ui/react-popover';
import { useSelect } from 'downshift';
import React from 'react';

import { styled } from '../../stitches.config';
import { DisplayNamed } from '../../storybook/utils';
import { getImpersonatedPicnicComponent } from '../../utils/impersonate-picnic-component';
import { Icon } from '../Icon';

import { SelectItemsList } from './NestedListComponents';
import {
  Menu,
  ArrowWrapper,
  SelectButton,
  SelectButtonText,
  PublicSelectItem,
  PublicSelectIconItem,
  PublicSelectThirdPartyIconItem,
  PublicSelectGroup,
} from './StyledSelectComponents';
import { useItems } from './extractItemsHook';
import { MenuItem, getItemPropsType, SelectProps, MenuItemValueType } from './types';

const PU_DOWNSHIFT_MENU_PROPS = { suppressRefError: true };
const PU_MENU_CONTENT_OFFSET = 8;
//minimum height for Select popout menu because respecting a screen size too small can render the option items to be distorted.
const PU_MENU_MIN_HEIGHT = 200;
//maximum height for Select popout menu because respecting a screen size too big can render a very long menu.
const PU_MENU_MAX_HEIGHT = 400;

const StyledPopoverContent = styled(PopoverPrimitive.Content, {
  zIndex: '$layer4',
});

// XXX: Copied from Radix as they don't export this event
type PointerDownOutsideEvent = CustomEvent<{
  originalEvent: PointerEvent;
}>;

const Value: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const SelectComponent = ({
  align = 'start',
  children = [],
  css,
  disabled,
  onChange,
  placeholder,
  value,
  size = 'medium',
  state = 'normal',
  selectedLines = 'one-line',
  ...rest
}: SelectProps) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  let displayValue: React.ReactElement | null = null;
  const otherChildren: React.ReactElement[] = [];

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    const type = getImpersonatedPicnicComponent(child);

    switch (type) {
      case Value:
        displayValue = child;
        break;
      default:
        otherChildren.push(child);
    }
  });

  const items = useItems(otherChildren);

  const itemsLookup = items.reduce(
    (prev: Record<MenuItemValueType, { item: MenuItem; index: number }>, curr, index) => {
      if (curr) {
        prev[curr.value] = {
          item: curr,
          index,
        };
      }
      return prev;
    },
    {}
  );

  const getItem = (itemValue: MenuItemValueType | undefined) => {
    if (itemValue !== undefined && itemValue !== null) {
      return items.find((item) => item && item.value === itemValue && !item.disabled) ?? null;
    }

    return null;
  };

  const handleChange = onChange as (value: MenuItemValueType | null) => void;

  const {
    isOpen,
    selectedItem = { value: '', label: '' },
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    closeMenu,
  } = useSelect({
    items,
    selectedItem: getItem(value),
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem) {
        if (!changes.selectedItem.disabled) {
          handleChange(changes.selectedItem ? changes.selectedItem.value : null);
        } else {
          handleChange(value as string);
        }
      }
    },
  });

  const availableHeightForMenu =
    typeof window === 'undefined'
      ? 0
      : window.innerHeight -
        // get the size of element to its relative position
        (buttonRef?.current?.getBoundingClientRect().bottom || 0) -
        //to match the offset at top from the select button
        PU_MENU_CONTENT_OFFSET * 2;

  return (
    <PopoverPrimitive.Root open={isOpen}>
      <PopoverPrimitive.Trigger asChild>
        <SelectButton
          css={{ width: '100%', ...css }}
          open={isOpen}
          disabledVisually={disabled}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          size={size}
          state={state}
          {...rest}
          {...getToggleButtonProps({ ref: buttonRef })}
        >
          {selectedItem === null && (
            <SelectButtonText
              selectTextState="placeholder"
              disabledVisually={disabled}
              selectedLines={selectedLines}
            >
              {placeholder}
            </SelectButtonText>
          )}
          {selectedItem !== null && (
            <SelectButtonText selectTextState="selectedItem" selectedLines={selectedLines}>
              {displayValue ? displayValue : selectedItem?.label}
            </SelectButtonText>
          )}

          <ArrowWrapper variant={isOpen ? 'up' : 'down'}>
            <Icon
              css={{ display: 'block', cursor: disabled ? 'not-allowed' : 'pointer' }}
              name="ChevronDown"
              size="small"
              description={isOpen ? 'Arrow pointing up' : 'Arrow pointing down'}
            />
          </ArrowWrapper>
        </SelectButton>
      </PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <StyledPopoverContent
          align={align}
          sideOffset={PU_MENU_CONTENT_OFFSET}
          onPointerDownOutside={(event: PointerDownOutsideEvent) => {
            // PU: Manually adds click handler to popover to control downshift more appropriately,
            // should not need this when we remove both and use radix select instead
            if (!buttonRef.current?.contains(event.target as Node)) {
              closeMenu();
            }
          }}
        >
          <Menu
            css={{
              minWidth: buttonRef?.current?.clientWidth,
              //Keeping the menu height between MIN and MAX, and a dynamic in between based on available space.
              maxHeight: Math.max(
                PU_MENU_MIN_HEIGHT,
                Math.min(availableHeightForMenu, PU_MENU_MAX_HEIGHT)
              ),
            }}
            // getMenuProps returns tabIndex: -1. This works when using downshift for the menu but causes issues when used in conjunction with radix
            {...getMenuProps({ tabIndex: 0 }, { ...PU_DOWNSHIFT_MENU_PROPS })}
            data-testid="picnic-select-list"
          >
            <SelectItemsList
              highlightedIndex={highlightedIndex}
              selectedItem={selectedItem as MenuItem}
              getItemProps={getItemProps as getItemPropsType}
              itemsLookup={itemsLookup}
              size={size}
            >
              {otherChildren}
            </SelectItemsList>
          </Menu>
        </StyledPopoverContent>
      </PopoverPrimitive.Portal>
      {/* if you Tab from menu, focus goes on button, and it shouldn't. only happens here. */}
    </PopoverPrimitive.Root>
  );
};

type ComponentType = typeof SelectComponent;
interface CompositeComponent extends ComponentType {
  Group: typeof PublicSelectGroup;
  Item: typeof PublicSelectItem;
  IconItem: typeof PublicSelectIconItem;
  ThirdPartyIconItem: typeof PublicSelectThirdPartyIconItem;
  Value: typeof Value;
}

const Select = SelectComponent as CompositeComponent & DisplayNamed;
Select.Group = PublicSelectGroup;
Select.Item = PublicSelectItem;
Select.IconItem = PublicSelectIconItem;
Select.ThirdPartyIconItem = PublicSelectThirdPartyIconItem;
Select.Value = Value;

Select.displayName = 'Select';
Select.Group.displayName = 'Select.Group';
Select.Item.displayName = 'Select.Item';
Select.IconItem.displayName = 'Select.IconItem';
Select.ThirdPartyIconItem.displayName = 'Select.ThirdPartyIconItem';
Select.Value.displayName = 'Select.Value';

export { Select };
