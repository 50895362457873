/**
 * @generated SignedSource<<4e2438d19c0385e065bff92b6e664e1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ZendeskLinkGenerateZendeskSSOTokenMutation$variables = Record<PropertyKey, never>;
export type ZendeskLinkGenerateZendeskSSOTokenMutation$data = {
  readonly generateZendeskJwt: {
    readonly jwt: string;
  } | null;
};
export type ZendeskLinkGenerateZendeskSSOTokenMutation = {
  response: ZendeskLinkGenerateZendeskSSOTokenMutation$data;
  variables: ZendeskLinkGenerateZendeskSSOTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "type": "ZENDESK_JWT_TYPE_SSO"
        }
      }
    ],
    "concreteType": "GenerateZendeskJwtPayload",
    "kind": "LinkedField",
    "name": "generateZendeskJwt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jwt",
        "storageKey": null
      }
    ],
    "storageKey": "generateZendeskJwt(input:{\"type\":\"ZENDESK_JWT_TYPE_SSO\"})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ZendeskLinkGenerateZendeskSSOTokenMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ZendeskLinkGenerateZendeskSSOTokenMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d81888d6d27e60ef04dffcc43bb2dd69",
    "id": null,
    "metadata": {},
    "name": "ZendeskLinkGenerateZendeskSSOTokenMutation",
    "operationKind": "mutation",
    "text": "mutation ZendeskLinkGenerateZendeskSSOTokenMutation {\n  generateZendeskJwt(input: {type: ZENDESK_JWT_TYPE_SSO}) {\n    jwt\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f7f69ebe26de09ba32cbf08db11f77b";

export default node;
