/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const HandWaving = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.475 3.3c.3-.525.975-.75 1.5-.375.525.3.75.975.375 1.5l-4.125 7.125a.395.395 0 0 0 .15.525c.15.075.375.075.525-.15L16.35 6c.3-.525.975-.75 1.5-.375.525.3.75.975.45 1.5l-3.45 5.925a.395.395 0 0 0 .15.525c.15.075.375.075.525-.15l2.775-4.8c.3-.525.975-.75 1.5-.375.525.3.75.975.375 1.5 0 0-5.025 8.625-5.025 8.7-1.575 2.7-5.025 3.6-7.65 2.025-.15-.075-.45-.3-.525-.375C4.2 18.15 3.45 15.975 3.6 11.775v-2.85c0-.6.525-1.125 1.125-1.125s1.125.525 1.125 1.125v3.15L10.725 3.6c.3-.525.975-.75 1.5-.375.525.3.75.975.45 1.5L9.6 9.975a.395.395 0 0 0 .15.525c.15.075.375.075.525-.15l4.2-7.05Z" />
  </svg>
);

const Memo = memo(HandWaving);
export default Memo;
