import merge from 'lodash/merge';
import React from 'react';

import { ResponsiveValue, responsiveRule } from '../../utils';
import { Box, BoxProps } from '../Box';

import { Cell } from './Cell';

interface GridProps extends BoxProps {
  columns: ResponsiveValue<number | null>;
}

const GridComponent: React.FC<React.PropsWithChildren<GridProps>> = ({
  children,
  columns,
  css = {},
  ...props
}) => (
  <Box
    css={merge({ display: 'grid' }, responsiveRule('gridTemplateColumnsRepeat', columns), css)}
    {...props}
  >
    {children}
  </Box>
);
type ComponentType = typeof GridComponent;
export interface GridCompositeComponent extends ComponentType {
  Cell: typeof Cell;
}

const Grid = GridComponent as GridCompositeComponent;
Grid.Cell = Cell;

Grid.displayName = 'Grid';
Grid.Cell.displayName = 'Grid.Cell';

export type { GridProps };
export { Grid };
