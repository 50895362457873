import { useMediaQuery } from '@react-hook/media-query';
import React, { useContext } from 'react';
import { PolymorphicComponent } from 'react-polymorphic-box';

import { media } from '../../media';
import { PicnicCss, styled } from '../../stitches.config';
import { compositeComponent } from '../../utils/composite-component';
import { BoxProps } from '../Box';
import { Button, ButtonProps } from '../Button';
import { Heading } from '../Heading';
import { Text } from '../Text';

export type HeaderVariant = 'responsive' | 'inline' | 'stacked';

const LayoutVariantContext = React.createContext<HeaderVariant | undefined>(undefined);

interface PageHeaderProps extends BoxProps {
  variant?: HeaderVariant;
}

const PageHeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  columnGap: '$space4',
  rowGap: '$space4',

  variants: {
    variant: {
      inline: {
        flexDirection: 'row',
      },
      stacked: {
        flexDirection: 'column',
      },
    },
  },
});

const TextContainer = styled('div', { display: 'flex', flexDirection: 'column' });

const ButtonContainer = styled('div', {
  display: 'flex',
  flexShrink: 0,
  flexWrap: 'wrap',
  rowGap: '$space4',
  columnGap: '$space4',
});

const HeadingComponent: React.FC<
  React.PropsWithChildren<{
    css?: PicnicCss;
  }>
> = (props) => {
  return <Heading variant="page" {...props} />;
};

const Description: React.FC<React.PropsWithChildren<{ css?: PicnicCss }>> = ({ css, ...rest }) => {
  return <Text variant="body" css={{ mt: '$space3', ...css }} {...rest} />;
};

const ButtonComponent: PolymorphicComponent<ButtonProps, 'button'> = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(({ css, ...rest }, ref) => {
  const variant = useContext(LayoutVariantContext);
  const size = variant === 'stacked' ? 'small' : 'medium';

  return <Button size={size} ref={ref} css={{ flexShrink: 0, ...css }} {...rest} />;
});

const useResolvedVariant = (variant: HeaderVariant): Exclude<HeaderVariant, 'responsive'> => {
  const atBp3 = useMediaQuery(media.bp3);

  // Non-responsive variants don't need to be resolved further.
  if (variant !== 'responsive') {
    return variant;
  }

  if (atBp3) {
    return 'inline';
  }

  return 'stacked';
};

const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({
  variant = 'responsive',
  ...rest
}) => {
  const resolvedVariant = useResolvedVariant(variant);

  return (
    <LayoutVariantContext.Provider value={resolvedVariant}>
      <PageHeaderContainer variant={resolvedVariant} {...rest} />
    </LayoutVariantContext.Provider>
  );
};

const PageHeaderNamespace = compositeComponent(PageHeader, {
  Heading: HeadingComponent,
  Description,
  Button: ButtonComponent,
  TextContainer,
  ButtonContainer,
});

export { PageHeaderNamespace as PageHeader };
