import React from 'react';

import {
  Box,
  Text,
  Heading,
  PicnicCss,
  ResponsiveImage,
  usePicnicStyles,
  DEFAULT_THEME,
} from '@attentive/picnic';

import appErrorSrc from '../assets/platform-unavailable.png';

const rootStyles: PicnicCss = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '$space6',
};

const PU_MAX_IMAGE_WIDTH = 660;

const AppError: React.FC = ({ children }) => {
  let errorMessage = (
    <Text>
      Our app encountered an error. We’re working on a solution &mdash; please check back soon!
    </Text>
  );

  if (children) {
    if (typeof children === 'string') {
      errorMessage = <Text>{children}</Text>;
    } else {
      errorMessage = children as JSX.Element;
    }
  }

  return (
    <Box id="app-error" css={rootStyles}>
      <ResponsiveImage
        ratio={1333 / 801}
        alt="Platform unavailable"
        src={appErrorSrc}
        css={{ width: '100%', maxWidth: PU_MAX_IMAGE_WIDTH, mb: '$space8' }}
      />
      <Heading variant="page" css={{ mb: '$space4' }}>
        Hmm... something's not right.
      </Heading>
      <Box css={{ mb: '$space4' }}>{errorMessage}</Box>
    </Box>
  );
};

export const InitError: React.FC = ({ children }) => {
  usePicnicStyles(DEFAULT_THEME);

  return <AppError>{children}</AppError>;
};
