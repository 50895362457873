import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Heading, Link, ResponsiveImage, Stack, Text } from '@attentive/picnic';

import notFoundImage from '../../assets/not-found.png';
import { contentWidth } from './constants';

const NotFound: React.FC = () => (
  <Stack
    css={{
      alignItems: 'center',
      marginTop: '$space5',
    }}
  >
    <ResponsiveImage
      ratio={1288 / 840}
      alt="Page Not Found"
      src={notFoundImage}
      css={{ width: '100%', maxWidth: contentWidth, mb: '$space8' }}
    />
    <Heading variant="page">404</Heading>
    <Heading variant="page">Page not found</Heading>
    <Text>Sorry, we can’t find the page you’re looking for.</Text>
    <Link as={RouterLink} to="/">
      Click here to return.
    </Link>
  </Stack>
);

export default NotFound;
