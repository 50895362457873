/*
    This is a generated file. Do not modify.
    To learn more about how to sync icon files with Figma, run: yarn figma:icons --help
  */
import React, { SVGProps, memo } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const Expand = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg viewBox="0 0 24 24" aria-labelledby={titleId} {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10 2H2v8h2V4h6V2ZM22 10V2h-8v2h6v6h2ZM22 22h-8v-2h6v-6h2v8ZM2 14v8h8v-2H4v-6H2Z" />
  </svg>
);

const Memo = memo(Expand);
export default Memo;
